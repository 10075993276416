import { Routes } from '@angular/router';
import { ActiveLinkGuard } from 'src/app/shared-components/core/active-link.guard';
import { AppIntegrationComponent } from './app-integration.component';
import { IntegrationsComponent } from './integrations.component';

export const INTEGRATION_ROUTES: Routes = [
    {
        path: 'apps',
        component: IntegrationsComponent,
        children: [
            {
                path: ':app-alias/integrations',
                component: AppIntegrationComponent,
                data: {
                    label: 'Apps'
                },
                resolve: [ActiveLinkGuard]
            }
        ]
    }
];
