<section id="get-started-section">
    <div class="main-container">
        <div class="column-layout c-2-1-1">
            <header class="get-started-header">
                <h3 class="get-started-caption caption">Ready to use Quickwork?</h3>
                <p class="get-started-description">Get started by creating your free account now. You can also schedule a call with our team for a tour of our platform. </p>
                <div class="get-started-cta">
                    <a [href]="platformURL" target="_blank" class="button filled">
                        Get started
                        <i class="fas fa-chevron-right"></i>
                    </a>
                    <!-- <button type="button" class="button plain">
                        Schedule a Platform Tour
                        <i class="fas fa-chevron-right"></i>
                    </button> -->
                </div>
            </header>
            <div class="column">
                <div class="column-icon icon">
                    <i class="fad fa-question-circle"></i>
                </div>
                <p class="column-title text-bold">Access Support Center</p>
                <p class="column-description p-3">Get access to a vast library of helpful articles and FAQs to resolve your doubts and queries.
                </p>
                <a [href]="supportCenterURL" target="_blank" class="button plain learn-more">
                    Learn More
                    <i class="fas fa-chevron-right"></i>
                </a>
            </div>
            <div class="column">
                <div class="column-icon icon">
                    <i class="fad fa-rocket-launch"></i>
                </div>
                <p class="column-title text-bold">App Directory</p>
                <p class="column-description p-3">Browse our rich app directory of 1,000+ apps from business, consumer, AI, analytic, messaging and IoT categories.</p>
                <a href="" class="button plain learn-more" routerLink="/product/app-directory">
                    Learn More
                    <i class="fas fa-chevron-right"></i>
                </a>
            </div>
        </div>
    </div>
</section>