import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntegrationAppsComponent } from './animated images/integration-apps/integration-apps.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared.module';
import { ConversationManagementComponent } from './animated images/conversation-management/conversation-management.component';



@NgModule({
  declarations: [IntegrationAppsComponent, ConversationManagementComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  exports: [IntegrationAppsComponent, ConversationManagementComponent]
})
export class SharedImagesModule { }
