<div [class]="'qw-alert '+type" *ngIf="showAlertBox">
    <div class="d-table-cell" style="vertical-align: top;">
        <img *ngIf="type != 'success' && type != 'info'" src="../../../content/images/svg/noun_information_icon.svg"
            alt="">
        <img *ngIf="type == 'success'" src="../../../content/images/svg/success.svg" alt="">
        <img *ngIf="type == 'info'" src="../../../content/images/svg/information_icon.svg" alt="">
    </div>
    <div class="d-table-cell qw-feedback-content">
        <ng-container *ngIf="!isHTML">
            {{feedbackContent}}
        </ng-container>
        <ng-container *ngIf="isHTML">
            <ng-template *ngTemplateOutlet="feedbackContentTemplate"></ng-template>
        </ng-container>
    </div>
</div>
<div *ngIf="!showAlertBox" class="qw-no-alert-box">
    <ng-container *ngIf="type == 'danger'">
        <img src="../../../content/images/svg/failure_1.svg" alt="" srcset="">
        <h4 class="qw-text-red">{{feedbackContent}}</h4>
    </ng-container>
</div>