<div id="privacy-policy-container" class="header-wave">
    <div class="main-container">
        <div class="privacy-policy">
            <div class="m-b-20">
                <div class="highlight-text">Terms Of Service</div>
            </div>
            <div class="policy-content">
                <p>Revision Date: {{revisionDate}}</p>
                <p class="c19"><span class="c25 c28">&nbsp;</span></p>
                <p class="c57 c34"><span class="c15 c36 c32"></span></p>
                <!-- <p class="c57"><span class="c15 c36 c32">Terms of Service</span></p> -->
                <p class="c21">
                    <span class="c1">Welcome to our Terms of Service (“Agreement”, “Terms and Conditions”,
                        “Terms of Use” and “Terms of Service” are names all used to refer to this document). It is
                        necessary for
                        you to read this carefully and agree to all terms herein before you start using the Quickwork
                        Platform
                        (defined hereinbelow). You can reach us at support@quickwork.co if you have any queries with
                        respect to
                        the use of Quickwork Platform.
                    </span>
                </p>
                <br>
                <p class="c21">
                    <span class="c9">This</span>
                    <span class="c9">&nbsp;Terms of Service govern the use and
                        access
                        of the
                        Quickwork Platform and the Products and Services provided thereunder (defined hereinbelow). This
                        Terms of
                        Service constitutes the legal and binding agreement between Customer (defined hereinbelow) and
                        Quickwork
                        (defined hereinbelow), </span><span>(hereinafter collectively referred to as the </span><span
                        class="c9">“</span><span class="c15">Parties'</span><span class="c9">” and each individually as
                        the
                        “</span><span class="c15">Party</span><span class="c1">”), with respect to Customer's use of the
                        Quickwork
                        Platform. This Agreement comes into effect on the date the Customer clicks the “Register” or any
                        other
                        button in the course of opening an online account with Quickwork (the “Commencement Date”).
                    </span>
                </p>
                <br>
                <p class="c21">
                    <strong class="c62 c15 c32">NOW, THEREFORE, IN CONSIDERATION OF MUTUAL UNDERSTANDINGS
                        CONTAINED
                        HEREINAFTER, THE PARTIES HERETO, INTENDING LEGALLY TO BE BOUND HEREBY, AGREE AS FOLLOWS:</strong>
                </p>
                <br>
                <p class="c21 c34"><span class="c15 c32 c62"></span></p>
                <p class="c21">
                    <span class="c1">These capitalized terms shall have the following meanings ascribed to
                        them:</span>
                </p>
                <br>
                <table class="c51" border="1">
                    <tbody>
                        <tr class="c61">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">API</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means application programming interface.</span></p>
                            </td>
                        </tr>
                        <tr class="c18">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Business Day</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means a day other than a Saturday, Sunday or public
                                        holiday
                                        in
                                        Mumbai, India as listed by the Reserve Bank of India on the website
                                        <a href="https://rbi.org.in">https://rbi.org.in</a> .</span></p>
                            </td>
                        </tr>
                        <tr class="c27">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Clients</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means the customers, users or clients of the
                                        Customer.</span>
                                </p>
                            </td>
                        </tr>
                        <tr class="c18">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Commencement Date</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c9">means the date</span><span class="c1">&nbsp;the Customer
                                        clicks the
                                        “Register” or similar button in the process of opening an online account with
                                        Quickwork.</span></p>
                            </td>
                        </tr>
                        <tr class="c55">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Confidential Information</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means and includes, without limitation, any software,
                                        data,
                                        technical, business, financial, operational, customer, vendor or other
                                        information
                                        disclosed
                                        or provided by a Party or any of its affiliates, and its and their employees,
                                        directors,
                                        officers, advisors, consultants, subcontractors or other agents or
                                        representatives
                                        to a
                                        Party or any of its representatives, whether before or after the Commencement
                                        Date,
                                        whether
                                        in writing, orally, visually (or other non-tangible formats) or any other means.
                                        Notwithstanding the foregoing, the term “Confidential Information” will not, for
                                        purposes of
                                        this Agreement, include information that: (a) was or becomes available to a
                                        Party on
                                        a
                                        non-confidential basis from a source other than a Party or its representatives;
                                        provided
                                        that such source is not bound by any confidentiality agreement with, or other
                                        contractual,
                                        legal or fiduciary obligation of secrecy to a Party; (b) at the time of
                                        disclosure
                                        or
                                        thereafter is or becomes available to and widely known by the public as to be
                                        reasonably
                                        regarded as public information, other than as a result of disclosure by a Party
                                        or
                                        any of
                                        its representatives in breach of this Agreement; (c) is developed by a Party
                                        independently
                                        of any disclosure hereunder or reference to a Party's Confidential Information,
                                        as
                                        evidenced
                                        by a Party's records, and without violating any of a Party's obligations under
                                        this
                                        Agreement; or (d) is disclosed by a Party with disclosing Party's prior written
                                        express
                                        approval.</span></p>
                            </td>
                        </tr>
                        <tr class="c18">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Connector</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means an interface developed for an application using
                                        APIs
                                        which
                                        enables connectivity of other applications and services with that
                                        application.</span></p>
                            </td>
                        </tr>
                        <tr class="c16">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Customer</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">shall include any person, firm, company, or entity that
                                        is a
                                        user or
                                        purchaser of Products and Services offered and provided by Quickwork, and all
                                        persons,
                                        firms, companies, or entities which control, or which are controlled by, the
                                        same
                                        person,
                                        firm or entity which controls such use or purchase.</span></p>
                            </td>
                        </tr>
                        <tr class="c27">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Customer Application</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means any application or service developed by the
                                        Customer
                                        that uses
                                        the Platform.</span></p>
                            </td>
                        </tr>
                        <tr class="c18">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Customer Data</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means electronic or digital content, data and information
                                        input into
                                        the Platform by the Customer/User or collected through the Platform by
                                        Customer/User.</span>
                                </p>
                            </td>
                        </tr>
                        <tr class="c29">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Customer Order</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means an order by the Customer for access to the Platform
                                        entered
                                        through Quickwork's online registration process on the Website and includes any
                                        order for
                                        the Products and Services, whether free or paid, including any approved
                                        quotation
                                        and/or
                                        proposal, submitted or made online or offline by the Customer through any
                                        ordering
                                        process
                                        as accepted by Quickwork. The Customer Order includes the Customization
                                        Order.</span></p>
                            </td>
                        </tr>
                        <tr class="c18">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Customization Fees</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means the fees payable by the Customer as determined by
                                        Quickwork
                                        while placing the optional Customization Order as a part of the Customer Order,
                                        through the
                                        Website or the Platform.</span></p>
                            </td>
                        </tr>
                        <tr class="c8">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Customization Order</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means the statement of work specified and assigned by the
                                        Customer to
                                        Quickwork for customization and covers project-wise requirements, activities,
                                        deliverables,
                                        and timelines, and forms a part of the Customer Order.</span></p>
                            </td>
                        </tr>
                        <tr class="c18">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Data Protection Agreement</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c9">refers to the agreement between the Customer and
                                        Quickwork
                                        and
                                        located on the Website at </span><span class="c35 c9 c42"><a class="c0"
                                            href="/legal/dpa">https://quickwork.co/legal/dpa</a></span>
                                </p>
                            </td>
                        </tr>
                        <tr class="c18">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Dealer</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">shall include any person, firm, company, or entity that
                                        is
                                        authorized
                                        by Quickwork to sell, resell, distribute, and support Products and Services in
                                        the
                                        Territory.</span></p>
                            </td>
                        </tr>
                        <tr class="c18">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Documentation</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means any user guides, manuals, handbooks, materials,
                                        instructions,
                                        and specifications made available by Quickwork to the Customer for using the
                                        Platform.</span></p>
                            </td>
                        </tr>
                        <tr class="c18">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">First Level Support</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means initial support and maintenance services provided
                                        to a
                                        Client
                                        by the Customer or Dealer or Channel Partner.</span></p>
                            </td>
                        </tr>
                        <tr class="c18">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Hosting Service</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means a service whereby applications are hosted on remote
                                        cloud
                                        infrastructure and are accessed through the internet which can be provisioned by
                                        Quickwork
                                        as per the Customer Order.</span></p>
                            </td>
                        </tr>
                        <tr class="c2">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Intellectual Property</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">shall mean and refers to ideas, concepts, creations,
                                        discoveries,
                                        domain names, inventions, improvements, know-how, trade or business secrets;
                                        patents,
                                        copyright (including all copyright in any designs and any moral rights),
                                        trademarks,
                                        service
                                        marks, designs, utility models, tools, devices, models, methods, procedures,
                                        processes,
                                        workflows, systems, principles, algorithms, works of authorship, flowcharts,
                                        drawings,
                                        books, papers, models, sketches, formulas, teaching techniques, source codes,
                                        object
                                        codes,
                                        electronic codes, proprietary techniques, research projects, and other
                                        confidential
                                        and
                                        proprietary information, computer programming code, databases, images, audio,
                                        video,
                                        software programs, data, documents, instruction manuals, records, memoranda,
                                        notes,
                                        user
                                        guides; in either printed or machine-readable form, whether or not copyrightable
                                        or
                                        patentable, or any written or verbal instructions or comments.</span></p>
                            </td>
                        </tr>
                        <tr class="c18">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">New Connector</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means a Connector requested by the Customer to be built
                                        by
                                        Quickwork
                                        which is publicly accessible and made available for use on the Platform by any
                                        or
                                        all
                                        Users.</span></p>
                            </td>
                        </tr>
                        <tr class="c20">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Personal Data</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means any information relating to an identified or
                                        identifiable
                                        natural person that is processed by Quickwork as a result of, or in connection
                                        with,
                                        the
                                        provision of the services as per the Customer Order on the Platform; an
                                        identifiable
                                        natural
                                        person can be identified, directly or indirectly, in particular by reference to
                                        an
                                        identifier such as a name, identification number, location data, an online
                                        identifier or to
                                        one or more factors specific to the physical, physiological, genetic, mental,
                                        economic,
                                        cultural or social identity of that natural person.</span></p>
                            </td>
                        </tr>
                        <tr class="c8">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Platform Fees</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means the total fees payable by the Customer to Quickwork
                                        which
                                        includes Subscription Fees, Customization Fees, Support Fees, and any other
                                        applicable fees
                                        as per the Customer Order and Customization Order.</span></p>
                            </td>
                        </tr>
                        <tr class="c8">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Private Connector</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means a Connector developed by Quickwork for a Customer
                                        as
                                        per
                                        Customization Order, is privately accessible and made available for use on the
                                        Platform only
                                        by the Customer placing that Customization Order.</span></p>
                            </td>
                        </tr>
                        <tr class="c8">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Products and Services</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means the provision of the Platform for subscription,
                                        customization,
                                        and support as per Customer Order and Customization Order including tools,
                                        software,
                                        channels, data feeds, computer programs, applications and services offered or
                                        designed by
                                        Quickwork.</span></p>
                            </td>
                        </tr>
                        <tr class="c18">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Public Connector</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means a Connector developed and maintained by Quickwork,
                                        is
                                        publicly
                                        accessible and made available for use on the Platform by any or all
                                        Users.</span>
                                </p>
                            </td>
                        </tr>
                        <tr class="c18">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Quickwork</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">refers to Quickwork Technologies Private Limited, 404,
                                        Matharu
                                        Arcade, Subhash Road, Vile Parle East, Mumbai 400057, Maharashtra, India.</span>
                                </p>
                            </td>
                        </tr>
                        <tr class="c60">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Quickwork Brand</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means and refers to any/all logos, signs, symbols,
                                        designs,
                                        icons,
                                        terms, product names, brand names, trademarks, wordmarks, copyrights owned or
                                        under
                                        application process for registration anywhere in the world by Quickwork
                                        including
                                        content in
                                        text, images, audio, video formats in any language used on websites, portals,
                                        products owned
                                        or published by Quickwork and all promotional materials and content used by
                                        Quickwork for
                                        marketing, advertising, business promotion, sales or public relations; in either
                                        printed or
                                        machine-readable form, whether or not copyrightable or patentable.</span></p>
                            </td>
                        </tr>
                        <tr class="c16">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Quickwork Platform or Platform</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c9">means Quickwork's proprietary, online, cloud-based
                                        integration,
                                        automation, API management and </span><span class="c9">conversation</span><span
                                        class="c1">&nbsp;management platform along with applications, services, and
                                        tools,
                                        offered
                                        as Software-as-a-Service (SaaS) to the Customer and includes all future
                                        modifications,
                                        additions and enhancements made to it by Quickwork from time to time.</span></p>
                            </td>
                        </tr>
                        <tr class="c18">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Quickwork Support</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means the provision of a response to problems by
                                        Quickwork
                                        through
                                        the support channels provided on the Platform.</span></p>
                            </td>
                        </tr>
                        <tr class="c18">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Service Level Agreement (SLA)</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means the service level agreement offered by Quickwork
                                        and
                                        located on
                                        the Website at https://quickwork.co/service-level-agreement/.</span></p>
                            </td>
                        </tr>
                        <tr class="c8">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Standard</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">mean any technical specification that is distributed,
                                        published, or
                                        otherwise made available by a consortium, standards organization, special
                                        interest
                                        group, or
                                        like entity, for the purpose of widespread industry adoption.</span></p>
                            </td>
                        </tr>
                        <tr class="c18">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Subscription Fees</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">the fees payable by the Customer as determined at the
                                        time of
                                        subscription while placing Customer Order through the Website or the
                                        Platform.</span></p>
                            </td>
                        </tr>
                        <tr class="c27">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Support</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means support services as per the Support
                                        Agreement.</span>
                                </p>
                            </td>
                        </tr>
                        <tr class="c18">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Support Agreement</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means the standard terms of support services offered by
                                        Quickwork and
                                        located on the Website at https://quickwork.co/support-agreement/.</span></p>
                            </td>
                        </tr>
                        <tr class="c18">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Support Fees</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means the fees payable by the Customer for availing
                                        Support
                                        from
                                        Quickwork as per the Customer Order through the Website or the Platform.</span>
                                </p>
                            </td>
                        </tr>
                        <tr class="c8">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Term</span></p>
                            </td>
                            <td class="c17 c25" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means the time period which starts on the Commencement
                                        Date
                                        and
                                        continues up to the date as specified in a Customer Order or up to the date the
                                        account of a
                                        Customer is deleted by the Customer or terminated by Quickwork.</span></p>
                            </td>
                        </tr>
                        <tr class="c18">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Territory</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means the sales area within which a Dealer is permitted
                                        to
                                        sell,
                                        resell, distribute, and support the Products and Services.</span></p>
                            </td>
                        </tr>
                        <tr class="c18">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Third-Party Application</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means any application or service developed by any person,
                                        firm,
                                        company, or entity other than Quickwork or the Customer.</span></p>
                            </td>
                        </tr>
                        <tr class="c8">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">User</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means and includes individuals, employees, agents, and
                                        independent
                                        contractors associated with the Customer who use the Platform on Customer's
                                        behalf
                                        or
                                        through Customer's account or passwords, whether authorized or not.</span></p>
                            </td>
                        </tr>
                        <tr class="c27">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Visitor</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c1">means any person or entity who visits or accesses the
                                        Website.</span>
                                </p>
                            </td>
                        </tr>
                        <tr class="c27">
                            <td class="c7" colspan="1" rowspan="1">
                                <p class="c26"><span class="c1">Website</span></p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1">
                                <p class="c3"><span class="c9">means the website which is owned, operated, and managed
                                        by
                                        Quickwork
                                        at </span><span class="c35 c9"><a class="c0"
                                            href="">https://quickwork.co</a></span><span
                                        class="c1">.</span></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br>
                <br>
                <ol class="c13 lst-kix_list_10-0 start" type="1">
                    <li class="c5 li-bullet-0"><strong class="c15">Acceptance</strong></li>
                    <ol class="c13 lst-kix_list_23-0 start" type="i">
                        <li class="c24 c40 li-bullet-0"><span class="c9 c32">The Customer acknowledges that it &nbsp;has
                                read
                                the Terms
                                of Service, understands them, and agrees to be bound by the terms as mentioned in this
                                Terms
                                of
                                Service.
                                The person accepting this agreement on the Customers behalf &nbsp;through the online
                                registration
                                process on Quickwork</span><span class="c9">'</span><span class="c1">s Platform or by
                                means
                                of a
                                Customer Order represents that he has the authority to bind the customer to this Terms
                                of
                                Service.</span></li>
                        <li class="c24 c40 li-bullet-0"><span class="c9 c32">The Customer further acknowledges that this
                                Agreement does
                                not have to be signed</span><span class="c9">, physically or digitally, </span><span
                                class="c1">in order
                                to be binding. The Customer will have indicated its assent to the terms of the Agreement
                                by
                                clicking on
                                the "Register" or “Sign In” or similar button that is presented to the Customer at the
                                time
                                of
                                its
                                opening or accessing of an online account with Quickwork or by executing and
                                transmitting to
                                Quickwork a
                                Customer Order.</span></li>
                    </ol>
                    <br>
                    <li class="c5 li-bullet-0"><strong class="c15">The Platform</strong></li>
                    <ol class="c13 lst-kix_list_24-0 start" type="i">
                        <li class="c24 c40 li-bullet-0"><span class="c1">Use of the Platform: During the Term, the
                                Customer
                                may
                                access
                                or use the Platform, subject to:</span></li>
                        <ol class="c13 lst-kix_list_16-0 start" type="a">
                            <li class="c39 li-bullet-0"><span class="c9">t</span><span class="c1">he terms as mentioned
                                    in the
                                    relevant and
                                    applicable Customer Order and the details regarding any features, function and
                                    limitations
                                    provided
                                    thereunder and/or</span></li>
                            <li class="c39 li-bullet-0"><span class="c9 c32">Acceptable Use Policy (located on the
                                    Website at
                                </span><span class="c35 c9 c42"><a class="c0"
                                        href="/acceptable-usage-policy">https://quickwork.co/acceptable-usage-policy</a></span><span
                                    class="c9 c32">)or any other policy (as updated from time to time) posted on the
                                    Website
                                    (</span><span class="c35 c9"><a class="c0"
                                        href="">https://quickwork.co</a></span><span
                                    class="c1">).</span></li>
                        </ol>
                        <li class="c39 li-bullet-0"><span class="c9">Use of Documentation: The Customer may use and
                                reproduce
                                the
                                Documentation only to the extent as may be necessary to support the Customer's or User's
                                &nbsp;interactions with and use of the Quickwork Platform. Revisions to the Platform:
                                Quickwork
                                may at
                                any time, with or without notice, revise any of the features and functions that it
                                provides
                                in
                                its
                                Platform, including removing such features and functions completely. If such revision to
                                the
                                Platform
                                materially reduces the features and functionality which was mentioned in the Customer
                                Order,
                                the
                                Customer may within 30 (thirty) days after providing notice, terminate such Customer
                                Order,
                                or
                                terminate
                                this Agreement if such Customer Order is the only one outstanding. However, its agreed
                                by
                                the
                                User,
                                where </span><span>&nbsp;</span><span class="c1">You are accessing the Platform, as an
                                employee,
                                service
                                provider, customer, client or agent of a Customer, the term, and conditions of the
                                agreement
                                between the
                                relevant Customer and Quickwork shall apply and supersede the provisions of this
                                Clause,</span>
                        </li>
                    </ol>
                    <br>
                    <li class="c5 li-bullet-0"><strong class="c15">Terms of payment and fees.</strong></li>
                    <ol class="c13 lst-kix_list_25-0 start" type="i">
                        <li class="c24 c40 li-bullet-0"><span class="c1">The Customer shall pay to Quickwork the
                                applicable
                                Platform
                                Fees in lieu of the access to the Platform and use of the Products and Services in
                                accordance
                                with each
                                Customer Order or as may have been agreed upon by means of an executed Customer
                                Order.</span>
                        </li>
                        <li class="c24 c40 li-bullet-0"><span class="c1">Unless specified in the Customer Order, the:
                                (a)
                                Platform Fees
                                paid are non-refundable and payment obligations are non-cancelable; (b) where Quickwork
                                choses
                                to modify
                                the Platform Fees in its sole discretion, the Platform Fees payable by You shall be the
                                Platform
                                Fees as
                                identified specifically on the Platform or provided by Quickwork to you in
                                writing,</span>
                        </li>
                        <li class="c24 c40 li-bullet-0"><span class="c1">Customer acknowledges that, if Customer
                                receives
                                access
                                to the
                                Quickwork Platform through an authorized Dealer and that if such Dealer is in breach of
                                its
                                obligations
                                to Quickwork (including a failure to pay fees owed to Quickwork), then Quickwork may at
                                its
                                sole
                                discretion terminate this Agreement and discontinue to provide the access to the
                                Platform to
                                Customer
                                without incurring any liability to either the Customer or the Dealer.</span></li>
                        <li class="c24 c40 li-bullet-0"><span class="c1">The Customer authorizes Quickwork to charge an
                                amount
                                equal to
                                the Platform Fees on a monthly/quarterly/annual basis (unless the relevant Customer
                                Order
                                provides
                                otherwise), to the credit card and/or bank accounts as may be specified in the Customer
                                Order in
                                advance
                                of the provision of services to the Customer by means of automatic debit or credit card
                                charge
                                for the
                                duration the Term. The Customer hereby agrees to keep such valid credit card and/or bank
                                account
                                in
                                effect with sufficient credit limit to enable Quickwork to charge the Platform Fees
                                every
                                month/quarter/annual period, as applicable, and not to challenge such charges or to
                                request
                                reversal of
                                such charges. Quickwork will not be required to refund the Platform Fees under any
                                circumstances.</span>
                        </li>
                        <li class="c24 c40 li-bullet-0"><span class="c9 c32">Unless otherwise stated, Quickwork's
                                Platform
                                Fees
                                do not
                                include any taxes, levies, duties</span><span class="c9">, </span><span class="c1">or
                                similar
                                governmental assessments of any nature, including but not restricted to value-added,
                                sales
                                and
                                use, or
                                withholding taxes, assessable by any local, state, provincial, federal, or foreign
                                jurisdiction
                                (collectively, "Taxes"). The Customer is responsible for paying all Taxes associated
                                with
                                its
                                purchases
                                hereunder. If Quickwork has the legal obligation to pay or collect Taxes for which
                                Customer
                                is
                                responsible under this paragraph, Customer shall indemnify Quickwork for such taxes and
                                the
                                appropriate
                                amount shall be invoiced to and paid by Customer, unless Customer provides Quickwork
                                with a
                                valid tax
                                exemption certificate authorized by the appropriate taxing authority.</span></li>
                    </ol>
                    <br>
                    <li class="c5 li-bullet-0"><strong class="c15">Customer's Responsibilities and
                            Restrictions</strong><span class="c1">:</span></li>
                    <ol class="c13 lst-kix_list_19-0 start" type="i">
                        <li class="c12 li-bullet-0"><span class="c9 c32">The Customer shall comply with the Acceptable
                                Use
                                Policy (AUP)
                                as available at </span><span class="c35 c9 c42"><a class="c0"
                                    href="/acceptable-usage-policy">https://quickwork.co/acceptable-usage-policy</a></span><span
                                class="c1">.</span></li>
                        <li class="c12 li-bullet-0"><span class="c1">The Customer shall NOT:</span></li>
                        <ol class="c13 lst-kix_list_1-0 start" type="a">
                            <li class="c10"><span class="c1">Misuse the Platform and/or the Website, or authorize any
                                    third
                                    party
                                    to do so.</span></li>
                            <li class="c10"><span class="c1">Provide Platform passwords and other login credentials to
                                    any
                                    third
                                    party and enable them to
                                    access
                                    or
                                    derive
                                    benefit out of the Platform.</span></li>
                            <li class="c10"><span class="c1">Share the non-public features of the Platform with any
                                    third
                                    party.</span></li>
                            <li class="c10"><span class="c1">Access the Platform and/or the Website in order to build a
                                    similar
                                    or
                                    competitive product or
                                    service, take or copy ideas from Quickwork Brand including, but not limited to
                                    graphics,
                                    designs,
                                    features,
                                    and functions.</span></li>
                        </ol>
                    </ol>
                    <p class="c43"><span class="c1">And in event of a breach or where it is suspected that there is a
                            breach
                            of
                            this
                            Article [4(i) and 4(ii)], Quickwork may suspend Customer's access to the Platform and/or the
                            Website, with
                            or without advance notice and without prejudice to the other remedies that Quickwork may
                            have.</span></p>
                    <br>
                    <ol class="c13 lst-kix_list_19-0" start="3" type="i">
                        <li class="c12 li-bullet-0"><span class="c1">The Customer shall take all reasonable steps to
                                prevent
                                unauthorized access to the Platform including but not limited to protecting the
                                passwords
                                and
                                other
                                login credentials.</span></li>
                        <li class="c12 li-bullet-0"><span class="c1">The Customer shall notify Quickwork of any
                                suspected
                                unauthorized
                                use of the Platform immediately or as soon as reasonably possible and shall use
                                reasonable
                                means
                                to stop
                                the said breach.</span></li>
                        <li class="c12 li-bullet-0"><span class="c9 c32">The Customer will immediately abstain using and
                                delete
                                any
                                product or service including all access provided by the Customer, which is &nbsp; built
                                by
                                him
                                by taking
                                or copying ideas from Quickwork Brand or Platform upon information of such activity by
                                Quickwork
                                or its
                                legal representatives .Quickwork further reserves the right to proceed with any such
                                suitable
                                action as
                                it may deem fit to restrict and ensure withdrawal of all such product and services and
                                recover
                                the costs
                                and damages incurred as a result of this breach</span><span class="c9">.</span></li>
                        <li class="c12 li-bullet-0"><span class="c1">In its use of the Platform, the Customer shall
                                follow
                                all
                                applicable laws and regulations including, but not limited to laws governing the
                                protection
                                of
                                Personal
                                Data and other laws applicable to the protection of Customer Data.</span></li>
                        <li class="c12 li-bullet-0"><span class="c1">The Customer is responsible and liable for:</span>
                        </li>
                        <ol class="c13 lst-kix_list_19-0" type="a">
                            <li class="c22"><span class="c1">User's use of and interactions with the Platform and the
                                    Website
                                    including, but not limited to,
                                    unauthorized User conduct and any User conduct that would violate the AUP or the
                                    requirements of
                                    this
                                    Agreement attributable to the Customer.</span>
                            </li>
                            <li class="c22"><span class="c1">Any use of Platform through Customer's account, whether
                                    authorized
                                    or unauthorized.</span>
                            </li>
                        </ol>
                    </ol>
                    <br>
                    <li class="c5 li-bullet-0"><strong class="c15">Customer Data and Privacy</strong></li>
                    <ol class="c13 lst-kix_list_26-0 start" type="i">
                        <li class="c45 c40 li-bullet-0"><span class="c1">Use of Customer Data:</span></li>
                        <p class="c43"><span class="c1">Unless Quickwork receives the prior consent of the Customer,
                                Quickwork:
                            </span></p>
                        <ol class="c13 lst-kix_list_26-0 start" type="a">
                            <li class="c10"><span class="c1"> Shall not access, process, or
                                    otherwise use
                                    Customer Data other than as may be necessary to perform, operate or facilitate the
                                    Platform,
                                    to
                                    provision
                                    and provide Products and Services and to improve the overall customer experience on
                                    the
                                    Platform.</span></li>
                            <li class="c10"><span class="c1"> Shall not intentionally grant
                                    any
                                    third party
                                    access to Customer Data, including but not limited to, other Customers, except
                                    subcontractors
                                    that
                                    are
                                    subject to a reasonable nondisclosure agreement.</span></li>
                            <li class="c10"><span class="c1"> Notwithstanding the foregoing,
                                    Quickwork may
                                    disclose Customer Data as required by applicable law or by proper legal or
                                    governmental
                                    authority.
                                    Quickwork
                                    may give Customer &nbsp;notice, if permitted by applicable law, of any such legal or
                                    governmental
                                    demand.
                                </span></li>
                        </ol>
                        <li class="c45 c40 li-bullet-0"><span class="c9">The </span><span class="c9 c37">Privacy Policy
                            </span><span class="c1">shall only apply to the Platform and the Website and does not apply
                                to
                                any
                                Third-Party
                                Application or other websites, or services linked to the Platform and/or Website or
                                being
                                recommended or
                                referred to through the Platform or by any person at Quickwork.</span></li>
                        <li class="c45 c40 li-bullet-0"><span class="c1">Quickwork shall not be responsible or take any
                                liability for
                                the accuracy of data uploaded to the Platform by the Customer including, but not limited
                                to
                                Customer
                                Data or any other data, materials or contents uploaded by the User.</span></li>
                        <li class="c45 c40 li-bullet-0"><span class="c1">Customer agrees to and recognizes the risk of
                                exposure
                                that
                                hosting data online carries with itself including, but not limited to unauthorized
                                disclosure,
                                manipulation, deletion while accessing and using the Platform. The Customer assumes such
                                risks
                                and
                                Quickwork offers no representation, warranty, or guarantee that Customer Data will not
                                be
                                exposed or
                                disclosed through errors or the actions of third parties.</span></li>
                        <li class="c45 c40 li-bullet-0"><span class="c1">Quickwork may, with or without notice,
                                permanently
                                erase
                                Customer Data if the Customer's Account is delinquent, suspended or terminated for more
                                than
                                30
                                days.</span></li>
                        <li class="c45 c40 li-bullet-0"><span class="c9 c32">Customer</span><span class="c1">&nbsp;shall
                                not
                                upload or
                                transmit any data on Quickwork's Platform which is subjected to higher degree of
                                protection
                                under the
                                applicable laws. </span></li>
                        <li class="c40 c25 c53 li-bullet-0"><span class="c9">The provision of personal information in
                                connection
                                with
                                use of the Quickwork Platform by Customer shall be subject to Quickwork's Privacy
                                Policy,
                                found
                                at
                            </span><span class="c9 c35"><a class="c0" target="_blank"
                                    href="/privacy-policy">https://quickwork.co/privacy-policy</a></span><span
                                class="c1">&nbsp;, which is hereby incorporated by reference into this Agreement,
                                provided
                                that
                                all
                                references to the “Terms of Service” in the Privacy Policy will refer to this
                                Agreement.</span>
                        </li>
                        <li class="c53 c40 c25 li-bullet-0"><span class="c9">To the extent that Quickwork is a Processor
                                for
                                Customer of
                                Personal Data that is subject to the European Union General Data Protection Regulation
                                and
                                for
                                which the
                                Customer is the Controller or Processor for their controller (each as defined in the
                                DPA),
                                the
                                EU Data
                                Processing Addendum located at </span><span class="c35 c9"><a class="c0"
                                    href="/legal/dpa">https://quickwork.co/legal/dpa</a></span><span
                                class="c1">&nbsp;(the “DPA”) is hereby incorporated into the Agreement with respect to
                                the
                                processing of
                                such Personal Data.</span></li>
                    </ol>
                    <br>
                    <li class="c5 li-bullet-0"><strong class="c15">Intellectual Property Rights</strong></li>
                    <ol class="c13 lst-kix_list_27-0 start" type="i">
                        <li class="c40 c47 li-bullet-0"><span class="c1">Quickwork retains all titles, rights, and
                                interests
                                in
                                and to
                                the Platform, Website, and Products and Services including, but not limited to all
                                software,
                                applications and services used to provide and operate the Platform, including any source
                                code,
                                improvements, enhancements, updates, modifications, or derivative works thereof, whether
                                patentable or
                                not.</span></li>
                        <li class="c47 c40 li-bullet-0"><span class="c1">Quickwork solely owns and retains all right,
                                title,
                                and
                                interest in and to the Quickwork Brand.</span></li>
                        <li class="c47 c40 li-bullet-0"><span class="c9 c32">Customer</span><span
                                class="c9 c32">&nbsp;shall
                                not
                                claim
                                any authority over the Intellectual Property of Quickwork or Quickwork Brand during or
                                after
                                the
                                existence of this Agreement.</span></li>
                    </ol>
                    <br>
                    <li class="c5 li-bullet-0"><strong class="c15">Feedback</strong></li>
                    <ol class="c13 lst-kix_list_17-0 start" type="i">
                        <li class="c11 li-bullet-1"><span class="c9 c32">“Feedback”</span><span
                                class="c15 c32">&nbsp;</span><span class="c1">shall refer to any suggestions or ideas
                                for
                                improving or modifying Quickwork's Platform,
                                Website or any of the Products and Services.</span></li>
                        <li class="c11 li-bullet-1"><span class="c1">Quickwork does not agree to treat any Feedback as
                                confidential and
                                nothing in this Agreement or any other Documentation or any dealing with the Customer or
                                User
                                shall
                                restrict Quickwork's right to use, disclose, publish, highlight, hide or otherwise
                                profit
                                out of
                                such
                                Feedback, without any accrual of compensation or credits to the Customer or the
                                User.</span>
                        </li>
                    </ol>
                    <br>
                    <li class="c5 li-bullet-0"><strong class="c15">Confidentiality</strong></li>
                    <ol class="c13 lst-kix_list_18-0 start" type="i">
                        <li class="c54 c59 li-bullet-2"><span class="c1">“Confidential Information” shall carry the
                                meaning
                                ascribed to
                                it in the Definitions part of this Terms of Service.</span></li>
                        <li class="c54 c59 li-bullet-2"><span class="c9 c32">Customer</span><span class="c1">&nbsp;shall
                                not
                                use
                                Confidential Information for any purpose other than the use of the Platform, and
                                Products
                                and
                                Services
                                in accordance with the Customer Order.</span></li>
                        <li class="c54 c59 li-bullet-2"><span class="c1">The Customer:</span></li>
                        <ol class="c13 lst-kix_list_20-0 start" type="a">
                            <li class="c14 li-bullet-0"><span class="c9 c32">Shall only disclose Confidential
                                    Information to
                                    any
                                    agent,
                                    employee, or a contractor of the Customer on a need</span><span
                                    class="c9">-</span><span class="c1">to-know basis, provided, an appropriate
                                    non-disclosure agreement has been
                                    executed
                                    between
                                    them.</span></li>
                            <li class="c14 li-bullet-0"><span class="c1">Shall not disclose Confidential Information to
                                    any
                                    other
                                    third
                                    party without Quickwork's prior written consent.</span></li>
                            <li class="c14 li-bullet-0"><span class="c1">Shall protect Confidential Information in the
                                    manner
                                    and
                                    with the
                                    same degree of care it uses to protect its own Confidential Information of similar
                                    nature
                                    and
                                    significance, but with no less than what is considered reasonable care.</span></li>
                            <li class="c14 li-bullet-0"><span class="c1">Shall promptly notify Quickwork of any misuse
                                    or
                                    misappropriation
                                    of Confidential Information that comes to Customer's knowledge.</span></li>
                            <li class="c14 li-bullet-0"><span class="c9 c32">Notwithstanding the foregoing, the Customer
                                    may
                                    disclose
                                    Confidential Information as required by applicable law or by proper legal or
                                    governmental
                                    authority.
                                </span><span class="c9 c32">Customer</span><span class="c1">&nbsp;shall give Quickwork
                                    prompt
                                    notice
                                    of any
                                    such legal or governmental demand and reasonably cooperate with Quickwork in any
                                    effort
                                    to
                                    seek
                                    a
                                    protective order or otherwise to contest such required disclosure.</span></li>
                            <li class="c14 li-bullet-0"><span class="c1">Customer acknowledges and agrees that breach of
                                    this
                                    Article 8
                                    would cause Quickwork irreparable harm, damage, or injury, for which monetary
                                    damages
                                    would
                                    not
                                    provide
                                    adequate compensation, and that without prejudice to any other remedy, Quickwork
                                    will be
                                    entitled to
                                    injunctive relief against such breach or threatened breach, without proving actual
                                    damage.</span></li>
                            <li class="c14 li-bullet-0"><span class="c1">This Agreement does not transfer the ownership
                                    of
                                    any
                                    Confidential
                                    Information. Quickwork will retain all rights, titles, and interests in and to all
                                    Confidential
                                    Information.</span></li>
                        </ol>
                        <li class="c44 li-bullet-3"><span class="c9 c32 c25">Throughout the Term of this Agreement, and
                                for
                                a
                                period of
                                5 (five) years following termination, neither Party will use or divulge any
                                Conf</span><span class="c1">idential Information of the other Party except as provided
                                herein.</span></li>
                    </ol>
                    <br>
                    <li class="c5 li-bullet-0"><strong class="c15">Representations and Warranties</strong></li>
                    <ol class="c13 lst-kix_list_21-0 start" type="i">
                        <li class="c24 c40 li-bullet-0"><span class="c1">Mutual Warranties: Without limiting any other
                                representation,
                                warranty, or covenant herein, each Party hereby represents and warrants to the other
                                Party
                                that:</span>
                        </li>
                        <ol class="c13 lst-kix_list_21-0 start" type="a">
                            <li class="c10"><span class="c1">It has the complete corporate power and authority to enter
                                    into
                                    this
                                    Agreement.</span></li>
                            <li class="c10"><span class="c1">This Agreement is a valid and binding obligation of such
                                    Party.</span>
                            </li>
                            <li class="c10"><span class="c1">It has obtained and shall maintain throughout the Term of
                                    this
                                    Agreement all required
                                    authorizations, approvals and consents to enter into and perform its obligations
                                    hereunder in
                                    compliance
                                    with all applicable laws, rules, and regulations.</span></li>
                        </ol>
                        <li class="c23 c33"><span class="c1">Quickwork represents and warrants that it shall use
                                processes
                                and
                                technology as per industry Standard which are designed to prevent attacks on its'
                                Platform.</span>
                        </li>
                        <li class="c23 c33"><span class="c1">Quickwork shall maintain physical, technical, and
                                administrative
                                safeguards
                                to protect Personal Data.</span></li>
                        <li class="c23 c33"><span class="c1">Quickwork Support: Quickwork shall undertake commercially
                                reasonable
                                efforts
                                to offer and provide support to the Customer to use the Platform and, Products and
                                Services
                                as
                                per
                                our
                                support policy as displayed on our website from time to time</span></li>
                        <li class="c23 c33"><span class="c1">Quickwork shall undertake commercially reasonable efforts
                                to
                                make
                                the
                                Quickwork Platform,
                                Website, and Products and Services available to the Customer as per service levels and
                                remedies
                                in
                                accordance with our support policy as displayed on our website from time to time</span>
                        </li>
                        <li class="c54 c63"><span class="c9">Other than the express warranties in Article 9, Quickwork
                                makes
                                no warranty,
                                express or implied,
                                with respect to any matter, including without limitation advertising and other services,
                                and
                                expressly
                                disclaims the implied warranties or conditions of non-infringement, merchantability, and
                                fitness
                                for
                                any
                            </span><span class="c9 c25">specific</span><span class="c1">purpose. Quickwork makes no
                                warranties
                                with
                                respect to the results of or use of the Quickwork Platform or the Website or the
                                Products
                                and
                                Services and
                                the Customer assumes all risk and responsibility with respect thereto.</span></li>
                    </ol>
                    <br>
                    <li class="c5 li-bullet-0"><strong class="c15">Indemnification</strong></li>
                    <ol class="c13 lst-kix_list_22-0 start" type="i">
                        <li class="c24 c40 li-bullet-0"><span class="c9 c32">Customer shall defend, indemnify, and hold
                                harmless
                                Quickwork, its affiliates and each of its and its affiliates' officers, directors,
                                agents
                                and
                                employees
                                from all liabilities, claims, and expenses (including reasonable attorneys' fees) paid
                                or
                                payable to any
                                third party, that arise from or relate to any third</span><span class="c9">-</span><span
                                class="c1">party claim including but not limited to:</span></li>
                        <ol class="c13 lst-kix_list_15-0 start" type="a">
                            <li class="c41 c40 li-bullet-0"><span class="c9 c32">Allegations that any Customer Data
                                    infringes or
                                </span><span class="c9 c32">misappropriates</span><span class="c1">&nbsp;such third
                                    party's
                                    Intellectual
                                    Property rights or proprietary rights.</span></li>
                            <li class="c41 c40 li-bullet-0"><span class="c1">Arising from Customer's use of the
                                    Quickwork
                                    Platform,
                                    Website,
                                    or Products and Services in violation of this Agreement, the Documentation,
                                    applicable
                                    law,
                                    or
                                    any other
                                    policies, as amended and made available/notified by Quickwork on their Platform
                                    and/or
                                    Website.</span>
                            </li>
                        </ol>
                    </ol>
                    <br>
                    <li class="c5 li-bullet-0"><strong class="c15">Limitation of Liability</strong></li>
                    <ol class="c13 lst-kix_list_22-0 start" type="i">
                        <li class="c23"><span class="c9">Quickwork's liability arising out of or related to this
                                Agreement shall not
                                exceed the amount
                                actually paid by the Customer as Platform Fees to Quickwork in a period o</span><span
                                class="c9 c25">f 3
                                (three) </span><span class="c1">months immediately preceding the date on which the
                                Customer
                                presents
                                such a
                                claim to Quickwork with respect to a breach of Quickwork's obligations as herein
                                provided in
                                this
                                Agreement.
                            </span></li>
                        <li class="c23"><span class="c9">Under no circumstances, including, but not limited to,
                                negligence,
                                system failure or network
                                outage, shall Quickwork or its affiliates be liable for any special, indirect,
                                incidental,
                                consequential,
                                punitive, reliance, or exemplary damages that result from this Agreement, even if such
                                party or
                                its
                                authorized representative has been advised of the possibility of such </span><span
                                class="c1 c25">liability
                                for damages.</span></li>
                        <li class="c23"><span class="c1">Quickwork shall have no liability for any claims, losses or
                                damages arising
                                out of or in
                                connection with Customer's use of any Third-Party Applications, linked website or the
                                products,
                                services,
                                software or other websites linked in the Platform.</span></li>
                        <li class="c23"><span class="c9">The Quickwork Platform may provide links (or allow the User to
                                provide links) (the
                                “Linked
                                Websites”) that allow the User to leave the Quickwork Platform and/or access third party
                                websites or
                                access
                                the Quickwork Platform through third party websites and the access of the Linked
                                Websites by the
                                User shall
                                be governed by the terms of service and privacy policy of the </span><span
                                class="c9 c25">applicable
                            </span><span class="c1">Linked Websites. The Linked Websites are not under the control of
                                Quickwork,
                                and
                                it is
                                not responsible for the contents of any Linked Websites, or any link contained in a
                                Linked
                                Website,
                                or any
                                changes or updates to such sites or the performance or security of such other sites.
                                Quickwork
                                is
                                not
                                responsible for any content of or transmission to or from any Linked Website. Quickwork
                                provides
                                these links
                                only as a convenience or feature of the Quickwork Platform, and the inclusion of any
                                link does
                                not
                                imply
                                endorsement by Quickwork of the Linked Websites. Users may access any such third-party
                                websites
                                or
                                access
                                the Quickwork Platform through any third-party websites at the sole risk and discretion
                                of the
                                User.</span>
                        </li>
                        <li class="c23"><span class="c9">If applicable law limits the application of the provisions of
                                this
                                Article 11 , the liability of
                                Quickwork will be limited to the maximum extent permissible. For the removal of any
                                doubt,
                                Quickwork's
                                liability limits and other rights set forth in this Article 11 &nbsp;apply likewise to
                                Quickwork's
                                affiliates, suppliers, advertisers, agents, sponsors, directors, officers, employees,
                                consultants,
                                and other
                                representatives.</span></li>
                    </ol>
                    <br>
                    <li class="c5 li-bullet-0"><strong class="c15">Term and Termination</strong></li>
                    <ol class="c13 lst-kix_list_22-0 start" type="i">
                        <li class="c23"><span class="c1">The term “Term” shall have the meaning prescribed to in the
                                definitions
                                part
                                given above.</span>
                        </li>
                        <li class="c23"><span class="c1">The Customer may terminate the Agreement by giving 30
                                (thirty)
                                days
                                prior written notice to
                                Quickwork.</span></li>
                        <li class="c23"><span class="c1">Either Party may terminate this Agreement or any Customer Order
                                if
                                the
                                other
                                party:</span></li>
                        <ol class="c13 lst-kix_list_22-0 start" type="a">

                            <li class="c10"><span class="c1">fails to cure any material breach (including, without
                                    limitation,
                                    Customer's
                                    failure to pay the Platform Fees) of this Agreement within 30 (thirty) days after
                                    written
                                    notice
                                    of
                                    such
                                    breach; or</span></li>
                            <li class="c10"><span class="c1">ceases operation without a successor; or</span></li>
                            <li class="c10"><span class="c1">seeks protection under any bankruptcy, receivership,
                                    trust
                                    deed,
                                    creditors
                                    arrangement, composition, or comparable proceeding, or if any such proceeding is
                                    instituted
                                    against
                                    such
                                    Party (and not dismissed within sixty (60) days thereafter).</span></li>
                        </ol>
                        <li class="c54 c58"><span class="c1">Upon termination of this Agreement, Quickwork shall cease
                                Customer's
                                access to
                                the Platform, and Products and Services and Customer shall discontinue all use of the
                                Platform
                                and
                                delete,
                                destroy, or return all copies of the Documentation in its possession or control. The
                                following
                                provisions
                                will survive termination or expiration of this Agreement:</span></li>

                        <ol class="c13 lst-kix_list_22-0 start" type="a">
                            <li class="c10"><span class="c1">Any obligation of the Customer to pay the Platform
                                    Fees.</span>
                            </li>
                            <li class="c10"><span class="c1">Articles 6 (IP), 7 (Feedback), 8 (Confidential
                                    Information), 9
                                    (Warranties), 10
                                    (Indemnification), 11 (Limitation of Liability), 13 (Publicity), 14 (Miscellaneous)
                                    and any
                                    other
                                    provision
                                    of this Agreement that must survive to fulfill its essential purpose.</span></li>
                        </ol>
                    </ol>
                    <br>
                    <li class="c5 li-bullet-0"><strong class="c15">Publicity</strong></li>
                    <ol class="c13 lst-kix_list_13-0 start" type="i">
                        <li class="c23"><span class="c9">The Customer agrees to
                                permit Quickwork to use Customer's name, trademarks, and service marks with use cases in
                                order
                                to
                                identify
                                as Quickwork's Customer on the Website, in Quickwork's marketing materials or in any
                                other
                                marketing
                                and
                                sales activities, unless that</span><span class="c1 c25">&nbsp;Customer notifies
                                Quickwork
                                in
                                writing of its
                                revocation of such permission.</span></li>
                    </ol>
                    <br>
                    <li class="c5 li-bullet-0"><strong class="c15">Miscellaneous</strong></li>
                    <ol class="c13 lst-kix_list_13-0 start" type="i">
                        <li class="c23"><strong class="c15 c36 c32">No Agency</strong></li>
                        <p class="c24"><span class="c1">Quickwork and Customer are independent contractors, and neither
                                Quickwork
                                nor
                                Customer is an agent, representative or partner of the other. Quickwork and Customer
                                shall
                                each
                                have
                                sole
                                responsibility for all acts and omissions of their respective personnel. Neither Party
                                shall
                                have
                                any
                                obligation for any employee-related benefits or withholding taxes applicable to the
                                other
                                Party's
                                personnel
                                performing services pursuant to this Agreement.</span></p>
                        <br>
                        <li class="c23"><strong class="c15">Notice</strong></li>
                        <p class="c23"><span class="c1">Any notices under this Agreement shall be in writing and shall
                                be
                                sent
                                to
                                the email
                                address associated with the account (in the case of Customer) or to support@quickwork.co
                                (in
                                the
                                case of
                                Quickwork) by electronic mail or nationally recognized express delivery courier service
                                and
                                deemed
                                given
                                upon receipt.</span></p>
                        <br>
                        <li class="c23"><strong class="c15 c36 c32">Force Majeure</strong></li>
                        <p class="c24"><span class="c1">No delay, failure, or default, other than a failure to pay fees
                                when
                                due,
                                will
                                constitute a breach of this Agreement to the extent caused by acts of war, terrorism,
                                hurricanes,
                                earthquakes, other acts of God or of nature, strikes or other labor disputes, riots or
                                other
                                acts of
                                civil
                                disorder, embargoes, or other causes beyond the performing Party's reasonable
                                control.</span>
                        </p>
                        <br>
                        <li class="c23"><strong class="c15 c32 c36">Severability</strong></li>
                        <p class="c24"><span class="c1">To the extent permitted by applicable law, the parties hereby
                                waive
                                any
                                provision of
                                law that would render any clause of this Agreement invalid or otherwise unenforceable in
                                any
                                respect. In the
                                event that a provision of this Agreement is held to be invalid or otherwise
                                unenforceable,
                                such
                                provision
                                will be interpreted to fulfill &nbsp;its intended purpose to the maximum extent
                                permitted by
                                applicable law,
                                and the remaining provisions of this Agreement will continue in full force and
                                effect.</span>
                        </p>
                        <br>
                        <li class="c23"><strong class="c15 c36 c32">No Waiver</strong></li>
                        <p class="c24"><span class="c1">Neither Party will be deemed to have waived any of its rights
                                under
                                this
                                Agreement
                                by lapse of time or by any statement or representation other than by an authorized
                                representative in
                                an
                                explicit written waiver. No waiver of a breach of this Agreement will constitute a
                                waiver of
                                any
                                other
                                breach of this Agreement.</span></p>
                        <br>
                        <li class="c23"><strong class="c15 c36 c32">Choice of Law &amp; Jurisdiction</strong></li>
                        <p class="c24"><span class="c1">This Agreement shall be governed by the laws of India, without
                                giving
                                effect
                                to
                                principles of conflicts of law. Subject to the provisions of this Article 14(vi), this
                                Agreement
                                shall be
                                subject to the exclusive jurisdiction of, and venue in, the courts of Mumbai,
                                India.</span>
                        </p>
                        <br>
                        <li class="c23"><strong class="c15 c36 c32">Dispute Resolution</strong></li>
                        <p class="c24"><span class="c1">In event of any dispute, controversy or claim arising out of or
                                relating
                                to
                                this
                                Agreement, or the breach, termination or invalidity of it, the parties shall further aim
                                to
                                resolve
                                any such
                                dispute mutually.</span></p>
                        <p class="c24"><span class="c9">In the event the parties fail to </span><span
                                class="c9 c25">resolve</span><span class="c1">&nbsp;the dispute mutually, the parties
                                then
                                shall
                                settle the dispute, controversy or claim in
                                accordance with the Arbitration &amp; Conciliation Act, 1996 together with any
                                amendments,
                                modifications,
                                and re-enactments thereof. The arbitration panel shall consist of a single arbitrator
                                mutually
                                agreed by the
                                parties or, in the absence of such agreement, designated by the Courts of Mumbai. The
                                place
                                of
                                arbitration
                                shall be Mumbai and all proceedings and submissions shall be in English. The decision
                                and
                                award
                                of
                                the
                                arbitrator shall be binding on and enforceable against the parties. Each Party waives
                                any
                                right
                                to
                                appeal
                                such decision and award but retains the right to seek judicial assistance to compel
                                arbitration,
                                to
                                obtain
                                interim measures of protection pending arbitration and/or to enforce any decision of the
                                arbitrator,
                                including the final award.</span></p>
                        <br>
                        <li class="c23"><strong class="c15 c36 c32">Conflicts and Inconsistencies</strong></li>
                        <p class="c24"><span class="c1">In the event of any conflict between this Agreement and any
                                Quickwork
                                policy
                                posted
                                online on the Platform or Website, including without limitation the AUP, Data Protection
                                and
                                Privacy
                                Policy,
                                the terms of this Agreement will govern.</span></p>
                        <br>
                        <li class="c23"><strong class="c15 c36 c32">Amendment</strong></li>
                        <ol class="c13 lst-kix_list_13-0 start" type="a">
                            <li class="c10"><span class="c1">Quickwork may amend this Agreement from time to time by
                                    posting an
                                    amended
                                    version on its Website/Platform.</span></li>
                            <li class="c10"><span class="c1">Customer's continued use of the Platform, and Products and
                                    Services
                                    after such
                                    amendment will confirm Customer's consent thereto.</span></li>
                            <li class="c10"><span class="c1">Quickwork may revise the Privacy Policy and AUP at any time
                                    by
                                    posting
                                    a new
                                    version of either at the Website/Platform, and such a new version will become
                                    effective on
                                    the
                                    date
                                    it is
                                    posted.</span></li>
                        </ol>
                        <br>
                        <li class="c23"><strong class="c15 c36 c32">Entire Agreement</strong></li>
                        <p class="c24"><span class="c1">This Agreement sets forth the entire agreement of the parties
                                and
                                supersedes
                                all
                                prior or agreements (whether in oral or written format), discussions, and negotiations
                                with
                                respect
                                to its
                                subject matter. Neither Party has relied upon any such prior or contemporaneous
                                communications.</span></p>
                        <br>
                        <li class="c23"><strong class="c15 c36 c32">Headings</strong></li>
                        <p class="c24"><span class="c1">The article headings in this Agreement are intended solely for
                                convenience
                                of
                                reference and shall be given no effect in the construction or interpretation of this
                                Agreement.</span></p>
                    </ol>
                </ol>
            </div>
        </div>
    </div>
</div>