import { Component, Input, OnChanges } from '@angular/core';
import { SEARCH_REGEX } from 'src/app/app.service';

@Component({
  selector: 'qw-high-light-text',
  template: `
        <ng-container *ngFor="let match of result">
            <mark *ngIf="match.toLowerCase() === needle.toLowerCase(); else nomatch">{{match}}</mark>
            <ng-template #nomatch>{{match}}</ng-template>
        </ng-container>
    `,
})
export class HighLightTextComponent implements OnChanges {

  @Input() needle = '';
  @Input() haystack: string;
  public result: string[];

  ngOnChanges() {
    if (this.haystack) {
      Promise.resolve(undefined).then(() => {
        if (this.needle) {
          const regEx = SEARCH_REGEX(this.needle);
          this.result = this.haystack.split(regEx);
        } else {
          this.result = [this.haystack];
        }
      });
    }
  }

}
