import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'qw-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  teamMembers: {
    name: string;
    designation: string;
    fileName: string;
  }[] = [
      {
        name: 'Dr. Milind R. Agarwal',
        designation: ' Founder & CEO | Business Team',
        fileName: 'Dr. Milind R. Agarwal _ Founder & CEO _ Business Team.png'
      },
      {
        name: 'Deepak Bhatia',
        designation: 'Co-founder | Business Team',
        fileName: 'Deepak Bhatia _ Cofounder _ Business Team.png'
      },
      {
        name: 'Omkar Sathe',
        designation: 'Co-founder | Operations Team',
        fileName: 'Omkar Sathe _ Cofounder _ Operations Team.png'
      },
      {
        name: 'Krish Advani',
        designation: 'Co-founder | Technology Team',
        fileName: 'Krish Advani _ Cofounder _ Technology Team .png'
      },
      {
        name: 'Preeti Joshi',
        designation: 'Talent Team',
        fileName: 'Preeti Joshi _ Talent Team.png'
      },
      {
        name: 'Madhur Kaup',
        designation: 'CISO | Product Team',
        fileName: 'Madhur Kaup _ CISO _ Solutions Team.png'
      },
      {
        name: 'Amit Kawadiwale',
        designation: 'Product Team',
        fileName: 'Amit Kawadiwale _ Product Team.png'
      },
      {
        name: 'Rajnish Kucheria',
        designation: 'Technology Team',
        fileName: 'Rajnish Kucheria _ Business Team.png'
      }
    ];

  visionStatements: {
    title: string;
    description: string;
  }[] = [
      {
        title: 'Easy',
        description: 'By making no code user interface and user experience easier, simpler and delightful for citizen developers with every updated release for our platform.'
      },
      {
        title: 'Affordable',
        description: 'By continuously striving to drive the unit transaction cost of automation to the lowest level possible, so that more businesses world-wide can afford to adopt automation.'
      },
      {
        title: 'Accessible',
        description: 'By making our platform available in real-time, in multiple languages, in on-premise, on cloud and hybrid formats, so that it can be accessed by anyone anywhere in the world.'
      },
      {
        title: 'Compliant',
        description: 'By proactively implementing the best practices around information security and privacy policies and safeguards, so that our users feel comfortable and assured about accepting and adopting automation.'
      }
    ];

  constructor(private metaService: Meta, private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('About Us, Customer Centricity | Quickwork');
    this.metaService.addTags([
      { name: 'description', content: 'Quickwork has a mission to democratise automation with its no code automation platform. The leading iPaaS platform for automating workflows in few steps.' },
      { name: 'robots', content: 'index, follow' }
    ]);
  }

}
