<div id="header-section" class="trapezoid-fill animated gradient-6">
    <div class="main-container">
        <div class="use-cases-header">
            <h1 class="use-cases-title">A platform which works across all verticals</h1>
        </div>
        <!-- <qw-case-study-filters (onFilter)="filterCaseStudies($event)"></qw-case-study-filters> -->
    </div>
</div>

<div id="all-apps-section">
    <div class="main-container">
        <div class="use-cases-container">
            <ng-container *ngIf="caseStudies$ | async as caseStudies; else errorUseCases">
                <ng-container *ngIf="caseStudies.length > 0; else noUseCasesTemplate;">
                    <div class="column-layout c-1-half" [class.c-2-el]="caseStudies.length < 3">
                        <ng-container *ngFor="let caseStudy of caseStudies">
                            <qw-case-study class="column" [caseStudy]="caseStudy"></qw-case-study>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-template #noUseCasesTemplate>
                    <div class="use-cases">
                        {{ noCaseStudiesMessage }}
                    </div>
                </ng-template>
            </ng-container>
            <ng-template #errorUseCases>
                <ng-container *ngIf="caseStudiesError$ | async as error; else caseStudiesLoading">
                    <div class="error" [qwConditionalScroll]="![null, undefined].includes(currentCategory)"
                        [offset]="-120">
                        <h4>Failed to fetch use-cases</h4>
                        <p class="m-0">{{ error | manageError }}</p>
                        <button type="button" class="button filled bg-red m-t-20" (click)="refetchCaseStudies()">
                            Try again
                        </button>
                    </div>
                </ng-container>
                <ng-template #caseStudiesLoading>
                    <div class="loader">
                        <qw-spinner loadingText="Fetching case-studies"></qw-spinner>
                    </div>
                </ng-template>
            </ng-template>
            <ng-container *ngIf="totalItems > itemsPerPage">
                <qw-pagination [(page)]="page" [itemsPerPage]="itemsPerPage" [totalItems]="totalItems"
                    (pageChange)="getCaseStudies(page)"></qw-pagination>
            </ng-container>
        </div>
    </div>
</div>