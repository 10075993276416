import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'qw-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit {

  securityDetails = [
    {
      title: 'Information Security & Privacy',
      points: [
        'SOC 2 Type II compliant',
        'ISO/IEC 27001: 2013 certification completed',
        'EU GDPR compliant'
      ],
      showContent: true
    },
    {
      title: 'Data Security',
      points: [
        'TLS v1.3 and PGP to facilitate data security and privacy protection during communication',
        'SSL Certificate Pinning for protection from complex security attacks',
        'SSL TLS 1.2+ with SSL certificates using SHA-256 with RSA Encryption for protecting Personal Data in transition',
        'Data Masking enables users to disable display of sensitive data',
        'Industry Standard Approach to safeguard Production Keys',
        'Stringent org level controls to ensure safety of Personally Identifiable Information (PII) data',
        'Policy protecting data from third party use',
        'Activity log maintenance that tracks all user activities',
        'Quickwork does not use your data for marketing or advertising',
        'VAPT done periodically by certified third parties to test security and identify vulnerabilities in the system'
      ],
      showContent: true
    },
    {
      title: 'Identity Management',
      points: [
        'Provision of secure connection between you and our hosting server through SSL/TLS encryption',
        'Encryption of User Credentials',
        'Session timeout of inactive user sessions',
        'Centralized user management',
        'IP address based whitelisting and range restrictions',
        'Ability to delete data remotely for disabled users'
      ],
      showContent: true
    },
    {
      title: 'Cloud Security',
      points: [
        'IP Address range restrictions',
        'Firewalls, DDoS mitigation, Port scanning',
        'Customer Application Isolation'
      ],
      showContent: true
    },
    {
      title: 'Data Center and Deployment Architecture',
      points: [
        'Robust Data Center Disaster Avoidance and Recovery Policy in place',
        'Your data is saved in two localized data centers',
        'Backups are taken daily'
      ],
      showContent: true
    }
  ];

  complianceLogos = [
    'GDPR.png',
    'ISM.png',
    'SOC 2.png'
  ];

  constructor(
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Security | Quickwork');
    this.metaService.addTags([
      { name: 'description', content: 'Quickwork is committed to providing a highly secure and reliable environment with SOC 2 Type II, ISO 27001 and GDPR compliance.' },
      { name: 'robots', content: 'index, follow' }
    ]);
  }

  toggleContent(detail: any) {
    detail.showContent = !detail.showContent;
  }

}
