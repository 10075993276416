import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'qw-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  footerLinks: {
    title: string;
    links: {
      name: string;
      link?: string;
      type?: string;
      target?: string;
      [key: string]: any;
    }[]
  }[] = [
      {
        title: 'Popular apps',
        links: [
          {
            name: 'Facebook Messenger',
            link: '/apps/facebook_messenger/integrations',
            type: 'router'
          },
          {
            name: 'Conversation Management by Quickwork',
            link: '/apps/quickwork_chat_v2/integrations',
            type: 'router'
          },
          {
            name: 'Salesforce',
            link: '/apps/salesforce/integrations',
            type: 'router'
          },
          {
            name: 'HanaDB',
            link: '/apps/hana_db/integrations',
            type: 'router'
          },
          {
            name: 'ActiveCampaign',
            link: '/apps/active_campaign/integrations',
            type: 'router'
          },
          {
            name: 'Facebook Lead Ads',
            link: '/apps/facebook_ads_leads/integrations',
            type: 'router'
          }
        ]
      },
      {
        title: 'Product',
        links: [
          {
            name: 'Platform',
            link: '/product/features',
            type: 'router',
          },
          {
            name: 'App Directory',
            link: '/product/app-directory',
            type: 'router',
          },
        ]
      },
      {
        title: 'Company',
        links: [
          {
            name: 'About Us',
            link: '/about-us',
            type: 'router'
          },
          {
            name: 'Partnership',
            link: '/partnerships',
            type: 'router'
          },
          {
            name: 'Privacy Policy',
            link: '/privacy-policy',
            type: 'router',
          },
          {
            name: 'Terms of service',
            link: '/terms-of-service',
            type: 'router',
          },
          {
            name: 'Pricing',
            link: '/pricing',
            type: 'router',
          },
          {
            name: 'GDPR',
            link: '/privacy-policy/gdpr',
            type: 'router',
          },
          {
            name: 'Cookie Policy',
            link: '/cookie-policy',
            type: 'router',
          },
          {
            name: 'DPA',
            link: '/legal/dpa',
            type: 'router',
          },
          {
            name: 'Security',
            link: '/security',
            type: 'router',
          },
        ]
      },
      {
        title: 'Resources',
        links: [
          {
            name: 'Blogs',
            link: 'https://blog.quickwork.co/',
            type: 'href',
          },
          // {
          //   name: 'Helpdesk',
          //   link: 'http://helpdesk.quickwork.co/',
          //   type: 'href',
          // },
          {
            name: 'Case studies',
            link: '/case-studies',
            type: 'router',
          },
          {
            name: 'Support Center',
            link: 'https://support.quickwork.co/hc/en-us',
            type: 'href',
          }
        ]
      },
    ];

    complianceLogos = [
      'GDPR.png',
      'ISM.png',
      'SOC 2.png'
    ];

  currentYear = new Date().getFullYear();

  constructor(private router: Router) { }

  redirectToIntegrations(link: string) {
    this.router.navigateByUrl('/apps', { skipLocationChange: true }).then(() => {
      this.router.navigate([link]);
    });
  }

}
