<ng-container *ngIf="caseStudiesTabs.length && caseStudiesTabs.length > 1">
    <div class="tabs-container">
        <qw-tabs-layout [tabs]="caseStudiesTabs | slice:0:caseStudiesSliceFactor" (onTabSwitch)="setCaseStudiesIndex($event)" scrollShadow
            orientation="horizontal"></qw-tabs-layout>
            <button type="button" class="button filled" *ngIf="showMore && caseStudiesTabs.length > 5" routerLink="/case-studies">
                Explore all case-studies
                <i class="fas fa-chevron-right m-l-15"></i>
            </button>
    </div>
</ng-container>

<ng-container *ngFor="let caseStudyGroup of groupedCaseStudies">
    <ng-container *ngIf="caseStudyGroup.id === currentCaseStudyIndustry">

        <ng-container *ngFor="let caseStudy of caseStudyGroup.data | slice:0:caseStudiesSliceFactor; let i = index;">
            <div class="customer-content" *ngIf="i === caseStudiesIndex">
                <div class="customer-parameters l-m-border">
                    <div class="customer-parameter">
                        <h3 class="parameter-title border-highlight">Go Live</h3>
                        <p class="p-2">{{ caseStudy.goLiveDays }} days</p>
                    </div>
                    <div class="customer-parameter">
                        <h3 class="parameter-title border-highlight">Industry</h3>
                        <p class="p-2">{{ caseStudy.industry }}</p>
                    </div>
                    <div class="customer-parameter">
                        <h3 class="parameter-title border-highlight">Integrations established</h3>
                        <div class="integration-apps m-t-10">
                            <div class="integration-app" *ngFor="let app of caseStudy.apps | slice:0:appsSliceFactor ">
                                <qw-app-icon [appAlias]="app"></qw-app-icon>
                                <p>{{ app | appName | async }}</p>
                            </div>
                            <ng-container *ngIf="caseStudy.apps.length > appsSliceFactor">
                                <div class="more-integration-apps">
                                    <div class="ellipsis-icon">
                                        <i class="fad fa-ellipsis-h-alt"></i>
                                    </div>
                                    <p>{{ caseStudy.apps.length - appsSliceFactor }} More</p>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="customer-representation">
                    <a href="" [routerLink]="'/case-studies/'+caseStudy.id">
                        <div class="img-card" id="customer-image-card" [bgImage]="caseStudy.imageThumbnailSrc" [overlayColor]="caseStudy.imageOverlayColor">
                            <div class="img-card-content">
                                <p class="img-card-title">
                                    {{ caseStudy.title }}
                                </p>
                                <div class="img-card-read-more">Read more</div>
                            </div>
                        </div>
                    </a>
                    <!-- <div class="customer-logo">
                  <img src="../../../assets/images/enterprise-qjb/home-page/BancoGuyaquilLogo.png" alt="">
                </div> -->
                </div>
            </div>
        </ng-container>
        <div class="indicators">
            <div class="slider-indicators">
                <div class="slider-indicator" *ngFor="let indicator of caseStudyGroup.data; let i = index"
                    [class.active]="i === caseStudiesIndex" (click)="changeCaseStudyIndex(i)"></div>
            </div>
        </div>
    </ng-container>
</ng-container>