<ng-container [ngSwitch]="loadingState">
    <ng-container *ngSwitchCase="'pending'">
       <div class="main-container" class="flex flex-justify-content-center">
          <qw-spinner loadingText="Fetching app"></qw-spinner>
       </div>
    </ng-container>
    <ng-container *ngSwitchCase="'success'">
       <ng-container *ngIf="appNotFound;else appFoundTemplate;">
          <div class="main-container" class="app-not-found t-a-c">
             <h1 class="m-t-40">404</h1>
             <h2>App not found</h2>
          </div>
       </ng-container>
       <ng-template #appFoundTemplate>
          <section id="app-header-section" *ngIf="app" class="trapezoid-fill animated gradient-5" [class.no-journeys]="!hasJourneys">
             <div class="main-container">
                <div class="app-title">
                   <div class="app-logo">
                      <qw-app-icon [appAlias]="app.alias"></qw-app-icon>
                   </div>
                   <h2 class="app-name">{{app.name}}</h2>
                </div>
                <p class="app-description p-2">{{app.description}}</p>
                <a [href]="platformURL" target="_blank" class="button filled">
                   Get Started
                   <i class="fas fa-chevron-right"></i>
                </a>
             </div>
          </section>
          <ng-container *ngIf="journeys$ | async as journeys">
             <ng-container *ngIf="journeys.length">
                <section id="app-journeys-section">
                   <div class="main-container">
                      <div class="app-journeys-header">
                         <div class="app-journeys-heading">
                            <h2 class="app-related-journeys-title">
                               Pre-Built Journeys with {{app.name}}
                            </h2>
                            <p class="p-2">Import these pre-built Journeys in your account with a single click</p>
                         </div>
                         <!-- <button type="button" class="button filled">
                            Explore our Journey Store
                            <i class="fas fa-chevron-right"></i>
                         </button> -->
                      </div>
                      <div id="journeys-list" class="pos-rel">
                         <div class="app-related-journeys-content webkit-inline flex-direction-row">
                            <ng-container *ngFor="let journey of journeys | slice:0:journeysSliceFactor">
                               <div class="app-related-journey-single">
                                  <div class="app-related-journey-single-icons">
                                     <ng-container *ngFor="let journeyApp of journey.apps | slice:0:3; let i = index">
                                        <div class="app-related-journey-single-icon">
                                           <qw-app-icon [appAlias]="journeyApp"></qw-app-icon>
                                        </div>
                                        <ng-container *ngIf="i === 0">
                                           <i class="fas fa-caret-right"></i>
                                        </ng-container>
                                     </ng-container>
                                  </div>
                                  <div class="app-related-journey-content">
                                     <p class="app-related-journey-title p-2">
                                        {{journey.description}}
                                     </p>
                                     <!-- <div class="app-related-journey-learn-more">
                                        <a target="_blank"
                                           href="https://automation.quickwork.co/#/journey-store/details/{{superEncodeURI(journey.param)}}">
                                           <button class="button plain color-blue">
                                              Use Journey
                                              <i class="fas fa-chevron-right"></i>
                                           </button>
                                        </a>
                                     </div> -->
                                  </div>
                               </div>
                            </ng-container>
                         </div>
                         <ng-container *ngIf="journeysSliceFactor < journeys.length">
                            <button class="button filled" (click)="loadMoreJourneys()">
                               Load More
                               <i class="fas fa-chevron-right"></i>
                            </button>
                         </ng-container>
                      </div>
                   </div>
                </section>
             </ng-container>
          </ng-container>
          <ng-container *ngIf="app">
             <section id="app-triggers-actions-section">
                <div class="main-container">
                   <div class="app-triggers-actions">
                      <div class="app-trigger-actions-header column-layout c-1-1 size-3fr-1fr">
                         <h2 class="app-trigger-actions-title">
                            Explore what you can do with {{app.name}}
                         </h2>
                         <ng-container *ngIf="filterTabs.length > 2">
                            <qw-tabs-layout [tabs]="filterTabs" (onTabSwitch)="filterActionsOrTriggers($event)">
                            </qw-tabs-layout>
                         </ng-container>
                      </div>
                      <div class="app-triggers-actions-content">
                         <ng-container *ngFor="let api of appAPIs | slice:0:actionTriggersSlicingFactor">
                            <ng-container *ngIf="api.type === 'trigger'">
                               <ng-container *ngIf="app.triggers && app.triggers[api.alias]">
                                  <ng-container
                                     *ngTemplateOutlet="apiTemplate;context: { api: app?.triggers[api.alias], apiType: api.type }">
                                  </ng-container>
                               </ng-container>
                            </ng-container>
                            <ng-container *ngIf="api.type === 'action'">
                               <ng-container *ngIf="app.actions && app.actions[api.alias]">
                                  <ng-container
                                     *ngTemplateOutlet="apiTemplate;context: { api: app?.actions[api.alias], apiType: api.type }">
                                  </ng-container>
                               </ng-container>
                            </ng-container>
                         </ng-container>
                      </div>
                      <ng-container *ngIf="actionTriggersSlicingFactor < appAPIs.length">
                         <div class="t-a-c m-t-50">
                            <button class="button filled" (click)="loadMoreActionsOrTriggers()">
                               Load More
                               <i class="fas fa-chevron-right"></i>
                            </button>
                         </div>
                      </ng-container>
                   </div>
                </div>
             </section>
          </ng-container>
 
 
          <ng-container *ngIf="blogs$ | async as blogs">
             <ng-container *ngIf="blogs.length">
                <section id="blogs-section">
                   <div class="main-container">
                      <header>
                         <h3 class="caption">Blogs</h3>
                         <h2 class="blogs-title">Stay up to date on {{app.name}}</h2>
                      </header>
                      <div class="blogs-container">
                         <div class="column-layout c-1-half" [class.c-2-el]="blogs.length < 3">
                            <ng-container *ngFor="let blog of blogs">
                               <a [href]="blog.url" target="_blank" class="column">
                                  <div class="img-card" [bgImage]="blog.feature_image">
                                     <div class="img-card-content">
                                        <div class="img-card-title">{{blog.title}}</div>
                                        <div class="img-card-read-more">
                                           Read full story
                                        </div>
                                     </div>
                                  </div>
                               </a>
                            </ng-container>
                         </div>
                      </div>
                   </div>
                </section>
             </ng-container>
          </ng-container>
 
       </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'failure'">
       <div class="main-container" class="flex flex-justify-content-center">
          <div class="full-width t-a-c error-section">
             <h2>Failed to load App</h2>
             <button type="button" class="button bg-red m-t-20" (click)="refetchMetaApp()">Try again</button>
          </div>
       </div>
    </ng-container>
 </ng-container>
 
 <qw-get-started-section></qw-get-started-section>
 
 
 <ng-template #apiTemplate let-api="api" let-apiType="apiType">
    <div class="app-content-single">
       <div class="app-content-single-header">
          <p>{{ apiType | uppercase }}</p>
          <div class="trigger-type" *ngIf="apiType === 'trigger'">
             <ng-container *ngIf="api.type === 'poll'">
                <i class="fad fa-clock"></i>
                Polling
             </ng-container>
             <ng-container *ngIf="['static_webhook', 'dynamic_webhook'].includes(api.type)">
                <i class="fas fa-bolt"></i>
                Real-time
             </ng-container>
          </div>
       </div>
       <div class="app-content-container">
          <div class="app-content-header">
             <div class="app-content-logo">
                <qw-app-icon [appAlias]="app.alias"></qw-app-icon>
             </div>
          </div>
          <div class="app-content">
             <h3 class="app-content-title" [innerHTML]="api.name"></h3>
             <p class="app-content-description p-2" [innerHTML]="api.description"></p>
          </div>
       </div>
    </div>
 </ng-template>