import { AfterViewInit, Component, ElementRef, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';

@Component({
  selector: 'qw-journey-template',
  templateUrl: './journey-template.component.html',
  styleUrls: ['./journey-template.component.scss']
})
export class JourneyTemplateComponent implements AfterViewInit {

  @ViewChild('journeyCardsContainer', { static: true }) journeyCardsContainer: HTMLElement;
  @ViewChildren('journeyCards') journeyCards: QueryList<ElementRef>;

  constructor(private renderer: Renderer2) { }

  ngAfterViewInit() {

    this.journeyCards['_results'].forEach((journeyCard: ElementRef) => {

      const journeyCards = this.journeyCards['_results'] as HTMLElement[];
      let current = document.querySelector('.card--current');
      let next: HTMLElement;

      // var $this = $(this),
      //   $cards = $this.find('.card'),
      //   $current = $cards.filter('.card--current'),
      //   $next;

      this.renderer.listen(journeyCard.nativeElement, 'click', () => {
        if (!current.isEqualNode(journeyCard.nativeElement) || !current) {
 
          this.renderer.removeClass(journeyCard.nativeElement, 'card--current');
          this.renderer.removeClass(journeyCard.nativeElement, 'card--out');
          this.renderer.removeClass(journeyCard.nativeElement, 'card--next');
          this.renderer.addClass(current, 'card--out');
          current = journeyCard.nativeElement;
          this.renderer.addClass(current, 'card--current');
          next = current.nextElementSibling as HTMLElement;
          next = next ? next : journeyCards[0];
          this.renderer.addClass(next, 'card--next');
 
 
          // $cards.removeClass('card--current card--out card--next');
          // $current.addClass('card--out');
          // $current = $(this).addClass('card--current');
          // next = $current.next();
          // next = next.length ? next : $cards.first();
          // next.addClass('card--next');
        }
       });

      if (!current) {
        current = journeyCards[journeyCards.length - 1];
        // journeyCards[0].click();
      }

      // this.renderer.addClass(this.journeyCardsContainer, 'cards--active');

      // $this.addClass('cards--active');
    });
  }

}
