import { Component, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import { AppService, UseCase } from 'src/app/app.service';
import { Title, Meta } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CaseStudiesService, IGroupedCaseStudies, IMetaCaseStudy } from '../case-studies/case-studies.service';
import { takeUntil, tap } from 'rxjs/operators';
import { PricingService } from '../pricing/pricing.service';
import { AT2URL } from 'src/app/shared-components/constants/miscellaneous.constants';

@Component({
  selector: 'qw-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject();
  useCases: UseCase[];
  currentCategory: string;
  noUseCasesMessage = 'No journeys found';

  platformFeatures = {
    industrial: [
      'Real-time',
      'One minute poll',
      // '1,000+ apps',
      'Error monitoring',
      'Formula engine',
      'Branching operators',
      'Version control',
    ],
    serverlessAPIS: [
      'Analytics',
      'Elastic Scalability',
      'IP Whitelisting',
      'CORS',
      'Client & access profiles',
      'API Key & OAuth support',
      'Rate limiting & quota management',
    ],
    conversationManagement: [
      'Real-time triggers',
      'Context handling',
      'NLP supported',
      'Analytics',
      'Multi-channel',
      'Human-agent handover',
    ]
  };

  productImportancePoints = [
    {
      title: 'Real-time',
      description: 'Ensure that your workflow executes instantaneously on change without any delay.',
      icon: 'fa-history'
    },
    {
      title: 'Collaboration',
      description: 'Create a team, assign roles and permissions to your colleagues and collaborate on projects in real-time.',
      icon: 'fa-user-friends'
    },
    {
      title: 'No Code',
      description: 'Build large and complex workflows quickly with multiple applications using our no code tool.',
      icon: 'fa-code'
    },
    {
      title: 'Conversations',
      description: 'Manage conversations across all popular messaging channels with NLP, context handling, and conversation handover capabilities.',
      icon: 'fa-comments-alt'
    }
  ];

  customerStoriesTabs = [
    'Finance',
    'Logistics',
    'Real estate',
    'E-Commerce',
    'Pharmaceutical'
  ];

  customerApps = [
    'salesforce',
    'razorpay',
    'gmail'
  ];

  tabsActiveIndex = 0;

  caseStudies$ = new Observable<IGroupedCaseStudies[]>();
  caseStudiesError$ = new Subject();
  caseStudyIndex = 0;

  individualCaseStudy$ = new Observable<IMetaCaseStudy>();

  currency: string;
  pricingDisplayStatus: string;

  platformURL = AT2URL;

  constructor(
    private appService: AppService,
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private caseStudiesService: CaseStudiesService,
    private pricingService: PricingService
  ) { }


  ngOnInit() {
    this.currentCategory = 'whatsapp';
    this.titleService.setTitle('The real-time Integration, API & Conversation Management Platform | Quickwork');
    this.metaService.addTags([
      {name: 'keywords', content: 'iPaaS,APIs,Messaging,Integrations,Automation,SaaS'},
      {name: 'description', content: 'Quickwork is a no-code automation platform, real-time, enterprise iPaaS with 1,000+ pre-integrated apps to build enterprise and consumer workflows,  publish APIs, and manage conversations.'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.getCaseStudies();

    this.appService.getPricingPageDisplayState().pipe(
      tap(state => this.pricingDisplayStatus = state),
      takeUntil(this.destroyed$)
    ).subscribe();

    this.pricingService.getCurrency().pipe(
      tap(currency => this.currency = currency),
      takeUntil(this.destroyed$)
    ).subscribe();
  }

  redirectToJourneyStore() {
    this.appService.redirectToJourneyStore();
  }

  redirect(index: number) {
    if (this.useCases[index].tryNowURL) {
      this.appService.redirect(this.useCases[index].tryNowURL);
    }
  }

  filterJourneysByCategory(alias: string) {
    this.currentCategory = alias;
    this.useCases = [];
    if (this.useCases.length === 0) {
      this.noUseCasesMessage = `No journeys found under alias`;
    }
  }

  navigateToAppDirectory() {
    this.router.navigate(['product/app-directory']);
  }

  navigateToJourneyStore() {
    this.appService.redirectToJourneyStore();
  }

  openIntercom() {
    this.appService.openIntercomBot();
  }

  setActiveIndex(i = 0) {
    this.tabsActiveIndex = i;
  }

  getCaseStudies() {
    this.caseStudies$ = this.caseStudiesService.getGroupedCaseStudies().pipe();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
