<div id="privacy-policy-container" class="header-wave">
    <div class="main-container">
        <div class="privacy-policy">

            <div class="policy-content">

                <p class="c13 t-a-c"><strong class="c6">ACCEPTABLE USE POLICY</strong></p>
                <br>
                <br>
                <br>
                <p class="c1" id="h.gjdgxs">
                    <span class="c8">Kindly read this Acceptable Use Policy (“AUP”)
                        carefully before using the Platform, Website, and Products and Services offered by Quickwork
                        (“Quickwork”, “We”, “Our”). This AUP defines the acceptable use of and access to the
                        Platform, Website, and Products and Services offered by Quickwork. BY ACCESSING OR USING OUR
                        PLATFORM, WEBSITE OR PRODUCTS AND SERVICES, YOU (“Customer”) AGREE TO THE TERMS OF THIS AUP.
                        The AUP forms a part of the Terms of Service of Quickwork located on the Website at
                    </span>
                    <span class="c8 c10"><a class="c7" href="/terms-of-service"
                            target="_blank">https://quickwork.co/terms-of-service</a></span><span class="c0">.</span>
                </p>
                <br>
                <p class="c1">
                    <span class="c0">Our Platform, Website, and Products and Services may only be used for
                        lawful purposes. You agree to comply with all the applicable laws, rules, and regulations in
                        association with your use of our Platform, Website, and Products and Services. Any material
                        or conduct that in our judgment violates this policy in any manner shall result in
                        suspension or termination of the services or removal of Customer and/or User account with or
                        without notice.</span>
                </p>
                <br>
                <p class="c1"><span class="c0">Unless otherwise defined herein, all capitalized terms used within
                        this AUP have the same meaning as ascribed to such Terms of Service.</span></p>
                <br>
                <br>
                <ol class="c4 lst-kix_list_2-0 start" start="1" type="I">
                    <li class="c1 c5 li-bullet-0"><strong class="c3">Prohibited Use and Customer Data</strong></li>
                    <p class="c1">
                        <span class="c0">Customer shall not use the Website and/or the Platform and/or its
                            Products and Services to publish content or participate in any activity that is illegal
                            under the applicable law, that is harmful to others, or that would subject us to liability,
                            including, without limitation, in association with any of the following, that Quickwork
                            believes is:</span>
                    </p>
                    <br>
                    <ol class="c4 lst-kix_list_1-0 start" start="1">
                        <li class="c2 li-bullet-1"><span class="c0">Violative of any local, state, national,
                                international/foreign, (herein cumulatively referred to as the "Laws" and (to the extent
                                enforceable on the relevant date) and wherever applicable, the rules framed thereunder
                                and any subsequent amendment or re-enactment thereof for the time being in
                                force);</span></li>
                        <li class="c2 li-bullet-2"><span class="c0">Displaying, performing, sending, receiving or
                                storing any content that is obscene, pornographic or any explicit and/or objectionable
                                material, including but not limited to material that may be lewd, offensive, slanderous,
                                lascivious, hateful, abusive, defamatory, or depicting excessive violence, regardless of
                                whether the material or its dissemination is unlawful; any discriminatory material based
                                on race, sex, religion, nationality, disability, sexual orientation or age;</span></li>
                        <li class="c2 li-bullet-2"><span class="c0">Advocating or encouraging violence against any
                                government, organization, group, individual or property, or providing instruction,
                                information, or assistance in causing or carrying out such violence, regardless of
                                whether such activity is unlawful;</span></li>
                        <li class="c2 li-bullet-1"><span class="c0">Infringes upon the rights of others, including, but
                                not limited to, misappropriation of any intellectual property or proprietary right of
                                any other third-party; transmitting sensitive personal information of an individual in a
                                manner that can be associated with the individual, such as social security number,
                                government-issued identification number, health or medical information, credit card or
                                debit card number, financial account information, access codes and password, or date of
                                birth; inducing or advocating any illegal activity; stalking, harassment, or harming
                                anyone, including minors; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span></li>
                        <li class="c2 li-bullet-1"><span class="c0">Impersonating any individual or entity or otherwise
                                misrepresenting Customer's affiliation with a person or entity;</span></li>
                        <li class="c2 li-bullet-1"><span class="c0">Tampering with, modifying, altering, repairing, or
                                otherwise creating- derivative works of any software included in the Platform;</span>
                        </li>
                        <li class="c2 li-bullet-1"><span class="c0">Constitutes to reverse engineering, disassembles, or
                                decompiles the Website and/or the Platform or Products and Services or software included
                                in the Platform (except as allowed by the law pertaining in India);</span></li>
                        <li class="c2 li-bullet-1"><span class="c0">Accessing/using or attempting to access/use the
                                Platform in a manner intended to avoid incurring fees or exceeding usage or subscription
                                limits or quotas;</span></li>
                        <li class="c2 li-bullet-2"><span class="c0">Interfering with or disrupting the Website and/or
                                the Platform or servers or networks connected to the Platform or the Website or hacking,
                                destabilizing, or adapting the Platform, or changing another website to incorrectly
                                suggest it is associated with our Platform and or the Website.</span></li>
                        <li class="c2 li-bullet-1"><span class="c0">Attempting to gain unauthorized or illegal access,
                                in any form, to any feature of the Website and/or the Platform, whether through hacking,
                                phishing, password mining, or any other method;</span></li>
                        <li class="c2 li-bullet-1"><span class="c0">Uploading any contents/materials/data that contains
                                viruses, worms, corrupt files, Trojan horses, or other forms of corruptive code, or any
                                additional content that may compromise the security or integrity of the Website and/or
                                the Platform;</span></li>
                        <li class="c2 li-bullet-1"><span class="c0">Monitoring data or traffic on the Website and/or the
                                Platform without valid authorizations or permission;</span></li>
                        <li class="c2 li-bullet-1"><span class="c0">Accessing or using the Website and/or Platform or
                                any Product and Services without permission, including attempts to probe, scan, or test
                                the vulnerability of the Website and/or the Platform and/or Product and Services or to
                                breach any security obligations or authentication measures used by the Website and/or
                                Platform; and</span></li>
                        <li class="c2 li-bullet-1"><span class="c0">Intentionally or unintentionally interfering with
                                the proper functioning of the Website and/or the Platform and any Products and Services,
                                including any deliberate attempt to overload it by any means;</span></li>
                        <li class="c1 c12 li-bullet-1"><span class="c0">The Customer shall not provide Platform
                                passwords and other login credentials to any third party and enable them to access or
                                derive benefit out of the Platform (relatives and other </span><span
                                class="c0">associate</span><span class="c0">).</span></li>
                    </ol>
                    <p class="c1"><span class="c0">Without prejudice to the above, Quickwork reserves the sole
                            discretion to ascertain whether Customer Data or Customer's use of the Platform is
                            prohibited. All Customer Data or any other materials and content that is provided to
                            Quickwork or activities that are performed via Customer's account, whether provided or
                            performed by Customer's employees, Customer's contractors, consultants, &nbsp;dealers and
                            Users, shall be deemed to be performed by the Customer and the Customer shall bear the sole
                            responsibility of such data/content/material and the consequence arising therewith.</span>
                    </p>
                    <br>
                    <br>
                    <li class="c1 c5 li-bullet-0"><strong class="c3">Our Monitoring and Enforcement</strong></li>
                    <p class="c1"><span class="c0">We reserve the right, but do not assume the obligation, to
                            investigate any violation or misuse of this AUP in accordance with the Terms of Service.
                            Quickwork may at its sole discretion or upon requests may:</span>
                    </p>
                    <br>
                    <ol class="c4 lst-kix_list_3-0 start" start="1">
                        <li class="c2 li-bullet-1"><span class="c0">With or without notice to you, remove, disable
                                access to, or modify any content or resource that violates this AUP or any other
                                agreement we have with you by investigating violations (actual or potential) of this AUP
                                or misuse of the Website and/or the Platform and/or Products and Services;</span></li>
                        <li class="c2 li-bullet-1"><span class="c0">Report any activity that we suspect violates any law
                                or regulation to appropriate law enforcement officials, regulators, or other appropriate
                                third parties, including disclosing appropriate customer information, as provided in the
                                Privacy Policy; investigate and aid in prevention of security threats, fraud, or other
                                illegal, malicious, or inappropriate activity;</span></li>
                        <li class="c2 li-bullet-2"><span class="c0">Cooperate with appropriate law enforcement agencies,
                                regulators, or other appropriate third parties to help with the investigation and
                                prosecution of illegal conduct by providing network and systems information related to
                                alleged violations of this AUP.</span></li>
                        <li class="c2 li-bullet-2"><span class="c0">Remove, disable access to, or modify (with or
                                without notice) any Customer Data or resource that Quickwork believes in violation of
                                this AUP or any other agreement Quickwork has with Customer for use of the Website
                                and/or the Platform; or</span></li>
                        <li class="c1 c12 li-bullet-1"><span class="c0">Suspend or terminate services of Platform to
                                Customer for uses that violate this AUP or any other agreement Quickwork has with
                                Customer for use of the Platform.</span></li>
                    </ol>
                    <br>
                    <br>
                    <li class="c1 c5 li-bullet-0"><strong class="c3">Report Violations</strong></li>
                    <p class="c1"><span class="c8">Customer</span><span class="c0">&nbsp;can report the misuse of this
                            AUP to support@quickwork.co.</span></p>
                    <br>
                    <br>
                    <li class="c1 c5 li-bullet-0"><strong class="c3">Modifications to the AUP</strong></li>
                    <p class="c1"><span class="c0">Quickwork may, in its sole and absolute discretion, modify this AUP
                            from time to time. If Customer objects to any such changes, Customer's sole recourse shall
                            be to stop using the Website, Platform and Products and Services. Continued use of the
                            Website or Platform or Products and Services following notice of any such changes shall
                            indicate Customer's acknowledgement of such changes and agreement to be bound by the terms
                            and conditions of such changes.</span></p>
                </ol>
            </div>
        </div>
    </div>
</div>