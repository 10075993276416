import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlatformComponent } from './platform.component';
import { SharedModule } from 'src/app/shared-components/shared.module';
import { JourneyTemplateComponent } from './journey-template/journey-template.component';



@NgModule({
  declarations: [PlatformComponent, JourneyTemplateComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class PlatformModule { }
