import { AfterViewInit, Component, ElementRef, Input, OnDestroy, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { ICurrency, IPlan } from './pricing.model';
import { combineLatest, fromEvent, Subject } from 'rxjs';
import { concatMap, filter, map, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'qw-plan-selector',
  templateUrl: './plan-selector.component.html',
  styleUrls: ['./plan-selector.component.scss']
})
export class PlanSelectorComponent implements AfterViewInit, OnDestroy {

  @Input() businessPlans: IPlan[];
  @Input() freedomPlan: IPlan;
  @Input() showAnnualPlan: boolean;
  @Input() currency: ICurrency;
  @ViewChildren('planPoint') planPoint: QueryList<HTMLElement>;
  @ViewChild('planSlider') planSlider: ElementRef;
  @ViewChild('sliderSelection') sliderSelection: ElementRef;
  @ViewChild('plansElement') plansElement: ElementRef;
  currentPlanIndex = 0;

  isDragging = false;
  private pointerState: 'down' | 'move';
  private destroyed$ = new Subject();

  constructor(private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.selectPlan();

    fromEvent(window, 'resize').pipe(
      tap(() => this.selectPlan(this.currentPlanIndex)),
      takeUntil(this.destroyed$)
    ).subscribe();

    fromEvent(this.planSlider.nativeElement, 'pointerdown').pipe(
      filter((event: PointerEvent) => event.button === 0),
      tap(() => this.pointerState = 'down'),
      map(event => event.pointerId),
      switchMap(pointerId => combineLatest(
        fromEvent(document, 'pointermove').pipe(
          filter((event: PointerEvent) => pointerId === event.pointerId),
          tap(() => {
            this.isDragging = true;
            this.pointerState = 'move';
          }),
          takeWhile(() => !this.pointerState)
        ),
        fromEvent(document, 'pointerup').pipe(
          filter((event: PointerEvent) => pointerId === event.pointerId && this.isDragging),
          tap(() => {
            this.isDragging = false;
            this.pointerState = null;
          })
        ),
      )),
    ).subscribe();
  }

  selectPlan(index = 0) {
    this.currentPlanIndex = index;
    const scrollLeft = (this.plansElement.nativeElement as HTMLElement).scrollLeft;
    const planPoint = this.planPoint['_results'][index].nativeElement as HTMLElement;
    const width = planPoint.offsetWidth / 2;
    const left = (planPoint.offsetLeft + width) - scrollLeft;
    this.renderer.setStyle(this.planSlider.nativeElement, 'left', `${left - 24}px`);
    this.renderer.setStyle(this.sliderSelection.nativeElement, 'width', `${left - 4}px`);
  }

  updateSliderPosition(index: number) {
    if (this.isDragging) { this.selectPlan(index); }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
