import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppDirectoryComponent } from './app-directory/app-directory.component';
import { SharedModule } from 'src/app/shared-components/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UseCasesModule } from './use-cases/use-cases.module';
import { PlatformModule } from './platform/platform.module';
import { CategorizedAppsComponent } from './app-directory/categorized-apps.component';



@NgModule({
  declarations: [
    AppDirectoryComponent,
    CategorizedAppsComponent
  ],
  imports: [
    CommonModule,
    UseCasesModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    PlatformModule
  ],
  exports: [
    UseCasesModule,
    CategorizedAppsComponent
  ]
})
export class ProductModule { }
