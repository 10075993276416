import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { PLATFORM_BASE_PATH } from 'src/app/app.constants';
import { createRequestOption } from 'src/app/app.service';

export interface IUseCase {
  id: string;
  title: string;
  category: string;
  imageSrc: string;
  imageOverlayColor: string;
}

export interface IMetaUseCase {
  id: string;
  active: true;
  title: string;
  category: string;
  problemStatement: string;
  solution: string;
  apps: string[];
  impact: string[];
  imageSrc: string;
  imageOverlayColor: string;
}

export interface IGroupedUseCases {
  data: IMetaUseCase[];
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class UseCasesService {

  resourceURL = `${PLATFORM_BASE_PATH}workflow/api/public/use-cases`;
  private totalUseCases: number;
  private categories: string[] = [];

  constructor(private http: HttpClient) { }

  getUseCases(req = {}) {
    const params = createRequestOption(req);
    return this.http.get<IUseCase[]>(this.resourceURL, { observe: 'response', params }).pipe(
      tap(response => this.totalUseCases = parseInt(response.headers.get('X-Total-Count'), 10)),
      map(response => response.body)
    );
  }

  getUseCasesCategories() {
    const url = `${this.resourceURL}api/public/use-cases/categories`;
    // const url = 'https://run.mocky.io/v3/c715a5b2-a4e8-4256-a612-c1c18c9c8cb1';
    return this.http.get<string[]>(url, { observe: 'response' }).pipe(
      map(response => response.body)
    );
  }

  getUseCase(id: string) {
    return this.http.get<IMetaUseCase>(`${this.resourceURL}/${id}`);
  }

  getTotalUseCases() {
    return this.totalUseCases;
  }

  getGroupedUseCases() {
    const url = `${this.resourceURL}/group/category`;
    return this.http.get<IGroupedUseCases[]>(url).pipe(
      tap(useCases => this.appendCategories(useCases))
    );
  }

  private appendCategories(caseStudies: IGroupedUseCases[]) {
    caseStudies.forEach(caseStudy => {
      if (caseStudy.id) {
        const industry = caseStudy.id;
        if (!this.categories.includes(industry)) {
          this.categories.push(industry);
        }
      }
    });
  }

  getCategories() {
    return this.categories;
  }
}
