import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricingComponent } from './pricing.component';
import { SharedModule } from 'src/app/shared-components/shared.module';
import { PlanSelectorComponent } from './plan-selector.component';
import { PlanIntervalToggleComponent } from './plan-interval-toggle/plan-interval-toggle.component';
import { CurrencySelectorComponent } from './currency-selector/currency-selector.component';

@NgModule({
  declarations: [
    PricingComponent,
    PlanSelectorComponent,
    PlanIntervalToggleComponent,
    CurrencySelectorComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [CurrencySelectorComponent]
})
export class PricingModule { }
