import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'qw-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent {

  @Input() toggleValue: boolean;
  @Output() toggleValueChange = new EventEmitter();
  @HostListener('click')
  toggle() {
    this.toggleValueChange.emit(this.toggleValue);
  }

}
