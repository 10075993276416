import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { fadeEnterExit } from '../animations/fade-enter-exit.animation';
import { CookieService } from '../core/cookie-service.service';

@Component({
  selector: 'qw-cookie-policy-consent',
  templateUrl: './cookie-policy-consent.component.html',
  styleUrls: ['./cookie-policy-consent.component.scss'],
  animations: [fadeEnterExit]
})
export class CookiePolicyConsentComponent implements OnInit {

  showConsentBox: boolean;

  constructor(
    private cookieService: CookieService,
    private appService: AppService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router
  ) { }

  ngOnInit() {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (!this.router.url.includes('cookie-policy')) {
          this.showConsentBox = this.cookieService.getCookieTrackingConsent();
          this.setMobileBackdrop();
        } else this.showConsentBox = false;
      }
    });
  }

  accept() {
    this.cookieService.acceptCookie();
    this.showConsentBox = false;
    this.setMobileBackdrop();
  }

  reject() {
    this.cookieService.rejectCookie();
    this.showConsentBox = false;
    this.setMobileBackdrop();
  }

  private setMobileBackdrop() {
    if (this.appService.getIsPlatformBrowser()) {
      if (this.showConsentBox) {
        this.renderer.addClass(this.elementRef.nativeElement, 'mobile-backdrop');
      } else this.renderer.removeClass(this.elementRef.nativeElement, 'mobile-backdrop');
    }
  }

}
