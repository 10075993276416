<ng-container *ngIf="useCase$ | async as useCase">
    <section id="header-section" class="trapezoid-fill">
        <div class="main-container">
            <div class="use-case-header">
                <div class="use-case-header-content">
                    <h1 class="use-case-header-title">{{ useCase.title }}</h1>
                    <h3 class="caption">About customer</h3>
                    <p class="customer-description">Flexiloans is a leading fintech lending platform, primarily
                        focussing on providing business loans to Small and Medium Enterprises. Their vision is to
                        provide “Financial Access at a Click”.
                    </p>
                </div>
                <div class="use-case-header-representation">
                    <div class="use-case-header-image img-card no-hover" [bgImage]="useCase.imageSrc" [overlayColor]="useCase.imageOverlayColor">
                        <!-- <img [src]="useCase.imageSrc" alt="" srcset=""> -->
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="use-case-challenge-section">
        <div class="main-container">
            <div class="column-layout c-1-1 size-3fr-1fr">
                <div class="column l-m-border" id="customer-problem">
                    <div class="column-body">
                        <h2 class="border-highlight">Customer Challenge</h2>
                        <p>{{ useCase.problemStatement }}</p>
                    </div>
                    <div class="column-body">
                        <h2 class="border-highlight">Solution</h2>
                        <p [innerHTML]="useCase.solution"></p>
                    </div>
                </div>
                <div class="column">
                    <div class="use-case-integrations">
                        <p class="integrations-title">Integrations used</p>
                        <div class="integration-apps">
                            <div class="integration-app" *ngFor="let app of useCase.apps">
                                <qw-app-icon [appAlias]="app"></qw-app-icon>
                                <p>{{ app | appName | async }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <ng-container *ngIf="otherUseCases$ | async as otherUseCases">
        <section id="other-case-studies-section" *ngIf="otherUseCases.length">
            <div class="main-container">
                <h2>Explore other use cases</h2>
                <div class="column-layout c-1-half" [class.c-2-el]="otherUseCases.length < 3">
                    <ng-container *ngFor="let otherUseCase of otherUseCases">
                        <qw-journey-use-case class="column" [useCase]="otherUseCase"></qw-journey-use-case>
                    </ng-container>
                </div>
            </div>
        </section>
    </ng-container>
</ng-container>