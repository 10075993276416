import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * Guard for displaying active link on navbar
 */

@Injectable({
  providedIn: 'root'
})
export class ActiveLinkGuard implements Resolve<any> {

  private activeRouteLabel$ = new BehaviorSubject<string>(null);

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let routeLabel = null;
    if (route.data.label) {
      routeLabel = route.data.label;
    }
    this.activeRouteLabel$.next(routeLabel);
  }

  getActiveRouteLabel() {
    return this.activeRouteLabel$.pipe(
      distinctUntilChanged()
    );
  }
}
