import { Component, OnInit, ViewChildren, QueryList, Input } from '@angular/core';
import { AppService, NavLinks } from 'src/app/app.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
// import { TooltipService } from '@teamhive/ngx-tooltip';
// import { MOBILE_NAVBAR_MENU_OPTIONS, NAVBAR_DROPDOWN_OPTIONS } from '../constants/tooltip.constants';
import { fadeEnterExit } from '../animations/fade-enter-exit.animation';
import { AT2URL } from '../constants/miscellaneous.constants';

@Component({
  selector: 'qw-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [fadeEnterExit]
})
export class NavbarComponent implements OnInit {

  @Input() username: string;
  navToggle = false;
  navIcon = 'bars';

  isOnQCPage: boolean;
  navLinks: NavLinks[] = [
    {
      name: 'Platform',
      link: '/product/features',
      show: true,
      type: 'link'
    },
    {
      name: 'Apps',
      link: '/product/app-directory',
      show: true,
      type: 'link'
    },
    {
      name: 'Case studies',
      link: '/case-studies',
      show: true,
      type: 'link'
    },
    {
      name: 'Blogs',
      link: 'https://blog.quickwork.co/',
      show: true,
      type: 'href'
    },
    {
      name: 'Pricing',
      link: '/pricing',
      show: true,
      type: 'link'
    }
    // {
    //   name: 'Resources',
    //   link: 'null',
    //   show: true,
    //   type: 'link',
    //   children: [
    //     {
    //       name: 'Blogs',
    //       link: 'https://blog.quickwork.co/',
    //       show: true,
    //       type: 'href'
    //     },
    //     // {
    //     //   name: 'Customers',
    //     //   link: '/customers',
    //     //   show: true,
    //     //   type: 'link'
    //     // },
    //     // {
    //     //   name: 'Use cases',
    //     //   link: '/use-cases',
    //     //   show: true,
    //     //   type: 'link'
    //     // },
    //     // {
    //     //   name: 'Helpdesk',
    //     //   link: 'https://helpdesk.quickwork.co/',
    //     //   show: true,
    //     //   type: 'href'
    //     // },
    //     {
    //       name: 'Support Center',
    //       link: 'https://support.quickwork.co/hc/en-us',
    //       show: true,
    //       type: 'href'
    //     }
    //   ]
    // }
  ];
  // navbarDropdownOptions = NAVBAR_DROPDOWN_OPTIONS;

  activatedRouteLabel: string;

  platformURL = AT2URL;

  @ViewChildren('productElement') productElement: QueryList<HTMLElement>;

  constructor(
    private appService: AppService,
    private router: Router,
    // private tooltipService: TooltipService
  ) { }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.closeNav();
      }
    });

  }

  closeNav() {
    this.navToggle = false;
    this.navIcon = 'bars';
  }

  toggleNav() {
    this.navToggle = !this.navToggle;
    this.navIcon = !this.navToggle ? 'bars' : 'times';
  }

  navigate(link: string, type: 'link' | 'href') {
    switch (type) {
      case 'link':
        this.router.navigate([link]);
        break;
      case 'href':
        window.open(link);
        break;
    }
    // this.tooltipService.hideAll();
  }

}
