import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { AppService } from './app.service';
import { Intercom } from '../assets/js/Intercom.js';
import { PricingService } from './pages/pricing/pricing.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CookieService } from './shared-components/core/cookie-service.service';
import { CaseStudiesService } from './pages/case-studies/case-studies.service';

@Component({
  selector: 'qw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'QW-Website';
  username: string;
  private destroyed$ = new Subject();

  @HostListener('mousedown')
  onMouseDown() {
    this.appService.mouseDownEvent.emit();
  }

  constructor(
    private appService: AppService,
    private pricingService: PricingService,
    private cookieService: CookieService,
    private caseStudiesService: CaseStudiesService
  ) { }

  ngOnInit() {
    // this.appService.getGeoIP().pipe(
    //   takeUntil(this.destroyed$)
    // ).subscribe();
    this.appService.getAppsFromURL().pipe(
      takeUntil(this.destroyed$)
    ).subscribe();
    this.pricingService.getAllPlans().pipe(
      takeUntil(this.destroyed$)
    ).subscribe();
    this.caseStudiesService.getGroupedCaseStudies().pipe(
      takeUntil(this.destroyed$)
    ).subscribe();
    Intercom();
    this.username = this.cookieService.getCookie('username');
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
