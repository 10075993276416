<div id="privacy-policy-container" class="header-wave">
    <div class="main-container">
        <div class="privacy-policy">
            <div class="m-b-20">
                <div class="highlight-text">Refund and Cancellation Policy</div>
            </div>

            <div class="policy-content">
                <p class="c10"><span class="c2">Please read the guidelines governing the refund and cancellation
                        policy.</span></p>
                <ol class="c3 lst-kix_ky5m55de5brc-0 start" start="1">
                    <li class="c0 li-bullet-0">
                        <span class="c5 c1">Quickwork offers a variety of subscription plans to
                            fit any need. Customers can sign up and pay in advance or at time-of use, with all services
                            rendered non refundable once selected. Once a customer selects a subscription plan and
                            provides billing information, Quickwork will calculate a pro-rated amount with the first
                            payment due on signing.</span>
                    </li>
                    <li class="c0 li-bullet-0">
                        <span>
                            <span class="c1">All subscriptions monthly and yearly renew automatically
                                on their due renewal date according to date of purchase until officially canceled in
                                writing. Customers may cancel anytime by emailing a notice to: </span>
                            <span class="c6 c4">
                                <a class="c8" href="mailto:support@quickwork.com">support@quickwork.co</a>
                            </span>
                            <span class="c1">&nbsp;originating from the email id used to create an account on Quickwork.
                            </span>
                            <span class="c1 c5">Customers will receive an email from Quickwork confirming that their
                                subscription to the service has been canceled.</span>
                        </span>
                    </li>
                    <li class="c0 li-bullet-0"><span class="c5 c1">Important: No refunds or credits for partial months,
                            quarters or years of service will be refunded to a customer upon cancellation.</span></li>
                    <li class="c0 li-bullet-0">
                        <span>
                            <span class="c4">All fees are exclusive of all taxes, levies, or duties
                                imposed by taxing authorities, and the customer is responsible for payments of all such
                                taxes, levies, or duties. </span>
                            <span class="c4">Any currency exchange settlements are
                                based on the customer's agreement with the payment method provider and these charges are
                                the
                                customer's responsibility.</span>
                        </span>
                    </li>
                    <li class="c0 li-bullet-0">
                        <span>
                            <span class="c1">Customers can upgrade or downgrade their subscription
                                plan at any time. For any upgrade or downgrade in subscription, the customer has to
                                write to
                            </span><span class="c4 c6"><a class="c8"
                                    href="mailto:support@quickwork.co">support@quickwork.co</a></span><span
                                class="c5 c1">&nbsp;or change it from their subscription page under the account
                                settings.</span>
                        </span>
                    </li>
                    <li class="c0 li-bullet-0"><span class="c5 c1">The chosen billing method will automatically be
                            charged the new subscription rate on the next billing cycle.</span></li>
                    <li class="c0 li-bullet-0"><span class="c5 c1">Regarding downgrades on a subscription, Quickwork
                            does not issue refunds or credits for partial months, quarters or years of service.</span>
                    </li>
                    <li class="c0 li-bullet-0"><span class="c5 c1">Quickwork reserves the right to refuse/cancel a
                            subscription to any SaaS products or services bought from Quickwork. No refunds will be
                            offered if Quickwork refuses a new or renewing subscription.</span></li>
                    <li class="c0 li-bullet-0"><span class="c1">The above policies apply to all the SaaS products and
                            services offered by Quickwork unless otherwise noted in the corresponding program
                            materials.</span></li>
                </ol>
            </div>
        </div>
    </div>
</div>