import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable, Subject, EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UseCasesService } from '../../product/use-cases/use-cases.service';

@Component({
  selector: 'qw-case-study-filters',
  templateUrl: './case-study-filters.component.html',
  styleUrls: ['./case-study-filters.component.scss']
})
export class CaseStudyFiltersComponent implements OnInit {

  currentCategory = 'All case-studies';
  categories$ = new Observable();
  categoriesError$ = new Subject();

  @Output() onFilter = new EventEmitter();

  constructor(private useCasesService: UseCasesService) { }

  ngOnInit() {
    this.getCategories();
  }

  getCategories() {
    this.categoriesError$.next(undefined);
    this.categories$ = this.useCasesService.getUseCasesCategories().pipe(
      map(categories => categories.filter(this.getDistinctApps)),
      map(categories => ['All case-studies', ...categories]),
      catchError((error: HttpErrorResponse) => { this.categoriesError$.next(error); return EMPTY; })
    );
  }

  getDistinctApps(value, index, self) {
    return self.indexOf(value) === index;
  }

  filterUseCases(value: string, key: string) {
    this.onFilter.emit({
      category: value
    });
  }

}
