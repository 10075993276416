import { AfterViewInit } from '@angular/core';
import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[scrollShadow]'
})
export class ScrollShadowDirective implements AfterViewInit {

  @Input() orientation: 'horizontal' | 'vertical' = 'vertical';
  @HostBinding('class.scroll-shadow') scrollShadow = true;

  // Orientation class variables
  @HostBinding('class.vertical') vertical: boolean;
  @HostBinding('class.horizontal') horizontal: boolean;

  // Position class variables
  @HostBinding('class.top-shadow') topShadow: boolean;
  @HostBinding('class.bottom-shadow') bottomShadow: boolean;
  @HostBinding('class.left-shadow') leftShadow: boolean;
  @HostBinding('class.right-shadow') rightShadow: boolean;

  // Scroll event listener
  @HostListener('scroll') setScrollShadow() {
    const element = this.element.nativeElement as HTMLElement;
    if (this.orientation === 'horizontal') {
      this.horizontal = true;
      const scrollWidth = element.scrollWidth;
      const scrollLeft = Math.ceil(element.scrollLeft);
      const width = element.offsetWidth;

      const widthDiff = scrollWidth - width;
      if (width < scrollWidth) {
        if (scrollLeft === 0) {
          this.leftShadow = false;
          this.rightShadow = true;
        } else if (scrollLeft > 0 && scrollLeft < widthDiff) {
          this.leftShadow = true;
          this.rightShadow = true;
        } else if (scrollLeft === widthDiff) {
          this.leftShadow = true;
          this.rightShadow = false;
        }
      }
    } else if (this.orientation === 'vertical') {
      this.vertical = true;
      const scrollHeight = element.scrollHeight;
      const scrollTop = Math.ceil(element.scrollTop);
      const height = element.offsetHeight;

      const heightDiff = scrollHeight - height;
      if (height < scrollHeight) {
        if (scrollTop === 0) {
          this.topShadow = false;
          this.bottomShadow = true;
        } else if (scrollTop > 0 && scrollTop < heightDiff) {
          this.topShadow = true;
          this.bottomShadow = true;
        } else if (scrollTop === heightDiff) {
          this.topShadow = true;
          this.bottomShadow = false;
        }
      }
    }
  }

  constructor(private element: ElementRef) { }

  ngAfterViewInit() {
    Promise.resolve().then(() => {
      this.setScrollShadow();
    });
  }
}
