import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { IUseCase, UseCasesService } from './use-cases.service';

@Component({
  selector: 'qw-use-cases',
  templateUrl: './use-cases.component.html',
  styleUrls: ['./use-cases.component.scss']
})
export class UseCasesComponent implements OnInit {

  useCases$ = new Observable<IUseCase[]>();
  useCasesError$ = new Subject();
  currentCategory = 'All use-cases';
  page = 1;
  itemsPerPage = 20;
  totalItems = 0;
  noUseCasesMessage: string;

  constructor(
    private appService: AppService,
    private useCasesService: UseCasesService,
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit() {
    this.metaService.addTags([
      { name: 'robots', content: 'index, follow' }
    ]);
    this.appService.getApps().pipe(
      take(1),
      tap(() => this.refetchUseCases())
    ).subscribe();
  }

  getUseCases(page = 1) {
    this.useCasesError$.next(undefined);
    this.page = page;
    const params = {
      page: this.page - 1,
      size: this.itemsPerPage,
      sort: ['id, desc']
    };
    if (this.currentCategory !== 'All use-cases') { Object.assign(params, { category: this.currentCategory }); }
    this.useCases$ = this.useCasesService.getUseCases(params).pipe(
      tap(useCases => {
        const hasUseCases = useCases.length > 0;
        if (!hasUseCases) {
          if (this.currentCategory !== 'All use-cases') {
            this.noUseCasesMessage = 'No use cases found under this category';
          } else {
            this.noUseCasesMessage = 'No use cases found';
          }
        }
        this.totalItems = this.useCasesService.getTotalUseCases();
      }),
      // map(useCases => {
      //   for (let i = 0; i < 20; i++) {
      //     useCases.push(useCases[0]);
      //   }
      //   return useCases;
      // }),
      catchError((error: HttpErrorResponse) => {
        this.useCasesError$.next(error);
        return EMPTY;
      })
    );
  }

  filterUseCases(filterParams) {
    this.currentCategory = filterParams.category;
    this.getUseCases();
  }

  getUseCasesByCategory(category: string = null) {
    this.currentCategory = category === 'All use-cases' ? '' : category;
    this.getUseCases();
  }

  refetchUseCases() {
    this.getUseCases();
  }

  getDistinctApps(value, index, self) {
    return self.indexOf(value) === index;
  }

}
