import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Ifaq } from 'src/app/app.service';

@Component({
    selector: 'app-partnerships',
    templateUrl: './partnerships.component.html',
    styleUrls: ['./partnerships.component.scss']
})
export class PartnershipsComponent implements OnInit {

    partnerCXPoints = [
        'Partnership portal',
        'Zero DevOps',
        'Elastic scalability',
        'White-label URLs',
        'Embedded integrations',
        '1000+ apps',
        '2000+ pre-built Journeys',
        'New revenue streams',
        'Training & certification',
        'Re-run workflows',
        'Version control',
        'Team collaboration',
        'API Management',
        'Built-in databases',
        'Custom pricing calculator',
        'Custom connectors',
        'Formula engine',
        'On-prem services',
        'Rich analytics',
        'Conversational integration'
    ];

    partnershipTypes = [
        {
            title: 'Integration partner',
            description: 'Our integration partner program is for on-cloud product and service companies who want to offer their users the capability of integration with 1000+ apps, building automated workflows, publishing APIs, and managing conversations with their product and technology stack.',
            points: [
                'Training & certification',
                'Zero setup',
                'Go-live in 1 day',
                'Your own brand',
                'Embedded Integrations',
                'Access to our customer success team',
                'Partner management console'
            ]
        },
        {
            title: 'Solution partner',
            description: 'Our solution partner program is for system integration, IT consulting companies, and software developers who want to offer integration and automation services in their portfolio.',
            points: [
                'Training & certification',
                'Zero setup',
                'Go-live in 1 day',
                'Your own brand',
                'Access to our customer success team',
                'Partner management console'
            ]
        },
        {
            title: 'Affiliate partner',
            description: 'Our affiliate partner program is for anyone who wants to refer prospective customers to us and earn a recurring income.',
            points: [
                'Monthly commission',
                'New revenue steams'
            ]
        }
    ];

    partnerShipsFAQs = [
        {
            icon: 'new-revenue-streams-icon.svg',
            title: 'New revenue streams',
            description: 'With Quickwork open new avenues with automation, differentiate your services and grow your business with multiple  revenue streams and opportunities.'
        },
        {
            icon: 'flexible-partner-programs-icon.svg',
            title: 'Flexible partner programs',
            description: 'Choose the program that best suits your business model and requirements.'
        },
        {
            icon: 'differentiated-offerings-icon.svg',
            title: 'Differentiated offerings',
            description: 'With integration and automation, differentiate your products and services from your competitors.'
        },
        {
            icon: 'training-and-certification-icon.svg',
            title: 'Training and certification',
            description: 'Get access to hands-on training and certification programs and stay updated to serve your customers better.'
        }
    ];

    partnerLogos = [
        {
            image: 'Swiftlet.png',
            label: 'Swiftlet'
        },
        {
            image: 'RChilli.png',
            label: 'RChilli'
        },
        {
            image: 'Panaceast.jpg',
            label: 'Panaceast'
        },
        {
            image: 'Logo_Intel Nexo.png',
            label: 'Logo_Intel Nexo'
        },
        {
            image: 'Kulkul.png',
            label: 'Kulkul'
        },
        {
            image: 'eBiashara.jpeg',
            label: 'eBiashara'
        },
        {
            image: 'Biomni.png',
            label: 'Biomni'
        },
        {
            image: 'aNumak.png',
            label: 'aNumak'
        },
    ];

    constructor(
        private titleService: Title,
        private metaService: Meta
    ) { }

    ngOnInit() {
        this.titleService.setTitle('Why Partner With Us | Quickwork ');
        this.metaService.addTags([
            {name: 'description', content: 'Partner with us to get immediate access to Quickwork’s capabilities for Integrations, API Management, and Conversation Management and enhance your solution offerings.'},
            {name: 'robots', content: 'index, follow'}
        ]);
    }

    scrollToPartnerForm(formSectionElement: HTMLElement) {
        window.scrollTo({
            top: formSectionElement.offsetTop,
            behavior: 'smooth'
        });
    }

}
