import { Component, OnInit, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'qw-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss']
})
export class AlertBoxComponent implements OnInit {
  @Input() feedbackContent: any;
  @Input() type: 'danger' | 'success' | 'info';
  @Input() showAlertBox = true;
  isHTML: boolean = false;
  feedbackContentTemplate: TemplateRef<any>;

  constructor() {}

  ngOnInit() {
      if (typeof this.feedbackContent !== 'string') {
          this.isHTML = true;
          this.feedbackContentTemplate = this.feedbackContent;
      } else this.isHTML = false;
  }

}
