import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'qw-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() totalItems: number;
  @Input() itemsPerPage = 20;
  @Input() page: number;
  @Input() pageSize = 5;
  @Output() pageChange = new EventEmitter();

  totalPages = 0;

  constructor() { }

  ngOnInit() {
    // tslint:disable-next-line: radix
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
  }

  updatePage(operationType: 'inc' | 'dec' | 'first' | 'last') {
    switch (operationType) {
      case 'inc':
        this.page++;
        break;
      case 'dec':
        this.page--;
        break;
      case 'first':
        this.page = 1;
        break;
      case 'last':
        this.page = this.totalPages;
        break;
    }
    this.pageChange.emit(this.page);
  }

}
