import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'qw-categorized-apps',
  templateUrl: './categorized-apps.component.html',
  styleUrls: ['./categorized-apps.component.scss']
})
export class CategorizedAppsComponent implements OnChanges {

  @Input() appsSearchValue: string;
  @Input() category: string;
  @Input() categorizedApps: string[];
  @Input() apps: any;
  @Input() currentCategory: string;

  @Output() onShowMore = new EventEmitter<string>();

  getAppsByCategory() {
    this.onShowMore.emit(this.category);
  }

  ngOnChanges() {

  }
}
