import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { AppRoutingModule } from '../app-routing.module';
import { FooterComponent } from './footer/footer.component';
import { CustomersComponent } from './customers/customers.component';
import { AppIconComponent } from './app-icon/app-icon.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertBoxComponent } from './alert-box/alert-box.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { SpinnerComponent } from './spinner/spinner.component';
import { FaqsComponent } from './faqs/faqs.component';
import { ModalComponent } from './modal/modal.component';
import { DecimalNumberPipe } from './pipes/decimal.pipe';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { IconComponent } from './icon/icon.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { GetCountryPipe } from './pipes/get-country.pipe';
import { PaginationComponent } from './pagination/pagination.component';
import { AppNamePipe } from './pipes/app-name.pipe';
import { ConditionalScrollDirective } from './directives/conditional-scroll.directive';
import { HighLightTextComponent } from './high-light-text/high-light-text.component';
import { RouterModule } from '@angular/router';
import { CategoriesDropdownComponent } from './categories-dropdown/categories-dropdown.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TabsLayoutComponent } from './tabs-layout/tabs-layout.component';
import { GetStartedSectionComponent } from './get-started-section/get-started-section.component';
import { GlobeComponent } from './globe/globe.component';
// import { TooltipModule } from '@teamhive/ngx-tooltip';
// import { TOOLTIP_OPTIONS } from './constants/tooltip.constants';
import { ManageErrorPipe } from './pipes/manage-error.pipe';
import { SantizeCSSPipe } from './pipes/santize-css.pipe';
import { SantizeHTMLPipe } from './pipes/santize-html.pipe';
import { ScrollShadowDirective } from './directives/scroll-shadow.directive';
import { BgImageDirective } from './directives/bg-image.directive';
import { GrowthSectionComponent } from './growth-section/growth-section.component';
import { FaqComponent } from './faqs/faq.component';
import { CookiePolicyConsentComponent } from './cookie-policy-consent/cookie-policy-consent.component';
import { ZohoCampaignFormComponent } from './zoho-campaign-form/zoho-campaign-form.component';
import { ActiveLinkDirective } from './directives/active-link.directive';

@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    CustomersComponent,
    AppIconComponent,
    AlertBoxComponent,
    SpinnerComponent,
    FaqsComponent,
    SantizeHTMLPipe,
    SantizeCSSPipe,
    ModalComponent,
    DecimalNumberPipe,
    ToggleSwitchComponent,
    IconComponent,
    TestimonialComponent,
    ManageErrorPipe,
    GetCountryPipe,
    PaginationComponent,
    AppNamePipe,
    ConditionalScrollDirective,
    HighLightTextComponent,
    CategoriesDropdownComponent,
    TabsLayoutComponent,
    GetStartedSectionComponent,
    GlobeComponent,
    ScrollShadowDirective,
    FaqComponent,
    BgImageDirective,
    GrowthSectionComponent,
    CookiePolicyConsentComponent,
    ZohoCampaignFormComponent,
    ActiveLinkDirective
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    OverlayModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    // TooltipModule.forRoot(TOOLTIP_OPTIONS),
  ],
  exports: [
    RouterModule,
    NavbarComponent,
    FooterComponent,
    CustomersComponent,
    AppIconComponent,
    AlertBoxComponent,
    SpinnerComponent,
    FaqsComponent,
    SantizeHTMLPipe,
    SantizeCSSPipe,
    ModalComponent,
    DecimalNumberPipe,
    ToggleSwitchComponent,
    IconComponent,
    TestimonialComponent,
    ManageErrorPipe,
    GetCountryPipe,
    PaginationComponent,
    AppNamePipe,
    ConditionalScrollDirective,
    HighLightTextComponent,
    CategoriesDropdownComponent,
    TabsLayoutComponent,
    GetStartedSectionComponent,
    GlobeComponent,
    ScrollShadowDirective,
    FaqComponent,
    BgImageDirective,
    GrowthSectionComponent,
    CookiePolicyConsentComponent,
    ZohoCampaignFormComponent,
    ActiveLinkDirective
  ]
})
export class SharedModule { }
