import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qw-acceptable-usage-policy',
  templateUrl: './acceptable-usage-policy.component.html',
  styleUrls: ['./acceptable-usage-policy.component.scss']
})
export class AcceptableUsagePolicyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
