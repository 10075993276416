import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'qw-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss']
})
export class CookiePolicyComponent implements OnInit {

  revisionDate: string;

  constructor(private appService: AppService, private metaService: Meta, private titleService: Title) { }

  ngOnInit() {
    this.metaService.addTags([
      { name: 'robots', content: 'index, follow' }
    ]);
    this.revisionDate = '24 Jan, 2022';
  }

}
