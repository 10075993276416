<div class="app-search-box" (mouseenter)="setInsideDropdown(true)" (mouseleave)="setInsideDropdown(false)" (click)="showCategories()">
    <!-- <h5 class="app-search-title">Categories</h5> -->
    <div class="app-search">
        <div class="app-search-input">
            {{ currentCategory }}
        </div>
        <!-- <input type="text" class="app-search-input" [formControl]="categorySearch" name="categorySearch" id="search"
            placeholder="Select category" (focus)="showCategories()" (blur)="hideCategories()" autocomplete="off"> -->
        <i class="fas fa-caret-down" [class.rotate]="toggleCategories"></i>
    </div>
    <div class="app-categories-container" *ngIf="toggleCategories" [@fadeEnterExit]>
        <div class="app-categories">
            <ng-container
                *ngTemplateOutlet="categoriesDropdown; context: { categoriesList: filteredCategories.length ? filteredCategories : categories }">
            </ng-container>
        </div>
    </div>
</div>

<ng-template #categoriesDropdown let-categoriesList="categoriesList">
    <ng-container *ngFor="let category of categoriesList">
        <div class="app-category" [class.active]="category === currentCategory" (click)="selectCategory(category);$event.stopPropagation()">{{
            category }}</div>
    </ng-container>
</ng-template>