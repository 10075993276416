import { Directive, HostBinding, Input, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ActiveLinkGuard } from '../core/active-link.guard';

/**
 * Directive for displaying active link on navbar
 */

@Directive({
  selector: '[activeLink]'
})
export class ActiveLinkDirective implements OnInit {

  @Input('activeLink') label: string;
  @HostBinding('class.active') isActive: boolean;

  constructor(private activeLink: ActiveLinkGuard) { }

  ngOnInit(): void {
    this.activeLink.getActiveRouteLabel().pipe(
      tap(label => this.isActive = this.label === label)
    ).subscribe();
  }


}
