import { Directive, Input, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { filter, tap, takeUntil } from 'rxjs/operators';
import { OnChange } from '../decorators/OnChange';

@Directive({
    selector: '[qwConditionalScroll]'
})
export class ConditionalScrollDirective implements AfterViewInit, OnDestroy {
    @OnChange('setCondition') @Input('qwConditionalScroll') condition: boolean;
    @Input('parentScroll') parentScroll = 0;
    @Input('parentElementId') parentElementId: string;
    @Input('offset') offset = 0;
    @Input('scrollBehavior') behavior?: ScrollBehavior = 'smooth';
    scrollElement: HTMLElement;
    private conditionChange = new BehaviorSubject<boolean>(false);
    private destroyed$ = new Subject();

    constructor(private element: ElementRef) {}

    setCondition() {
        this.conditionChange.next(this.condition);
    }

    ngAfterViewInit() {
        this.conditionChange.pipe(
            filter(condition => condition === true),
            tap(() => this.performScroll()),
            takeUntil(this.destroyed$)
        ).subscribe();
    }

    performScroll() {
        if (this.parentElementId) {
            this.scrollElement = document.getElementById(this.parentElementId);
            const scrollUnit = this.element.nativeElement.offsetTop + this.parentScroll + this.offset;
            this.scrollElement.scrollTo({ top: scrollUnit, behavior: this.behavior });
        } else {
            const body = document.body;
            const docEl = document.documentElement;
            const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
            const clientTop = docEl.clientTop || body.clientTop || 0;
            const elementTop = this.element.nativeElement.getBoundingClientRect().top + scrollTop - clientTop;
            const elementScrollPosition = elementTop + this.offset;
            window.scrollTo({ top: elementScrollPosition, behavior: this.behavior });
        }
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
