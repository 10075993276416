import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qw-growth-section',
  templateUrl: './growth-section.component.html',
  styleUrls: ['./growth-section.component.scss']
})
export class GrowthSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
