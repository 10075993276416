import { trigger, transition, style, animate, group, query } from '@angular/animations';

export const tabsSlide = trigger('tabsSlide', [
    transition(':increment', group([
        query(':enter', [
            style({
                opacity: 1,
                transform: 'translateX(100%)'
            }),
            animate('0.3s ease-out', style('*'))
        ]),
        query(':leave', [
            animate('0.3s ease-out', style({
                opacity: 0,
                transform: 'translateX(-100%)'
            }))
        ])
    ])),
    transition(':decrement', group([
        query(':enter', [
            style({
                opacity: 1,
                transform: 'translateX(-100%)'
            }),
            animate('0.3s ease-out', style('*'))
        ]),
        query(':leave', [
            animate('0.3s ease-out', style({
                opacity: 0,
                transform: 'translateX(100%)'
            }))
        ])
    ]))
]);
