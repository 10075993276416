import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[bgImage]'
})
export class BgImageDirective implements OnInit {

  @Input('bgImage') image: string;
  @Input('overlayColor') overlayColor: string = 'rgb(0, 0, 0)';

  constructor(private renderer: Renderer2, private element: ElementRef) {}

  ngOnInit() {
    const imageDiv = this.renderer.createElement('div');
    this.renderer.addClass(imageDiv, 'img-card-image');
    this.renderer.setStyle(imageDiv, 'background-image', `url(${this.image})`);
    // this.renderer.setStyle(imageDiv, 'background-color', this.hexToRGB(this.overlayColor, '0.2'));

    const imageGradient = this.renderer.createElement('div');
    this.renderer.addClass(imageGradient, 'img-card-gradient');
    this.renderer.setStyle(imageGradient, 'background-image', `linear-gradient(0deg, black, ${this.overlayColor})`);

    this.renderer.appendChild(this.element.nativeElement, imageDiv);
    this.renderer.appendChild(this.element.nativeElement, imageGradient);
  }

  hexToRGB(hex: string, alpha?: string) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return `rgb(${r}, ${g}, ${b})`;
  }

}
