import { Ifaq } from 'src/app/app.service';
import { ICurrency, ISuccessPlan } from './pricing.model';

export const FEATURES: {
    title: string;
    description: string;
    image: string;
}[] = [
        {
            title: 'Low / No code',
            description: 'With interactive drag and drop user interface, even non-technical users can set up automations with ease. ',
            image: 'Group 2157.png',
        },
        {
            title: 'Faster Automation',
            description: 'You can create simple as well as comprehensive journeys that go live in just a few minutes. ',
            image: 'Group 2158.png',
        },
        {
            title: 'Multi-Lingual',
            description: 'You can select the language of your choice so that you can make the most out of our platform. ',
            image: 'Group 2159.png',
        },
        {
            title: 'On Cloud and On Premise',
            description: 'Our platform is available in both environments, on-cloud and on-premise to meet your enterprise security needs. ',
            image: 'Group 2163.png',
        },
        {
            title: 'Multi-Channel',
            description: 'Our Platform supports communication amongst multiple channels that enable you to connect with various messaging applications.',
            image: 'Group 2162.png',
        },
        {
            title: 'Agile and Efficient',
            description: 'Change management and tracing errors becomes agile and efficient because of organised and visual history of transactions. ',
            image: 'Group 2164.png',
        },
        {
            title: 'iPaaS',
            description: 'Our integration platform-as-a-service allows you to connect applications of your choice irrespective of their presence on-cloud or on-premise. ',
            image: 'Group 2165.png',
        },
        {
            title: 'REST APIs',
            description: 'Rest APIs work easily with a variety of data formats across systems, programming language agnostic. ',
            image: 'Group 2166.png',
        },
        {
            title: 'Messenger and Chatbot',
            description: 'Ready and pre-integrated with chatbot friendly - public, social messengers like WhatsApp, Line, Facebook Messenger and private enterprise messengers like Slack, Microsoft Teams and our own Quickwork Chat.',
            image: 'Group 2167.png',
        },
    ];

export const FAQS: Ifaq[] = [
    {
        question: 'What are transactions and how many transactions do I need for my Journeys?',
        answer: 'A transaction is processing your entire Journeys once. You can have any amount of apps in a single Journeys, which once executed fully is counted as a transaction.'
    },
    // Temporarily hiding pricing FAQ
    // {
    //     question: 'Can I upgrade or downgrade later?',
    //     answer: 'Yes, you can upgrade or downgrade your existing plan. Go to Quickwork, click the Profile menu and select Account Settings. Then scroll down to the Subscription section and click the Change Plan button.'
    // },
    {
        question: 'What if I reach my monthly quota of transactions?',
        answer: 'Your triggers will be temporarily paused until your plan is renewed or upgraded.'
    },
    {
        question: 'Can you charge me in my currency?',
        answer: 'Quickwork currently accepts INR and USD for payments. All major credit/debit cards are supported along with Apple Pay and Google Pay.'
    },
    {
        question: 'What are apps?',
        answer: 'An app is a popular acronym for an “application”. In our context, an app is any application, service or system, which has been integrated with or being used on our platform. An app can perform multiple logical operations or steps and trigger events to meet the objective for which it was created.'
    },
    {
        question: 'What if I have a few more questions?',
        answer: 'You can access our Support Center site to get answers to your question or you can chat with us by clicking on the chat icon.'
    }
];

export const CURRENCIES: ICurrency[] = [
        {
            uppercaseUnit: 'USD',
            flagImage: 'USD.svg',
            symbol: '$',
            unit: 'usd',
            countryCode: 'US'
        },
        {
            uppercaseUnit: 'INR',
            flagImage: 'INR.svg',
            symbol: '₹',
            unit: 'inr',
            countryCode: 'IN'
        }
    ];

export const PLAN_FEATURES = [
    {
        name: 'Free',
        features: [
            {
                feature: 'Conditional Logic',
                isSpecial: false,
            },
            {
                feature: 'Unlimited steps',
                isSpecial: false,
            },
            {
                feature: 'Error and exception management',
                isSpecial: false,
            },
            {
                feature: 'Callable Journeys',
                isSpecial: false,
            },
            {
                feature: 'Formula Mode',
                isSpecial: false,
            },
            {
                feature: 'Audit & Trail',
                isSpecial: false,
            },
            {
                feature: 'Support for Webhook, Scheduler and Polling triggers',
                isSpecial: false,
            },
            {
                feature: 'Version Control',
                isSpecial: false,
            }
        ],
        support: 'Email, Helpdesk',
        sla: 'Do-it-yourself',
        srt: 'Not available'
    },
    {
        name: 'Starter',
        features: [
            {
                feature: 'Conditional Logic',
                isSpecial: false,
            },
            {
                feature: 'Unlimited steps',
                isSpecial: false,
            },
            {
                feature: 'Error and exception management',
                isSpecial: false,
            },
            {
                feature: 'Callable Journeys',
                isSpecial: false,
            },
            {
                feature: 'Formula Mode',
                isSpecial: false,
            },
            {
                feature: 'Audit & Trail',
                isSpecial: false,
            },
            {
                feature: 'Support for Webhook, Scheduler and Polling triggers',
                isSpecial: false,
            },
            {
                feature: 'Version Control',
                isSpecial: false,
            }
        ],
        support: 'Email, Helpdesk',
        sla: 'Do-it-yourself',
        srt: '2 working days'
        // inheritedPlan: 'Free'
    },
    {
        name: 'Pro',
        features: [
            {
                feature: 'Conditional Logic',
                isSpecial: false,
            },
            {
                feature: 'Unlimited steps',
                isSpecial: false,
            },
            {
                feature: 'Error and exception management',
                isSpecial: false,
            },
            {
                feature: 'Callable Journeys',
                isSpecial: false,
            },
            {
                feature: 'Formula Mode',
                isSpecial: false,
            },
            {
                feature: 'Audit & Trail',
                isSpecial: false,
            },
            {
                feature: 'Support for Webhook, Scheduler and Polling triggers',
                isSpecial: false,
            },
            {
                feature: 'Version Control',
                isSpecial: false,
            },
            {
                feature: 'API Management',
                isSpecial: false,
            },
            {
                feature: 'Team Management',
                isSpecial: false,
            },
            {
                feature: 'Data retention policies',
                isSpecial: false,
            },
            {
                feature: 'Chat based training',
                isSpecial: false,
            },
            {
                feature: 'Custom connectors',
                isSpecial: false,
            }
        ],
        support: 'Email, Helpdesk, Chat',
        sla: '10',
        srt: '1 working day'
        // inheritedPlan: 'Starter'
    },
    {
        name: 'Business',
        features: [
            {
                feature: 'Conditional Logic',
                isSpecial: false,
            },
            {
                feature: 'Unlimited steps',
                isSpecial: false,
            },
            {
                feature: 'Error and exception management',
                isSpecial: false,
            },
            {
                feature: 'Callable Journeys',
                isSpecial: false,
            },
            {
                feature: 'Formula Mode',
                isSpecial: false,
            },
            {
                feature: 'Audit & Trail',
                isSpecial: false,
            },
            {
                feature: 'Support for Webhook, Scheduler and Polling triggers',
                isSpecial: false,
            },
            {
                feature: 'Version Control',
                isSpecial: false,
            },
            {
                feature: 'API Management',
                isSpecial: false,
            },
            {
                feature: 'Team Management',
                isSpecial: false,
            },
            {
                feature: 'Data retention policies',
                isSpecial: false,
            },
            {
                feature: 'Chat based training',
                isSpecial: false,
            },
            {
                feature: 'Custom connectors',
                isSpecial: false,
            }
        ],
        support: 'Email, Helpdesk, Chat, Phone',
        sla: '15',
        srt: '12 hours'
        // inheritedPlan: 'Pro'
    },
    {
        name: 'Enterprise',
        features: [
            {
                feature: 'Conditional Logic',
                isSpecial: false,
            },
            {
                feature: 'Unlimited steps',
                isSpecial: false,
            },
            {
                feature: 'Error and exception management',
                isSpecial: false,
            },
            {
                feature: 'Callable Journeys',
                isSpecial: false,
            },
            {
                feature: 'Formula Mode',
                isSpecial: false,
            },
            {
                feature: 'Audit & Trail',
                isSpecial: false,
            },
            {
                feature: 'Support for Webhook, Scheduler and Polling triggers',
                isSpecial: false,
            },
            {
                feature: 'Version Control',
                isSpecial: false,
            },
            {
                feature: 'API Management',
                isSpecial: false,
            },
            {
                feature: 'Team Management',
                isSpecial: false,
            },
            {
                feature: 'Data retention policies',
                isSpecial: false,
            },
            {
                feature: 'Chat based training',
                isSpecial: false,
            },
            {
                feature: 'Custom connectors',
                isSpecial: false,
            },
            {
                feature: 'Region specific deployment',
                isSpecial: false,
            }
        ],
        support: 'Email, Helpdesk, Chat, Phone, Relationship Manager',
        sla: '30',
        srt: '6 hours'
        // inheritedPlan: 'Pro'
    }
];

export const PRICE_FACTOR = 12 * 100;
export const RECOMMENDED_PLAN = 'Pro';

export const SUCCESS_PLANS: ISuccessPlan[] = [
    {
        title: 'Success Five',
        description: `Get <b>5 hours</b> of our expert support to build your
        automation workflows.`
    },
    {
        title: 'Success Ten',
        description: `Get <b>10 hours</b> of our expert support to build your
        automation workflows.`
    },
    {
        title: 'Success Fifteen',
        description: `Get <b>15 hours</b> of our expert support to build your
        automation workflows.`
    },
    {
        title: 'Success Thirty',
        description: `Get <b>30 hours</b> of our expert support to build your
        automation workflows.`
    },
    {
        title: 'Success Fifty',
        description: `Get <b>50 hours</b> of our expert support to build your
        automation workflows.`
    }
];


export const FREE_PLAN_DETAILS = {
    features: [
        'Real-time triggers',
        'One-minute poll',
        'Formula engine',
        'Branching and loop operators',
        'Version control',
        'Team collaboration',
        'API Management',
        'Conversation Management',
        '1,000+ apps'
    ]
};
