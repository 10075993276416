<div id="privacy-policy-container" class="header-wave">
    <div class="main-container">
        <div class="privacy-policy">
            <div class="policy-content">
                <div class="m-b-20">
                    <div class="highlight-text">Cookie Policy</div>
                </div>
                <p class="c10"><span class="c5">Effective Date: {{ revisionDate }} </span></p>
                <br>
                <p class="c10"><span class="c6">When the Customer/User/Visitor (&ldquo;You&rdquo;, &ldquo;Your&rdquo;)
                        visits
                        Quickwork&rsquo;s (&ldquo;We&rdquo;, &ldquo;Our&rdquo;, &ldquo;Us&rdquo;) Website, We
                    </span><span class="c6">collect </span><span class="c23">relevant data</span><span class="c6"> with
                        the help of
                        cookies and related technologies. When You use or visit Our Website, </span><span
                        class="c23">including any
                        other media form, mobile website, media channel, or mobile application connected or related
                        thereto
                        (collectively, the &ldquo;Site&rdquo;),</span> <span class="c6">We
                        might
                    </span><span class="c23">use a </span><span class="c5">third-party
                        service provider
                        to use and place web beacons, cookies, tracking pixels, and new tracking technologies to gather
                        Personal
                        Data and tailor the Site and improve your experience. This Personal Data is collected openly and
                        routinely
                        by these third parties, and We do not participate in these data transmissions. </span></p>
                <br>
                <p class="c10"><span class="c6">This Cookie policy notifies You about how We make use of
                        cookies</span><span class="c23"> and</span><span class="c6"> their purpose</span><span
                        class="c6">.</span><span class="c6"> We reserve the right to make revisions to this Cookie
                        Policy at any time and for any
                        reason. We will notify you about any revisions by </span><span class="c23">updat</span><span
                        class="c6">ing
                        the &ldquo;</span><span class="c23">Effective</span><span class="c5">&rdquo; date of this Cookie
                        Policy. Any
                        modifications or amendments will be operative instantly upon posting the updated Cookie Policy
                        on the Site
                        and You waive the right to obtain precise notice of every such modification or change in
                        policy.</span></p>
                <br>
                <p class="c10"><span class="c6">You are advised to regularly review this Cookie Policy to stay informed
                        about all
                        policy </span><span class="c23">changes and updates</span><span class="c5">. You will be
                        considered to have
                        been made aware of, will be subject to, and will be thought to have acknowledged the changes in
                        any revised
                        Cookie Policy by your constant use of the Site after the date such a reviewed and updated Cookie
                        Policy is
                        posted.</span></p>
                <br>
                <p class="c10"><span class="c6">Capitalized terms used but not defined in this Cookie Policy shall have
                        the meaning
                        ascribed to them in Terms of </span><span class="c23">Service</span><span class="c6"> available
                        at
                    </span><span class="c6 c21">[</span><a href="/terms-of-service" target="_blank"
                        class="c23 c21">https://quickwork.co/terms-of-service</a><span class="c6 c21">]</span><span
                        class="c6"> and/or the Privacy Policy available at [</span><a href="/privacy-policy"
                        target="_blank" class="c23 c21">https://quickwork.co/privacy-policy</a><span class="c5">]</span>
                </p>
                <br>
                <br>
                <p class="c10"><span class="c16">Notice and Cookie Management</span></p>
                <p class="c10"><span class="c6">A notice is displayed on the Website/Portal to all the visitors when
                        they visit the
                        Website/Portal notifying them of the use of cookies. Once this notice is approved and accepted
                        by You,
                    </span><span class="c6">this notice will</span><span class="c23 c37"> </span><span
                        class="c23 c37">not
                        reappear for You unless there is a change or update in the existing cookie policy</span><span
                        class="c23">.</span><span class="c6"> I</span><span class="c5">f You want to remove
                        existing cookies
                        from Your device,You may do so using your browser options. Please note that deleting and
                        blocking cookies
                        may have an impact on Your overall user-experience.</span></p>
                <br>
                <br>
                <p class="c32"><span class="c29">How Do We Use Cookies and Other Tracking technologies</span><span
                        class="c5">?</span></p>
                <br>
                <br>
                <p class="c9"><span class="c16">Cookies by Quickwork: </span></p>
                <p class="c9"><span class="c5">Quickwork does not use any first party cookies on its website.</span></p>
                <br>
                <p class="c9"><span class="c16">Third Party Cookies: </span><span class="c5">We have engaged and
                        contracted third
                        party service providers to analyze and track statistical usage and volume of information from
                        the
                        Customer/User/Visitor of Portal. These third party service providers use cookies to assist us to
                        improve and
                        develop the user experience, manage and control Our Portal content, and analyze how
                        Customer/User/Visitor
                        navigate and operate on our Portal.</span></p>
                <br>
                <ul class="c24 lst-kix_list_2-0 start">
                    <li class="c10 c25 li-bullet-0"><span class="c6">Quickwork uses tracking services from third party
                            companies.
                            Cookies from these third party companies might be placed on the Portal and/or other
                            websites. The below
                            table will give You more information on the cookies We might use. </span></li>
                </ul>
                <br>
                <p class="c32"><span class="c12">The cookies We use: </span></p>
                <br>
                <table class="c38" border="1">
                    <tbody>
                        <tr class="c42">
                            <th class="c35" colspan="1" rowspan="1">
                                <p class="c4"><span class="c19">Service</span></p>
                            </th>
                            <th class="c18" colspan="1" rowspan="1">
                                <p class="c4"><span class="c19">Description</span></p>
                            </th>
                            <th class="c31" colspan="1" rowspan="1">
                                <p class="c4"><span class="c19">URL for Privacy Notice and Opt-Out</span></p>
                            </th>
                        </tr>
                        <tr class="c17">
                            <td class="c1" colspan="1" rowspan="1">
                                <p class="c4"><span class="c7">Amazon AWS</span></p>
                            </td>
                            <td class="c18" colspan="1" rowspan="1">
                                <p class="c4"><span class="c7">Cloud Infrastructure for our platform</span></p>
                            </td>
                            <td class="c31" colspan="1" rowspan="1">
                                <p class="c4"><span class="c39"><a class="c3"
                                            href="https://www.google.com/url?q=https://aws.amazon.com/privacy/&amp;sa=D&amp;source=editors&amp;ust=1643029856371993&amp;usg=AOvVaw2D5yj3W3k5c2xFeN7q7Kz6">https://aws.amazon.com/privacy/</a></span>
                                </p>
                            </td>
                        </tr>
                        <tr class="c40">
                            <td class="c35" colspan="1" rowspan="1">
                                <p class="c4"><span class="c7">Stripe</span></p>
                            </td>
                            <td class="c18" colspan="1" rowspan="1">
                                <p class="c4"><span class="c7">Customer payments</span></p>
                            </td>
                            <td class="c31" colspan="1" rowspan="1">
                                <p class="c4"><span class="c39"><a class="c3"
                                            href="https://www.google.com/url?q=https://stripe.com/in/privacy&amp;sa=D&amp;source=editors&amp;ust=1643029856373241&amp;usg=AOvVaw0Vlv__2VHHNotn_s7iFro-">https://stripe.com/in/privacy</a></span>
                                </p>
                            </td>
                        </tr>
                        <tr class="c41">
                            <td class="c35" colspan="1" rowspan="1">
                                <p class="c4"><span class="c7">Google</span></p>
                            </td>
                            <td class="c18" colspan="1" rowspan="1">
                                <p class="c4"><span class="c7">Platform analytics (web) and customer interactions
                                        (email)</span></p>
                            </td>
                            <td class="c31" colspan="1" rowspan="1">
                                <p class="c4"><span class="c39"><a class="c3"
                                            href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den-US&amp;sa=D&amp;source=editors&amp;ust=1643029856374581&amp;usg=AOvVaw0Tu2LgECRY9u4xBjoA0MrY">https://policies.google.com/privacy?hl=en-US</a></span>
                                </p>
                            </td>
                        </tr>
                        <tr class="c17">
                            <td class="c35" colspan="1" rowspan="1">
                                <p class="c4"><span class="c7">Intercom</span></p>
                            </td>
                            <td class="c18" colspan="1" rowspan="1">
                                <p class="c4"><span class="c7">Customer Support and Lead Interaction</span></p>
                            </td>
                            <td class="c31" colspan="1" rowspan="1">
                                <p class="c4"><span class="c39"><a class="c3"
                                            href="https://www.google.com/url?q=https://www.intercom.com/legal/privacy&amp;sa=D&amp;source=editors&amp;ust=1643029856375932&amp;usg=AOvVaw0qJrB4nDI7wok6XPeyCvvC">https://www.intercom.com/legal/privacy</a></span>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br>
                <br>
                <p class="c10"><span class="c16">Privacy Policy</span></p>
                <p class="c36"><span class="c6">To see more information about what data We collect and why, please
                        review Our
                        Privacy Policy available at </span><span class="c6 c21">&lt;</span><a href="/privacy-policy"
                        target="_blank" class="c23 c21">https://quickwork.co/privacy-policy</a><span class="c6">&gt;.
                    </span>
                </p>
                <hr>
                <br>
                <h3 class="c27 c10" id="h.kqu6u71obmb9"><span class="c30">How often will you update this Cookie
                        Policy?</span></h3>
                <p class="c27 c10"><span class="c11">We may update this Cookie Policy from time to time in order to
                        reflect, for
                        example, changes to the cookies we use or for other operational, legal or regulatory reasons.
                        Please
                        therefore re-visit this Cookie Statement regularly to stay informed about our use of cookies and
                        related
                        technologies</span><span class="c34">.</span></p>
                <br>
                <p class="c10 c27"><span class="c34">The date at the top of this Cookie Statement indicates when it was
                        last
                        updated.</span></p>
                <br>
                <p class="c20"><span class="c16">CONTACT US</span></p>
                <p class="c15"><span class="c11">If you have questions or comments about this Cookie Policy or
                    </span><span class="c37 c43">our use of cookies, please email us at </span><a
                        href="mailto:support@quickwork.co" class="c26">support@quickwork.co</a></p>
            </div>
        </div>
    </div>
</div>