import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';
import { PLATFORM_BASE_PATH } from 'src/app/app.constants';
import { createRequestOption } from 'src/app/app.service';

export interface ICaseStudy {
  id: string;
  title: string;
  category: string;
  imageSrc: string;
  imageThumbnailSrc: string;
  imageOverlayColor: string;
}

export interface IMetaCaseStudy {
  id: string;
  active: true;
  title: string;
  category: string;
  problemStatement: string;
  solution: string;
  apps: string[];
  impact: string[];
  imageThumbnailSrc: string;
  imageOverlayColor: string;
  customerChallenge: string;
  aboutCompany: string;
  goLiveDays: number;
  industry?: string;
  companyHqCountry?: string;
  companyName?: string;
}

export interface IGroupedCaseStudies {
  data: IMetaCaseStudy[];
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class CaseStudiesService {

  resourceURL = `${PLATFORM_BASE_PATH}workflow/api/public/case-studies`;
  private industries: string[] = [];
  private groupedCaseStudies: IGroupedCaseStudies[] = [];
  private groupedCaseStudies$ = new BehaviorSubject<IMetaCaseStudy[]>([]);

  private groupedCaseStudiesCache$: Observable<any>;

  constructor(private http: HttpClient) { }

  getCaseStudies(req = {}) {
    const params = createRequestOption(req);
    return this.http.get<ICaseStudy[]>(this.resourceURL, { params });
  }

  getCaseStudyCategories() {
    // const url = 'https://at2-staging.quickwork.co/api/public/use-cases/categories';
    const url = 'https://run.mocky.io/v3/c715a5b2-a4e8-4256-a612-c1c18c9c8cb1';
    return this.http.get<string[]>(url, { observe: 'response' }).pipe(
      map(response => response.body)
    );
  }

  getCaseStudy(id: string) {
    return this.http.get<IMetaCaseStudy>(`${this.resourceURL}/${id}`);
  }

  getGroupedCaseStudies() {
    if (!this.groupedCaseStudiesCache$) {
      this.groupedCaseStudiesCache$ = this.http.get<IGroupedCaseStudies[]>(`${this.resourceURL}/group/industry`).pipe(
        tap(caseStudies => {
          this.groupedCaseStudies = caseStudies;
          // this.groupedCaseStudies$.next(caseStudies);
          this.appendIndustries(caseStudies);
        }),
        shareReplay(1)
      );
    }
    return this.groupedCaseStudiesCache$;
  }

  private appendIndustries(caseStudies: IGroupedCaseStudies[]) {
    caseStudies.forEach(caseStudy => {
      if (caseStudy.id && caseStudy.data && caseStudy.data.length) {
        const industry = caseStudy.id;
        if (!this.industries.includes(industry)) {
          this.industries.push(industry);
        }
      }
    });
  }

  getIndustries() {
    return this.industries;
  }

}
