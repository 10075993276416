<div class="pagination">
    <div class="pagination-buttons">

        <button [disabled]="page === 1" class="pagination-button first" type="button" (click)="updatePage('first')">
            First
        </button>
        <button [disabled]="page === 1" class="pagination-button prev" type="button" (click)="updatePage('dec')">
            Prev
        </button>
        <button class="pagination-button current-page" type="button">
            {{ page }}
        </button>
        <button [disabled]="page === totalPages" class="pagination-button next" type="button" (click)="updatePage('inc')">
            Next
        </button>
        <button [disabled]="page === totalPages" class="pagination-button last" type="button" (click)="updatePage('last')">
            Last
        </button>
    </div>
</div>