import { Component, Input } from '@angular/core';
import { Ifaq } from 'src/app/app.service';
import { collapseAnimation } from '../animations/collapse.animation';

@Component({
  selector: 'qw-faqs',
  template: `
    <section class="faqs-section">
        <div class="main-container">
            <h2>{{ FAQtitle || 'Frequently asked questions' }}</h2>
            <div class="faqs">
                <qw-faq *ngFor="let faq of FAQs" [FAQ]="faq"></qw-faq>
            </div>
        </div>
    </section>
  `,
  styleUrls: ['./faqs.component.scss'],
  animations: [collapseAnimation]
})
export class FaqsComponent {
  @Input() FAQs: Ifaq[];
  @Input() FAQtitle: string;
}
