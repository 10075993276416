import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  private cookieConsentAlias = 'cookie-tracking-allowed';

  constructor() { }

  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  getCookie(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length).replace(/%20/g, ' ');
      }
    }
    return '';
  }

  checkCookie() {
    let user = this.getCookie('username');
    if (user !== '') {
      alert('Welcome again ' + user);
    } else {
      user = prompt('Please enter your name:', '');
      if (user !== '' && user != null) {
        this.setCookie('username', user, 365);
      }
    }
  }

  acceptCookie() {
    this.setCookie(this.cookieConsentAlias, true, 365);
  }

  rejectCookie() {
    this.setCookie(this.cookieConsentAlias, false, 365);
  }

  getCookieTrackingConsent() {
    return this.getCookie(this.cookieConsentAlias) === '';
  }


}
