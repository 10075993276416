import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'qw-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['../../../assets/scss/document.scss', 'privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  revisionDate: string;

  constructor(private appService: AppService, private metaService: Meta, private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('Privacy Policy | Quickwork');
    this.metaService.addTags([
      { name: 'description', content: 'The Privacy Policy of  Quickwork, an enterprise grade platform explains the manner in which your data is collected, stored & used by us. Please read it carefully before you use our products or services' },
      { name: 'robots', content: 'index, follow' }
    ])
    this.revisionDate = '03 Feb, 2022';
  }

}
