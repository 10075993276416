<ng-container [ngSwitch]="icon">
  <ng-container *ngSwitchCase="'arrow'">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.144" height="9"
      viewBox="0 0 12.144 9">
      <defs>
        <clipPath id="clip-path">
          <rect width="12.144" height="9" fill="none" />
        </clipPath>
      </defs>
      <g id="Arrow_Right_White" data-name="Arrow Right White" clip-path="url(#clip-path)">
        <line id="Line_1" data-name="Line 1" x2="10.421" transform="translate(0.5 4.383)" fill="none"
          stroke="currentcolor" stroke-linecap="round" stroke-width="1" />
        <path id="Path_31" data-name="Path 31" d="M384.414,437.216l3.252,4-3.252,4"
          transform="translate(-376.165 -436.716)" fill="none" stroke="currentcolor" stroke-linecap="round"
          stroke-width="1" />
      </g>
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'video'">
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
      <g id="play-button" transform="translate(-9)">
        <g id="Group_2238" data-name="Group 2238" transform="translate(9)">
          <path id="Path_1870" data-name="Path 1870"
            d="M12.5,0A12.5,12.5,0,1,0,25,12.5,12.5,12.5,0,0,0,12.5,0Zm5.264,12.9a.893.893,0,0,1-.4.4v0l-7.143,3.571a.893.893,0,0,1-1.292-.8V8.929a.893.893,0,0,1,1.292-.8L17.363,11.7A.893.893,0,0,1,17.764,12.9Z"
            fill="currentcolor" />
        </g>
      </g>
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'search'">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
        stroke="#23374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M20.9984 21.0004L16.6484 16.6504" stroke="#23374D" stroke-width="2" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'quote'">
    <svg width="130" height="98" viewBox="0 0 130 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34.676 0.871991C13.148 16.052 0.176 37.304 0.176 61.592C0.176 84.224 15.356 97.196 32.192 97.196C47.648 97.196 60.896 84.224 60.896 68.216C60.896 52.76 50.132 42.548 36.884 42.548C33.848 42.548 31.088 43.1 29.708 43.652C33.296 33.164 45.992 21.02 54.824 17.156L34.676 0.871991ZM102.848 0.871991C81.32 16.052 68.348 37.304 68.348 61.592C68.348 84.224 83.528 97.196 100.364 97.196C115.82 97.196 129.068 84.224 129.068 68.216C129.068 52.76 118.304 42.548 105.056 42.548C102.02 42.548 99.26 43.1 97.88 43.652C101.468 33.164 114.164 21.02 122.996 17.156L102.848 0.871991Z"
        fill="currentcolor" />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'star'">
    <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 0L19.6729 8.56832L29.2658 10.3647L22.5609 17.4567L23.8168 27.1353L15 22.95L6.18322 27.1353L7.4391 17.4567L0.734152 10.3647L10.3271 8.56832L15 0Z"
        fill="currentcolor" />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'double-chevron'">
    <svg xmlns="http://www.w3.org/2000/svg" height="426pt" viewBox="0 -32 426.66667 426" width="426pt">
      <path fill="currentcolor"
        d="m213.332031 181.667969c0 4.265625-1.277343 8.53125-3.625 11.730469l-106.667969 160c-3.839843 5.761718-10.238281 9.601562-17.707031 9.601562h-64c-11.730469 0-21.332031-9.601562-21.332031-21.332031 0-4.269531 1.28125-8.535157 3.625-11.734375l98.773438-148.265625-98.773438-148.269531c-2.34375-3.199219-3.625-7.464844-3.625-11.730469 0-11.734375 9.601562-21.335938 21.332031-21.335938h64c7.46875 0 13.867188 3.839844 17.707031 9.601563l106.667969 160c2.347657 3.199218 3.625 7.464844 3.625 11.734375zm0 0" />
      <path fill="currentcolor"
        d="m426.667969 181.667969c0 4.265625-1.28125 8.53125-3.628907 11.730469l-106.664062 160c-3.839844 5.761718-10.242188 9.601562-17.707031 9.601562h-64c-11.734375 0-21.335938-9.601562-21.335938-21.332031 0-4.269531 1.28125-8.535157 3.628907-11.734375l98.773437-148.265625-98.773437-148.269531c-2.347657-3.199219-3.628907-7.464844-3.628907-11.730469 0-11.734375 9.601563-21.335938 21.335938-21.335938h64c7.464843 0 13.867187 3.839844 17.707031 9.601563l106.664062 160c2.347657 3.199218 3.628907 7.464844 3.628907 11.734375zm0 0" />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'chevron'">
    <svg xmlns="http://www.w3.org/2000/svg" height="362pt" viewBox="-74 0 362 402.66667" width="362pt">
      <path fill="currentcolor"
        d="m213.667969 181.332031c0 4.269531-1.28125 8.535157-3.628907 11.734375l-106.664062 160c-3.839844 5.761719-10.242188 9.601563-17.707031 9.601563h-64c-11.734375 0-21.335938-9.601563-21.335938-21.335938 0-4.265625 1.28125-8.53125 3.628907-11.730469l98.773437-148.269531-98.773437-148.265625c-2.347657-3.199218-3.628907-7.464844-3.628907-11.734375 0-11.730469 9.601563-21.332031 21.335938-21.332031h64c7.464843 0 13.867187 3.839844 17.707031 9.601562l106.664062 160c2.347657 3.199219 3.628907 7.464844 3.628907 11.730469zm0 0" />
    </svg>
  </ng-container>
</ng-container>