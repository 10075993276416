import { Component, Input, OnInit } from '@angular/core';
import { CaseStudiesService, IGroupedCaseStudies } from '../case-studies.service';

@Component({
  selector: 'qw-grouped-case-studies',
  templateUrl: './grouped-case-studies.component.html',
  styleUrls: ['./grouped-case-studies.component.scss']
})
export class GroupedCaseStudiesComponent implements OnInit {

  @Input() groupedCaseStudies: IGroupedCaseStudies[] = [];
  @Input() showMore = false;
  caseStudiesTabs: string[] = [];
  caseStudiesIndex = 0;
  currentCaseStudyIndustry: string;
  appsSliceFactor = 3;
  caseStudiesSliceFactor = 5;

  constructor(private caseStudiesService: CaseStudiesService) { }

  ngOnInit() {
    this.caseStudiesTabs = this.caseStudiesService.getIndustries();
    this.setCaseStudiesIndex(0);
  }

  setCaseStudiesIndex(index: number) {
    this.currentCaseStudyIndustry = this.caseStudiesTabs[index];
    this.caseStudiesIndex = 0;
  }

  changeCaseStudyIndex(index: number) {
    this.caseStudiesIndex = index;
  }

}
