import { Injectable, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { tap, map, filter } from 'rxjs/operators';
import { AsyncSubject, BehaviorSubject, Observable } from 'rxjs';
import { PLATFORM_BASE_PATH } from './app.constants';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { checkContinent } from './shared-components/constants/continents.constants';
import { PricingService } from './pages/pricing/pricing.service';
import { AT2URL, AT2URL_JOURNEY_STORE } from './shared-components/constants/miscellaneous.constants';

export const createRequestOption = (req?: any): HttpParams => {
  let options: HttpParams = new HttpParams();
  if (req) {
    Object.keys(req).forEach(key => {
      if (key !== 'sort') {
        options = options.set(key, req[key]);
      }
    });
    if (req.sort) {
      req.sort.forEach(val => {
        options = options.append('sort', val);
      });
    }
  }
  return options;
};

export const SEARCH_REGEX = (searchQuery: string) => {
  const specCharRegex = new RegExp(/([[\](){}+*/\-?!:./\\^$|])/g);
  searchQuery = searchQuery.replace(specCharRegex, '\\$&');
  const regex = `(${searchQuery})`;
  return new RegExp(regex, 'gi');
};

export interface UseCase {
  categories?: {
    title: string;
    apps: {
      name: string;
      fileName: string;
      alias?: string;
    }[];
  }[];
  apps?: {
    name: string;
    fileName: string;
  }[];
  category?: string;
  title: string;
  description: string;
  learnMoreLink?: string;
  hasButtons: boolean;
  tryNowURL?: string;
}

export interface Ifaq {
  question: string;
  answer: string;
}

export interface Price {
  billing?: {
    USD: number,
    INR: number
  };
  plan: string;
  plans?: {
    price: {
      USD?: number,
      INR?: number
    };
    billing?: {
      USD?: number,
      INR?: number
    };
    transactionCount: number | string;
  }[];
  inheritedPlan?: string;
  inheritedFeatures?: PriceFeatures[];
  journeyCount?: number | string;
  features?: PriceFeatures[];
  support?: string[];
  sla?: string;
}

export interface PriceFeatures {
  feature: string;
  isSpecial?: boolean;
}

export interface ITestimonial {
  name: string;
  customer_image: string;
  customer_position: string;
  testimonal_qoute: string;
  starRating?: number;
  companyLogo?: string;
  company_name?: string;
}

export interface NavLinks {
  name: string;
  link: string;
  show: boolean;
  type: 'link' | 'button' | 'href';
  children?: NavLinks[];
  description?: string;
  fragmentId?: string;
  clickCallBack?: () => void;
  active?: () => boolean | boolean;
}

// export interface IUseCase {
//   id: string;
//   title: string;
//   active: boolean;
//   description: string;
//   tags: string[];
//   apps: string[];
//   categories: string[];
//   countries: string[];
// }

export type LoadingState = 'pending' | 'success' | 'failure';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  revisionDate = '24 Jun, 2020';

  private basePath = PLATFORM_BASE_PATH;
  private appURL = `${this.basePath}workflow/api/public/apps`;

  private apps$ = new BehaviorSubject(undefined);
  private appsFetched$ = new BehaviorSubject(undefined);

  private apps = {};
  private appKeys: string[] = [];
  private storedApps: string[] = [];

  mouseDownEvent = new EventEmitter();

  private userGeoLocation: any;
  private userGeoLocation$ = new BehaviorSubject<any>(undefined);
  // private showPricingPage$ = new AsyncSubject<string>();
  private showPricingPage$ = new BehaviorSubject<string>('hide');

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object,
    private pricingService: PricingService
  ) { }

  redirect(navigationURL: string) {
    window.location.href = navigationURL;
  }

  openRedirect(navigationURL: string) {
    window.open(navigationURL, '_blank');
  }

  getApps() {
    return this.apps$.pipe(filter(apps => apps !== undefined));
  }

  getAppsFromURL() {
    return this.http.get(this.appURL).pipe(
      tap(apps => {
        const appEntries = Object.entries(apps);
        appEntries.sort((a, b) => {
          const first = a[1].name.toLowerCase();
          const second = b[1].name.toLowerCase();
          if (first < second) { return -1; }
          if (first > second) { return 1; }
          return 0;
        });

        const appKeys = [];

        appEntries.forEach(entry => {
          appKeys.push(entry[0]);
        });

        this.appKeys = appKeys;
        this.apps = apps;
        this.apps$.next(this.apps);
        this.appsFetched$.next(null);
        this.appsFetched$.complete();
      }, error => {
        this.apps$.error(error);
        this.appsFetched$.error(null);
        this.appsFetched$.complete();
      })
    );
    // return this.http.get(`${this.appURL}`);
  }

  getAllAppAliases() {
    return this.appKeys;
  }

  getAppFromAlias(alias: string) {
    return this.apps[alias];
  }

  getStoredApps() {
    return this.storedApps;
  }

  getAppByAlias(alias: string) {
    return this.getApps().pipe(
      filter(apps => apps[alias] !== undefined || apps[alias] !== null),
      map(apps => apps[alias])
    );
  }

  getMetaObject(appAlias: string): Observable<HttpResponse<any>> {
    const url = `${this.basePath}workflow/api/apps/metaobject?name=${appAlias}`;
    return this.http.get<any>(url, { observe: 'response' }).pipe(
      map(response => response.body),
      filter(metaApp => metaApp[appAlias] !== undefined),
      tap(metaApp => {
        Object.assign(this.apps, metaApp);
        this.storedApps.push(appAlias);
        this.apps$.next(this.apps);
      })
    );
  }

  sortAppsByName(apps) {
    apps.sort((a, b) => {
      const first = this.apps[a].name.toLowerCase();
      const second = this.apps[b].name.toLowerCase();
      if (first < second) { return -1; }
      if (first > second) { return 1; }
      return 0;
    });
  }

  getJourneysInStoreByAlias(appAlias: string) {
    const url = `${this.basePath}workflow/api/public/workflows/${appAlias}`;
    return this.http.get<any[]>(url, { observe: 'response' }).pipe(
      map(response => response.body)
    );
  }

  getBlogs(appAlias: string) {
    // const url = 'https://run.mocky.io/v3/ed1b3436-0bc0-414e-aa85-b7adcb1939e9';
    const url = `https://blog.quickwork.co/ghost/api/v3/content/posts/?key=33a9ae3e3ae9237ac8570e6309&filter=tag:${appAlias}&limit=3&fields=title,url,feature_image,meta_description`;
    return this.http.get<any[]>(url, { observe: 'response' }).pipe(
      map(response => response.body)
    );
  }

  getLatestBlogs(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`https://blog.quickwork.co/ghost/api/v3/content/posts/?key=33a9ae3e3ae9237ac8570e6309&limit=3&fields=title,url,feature_image,meta_description`, { observe: 'response' }).pipe(
      map(response => response.body)
    );
  }

  searchApps(keyword: string) {
    const params = createRequestOption({ keyword });
    return this.http.get(`${this.basePath}apps/api/v1/search`, { params });
  }

  getCategoriesOfApps() {
    return this.http.get<string[]>(`${this.appURL}/categories`);
  }

  getGroupedApps() {
    return this.http.get<[]>(`${this.appURL}/group/category`).pipe(
      // map(groupedApps => groupedApps.filter(appsGroup => appsGroup['category'] !== '')),
      // map(groupedApps => groupedApps.sort((app1, app2) => {
      //   const app1Category = app1['category'];
      //   const app2Category = app2['category'];
      //   if (app1Category < app2Category) { return -1; }
      //   if (app1Category > app2Category) { return 1; }
      //   return 0;
      // }))
    );
  }

  submitContactForm(formData) {
    return this.http.post('https://automation.quickwork.co/staticwebhook/api/http_app/notify/krish@quickwork.co/contact-us', formData, { observe: 'body' });
  }

  openIntercomBot() {
    window['Intercom']('show');
  }

  getAppsByCategory(category: string) {
    return this.http.post(this.appURL, { category }).pipe();
  }

  redirectToJourneyStore() {
    window.open(AT2URL_JOURNEY_STORE, '_blank');
  }

  submitPartnerForm(partnerForm: object) {
    // return this.http.post('https://automation.quickwork.co/staticwebhook/api/http_app/notify/krish@quickwork.co/partner-form-submit', partnerForm, { observe: 'response' });
    return this.http.post('https://automation.quickwork.co/staticwebhook/api/http_app/notify/5d639e45ab25401c5ce96ac2/partnership_form_quickwork_website', partnerForm, { observe: 'response' });
  }

  getGeoIP() {
    return this.http.get('https://freegeoip.app/json/').pipe(
      tap(res => this.storeUserGeoLocation(res)),
      tap(res => {
        this.showPricingPage$.next(checkContinent(res['country_code']));
        this.showPricingPage$.complete();
        this.pricingService.setCurrencyViaLocale(res['country_code']);
      })
    );
  }

  getUserGeoLocation() {
    return this.userGeoLocation;
  }

  storeUserGeoLocation(geoLocation) {
    this.userGeoLocation = geoLocation;
    this.userGeoLocation$.next(geoLocation);
  }

  redirectToSupportCenter() {
    window.open('https://support.quickwork.co/hc/en-us', '_blank');
  }

  getIsPlatformBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  getPricingPageDisplayState() {
    return this.showPricingPage$.pipe();
  }

}
