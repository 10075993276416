import { animate, style, transition, trigger } from '@angular/animations';

const DEFAULT_DURATION = 300;

export const collapseAnimation = [trigger('collapseAnimation', [
    transition(':enter', [
        style({ opacity: 0, transform: 'scaleY(0)', height: 0, transformOrigin: 'top' }),
        animate(DEFAULT_DURATION, style({ opacity: 1, transform: 'scaleY(1)', height: 'max-content', transformOrigin: 'top' })),
    ]),
    transition(':leave', [
        style({ transformOrigin: 'top' }),
        animate(DEFAULT_DURATION, style({ opacity: 0, transform: 'scaleY(0)', height: 0, transformOrigin: 'top' })),
    ]),
])];
