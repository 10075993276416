<div class="calculate-cost">
    <span class="fa-stack fa-2x icon">
        <i class="fas fa-circle fa-stack-1x"></i>
        <i class="fad fa-dollar-sign fa-stack-1x"></i>
    </span>
    CALCULATE COST
</div>
<div class="plan-selector">
    <div class="plan-selector-parameters-section">
        <div class="plan-selector-parameters">
            <div class="plan-selector-parameter">
                <div class="plan-parameter">
                    <h3>{{ businessPlans[currentPlanIndex].transactions | number }}</h3>
                    <p class="p-2">Transactions per month</p>
                </div>
            </div>
            <div class="plan-selector-parameter">
                <div class="plan-parameter">
                    <h3>{{ (businessPlans[currentPlanIndex].amount[currency.unit].amount / 100) | currency: currency.uppercaseUnit:'symbol':'0.0-2' }}</h3>
                    <p class="p-2">Per {{ showAnnualPlan ? 'year' : 'month' }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="plan-selector-slider-section">
        <div class="plan-selector-slider-container">
            <div class="plan-selector-slider-area">
                <div class="plan-selector-slider-bar">
                    <div class="plan-selector-slider-selection" #sliderSelection></div>
                </div>
                <div class="plan-selector-plans" #plansElement>
                    <ng-container *ngFor="let plan of businessPlans; let i = index;">
                        <div class="plan-selector-plan" #planPoint [class.slider-dragging]="isDragging" (mouseenter)="updateSliderPosition(i)">
                            <div class="plan-selector-point" (click)="selectPlan(i)" [class.previous-selection]="currentPlanIndex >= i"></div>
                            <div class="plan-details">
                                <p class="transaction-value">{{ plan.transactions | number }}</p>
                                <!-- <p class="transaction-rate">{{ plan.amount[currency.unit].perTransactionCost | currency: currency.uppercaseUnit:'symbol':'0.0-4' }}/transaction</p> -->
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="plan-selector-slider" [class.dragging]="isDragging" #planSlider>
                    <i class="fas fa-plus"></i>
                </div>
            </div>
        </div>
        <div class="plan-selector-plans-mobile">
            <ng-container *ngFor="let plan of businessPlans; let i = index;">
                <div class="plan-selector-plan-mobile" [class.selected]="currentPlanIndex === i">
                    <p class="transaction-value">{{ plan.transactions | number }}</p>
                    <!-- <p class="transaction-rate">{{ plan.amount[currency.unit].perTransactionCost | currency: currency.uppercaseUnit:'symbol':'0.0-4' }}/transaction</p> -->
                </div>
            </ng-container>
        </div>
    </div>
</div>