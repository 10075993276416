import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingServiceService {

  constructor() { }

  getErrorMessage(error: HttpErrorResponse) {
    if (error.error) {
      return error.error.title || error.error.message;
    }
    return null;
  }
}
