import { Component, Input } from '@angular/core';
import { IUseCase } from '../use-cases.service';

@Component({
  selector: 'qw-journey-use-case',
  templateUrl: './journey-use-case.component.html',
})
export class JourneyUseCaseComponent {

  @Input() useCase: IUseCase;

}
