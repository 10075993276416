import { Route } from '@angular/router';
import { ActiveLinkGuard } from 'src/app/shared-components/core/active-link.guard';
import { AppDirectoryComponent } from './app-directory/app-directory.component';
import { CategorizedAppsComponent } from './app-directory/categorized-apps.component';
import { PlatformComponent } from './platform/platform.component';

export const ProductRoutes: Route = {
    path: 'product',
    children: [
        {
            path: 'app-directory',
            component: AppDirectoryComponent,
            data: {
                label: 'Apps'
            },
            resolve: [ActiveLinkGuard]
        },
        {
            path: 'app-directory?category=appCategory',
            component: CategorizedAppsComponent,
            data: {
                label: 'Apps'
            },
            resolve: [ActiveLinkGuard]
        },
        {
            path: 'features',
            component: PlatformComponent,
            data: {
                label: 'Platform'
            },
            resolve: [ActiveLinkGuard]
        }
    ]
};
