<div id="nav-container">
    <div class="main-container">
        <ng-container *ngIf="render">
            <!--Zoho Campaigns Web-Optin Form's Header Code Starts Here-->
            
            <script type="text/javascript" src="https://vudt.maillist-manage.in/js/optin.min.js" onload="setupSF('sf3z38452c9ce6ea8189f40914faf653f7d839a99295cbe4c0da8bdaea4cf264f6e7','ZCFORMVIEW',false,'light',false,'0')"></script>
            <script type="text/javascript">
                function runOnFormSubmit_sf3z38452c9ce6ea8189f40914faf653f7d839a99295cbe4c0da8bdaea4cf264f6e7(th){
                    /*Before submit, if you want to trigger your event, "include your code here"*/
                };
            </script>
            
            <!--Zoho Campaigns Web-Optin Form's Header Code Ends Here--><!--Zoho Campaigns Web-Optin Form Starts Here-->
            <div id="sf3z38452c9ce6ea8189f40914faf653f7d839a99295cbe4c0da8bdaea4cf264f6e7" data-type="signupform" style="opacity: 1;margin-bottom: 200px">
                <div id="customForm">
                    <div class="quick_form_5_css" style="background-color: #0e106a; width: 500px; z-index: 2; font-family: Arial; border: 1px solid rgb(206, 206, 206); overflow: hidden" name="SIGNUP_BODY">
                        <div>
                            <span style="font-size: 16px; font-weight: normal; color: rgb(255, 255, 255); text-align: left; padding: 15px 35px 5px; width: 100%; display: block; font-family: Lato, Helvetica, sans-serif" id="SIGNUP_HEADING">Register Now</span>
                            <div style="position:relative;">
                                <div id="Zc_SignupSuccess" style="display:none;position:absolute;margin-left:4%;width:90%;background-color: white; padding: 3px; border: 3px solid rgb(194, 225, 154);  margin-top: 10px;margin-bottom:10px;word-break:break-all">
                                    <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td width="10%">
                                                    <img class="successicon" src="https://vudt.maillist-manage.in/images/challangeiconenable.jpg" align="absmiddle">
                                                </td>
                                                <td>
                                                    <span id="signupSuccessMsg" style="color: rgb(73, 140, 132); font-family: sans-serif; font-size: 14px;word-break:break-word">&nbsp;&nbsp;Thank you for Signing Up</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <form method="POST" ngNoForm id="zcampaignOptinForm" style="margin: 0px; width: 100%" action="https://maillist-manage.in/weboptin.zc" target="_zcSignup">
                                <div style="background-color: rgb(255, 235, 232); padding: 10px; color: rgb(210, 0, 0); font-size: 11px; margin: 20px 10px 0px; border: 1px solid rgb(255, 217, 211); opacity: 1; display: none" id="errorMsgDiv">Please correct the marked field(s) below.</div>
                                <div style="position: relative; margin: 15px 0 10px 10px; width: 150px; height: 28px; display: inline-block">
                                    <div id="Zc_SignupSuccess" style="position: absolute; width: 87%; background-color: white; padding: 3px; border: 3px solid rgb(194, 225, 154); margin-bottom: 10px; word-break: break-all; opacity: 1; display: none">
                                        <div style="width: 20px; padding: 5px; display: table-cell">
                                            <img class="successicon" src="https://campaigns.zoho.com/images/challangeiconenable.jpg" style="width: 20px">
                                        </div>
                                        <div style="display: table-cell">
                                            <span id="signupSuccessMsg" style="color: rgb(73, 140, 132); font-family: sans-serif; font-size: 14px; line-height: 30px; display: block"></span>
                                        </div>
                                    </div>
                                    <input type="text" style="font-size: 12px; border-width: 1px; border-color: rgb(214, 205, 205); border-style: solid; width: 100%; height: 100%; z-index: 4; outline: none; padding: 5px 10px; color: rgb(113, 106, 106); text-align: left; font-family: Arial; border-radius: 0px; background-color: rgb(255, 255, 255); box-sizing: border-box" placeholder="Email" changeitem="SIGNUP_FORM_FIELD" name="CONTACT_EMAIL" id="EMBED_FORM_EMAIL_LABEL">
                                </div>
                                <div style="position: relative; margin: 15px 0 10px 10px; width: 150px; height: 28px; display: inline-block">
                                    <input type="text" style="font-size: 12px; border-width: 1px; border-color: rgb(214, 205, 205); border-style: solid; width: 100%; height: 100%; z-index: 4; outline: none; padding: 5px 10px; color: rgb(113, 106, 106); text-align: left; border-radius: 0px; background-color: rgb(255, 255, 255); box-sizing: border-box; font-family: Lato, Helvetica, sans-serif" placeholder="Name" changeitem="SIGNUP_FORM_FIELD" name="LASTNAME" id="EMBED_FORM_NAME_LABEL">
                                </div>
                                <div style="position: relative; width: 100px; height: 28px; margin: 0 0 10px 12px; display: inline-block">
                                    <input type="submit" style="text-align: center; width: 100%; height: 100%; z-index: 5; border: 1px none; color: rgb(14, 16, 106); cursor: pointer; outline: none; font-size: 14px; background-color: rgb(243, 177, 78); border-radius: 1px; font-family: Lato, Helvetica, sans-serif" name="SIGNUP_SUBMIT_BUTTON" id="zcWebOptin" value="Sign up">
                                </div>
                                <input type="hidden" id="fieldBorder" value="">
                                <input type="hidden" id="submitType" name="submitType" value="optinCustomView">
                                <input type="hidden" id="emailReportId" name="emailReportId" value="">
                                <input type="hidden" id="formType" name="formType" value="QuickForm">
                                <input type="hidden" name="zx" id="cmpZuid" value="1df9057cb1">
                                <input type="hidden" name="zcvers" value="2.0">
                                <input type="hidden" name="oldListIds" id="allCheckedListIds" value="">
                                <input type="hidden" id="mode" name="mode" value="OptinCreateView">
                                <input type="hidden" id="zcld" name="zcld" value="">
                                <input type="hidden" id="zctd" name="zctd" value="">
                                <input type="hidden" id="document_domain" value="">
                                <input type="hidden" id="zc_Url" value="vudt.maillist-manage.in">
                                <input type="hidden" id="new_optin_response_in" value="0">
                                <input type="hidden" id="duplicate_optin_response_in" value="0">
                                <input type="hidden" name="zc_trackCode" id="zc_trackCode" value="ZCFORMVIEW">
                                <input type="hidden" id="zc_formIx" name="zc_formIx" value="3z38452c9ce6ea8189f40914faf653f7d839a99295cbe4c0da8bdaea4cf264f6e7">
                                <input type="hidden" id="viewFrom" value="URL_ACTION">
                                <span style="display: none" id="dt_CONTACT_EMAIL">1,true,6,Contact Email,2</span>
                                <span style="display: none" id="dt_FIRSTNAME">1,false,1,First Name,2</span>
                                <span style="display: none" id="dt_LASTNAME">1,false,1,Last Name,2</span>
                            </form>
                        </div>
                    </div>
                </div>
                <img src="https://vudt.maillist-manage.in/images/spacer.gif" id="refImage" onload="referenceSetter(this)" style="display:none;">
            </div>
            <input type="hidden" id="signupFormType" value="QuickForm_Horizontal">
            <div id="zcOptinOverLay" oncontextmenu="return false" style="display:none;text-align: center; background-color: rgb(0, 0, 0); opacity: 0.5; z-index: 100; position: fixed; width: 100%; top: 0px; left: 0px; height: 988px;"></div>
            <div id="zcOptinSuccessPopup" style="display:none;z-index: 9999;width: 800px; height: 40%;top: 84px;position: fixed; left: 26%;background-color: #FFFFFF;border-color: #E6E6E6; border-style: solid; border-width: 1px;  box-shadow: 0 1px 10px #424242;padding: 35px;">
                <span style="position: absolute;top: -16px;right:-14px;z-index:99999;cursor: pointer;" id="closeSuccess">
                    <img src="https://vudt.maillist-manage.in/images/videoclose.png">
                </span>
                <div id="zcOptinSuccessPanel"></div>
            </div>
            <!--Zoho Campaigns Web-Optin Form Ends Here-->
            
        </ng-container>
    </div>
</div>



