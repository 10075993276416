import { AfterViewInit, Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
declare var noise: any;

const SCROLL_SPEED = 0.3;
const NOISE_SPEED = 0.004;
const NOISE_AMOUNT = 5;
const CANVAS_WIDTH = 600;

@Component({
  selector: 'qw-integration-apps',
  template: `
  <div class="bubbles" role="img" alt="Application Integration">
    <ng-container *ngFor="let bubble of bubblesSpecs; let i = index">
      <a href="" [routerLink]="['/apps', bubble.alias, 'integrations']">
        <div class="bubble" #bubblesEl id="logo-{{ i + 1}}">
          <qw-app-icon [appAlias]="bubble.alias"></qw-app-icon>
        </div>
      </a>
    </ng-container>
  </div>
  `,
  // templateUrl: './integration-apps.component.html',
  styleUrls: ['./integration-apps.component.scss']
})
export class IntegrationAppsComponent implements AfterViewInit {

  @ViewChildren('bubblesEl') bubblesEl: QueryList<HTMLDivElement>;
  bubblesSpecs = [
    { s: .5, x: 361, y: 53, alias: 'facebook_messenger' },
    { s: .5, x: 463, y: 333, alias: 'scheduler' },
    { s: .5, x: 580, y: 302, alias: 'logger' },
    { s: .5, x: 793, y: 382, alias: 'facebook_ads_leads' },
    { s: .5, x: 1003, y: 119, alias: 'mysql_adapter' },
    { s: .5, x: 724, y: 177, alias: 'http_app' },
    { s: .5, x: 91, y: 356, alias: 'shopify_private' },
    { s: .5, x: 67, y: 275, alias: 'google_drive' },
    { s: .5, x: 280, y: 416, alias: 'quickwork_chat_v2' },
    { s: .5, x: 254, y: 330, alias: 'gmail' },
    { s: .5, x: 647, y: 380, alias: 'google_calendar' },
    { s: .6, x: 438, y: 119, alias: 'salesforce' },
    { s: .6, x: 334, y: 149, alias: 'karix_e' },
    { s: .6, x: 161, y: 288, alias: 'surveyanyplace' },
    { s: .6, x: 142, y: 61, alias: 'nodejs' },
    { s: .6, x: 186, y: 393, alias: 'telegram' },
    { s: .6, x: 378, y: 385, alias: 'github' },
    { s: .6, x: 540, y: 398, alias: 'google_sheets' },
    { s: .6, x: 802, y: 63, alias: 'lookup_table_by_quickwork' },
    { s: .6, x: 903, y: 99, alias: 'postgresql' },
    { s: .6, x: 825, y: 170, alias: 'eventbrite' },
    { s: .6, x: 834, y: 283, alias: 'amazon_rekognition' },
    // { x: 485, y: 228, alias: 'wso2-production' },
    { x: 485, y: 228, alias: 'dialogflow' },
    { x: 818, y: 735, alias: 'redis' },
    { x: 549, y: 73, alias: 'google_docs' },
    { x: 613, y: 191, alias: 'callable_workflow' },
    { x: 708, y: 290, alias: 'sendgrid' },
    { x: 345, y: 266, alias: 'hubspot' },
    { x: 254, y: 68, alias: 'facebook' },
    { x: 107, y: 169, alias: 'slack' },
    { x: 229, y: 197, alias: 'airtable' },
    { x: 941, y: 219, alias: 'bitly' },
    { x: 683, y: 71, alias: 'mailchimp' }
  ];

  bubblesAnimation: Bubbles;

  ngAfterViewInit() {
    noise.seed(Math.floor(Math.random() * 64000));
    this.bubblesAnimation = new Bubbles(this.bubblesSpecs, this.bubblesEl['_results']);
  }

}


class Bubble {

  index: number;
  x: number;
  y: number;
  scale: number;
  noiseSeedX: number;
  noiseSeedY: number;
  el: HTMLDivElement;


  constructor(index: number, { x, y, s = 0.8 }: any, bubblesElement: HTMLDivElement) {
    this.index = index;
    this.x = x;
    this.y = y;
    this.scale = s;

    this.noiseSeedX = Math.floor(Math.random() * 64000);
    this.noiseSeedY = Math.floor(Math.random() * 64000);

    this.el = bubblesElement;
  }

  update() {
    this.noiseSeedX += NOISE_SPEED;
    this.noiseSeedY += NOISE_SPEED;
    const randomX = noise.simplex2(this.noiseSeedX, 0);
    const randomY = noise.simplex2(this.noiseSeedY, 0);

    if (this.x < -400) {
      this.x = CANVAS_WIDTH;
      this.el.style.display = 'none';
    } else {
      this.x -= SCROLL_SPEED;
      this.el.style.display = 'block';
    }

    const xWithNoise = this.x + (randomX * NOISE_AMOUNT);
    const yWithNoise = this.y + (randomY * NOISE_AMOUNT);
    // const xWithNoise = this.x;
    // const yWithNoise = this.y;

    this.el.style.transform = `translate(${yWithNoise}px, ${xWithNoise}px) scale(${this.scale})`;
  }
}

class Bubbles {

  bubbles: Bubble[];
  raf: number;

  constructor(specs: any[], bubblesElement: ElementRef) {
    this.bubbles = [];

    specs.forEach((spec: { x: number; y: number; s?: number; }, index: number) => {
      this.bubbles.push(new Bubble(index, spec, bubblesElement[index].nativeElement));
    });

    requestAnimationFrame(this.update.bind(this));
  }

  update() {
    this.bubbles.forEach(bubble => bubble.update());
    this.raf = requestAnimationFrame(this.update.bind(this));
  }
}

