import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';

@Component({
  selector: 'qw-tabs-layout',
  templateUrl: './tabs-layout.component.html',
  styleUrls: ['./tabs-layout.component.scss']
})
export class TabsLayoutComponent implements AfterViewInit {

  @Input() tabs: any[];
  @ViewChildren('tabElement') tab: QueryList<HTMLButtonElement>;
  @ViewChild('highlighter', { static: true }) highlighter: ElementRef;
  activeIndex = 0;

  @Output() onTabSwitch = new EventEmitter();

  constructor(private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.moveHighlighter(0, false);
  }

  moveHighlighter(i: number, emit = true) {
    const tab = (this.tab['_results'][i].nativeElement as HTMLElement);
    const left = tab.offsetLeft;
    const width = tab.offsetWidth;
    this.renderer.setStyle(this.highlighter.nativeElement, 'left', `${left}px`);
    this.renderer.setStyle(this.highlighter.nativeElement, 'width', `${width}px`);
    this.activeIndex = i;
    if (emit) this.onTabSwitch.emit(i);
  }

}
