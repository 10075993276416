<!-- VALUE PROPOSITION SECTION -->
<section id="header-section" class="trapezoid-fill animated gradient-1">
  <div class="main-container">
    <div class="header">
      <div class="header-content">
        <h1 class="header-title">
          The real-time Integration and API platform to build automated workflows.
        </h1>
        <p class="p-2 header-description">
          Enterprises use Quickwork to build simple and complex workflows, create and publish secure APIs, and manage
          conversational interactions with customers, employees and partners to provide a great user experience.
        </p>
        <div class="header-call-to-action">
          <a [href]="platformURL" target="_blank" class="button filled">
            Get started
            <i class="fas fa-chevron-right"></i>
          </a>
          <!-- <button type="button" class="button plain" (click)="redirectToAT2()">
            Contact us
            <i class="fas fa-chevron-right"></i>
          </button> -->
        </div>
      </div>
      <div class="header-representation">
        <div class="header-image">
          <!-- <img src="../../../assets/images/enterprise-qjb/home-page/quickwork_integration_hero.svg" alt="" srcset=""> -->
          <img src="../../../assets/images/enterprise-qjb/home-page/HeroImage.svg" width="100%" height="100%" alt="Automate Workflows" srcset="">
        </div>
      </div>
    </div>
  </div>
  <div class="customers-container">
    <div class="main-container">
      <qw-customers [flipAnimation]="true"></qw-customers>
    </div>
  </div>
</section>

<section id="platform-features-section">
  <div class="main-container">
    <header class="platform-features-header">
      <h3 class="platform-features-caption caption">Unified platform</h3>
      <h2 class="platform-features-title">An enterprise grade platform with all the tools you need</h2>
      <p class="platform-features-description p-2">
        Quickwork provides an all-in-one platform with the tools and services you need to build powerful & scalable
        integrations, serverless APIs, conversational experiences, and a lot more!
      </p>
      <a href="" class="button plain" routerLink="/product/features">
        Learn more about our platform
        <i class="fas fa-chevron-right"></i>
      </a>
    </header>
    <div class="platform-features l-m-border">
      <div class="platform-feature" id="integrations">
        <div class="platform-feature-content">
          <h3 class="platform-feature-title caption border-highlight">Integrations</h3>
          <p class="platform-feature-description p-2">
            Simply drag and drop the applications you wish to use to build powerful integrations without writing a
            single line of code. Choose from 1,000s of business, consumer, AI, analytics, messaging, and IoT apps to
            create any automated workflow you can imagine!
          </p>
          <div class="platform-feature-points">
            <ul class="platform-feature-points-list points">
              <li *ngFor="let featurePoint of platformFeatures.industrial">
                {{ featurePoint }}
              </li>
            </ul>
          </div>
          <!-- <button type="button" class="button filled" id="explore-apps" (click)="navigateToAppDirectory()">
            Explore our app universe
            <i class="fas fa-chevron-right"></i>
          </button> -->
        </div>
        <div class="platform-feature-representation bounded">
          <div class="platform-feature-image" id="integrations">
            <!-- <img src="../../../assets/images/enterprise-qjb/home-page/Integration.svg" alt="" srcset=""> -->
            <qw-integration-apps></qw-integration-apps>
          </div>
        </div>
      </div>
      <div class="platform-feature" id="serverless-apis">
        <div class="platform-feature-content">
          <h3 class="platform-feature-title caption border-highlight">API Management</h3>
          <p class="platform-feature-description p-2">
            Convert any workflow into an API with Quickwork’s single click API Management. Share your APIs securely with
            built-in authentication mechanisms and scale elastically with our serverless infrastructure.
          </p>
          <div class="platform-feature-points">
            <ul class="platform-feature-points-list points">
              <li *ngFor="let featurePoint of platformFeatures.serverlessAPIS">
                {{ featurePoint }}
              </li>
            </ul>
          </div>
        </div>
        <div class="platform-feature-representation bounded">
          <div class="platform-feature-image" id="api-managment">
            <img src="../../../assets/images/enterprise-qjb/home-page/APIManagement.svg" alt="API Management" width="100%" height="100%">
          </div>
        </div>
      </div>
      <div class="platform-feature" id="conversation-management">
        <div class="platform-feature-content">
          <h3 class="platform-feature-title caption border-highlight">Conversation Management</h3>
          <p class="platform-feature-description p-2">
            Build and manage real-time conversational and messaging workflows with human agents, chatbots and IoT
            devices, across multiple channels such as WhatsApp, Line, Facebook Messenger, Telegram, Slack, Microsoft
            Teams, Google Chat, web widgets and many more.
          </p>
          <div class="platform-feature-points">
            <ul class="platform-feature-points-list points">
              <li *ngFor="let featurePoint of platformFeatures.conversationManagement">
                {{ featurePoint }}
              </li>
            </ul>
          </div>
        </div>
        <div class="platform-feature-representation bounded">
          <div class="platform-feature-image">
            <qw-conversation-management></qw-conversation-management>
            <!-- <img src="../../../assets/images/enterprise-qjb/home-page/ConversationManagement.svg"
              alt="Conversational AI" srcset=""> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<qw-growth-section></qw-growth-section>


<section id="product-importance-section">
  <div class="main-container">
    <header class="product-importance-header">
      <h3 class="product-importance-caption caption">Why Quickwork?</h3>
      <h2 class="product-importance-title">
        Real-time events and a no code user interface enable infinite integration possibilities
      </h2>
    </header>
    <div class="column-layout c-1-1-1-1" id="product-importance-grid">
      <div class="column" *ngFor="let point of productImportancePoints">
        <div class="column-icon icon">
          <ng-container *ngIf="point.title === 'No Code'">
            <div class="fa-stack no-code-icon">
              <i class="fad fa-code fa-stack-1x"></i>
              <i class="fas fa-ban fa-stack-2x"></i>
            </div>
          </ng-container>
          <i *ngIf="point.title !== 'No Code'" class="fad {{ point.icon }}"></i>
        </div>
        <div class="column-title text-bold">{{ point.title }}</div>
        <p class="column-description">{{ point.description }}</p>
      </div>
    </div>
  </div>
</section>


<!-- Temporarily hiding pricing section  -->

<!-- 

  <section id="pricing-section">
    <div class="main-container">
      <div class="column-layout c-1-1 size-3fr-1fr">
        <header>
          <h3 class="caption">Pricing</h3>
          <h2>Simple, affordable and transparent pricing</h2>
          <p class="p-2">
            Our pricing is based on the <span class="text-bold" id="transaction-info">
              transaction
              <i class="fad fa-info-circle color-blue"></i>
            </span> volume required for your workflows. Start with our free forever Freedom plan which provides <span
              class="text-bold">10,000
              transactions per month</span> with 5 active workflows, and choose from any of our affordable paid plans with
            unlimited workflows as you grow.
          </p>
          <a href="" class="button plain" routerLink="/pricing">
            Learn more about our pricing
            <i class="fas fa-chevron-right"></i>
          </a>
          <div id="transaction-info-description">
            <i class="fad fa-info-circle"></i>
            <p>
              A transaction is counted as one end-to-end execution of a workflow journey. Each time a workflow journey is
              triggered for execution, one transaction is counted regardless of the status of (success, failure or
              pending) or the number of steps in the journey.
            </p>
          </div>
        </header>
        <div class="column l-m-border">
          <div class="column-body">
            <h3 class="caption border-highlight">Freedom Plan</h3>
            <div class="pricing-parameters">
              <div class="pricing-parameter">
                <h3 class="text-extra-bold">{{ 0 | currency: currency:'symbol':'0.0' }}</h3>
                <p class="p-3">Per month</p>
              </div>
              <div class="pricing-parameter">
                <h3 class="text-extra-bold">10,000</h3>
                <p class="p-3">Transactions per month</p>
              </div>
              <div class="pricing-parameter">
                <h3 class="text-extra-bold">5</h3>
                <p class="p-3">Active workflows</p>
              </div>
            </div>
          </div>
          <div class="column-body">
            <h3 class="caption border-highlight">Enterprise Plans</h3>
            <div class="pricing-parameters">
              <div class="pricing-parameter" *ngIf="pricingDisplayStatus === 'show'">
                <h3 class="text-extra-bold">$ 0.00199</h3>
                <p class="p-3">With rates as low as $0.001 per transaction</p>
              </div>
              <div class="pricing-parameter">
                <h3 class="text-extra-bold">Unlimited</h3>
                <p class="p-3">Active workflows</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

 -->
  

<section id="customer-stories-section">
  <div class="main-container">
    <header class="customer-stories-header">
      <h3 class="caption">Customer stories</h3>
      <h2 class="title">Companies across industry verticals use Quickwork to power their integrations</h2>
    </header>

    <ng-container *ngIf="caseStudies$ | async as caseStudyGroups">
      <qw-grouped-case-studies [groupedCaseStudies]="caseStudyGroups"></qw-grouped-case-studies>
    </ng-container>
  </div>
</section>

<qw-get-started-section></qw-get-started-section>