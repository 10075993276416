<div id="testimonial-section">
  <div class="main-container">
    <div class="testimonial-container">
      <div class="testimonial">
        <ng-container *ngIf="testimonials.length > 1">
          <div class="testimonial-arrow-container">
            <button type="button" class="testimonial-left-arrow" (click)="decTestimonial()">
              <i class="fa fa-chevron-left"></i>
            </button>
          </div>
        </ng-container>
        <div>
          <ng-container *ngFor="let testimonial of testimonials; let i = index">
            <div class="testimonial-content" *ngIf="i === currentIndex">
              <div class="testimonial-by">
                <div class="testimonial-company-logo">
                  <img [src]="testimonial.customer_image" [alt]="testimonial.name" width="100%" height="100%">
                </div>
                <div class="testimonial-customer-details">
                  <p class="customer-name">
                    {{ testimonial.name }}
                  </p>
                  <p class="customer-designation">
                    {{ testimonial.customer_position }} <br> {{ testimonial.company_name }}
                  </p>
                </div>
              </div>
              <div class="testimonial-text" [innerHTML]="testimonial.testimonal_qoute"></div>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="testimonials.length > 1">
          <div class="testimonial-arrow-container">
            <button type="button" class="testimonial-right-arrow" (click)="incTestimonial()">
              <i class="fa fa-chevron-right"></i>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="indicators">
      <div class="slider-indicators">
        <ng-container *ngFor="let t of testimonials; let i = index;">
          <div class="slider-indicator" [class.active]="i === currentIndex" (click)="setTestimonialIndex(i)"></div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

