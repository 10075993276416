import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService, LoadingState } from 'src/app/app.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY, from, Observable, Subject } from 'rxjs';
import { catchError, concatMap, debounceTime, distinct, map, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'qw-app-directory',
  templateUrl: './app-directory.component.html',
  styleUrls: ['./app-directory.component.scss']
})

export class AppDirectoryComponent implements OnInit, OnDestroy {

  originalApps: any;
  $apps: any;
  appKeys: string[] = [];
  categorizedAppKeys: string[] = [];
  loadingState: LoadingState;
  noApps: boolean;
  noAppsMessage: string;
  errorMessage: string;
  // categories: any[] = [];
  categories$ = new Observable<string[]>();
  currentCategory = 'All Apps';
  appsSearch = new FormControl('');
  private defaultSliceFactor = 80;
  sliceFactor: number;
  autoScroll = false;
  toggleCategories = false;

  private destroyed$ = new Subject();

  constructor(
    private appService: AppService,
    private router: Router,
    private titleService: Title,
    private metaService: Meta,
    private actRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Explore App Directory | Integrate and Automate With Quickwork ');
    this.metaService.addTags([
      { name: 'description', content: `Browse through Quickwork's rich app directory of 1,000+ apps from business, consumer, AI, analytic, messaging and IoT categories.` },
      { name: 'robots', content: 'index, follow' }
    ]);
    this.initSliceFactor();
    this.actRoute.queryParams.pipe(
      switchMap((params) => {
        this.loadingState = 'pending';
        if (params.category) {
          this.currentCategory = params.category;
          return this.getAppsByCategory$();
        } else if (params.search) {
          this.appsSearch.setValue(params.search, { emitEvent: false });
          return this.searchAppsByValue(params.search);
        }
        return this.getApps();
      })
    ).subscribe();

    this.appsSearch.valueChanges.pipe(
      debounceTime(500),
      map(searchValue => searchValue.trim()),
      tap(() => this.searchApps()),
      takeUntil(this.destroyed$)
    ).subscribe();

    this.categories$ = this.appService.getCategoriesOfApps().pipe(
      map(categoriesArray => categoriesArray.sort((a, b) => {
        const first = a.toLowerCase().trim();
        const second = b.toLowerCase().trim();
        if (first < second) { return -1; }
        if (first > second) { return 1; }
        return 0;
      })),
      map(categories => categories.filter(category => category !== '')),
      concatMap(categories => from(categories).pipe(
        distinct(),
        toArray(),
      )),
      map(categories => ['All Apps'].concat(categories)),
    );

  }

  private getApps() {
    return this.appService.getApps().pipe(
      tap(apps => {
        this.originalApps = apps;
        this.$apps = apps;
        this.appKeys = this.getAllAppAliases();
        this.noApps = this.appKeys.length === 0;
        if (this.noApps) {
          this.noAppsMessage = 'No apps found';
        } else {
          this.noAppsMessage = '';
        }
      }),
      concatMap(() => this.getAppsByCategory$()),
      catchError((error: HttpErrorResponse) => {
        this.errorMessage = error.message;
        this.loadingState = 'failure';
        return EMPTY;
      }),
      takeUntil(this.destroyed$),
    );
  }

  private searchApps() {
    if (this.currentCategory !== 'All Apps') {
      this.currentCategory = 'All Apps';
    }
    this.addQueryParams();
  }

  private searchAppsByValue(searchValue: string) {
    this.loadingState = 'pending';
    return this.appService.searchApps(searchValue).pipe(
      tap(apps => {
        this.$apps = apps;
        this.appKeys = [];
        if (apps) {
          this.appKeys = Object.keys(apps);
          this.noAppsMessage = '';
        } else {
          this.noAppsMessage = `Couldn't find any apps`;
        }
        this.loadingState = 'success';
        this.initSliceFactor();
      }),
      catchError(() => EMPTY)
    );
  }

  navigateToIntegrations(app) {
    // this.router.navigate(['apps', app, 'integrations']);
    window.open('./apps/' + app + '/integrations', '_blank');
  }

  getAppsByCategory(category: string) {
    this.autoScroll = false;
    if (!this.noApps && category !== this.currentCategory) {
      this.currentCategory = category;
    }
    this.addQueryParams();
  }

  private getAppsByCategory$() {
    if (this.currentCategory === 'All Apps') {
      this.setAllAppAliases();
      this.$apps = this.originalApps;
      this.loadingState = 'success';
      this.autoScroll = true;
      if (this.appsSearch.value) {
        this.appsSearch.setValue('', { emitEvent: false });
      } else { this.initSliceFactor(); }
    } else {
      this.loadingState = 'pending';
      return this.appService.getAppsByCategory(this.currentCategory).pipe(
        tap(apps => {
          this.$apps = apps;
          this.appKeys = Object.keys(apps);
          this.appService.sortAppsByName(this.appKeys);
          if (this.appsSearch.value) {
            this.appsSearch.setValue('', { emitEvent: false });
          } else { this.initSliceFactor(); }
          if (this.appKeys.length === 0) {
            this.noAppsMessage = `No apps found under this category`;
          } else {
            this.noAppsMessage = '';
          }
          this.autoScroll = true;
          this.loadingState = 'success';
        }, (error: HttpErrorResponse) => {
          this.errorMessage = error.message;
          this.loadingState = 'failure';
        }),
        takeUntil(this.destroyed$)
      );
    }
    return EMPTY;
  }

  private setAllAppAliases() {
    this.appKeys = this.getAllAppAliases();
  }

  private getAllAppAliases() {
    return this.appService.getAllAppAliases();
  }

  private addQueryParams() {
    if (this.currentCategory !== 'All Apps') {
      const category = this.currentCategory;
      this.router.navigate(['product', 'app-directory'], { queryParams: { category } });
    } else if (this.appsSearch.value) {
      const search = this.appsSearch.value;
      this.router.navigate(['product', 'app-directory'], { queryParams: { search } });
    } else this.router.navigate(['product', 'app-directory']);
  }

  private initSliceFactor() {
    if (this.sliceFactor !== this.defaultSliceFactor) {
      this.sliceFactor = this.defaultSliceFactor;
    }
  }

  incSliceFactor() {
    this.sliceFactor += this.defaultSliceFactor;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
