<div class="use-case-filters">
    <ng-container *ngIf="categories$ | async as categories; else errorCategories;">
        <qw-categories-dropdown [categories]="categories" [(currentCategory)]="currentCategory"
            (currentCategoryChange)="filterUseCases($event, 'categories')"></qw-categories-dropdown>
    </ng-container>
    <ng-template #errorCategories>
        <ng-container *ngIf="categoriesError$ | async as error; else categoriesLoading">
            <div class="error">
                <p>Failed to fetch categories</p>
                <p class="m-0">{{ error | manageError }}</p>
            </div>
        </ng-container>
        <ng-template #categoriesLoading>
            <div class="loader">
                <qw-spinner loadingText="Fetching categories"></qw-spinner>
            </div>
        </ng-template>
    </ng-template>
</div>