export const COUNTRY_WISE_CONTINENTS = {
    XS: 'Asia',
    XD: 'Asia',
    XE: 'Asia',
    XX: 'Oceania',
    ZM: 'Africa',
    YE: 'Asia',
    WS: 'Oceania',
    WF: 'Oceania',
    VE: 'South America',
    UZ: 'Asia',
    UY: 'South America',
    BF: 'Africa',
    VI: 'North America',
    US: 'North America',
    TZ: 'Africa',
    IM: 'Europe',
    JE: 'Europe',
    GG: 'Europe',
    GB: 'Europe',
    EG: 'Africa',
    MK: 'Europe',
    UA: 'Europe',
    UG: 'Africa',
    TV: 'Oceania',
    TC: 'North America',
    TM: 'Asia',
    TR: 'Europe',
    TN: 'Africa',
    AE: 'Asia',
    TT: 'North America',
    TO: 'Oceania',
    TK: 'Oceania',
    TG: 'Africa',
    TH: 'Asia',
    TJ: 'Asia',
    SY: 'Asia',
    CH: 'Europe',
    SE: 'Europe',
    SZ: 'Africa',
    SJ: 'Europe',
    SR: 'South America',
    SD: 'Africa',
    EH: 'Africa',
    SS: 'Africa',
    ES: 'Europe',
    ZW: 'Africa',
    ZA: 'Africa',
    SO: 'Africa',
    SI: 'Europe',
    VN: 'Asia',
    SK: 'Europe',
    SG: 'Asia',
    SL: 'Africa',
    SC: 'Africa',
    RS: 'Europe',
    SN: 'Africa',
    SA: 'Asia',
    ST: 'Africa',
    SM: 'Europe',
    VC: 'North America',
    PM: 'North America',
    MF: 'North America',
    LC: 'North America',
    AI: 'North America',
    KN: 'North America',
    SH: 'Africa',
    BL: 'North America',
    RW: 'Africa',
    RU: 'Europe',
    RO: 'Europe',
    RE: 'Africa',
    QA: 'Asia',
    PR: 'North America',
    TL: 'Asia',
    GW: 'Africa',
    PT: 'Europe',
    PL: 'Europe',
    PN: 'Oceania',
    PH: 'Asia',
    PE: 'South America',
    PY: 'South America',
    PG: 'Oceania',
    PA: 'North America',
    PK: 'Asia',
    PW: 'Oceania',
    MH: 'Oceania',
    FM: 'Oceania',
    UM: 'Oceania',
    MP: 'Oceania',
    NO: 'Europe',
    NF: 'Oceania',
    NU: 'Oceania',
    NG: 'Africa',
    NE: 'Africa',
    NI: 'North America',
    NZ: 'Oceania',
    VU: 'Oceania',
    NC: 'Oceania',
    BQ: 'North America',
    SX: 'North America',
    AW: 'North America',
    CW: 'North America',
    AN: 'North America',
    NL: 'Europe',
    NP: 'Asia',
    NR: 'Oceania',
    NA: 'Africa',
    OM: 'Asia',
    MZ: 'Africa',
    MA: 'Africa',
    MS: 'North America',
    ME: 'Europe',
    MD: 'Europe',
    MN: 'Asia',
    MC: 'Europe',
    MX: 'North America',
    MU: 'Africa',
    MR: 'Africa',
    MQ: 'North America',
    MT: 'Europe',
    ML: 'Africa',
    MV: 'Asia',
    MY: 'Asia',
    MW: 'Africa',
    MG: 'Africa',
    MO: 'Asia',
    LU: 'Europe',
    LT: 'Europe',
    LI: 'Europe',
    LY: 'Africa',
    LR: 'Africa',
    LV: 'Europe',
    LS: 'Africa',
    LB: 'Asia',
    LA: 'Asia',
    KG: 'Asia',
    KW: 'Asia',
    KR: 'Asia',
    KP: 'Asia',
    KE: 'Africa',
    JO: 'Asia',
    KZ: 'Europe',
    JP: 'Asia',
    JM: 'North America',
    CI: 'Africa',
    IT: 'Europe',
    IL: 'Asia',
    IE: 'Europe',
    IQ: 'Asia',
    IR: 'Asia',
    ID: 'Asia',
    IN: 'Asia',
    IS: 'Europe',
    HU: 'Europe',
    HK: 'Asia',
    HN: 'North America',
    VA: 'Europe',
    HM: 'Antarctica',
    HT: 'North America',
    GY: 'South America',
    GN: 'Africa',
    GT: 'North America',
    GU: 'Oceania',
    GP: 'North America',
    GD: 'North America',
    GL: 'North America',
    GR: 'Europe',
    KI: 'Oceania',
    GI: 'Europe',
    GH: 'Africa',
    DE: 'Europe',
    PS: 'Asia',
    GM: 'Africa',
    GE: 'Europe',
    GA: 'Africa',
    DJ: 'Africa',
    TF: 'Antarctica',
    PF: 'Oceania',
    GF: 'South America',
    FR: 'Europe',
    AX: 'Europe',
    FI: 'Europe',
    FJ: 'Oceania',
    GS: 'Antarctica',
    FK: 'South America',
    FO: 'Europe',
    EE: 'Europe',
    ER: 'Africa',
    ET: 'Africa',
    GQ: 'Africa',
    SV: 'North America',
    EC: 'South America',
    DO: 'North America',
    DM: 'North America',
    DK: 'Europe',
    BJ: 'Africa',
    CZ: 'Europe',
    CY: 'Europe',
    CU: 'North America',
    HR: 'Europe',
    CR: 'North America',
    CK: 'Oceania',
    CD: 'Africa',
    CG: 'Africa',
    YT: 'Africa',
    KM: 'Africa',
    CO: 'South America',
    CC: 'Asia',
    CX: 'Asia',
    TW: 'Asia',
    CN: 'Asia',
    CL: 'South America',
    TD: 'Africa',
    LK: 'Asia',
    CF: 'Africa',
    KY: 'North America',
    CV: 'Africa',
    CA: 'North America',
    CM: 'Africa',
    KH: 'Asia',
    BY: 'Europe',
    BI: 'Africa',
    MM: 'Asia',
    BG: 'Europe',
    BN: 'Asia',
    VG: 'North America',
    SB: 'Oceania',
    IO: 'Asia',
    BZ: 'North America',
    BR: 'South America',
    BV: 'Antarctica',
    BW: 'Africa',
    BA: 'Europe',
    BO: 'South America',
    BT: 'Asia',
    BM: 'North America',
    BE: 'Europe',
    BB: 'North America',
    AM: 'Europe',
    BD: 'Asia',
    BH: 'Asia',
    BS: 'North America',
    AT: 'Europe',
    AU: 'Oceania',
    AR: 'South America',
    AZ: 'Europe',
    AG: 'North America',
    AO: 'Africa',
    AD: 'Europe',
    AS: 'Oceania',
    DZ: 'Africa',
    AQ: 'Antarctica',
    AL: 'Europe',
    AF: 'Asia'
};

// const specificContinents = ['EU', 'Middle East', 'Africa'];
const specificContinents = ['Europe', 'Africa'];

export const checkContinent = (countryCode: string) => {
    if (COUNTRY_WISE_CONTINENTS[countryCode]) {
        const continent = COUNTRY_WISE_CONTINENTS[countryCode];
        if (specificContinents.includes(continent)) {
            return 'hide';
        }
    }
    return 'show';
};
