<section id="header-section" class="trapezoid-fill animated gradient-1">
    <div class="main-container">
        <div class="header">
            <h1 class="header-title">
                Our mission is to make automation easy, affordable, accessible and compliant
            </h1>
            <p class="p-2 header-description">
                As a team of experienced founders with young engineers and marketers, we came together everyday to
                manifest our shared vision to democratise automation for social good.
            </p>
        </div>
    </div>
</section>

<section id="points-section">
    <div class="main-container">
        <h2 class="points-title t-a-c">We make automation...</h2>
        <div class="column-layout c-1-1-1-1" id="product-importance-grid">
            <div class="column" *ngFor="let point of visionStatements">
                <div class="column-title m-b-10 text-bold">{{ point.title }}</div>
                <p class="column-description">{{ point.description }}</p>
            </div>
        </div>
    </div>
</section>

<section id="customers-section">
    <div class="main-container">
        <div class="customers-header">
            <h2 class="customers-title t-a-c">Used by small to large enterprises</h2>
            <p class="customers-description p-2">
                Enterprises across industry verticals use Quickwork to power integration, APIs and conversations at
                scale.
            </p>
        </div>
        <qw-customers></qw-customers>
    </div>
</section>

<qw-testimonial></qw-testimonial>

<section id="core-value-section">
    <div class="main-container">
        <div class="core-value-header">
            <h2 class="core-value-title">Our core value</h2>
            <p class="core-value-description p-2">
                Our core guiding value is <span class="color-blue">Customer
                    Centricity</span> inspired by the following quote by Mahatma Gandhi
            </p>
            <p class="core-value-message p-1">
                A customer is the most important visitor on our premises. He is not dependent on us. We are dependent on
                him. He is not an interruption in our work. He is the purpose of it. He is not an outsider in our
                business. He is part of it. We are not doing him a favour by serving him. He is doing us a favour by
                giving us the opportunity to do so.
            </p>
        </div>
    </div>
</section>

<section id="team-section">
    <div class="main-container">
        <div class="team-header">
            <h2 class="team-title">Our team</h2>
            <div class="team-members">
                <ng-container *ngFor="let member of teamMembers">
                    <div class="team-member">
                        <div class="team-member-image">
                            <!-- <div></div> -->
                            <img src="../../../assets/images/profiles/{{ member.fileName }}" [alt]="member.name" width="100%" height="100%">
                        </div>
                        <h4 class="team-member-name">
                            {{ member.name }}
                        </h4>
                        <h5 class="team-member-designation">
                            {{ member.designation }}
                        </h5>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</section>

<qw-growth-section></qw-growth-section>

<qw-get-started-section></qw-get-started-section>