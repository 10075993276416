<section id="header-section" class="trapezoid-fill animated gradient-7">
    <div class="main-container">
        <div class="header">
            <div class="header-content">
                <h1 class="header-title">
                    Quickwork powers integrations across hundreds of businesses
                </h1>
                <p class="p-2 header-description">
                    Our ever growing platform is built to serve enterprises of all sizes to enable powerful integrations
                    across departments and roles. Quickwork is adopted by business and IT users across various verticals
                    from startups to large enterprises.
                </p>
            </div>
            <!-- <div class="header-representation">
                <div class="header-image">
                    <img src="../../../assets/images/enterprise-qjb/home-page/quickwork_integration_hero.svg" alt="" srcset="">
                    <img src="../../../assets/images/enterprise-qjb/home-page/HeroImage.svg" alt="" srcset="">
                </div>
            </div> -->
        </div>
    </div>

</section>

<section id="customers-section">
    <div class="main-container">
        <qw-customers></qw-customers>
    </div>
</section>

<section id="case-studies-section">
    <div class="main-container">
        <header class="customer-stories-header">
            <h3 class="caption">Case Studies</h3>
            <h2 class="title">Companies from various verticals use Quickwork to power their integrations</h2>
        </header>

        <ng-container *ngIf="caseStudies$ | async as caseStudiesGroup">
            <qw-grouped-case-studies [groupedCaseStudies]="caseStudiesGroup" [showMore]="true">
            </qw-grouped-case-studies>
        </ng-container>
    </div>
</section>

<!-- <section id="use-cases-section">
    <div class="main-container">
        <header class="customer-stories-header m-b-30">
            <h3 class="caption">Use Cases</h3>
        </header>

        <ng-container *ngIf="useCasesStudies$ | async as useCasesGroup">
            <qw-grouped-use-cases [groupedUseCases]="useCasesGroup"></qw-grouped-use-cases>
        </ng-container>
    </div>
</section> -->

<qw-testimonial></qw-testimonial>

<qw-get-started-section></qw-get-started-section>