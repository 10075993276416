import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'qw-dpa',
  templateUrl: './dpa.component.html',
  styleUrls: ['./dpa.component.scss']
})
export class DPAComponent implements OnInit {

  constructor(private metaService: Meta, private titleService: Title) { }

  ngOnInit() {
    this.metaService.addTags([
      {name: 'robots', content: 'index, follow'}
    ]);
  }

}
