<div class="cookie-policy-alert" *ngIf="showConsentBox" [@fadeEnterExit]>
    <h4>Cookie settings</h4>
    <div class="cookie-policy-content">
        <p>By continuing to browse or by clicking “Accept”, you consent to our website's use of cookies to give you the most relevant experience by
            remembering your preferences and repeat visits. To learn more about how we use the cookies, please see our <a target="_blank" href="/cookie-policy">cookie policy</a>.</p>
        <div class="cookie-policy-buttons">
            <button type="button" class="button filled" (click)="reject()">Reject</button>
            <button type="button" class="button filled" (click)="accept()">Accept</button>
        </div>
    </div>
</div>