<div class="app-category-header" *ngIf="categorizedApps.length">
    <h3 class="app-section-title">{{ category }}</h3>
    <ng-container *ngIf="categorizedApps.length > 7 && currentCategory === 'All Apps'">
        <button class="button plain" type="button" (click)="getAppsByCategory()">
            See More
            <i class="fas fa-chevron-right"></i>
        </button>
    </ng-container>
</div>
<div class="app-list-container" *ngIf="apps">
    <ng-container *ngIf="currentCategory === 'All Apps'">
        <ng-container *ngFor="let app of categorizedApps | slice: 0 : 7">
            <ng-container *ngTemplateOutlet="appTemplate; context: { appGroup: app }"></ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="currentCategory !== 'All Apps'">
        <ng-container *ngFor="let app of categorizedApps">
            <ng-container *ngTemplateOutlet="appTemplate; context: { appGroup: app }"></ng-container>
        </ng-container>
    </ng-container>

    <ng-template #appTemplate let-appGroup="appGroup">
        <a [routerLink]="['/apps', appGroup, 'integrations']" class="single-app">
            <div class="app-icon">
                <qw-app-icon [appAlias]="appGroup"></qw-app-icon>
            </div>
            <p class="app-title">
                <qw-high-light-text [needle]="appsSearchValue" [haystack]="apps[appGroup].name">
                </qw-high-light-text>
            </p>
        </a>
    </ng-template>
</div>