<!-- PRICING INTRODUCTION SECTION -->

<ng-container [ngSwitch]="pricingLoadingState">
  <ng-container *ngSwitchCase="'pending'">
    <section class="loader-container">
      <qw-spinner></qw-spinner>
    </section>
  </ng-container>
  <ng-container *ngSwitchCase="'success'">
    <section id="header-section" class="trapezoid-fill animated gradient-5">
      <div class="main-container">
        <header class="pricing-header">
          <h1 class="pricing-header-title">Simple and flexible pricing</h1>
          <p class="pricing-header-description p-2">
            A simple pay as you go pricing which is flexible for enterprises of all sizes.
          </p>
        </header>

        <div class="pricing-card-container">
          <div class="pricing-card">
            <div class="free-plan">
              <header class="free-plan-header">
                <h3 class="caption">30-Day free trial</h3>

                <!-- Temporarily hiding freedom plan numbers  -->

                <!-- <h2 class="free-plan-amt">{{ 0 | currency: selectedCurrency.uppercaseUnit:'symbol':'0.0' }}</h2>
                <p class="free-plan-title p-1">Freedom plan is suitable for startups, developers and SMEs</p> -->
              </header>

              <!-- Temporarily hiding freedom plan numbers  -->

              <!-- <div class="free-plan-parameters">
                <div class="free-plan-parameter">
                  <h2>{{ freedomPlan.transactions | number }}</h2>
                  <p class="p-2">Transactions per month</p>
                </div>
                <div class="free-plan-parameter">
                  <h2>{{ freedomPlan.activeWorkflowLimit | number }}</h2>
                  <p class="p-2">Active workflows</p>
                </div>
              </div> -->
              <ul class="free-plan-features points">
                <li *ngFor="let feature of freePlanDetails.features">{{ feature }}</li>
              </ul>
            </div>
            <div class="create-account">
              <a [href]="platformURL" target="_blank" class="button plain">
                Get Started
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Temporarily hiding enterprise plan section -->

    <!-- 

      <section id="plans-section">
        <div class="main-container">
          <ng-container *ngIf="pricingDisplayStatus$ | async as displayStatus; else statusLoading">
            <ng-container *ngIf="displayStatus === 'show'; else noPricingTemplate">
              <header class="plans-header">
                <h2 class="plans-header-title">Enterprise plans</h2>
                <p class="plans-header-description p-2">If you wish to have a higher transaction volume and unlimited
                  workflows along with enterprise grade features, choose your plans as per your needs.</p>
              </header>
              <div class="plan-filters m-b-30">
                <qw-plan-interval-toggle [(intervalState)]="showAnnualPlan"
                  (intervalStateChange)="setPriceFilter($event)"></qw-plan-interval-toggle>
                <qw-currency-selector [(selectedCurrency)]="selectedCurrency" (selectedCurrencyChange)="selectCurrency($event)"></qw-currency-selector>
              </div>
              <qw-plan-selector [showAnnualPlan]="showAnnualPlan" [freedomPlan]="freedomPlan"
                [businessPlans]="businessPlans" [currency]="selectedCurrency"></qw-plan-selector>
              <div style="padding-top: 30px;">
                  <p><b>Note:</b> Extra / Additional Setup / Support will be charged 30 USD/hour</p>
              </div>
            </ng-container>
            <ng-template #noPricingTemplate>
              <header class="plans-header">
                <h2 class="plans-header-title">Enterprise plans</h2>
                <p class="plans-header-description p-2">If you wish to have a higher transaction volume and unlimited
                  workflows along with enterprise grade features, talk to us.</p>
                <button type="button" class="button filled" (click)="openIntercom()">Talk to us</button>
              </header>
            </ng-template>
          </ng-container>
          <ng-template #statusLoading>
            <header class="plans-header">
              <qw-spinner></qw-spinner>
            </header>
          </ng-template>
        </div>
      </section>

     -->

    <qw-testimonial></qw-testimonial>

    <qw-faqs [FAQs]="faqs"></qw-faqs>

    <qw-get-started-section></qw-get-started-section>
  </ng-container>
  <ng-container *ngSwitchCase="'failure'">
    <section class="loader-container">
      <h4 class="color-red">Failed to fetch pricing plans</h4>
    </section>
  </ng-container>
</ng-container>