import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'qw-zoho-campaign-form',
  templateUrl: './zoho-campaign-form.component.html',
  styleUrls: ['./zoho-campaign-form.component.scss']
})
export class ZohoCampaignFormComponent implements OnInit {

  render;
  constructor(private appService: AppService) { }

  ngOnInit() {
    this.render = this.appService.getIsPlatformBrowser();
  }

}
