import { Component, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Ifaq } from 'src/app/app.service';

@Component({
  selector: 'qw-faq',
  template: `
  <i class="fad fa-question-circle icon"></i>
  <div class="faq-content">
      <p class="faq-question p-2" [innerHTML]="FAQ.question | santizeHTML"></p>
      <p class="faq-answer p-3" [innerHTML]="FAQ.answer"></p>
  </div>
  `,
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  @Input() FAQ: Ifaq;
  @Input() toggleAnswer = false;
  @Input() index: number;
  @Input() toggle$ = new BehaviorSubject<number>(undefined);

  @HostBinding('class.faq-opened') opened = false;

  constructor() { }

  ngOnInit() {
    this.toggle$.pipe(
      filter(number => number !== undefined),
      tap(number => {
        this.opened = number === this.index;
      }),
      filter(number => number !== this.index),
      tap(() => this.toggleAnswer = false)
    ).subscribe();
  }

  @HostListener('click')
  toggleFAQ() {
    this.toggleAnswer = !this.toggleAnswer;
    this.toggle$.next(this.index);
  }

}
