<!-- NAV SECTION -->
<div id="nav-container">
    <div class="main-container">
        <div class="nav">
            <div class="logo" routerLink="/">
                <img src="../../../assets/images/company logo/Quickwork logo rectangle style-01.svg" alt="Quickwork Logo" width="100%" height="100%" />
            </div>
            <div id="nav-links-container">
                <div id="sub-link-container">
                    <ng-container *ngFor="let link of navLinks">
                        <ng-container *ngTemplateOutlet="navLinkTemplate; context: { $implicit: link }">
                        </ng-container>
                    </ng-container>
                </div>
                <ng-container *ngTemplateOutlet="getStartedButtonTemplate"></ng-container>
            </div>

            <!-- Mobile Menu section -->

            <div class="toggle" (click)="toggleNav()">
                <i [class]="'far fa-'+navIcon"></i>
            </div>
            <div id="mobile-menu" *ngIf="navToggle" [@fadeEnterExit]>
                <div class="mobile-menu-container">
                    <div class="mobile-menu-section">
                        <div class="mobile-menu-grid">
                            <ng-container *ngFor="let mobileMenuLink of navLinks">
                                <ng-container *ngIf="!mobileMenuLink.children">
                                    <div class="mobile-menu-link">
                                        <ng-container *ngTemplateOutlet="navLinkTemplate; context: { $implicit: mobileMenuLink }">
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                    <ng-container *ngFor="let mobileMenuLink of navLinks">
                        <ng-container *ngIf="mobileMenuLink.children">
                            <div class="mobile-menu-section">
                                <h5 class="mobile-menu-section-title">{{ mobileMenuLink.name }}</h5>
                                <div class="mobile-menu-grid">
                                    <ng-container *ngFor="let mobileLinkChild of mobileMenuLink.children">
                                        <div class="mobile-menu-link">
                                            <ng-container *ngTemplateOutlet="navLinkTemplate; context: { $implicit: mobileMenuLink }">
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <div class="mobile-menu-section" id="get-started">
                        <ng-container *ngTemplateOutlet="getStartedButtonTemplate"></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #navLinkTemplate let-link>
    <ng-container *ngIf="link.show">
        <ng-container [ngSwitch]="link.type">
            <ng-container *ngSwitchCase="'link'">
                <!-- <ng-container *ngIf="link?.children;else noChildren">
                    <a class="sub-link children">
                        <span [ngxTooltip]="navbarDropdownOptions" [tooltipContent]="navDropdown">
                            {{ link.name }}
                            <i class="fa fa-angle-down"></i>
                        </span>
                    </a>
                    <div id="navDropdown" #navDropdown>
                        <ng-container *ngFor="let linkChild of link.children">
                            <ng-container *ngTemplateOutlet="navLinkTemplate; context: { $implicit: linkChild }">
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container> -->
                <a class="sub-link" href="" [routerLink]="link.link" [activeLink]="link.name" (click)="navigate(link.link, link.type)">
                    {{ link.name }}
                </a>
                <!-- <ng-template #noChildren>
                </ng-template> -->
            </ng-container>
            <ng-container *ngSwitchCase="'href'">
                <a class="sub-link" [href]="link.link" target="_blank">
                    {{ link.name }}
                </a>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>


<ng-template #getStartedButtonTemplate>
    <a [href]="platformURL" target="_blank" class="button filled">
        <ng-container *ngIf="username">
            <span class="nav-username">
                {{ username | titlecase }}
            </span>
        </ng-container>
        <ng-container *ngIf="!username">
            Get Started
        </ng-container>
        <i class="fas fa-chevron-right m-l-15"></i>
    </a>
</ng-template>