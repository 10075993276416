import { trigger, transition, style, animate } from "@angular/animations";

const duration = 150;

export const fadeEnterExit = trigger('fadeEnterExit', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-10px)' }),
        animate(duration, style({ opacity: 1, transform: 'translateY(0)' })),
    ]),
    transition(':leave', [
        animate(duration, style({ opacity: 0, transform: 'translateY(-10px)' })),
    ]),
]);