import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ITestimonial } from 'src/app/app.service';
import { testimonialSlider } from '../animations/testimonial.animation';
import { testimonial } from '../constants/testimonial.constants';

@Component({
  selector: 'qw-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss'],
  animations: [testimonialSlider]
})
export class TestimonialComponent implements OnInit, OnDestroy {

  @Input() testimonials: ITestimonial[] = testimonial;
  currentIndex = 0;
  animState: 'inc' | 'dec';
  private destroyed$ = new Subject();

  constructor(@Inject(PLATFORM_ID) private platformId: object) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      interval(5000).pipe(
        tap(() => this.incTestimonial()),
        takeUntil(this.destroyed$)
      ).subscribe();
    }
  }

  onNext() {
    if (this.currentIndex !== this.testimonials.length - 1) {
      this.currentIndex++;
      this.animState = 'inc';
    }
  }

  onPrevious() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.animState = 'dec';
    }
  }

  incTestimonial() {
    if (this.currentIndex !== this.testimonials.length - 1) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0;
    }
  }

  decTestimonial() {
    if (this.currentIndex !== 0) {
      this.currentIndex--;
    } else {
      this.currentIndex = this.testimonials.length - 1;
    }
  }

  setTestimonialIndex(i: number) {
    this.currentIndex = i;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
