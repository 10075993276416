import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, tap, retry, filter } from 'rxjs/operators';
import { ISubscriptionPlan } from './pricing.model';
import { BehaviorSubject } from 'rxjs';
import { PLATFORM_BASE_PATH } from 'src/app/app.constants';

export type priceFilterType = 'yearly' | 'monthly';

@Injectable({
  providedIn: 'root'
})
export class PricingService {

  // private resourceURL = 'https://automation.quickwork.co/api/plans';
  private resourceURL = `${PLATFORM_BASE_PATH}workflow/api/public/plans`;
  annualPlan$ = new BehaviorSubject(false);
  private plans$ = new BehaviorSubject<ISubscriptionPlan[]>(undefined);
  private plansError$ = new BehaviorSubject<HttpErrorResponse>(undefined);
  private planFilter: boolean;
  private currency$ = new BehaviorSubject<string>('USD');

  constructor(private http: HttpClient) { }

  getAllPlans() {
    return this.http.get<ISubscriptionPlan[]>(this.resourceURL, { observe: 'response' }).pipe(
      map(response => response.body),
      map(plans => plans.filter(plan => !plan.hidden)),
      tap(
        plans => this.plans$.next(plans),
        (error: HttpErrorResponse) => this.plansError$.next(error)
      ),
      retry(2)
    );
  }

  getPlans() {
    return this.plans$.pipe(filter(plans => plans !== undefined));
  }

  getPlansError() {
    return this.plansError$.pipe(filter(plansError => plansError !== undefined));
  }

  setPlanFilter(planFilter: boolean) {
    this.planFilter = planFilter;
  }

  getPlanFilter() {
    return this.planFilter;
  }

  filterPlansByInterval(planFilter: boolean) {
    const plans = this.plans$.getValue();
    const interval: 'yearly' | 'monthly' = planFilter ? 'yearly' : 'monthly';
    const businessPlans = plans[1].plans;
    return businessPlans.filter(plan => plan.interval === interval);
  }

  getCurrency() {
    return this.currency$.pipe();
  }

  setCurrency(country: string) {
    return this.currency$.next(country);
  }

  setCurrencyViaLocale(countryCode: string) {
    if (countryCode === 'IN') {
      this.setCurrency('INR');
    } else this.setCurrency('USD');
  }

}
