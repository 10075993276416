<!-- VALUE PROPOSITION SECTION -->
<section id="platform-intro-section" class="trapezoid-fill animated gradient-2">
    <div class="main-container">
        <header class="platform-intro-header">
            <h1 class="platform-intro-title">Enterprise grade features with consumer grade simplicity</h1>
            <p class="platform-intro-description p-2">
                Quickwork makes automation easy, allowing anyone to build simple or complex workflows effortlessly.
            </p>
            <a [href]="platformURL" target="_blank" class="button filled">
                Get Started
                <i class="fas fa-chevron-right"></i>
            </a>
        </header>
    </div>
</section>


<section id="platform-features-section">
    <div class="main-container">
        <div class="column-layout c-1-1-1-1">
            <div class="column" *ngFor="let feature of platformFeatures">
                <div class="column-icon icon">
                    <i class="fad {{ feature.icon }}"></i>
                </div>
                <div class="column-title text-bold">{{ feature.title }}</div>
                <p class="column-description">{{ feature.description }}</p>
            </div>
        </div>
    </div>
</section>


<section id="trigger-events-section">
    <div class="main-container">
        <header class="trigger-events">
            <h3 class="caption">Versatility</h3>
            <h2>Supports all kinds of events</h2>
            <p class="p-1">
                Quickwork supports all types of events used in automated workflows, such as real-time, polling, scheduled, and manual. This unique capability enables building a wide range of workflows across industry verticals.
            </p>
        </header>
        <div class="column-layout c-1-1 size-1fr-3fr" id="real-time">
            <div class="column" id="column-1">
                <div class="l-m-border">
                    <ng-container *ngFor="let featurePoint of versatilityFeatures; let i = index">
                        <ng-container *ngIf="i === tabsIndexes.versatility">
                            <div class="column-body">
                                <h3 class="column-title border-highlight">{{ featurePoint.title }}</h3>
                                <p class="p-2">{{ featurePoint.description }}</p>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div class="column" id="feature-representation">
                <div id="feature-tabs">
                    <qw-tabs-layout [tabs]="versatilityTabs" (onTabSwitch)="setTabIndex($event, 'versatility')"
                        scrollShadow orientation="horizontal"></qw-tabs-layout>
                </div>
                <div class="column-representation m-t-20">
                    <ng-container *ngFor="let featurePoint of versatilityFeatures; let i = index">
                        <div class="column-image" *ngIf="i === tabsIndexes.versatility">
                            <img src="../../../../assets/images/enterprise-qjb/platform/{{ featurePoint.image }}"
                                [alt]="featurePoint.alt" width="100%" height="100%">
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="formula-section">
    <div class="main-container">
        <div class="column-layout c-1-1">
            <header class="formula-header">
                <h2>Power to transform any data</h2>
                <p class="p-1">
                    Quickwork provides a library of pre-built functions for data transformation. Using Node.js or by writing your own functions, you can transform data as per your needs or as required by your use case.
                </p>
                <a href="" class="button plain" (click)="navigateToFormulaHelpDesk()">
                    Learn how to use Formula Engine
                    <i class="fas fa-chevron-right"></i>
                </a>
            </header>
            <div class="column">
                <div class="column-representation">
                    <div class="column-image">
                        <img src="../../../../assets/images/enterprise-qjb/platform/FormulaEngine.svg" alt="No code automation" width="100%" height="100%">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="conversation-section">
    <div class="main-container">
        <header class="conversation-header">
            <h3 class="caption">Conversational friendly</h3>
            <h2>Build conversational interactions</h2>
            <p class="p-1">
                Quickwork is conversation friendly, designed to enable multi-channel interactions. Connect to any preferred messaging platform and manage conversations with humans, chatbots and IoT devices with an easy to use interface.
            </p>
        </header>
        <div class="column-layout c-1-1 size-1fr-2fr reversed">
            <div class="column">
                <div class="column-body l-m-border">
                    <header>
                        <div class="icon">
                            <i class="fad fa-comments-alt"></i>
                        </div>
                        <h3 class="column-title">Support all major channels</h3>
                        <p class="p-2">
                            Build and manage conversational workflows in real-time across multiple channels such as WhatsApp, Line, Facebook Messenger, Telegram, Slack, Microsoft Teams, Google Chat, web widgets and many more.
                        </p>
                    </header>
                    <header>
                        <div class="icon">
                            <i class="fad fa-language"></i>
                        </div>
                        <h3 class="column-title">Built-in NLP context handling</h3>
                        <p class="p-2">
                            Quickwork provides ready integration with top NLP tools and services such as Google Dialogflow, IBM Watson NLU, Microsoft Luis and many more to allow users to create intelligent conversational flows. Along with that, context handling for elements such as questions, quick reply buttons, or selection from menu is pre-built in Quickwork.
                        </p>
                    </header>
                </div>
            </div>
            <div class="column" id="representation-container">
                <qw-tabs-layout [tabs]="conversationTabs" (onTabSwitch)="setTabIndex($event, 'conversation')" scrollShadow orientation="horizontal"></qw-tabs-layout>
                <div class="column-representation">
                    <div class="column-image">
                        <img src="../../../../assets/images/enterprise-qjb/platform/{{conversationImages[tabsIndexes.conversation]}}.png" alt="Conversational workflows" width="100%" height="100%">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="templates-section">
    <div class="main-container">
        <header class="templates-header">
            <h3 class="caption">Templates</h3>
            <h2>Pre-built workflow templates for every use case</h2>
            <p class="p-1">
                Explore our Journey Store to access our library of 1,000s of pre-built workflows and start using the relevant templates immediately.
            </p>
        </header>
        <div class="column-layout c-1-1 size-1fr-2fr reversed">
            <div class="column">
                <div class="column-body l-m-border">
                    <div>
                        <div class="icon">
                            <i class="fad fa-hand-pointer"></i>
                        </div>
                        <h3 class="column-title">One click import from and export to Journey Store</h3>
                        <p class="p-2">
                            Select and import your chosen pre-built workflows from our Journey Store into your own account in just a single click. If you wish to share your workflow as a template with others, you can publish it on the Journey Store in a single click.
                        </p>
                    </div>
                    <div>
                        <div class="icon">
                            <i class="fad fa-user-friends"></i>
                        </div>
                        <h3 class="column-title">Collaboration</h3>
                        <p class="p-2">
                            Modify the imported workflows by yourself or in collaboration with your team as per the use case requirements.
                        </p>
                    </div>                  
                </div>
            </div>
            <div class="column" id="journey-template">
                <div class="column-representation">
                    <div class="column-image">
                        <img src="../../../../assets/images/enterprise-qjb/platform/JourneyStoreTemplate.svg" alt="One click API integration" width="100%" height="100%">
                    </div>
                    <!-- <qw-journey-template></qw-journey-template> -->
                </div>
            </div>
        </div>
    </div>
</section>

<section id="extras-section">
    <div class="main-container">
        <header>
            <h3 class="caption">What else is included?</h3>
            <h2>Manage integrations, end-to-end</h2>
        </header>
        <div class="column-layout c-1-1-1-1">
            <div class="column l-m-border" *ngFor="let extraPoint of extraPoints">
                <div class="column-title text-bold">{{ extraPoint.title }}</div>
                <ul class="points">
                    <li *ngFor="let point of extraPoint.points">{{ point }}</li>
                </ul>
            </div>
        </div>
    </div>
</section>

<qw-get-started-section></qw-get-started-section>