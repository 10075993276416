import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Observable, Subject } from 'rxjs';
import { filter, map, concatMap, tap, catchError } from 'rxjs/operators';
import { ErrorHandlingServiceService } from 'src/app/shared-components/core/error-handling-service.service';
import { CaseStudiesService, ICaseStudy, IMetaCaseStudy } from '../case-studies.service';

@Component({
  selector: 'qw-case-study-page',
  templateUrl: './case-study-page.component.html',
  styleUrls: ['./case-study-page.component.scss']
})
export class CaseStudyPageComponent implements OnInit {

  private caseStudyId: string;
  caseStudy$ = new Observable<IMetaCaseStudy>();
  caseStudyError$ = new Subject<any>();
  otherCaseStudies$ = new Observable<ICaseStudy[]>();
  linearGradient: string;

  @ViewChild('useCaseElement', { static: true }) useCaseElement: ElementRef;

  constructor(
    private caseStudiesService: CaseStudiesService,
    private actRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    private errorHandlerService: ErrorHandlingServiceService
  ) { }

  ngOnInit() {
    this.metaService.addTags([
      { name: 'robots', content: 'index, follow' }
    ]);
    this.caseStudy$ = this.actRoute.params.pipe(
      filter(params => params.id),
      map(params => params.id),
      tap(id => this.caseStudyId = id),
      concatMap(id => this.caseStudiesService.getCaseStudy(id).pipe(
        tap(caseStudy => {
          this.linearGradient = `linear-gradient(0deg, black, ${caseStudy.imageOverlayColor})`;
        })
      )),
      catchError(error => {
        this.caseStudyError$.next({
          title: 'Failed to fetch case-studies',
          message: this.errorHandlerService.getErrorMessage(error)
        });
        return EMPTY;
      })
    );

    this.otherCaseStudies$ = this.caseStudiesService.getCaseStudies({
      size: 4
    }).pipe(
      map(caseStudies => caseStudies.filter(caseStudy => caseStudy.id !== this.caseStudyId))
    );
  }

}
