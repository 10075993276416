import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { concatMap, filter, map, tap } from 'rxjs/operators';
import { IMetaUseCase, IUseCase, UseCasesService } from '../use-cases.service';

@Component({
  selector: 'qw-use-case-page',
  templateUrl: './use-case-page.component.html',
  styleUrls: ['./use-case-page.component.scss']
})
export class UseCasePageComponent implements OnInit {

  private useCaseId: string;
  useCase$ = new Observable<IMetaUseCase>();
  otherUseCases$ = new Observable<IUseCase[]>();

  @ViewChild('useCaseElement', { static: true }) useCaseElement: ElementRef;

  constructor(
    private useCaseService: UseCasesService,
    private actRoute: ActivatedRoute,
    private metaService: Meta,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.metaService.addTags([
      { name: 'robots', content: 'index, follow' }
    ]);
    this.useCase$ = this.actRoute.params.pipe(
      filter(params => params.id),
      map(params => params.id),
      tap(id => this.useCaseId = id),
      concatMap(id => this.useCaseService.getUseCase(id))
    );

    this.otherUseCases$ = this.useCaseService.getUseCases({
      size: 3
    }).pipe(
      map(useCases => useCases.filter(useCase => useCase.id !== this.useCaseId))
    );
  }

}
