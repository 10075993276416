import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { fadeEnterExit } from 'src/app/shared-components/animations/fade-enter-exit.animation';
import { CURRENCIES } from '../pricing.constants';
import { ICurrency } from '../pricing.model';

@Component({
  selector: 'qw-currency-selector',
  templateUrl: './currency-selector.component.html',
  styleUrls: ['./currency-selector.component.scss'],
  animations: [fadeEnterExit]
})
export class CurrencySelectorComponent implements OnInit, OnDestroy {

  @Input() selectedCurrency: ICurrency;
  @Output() selectedCurrencyChange = new EventEmitter<ICurrency>();
  currencies = CURRENCIES;
  toggleDropdown = false;
  insideDropdown: boolean;

  private destroyed$ = new Subject();

  constructor() { }

  ngOnInit() {
    fromEvent(window, 'mousedown').pipe(
      tap(() => this.hideCategories()),
      takeUntil(this.destroyed$)
    ).subscribe();
  }

  showCategories() {
    this.toggleDropdown = true;
  }

  hideCategories() {
    if (!this.insideDropdown) { this.toggleDropdown = false; }
  }

  selectCurrency(category: ICurrency) {
    if (category.unit !== this.selectedCurrency.unit) {
      this.selectedCurrencyChange.emit(category);
      this.toggleDropdown = false;
    }
  }

  setInsideDropdown(state: boolean) {
    this.insideDropdown = state;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
