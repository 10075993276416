import { AfterViewInit, Component, Input, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { CUSTOMER_LOGOS } from '../constants/miscellaneous.constants';

@Component({
  selector: 'qw-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit, AfterViewInit {

  @Input() flipAnimation = false;
  @Input() customers = CUSTOMER_LOGOS;
  @Input() path = 'enterprise-qjb/customers/';

  @ViewChildren('customerImage') customerImages: QueryList<HTMLDivElement>;

  constructor(
    private appService: AppService, private renderer: Renderer2
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.appService.getIsPlatformBrowser()) {
      if (this.flipAnimation) {
        let index = 0;
        const rotateArray = new Array<boolean>(this.customerImages['_results'].length);
        setInterval(() => {
          const customerImage = this.customerImages['_results'][index].nativeElement;
          let rotate = '180deg';
          if (rotateArray[index]) rotate = '0deg';
          rotateArray[index] = !rotateArray[index];
          this.renderer.setStyle(customerImage, 'transform', `rotateX(${rotate})`);
          if (index < 7) {
            index++;
          } else {
            index = 0;
          }
        }, 2000);
      }
    }
  }

}
