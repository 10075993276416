import { Component } from '@angular/core';
import { AT2URL, SUPPORT_CENTER_URL } from '../constants/miscellaneous.constants';

@Component({
  selector: 'qw-get-started-section',
  templateUrl: './get-started-section.component.html',
  styleUrls: ['./get-started-section.component.scss']
})
export class GetStartedSectionComponent {

  supportCenterURL = SUPPORT_CENTER_URL;
  platformURL = AT2URL;

}
