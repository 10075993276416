import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FormsModule } from '@angular/forms';
import { ProductModule } from './product/product.module';
import { SharedModule } from '../shared-components/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { GDPRComponent } from './gdpr/gdpr.component';
import { DPAComponent } from './dpa/dpa.component';
import { IntegrationsModule } from './integrations/integrations.module';
import { PricingModule } from './pricing/pricing.module';
import { AppRoutingModule } from '../app-routing.module';
import { PartnershipsModule } from './partnerships/partnerships.module';
import { CaseStudiesModule } from './case-studies/case-studies.module';
import { SharedImagesModule } from '../shared-components/shared-images.module';
import { CustomersPageComponent } from './customers-page/customers-page.component';
import { AcceptableUsagePolicyComponent } from './acceptable-usage-policy/acceptable-usage-policy.component';
import { RefundAndCancellationPolicyComponent } from './refund-and-cancellation-policy/refund-and-cancellation-policy.component';
import { SecurityComponent } from './security/security.component';



@NgModule({
  declarations: [
    HomeComponent,
    AboutUsComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    CookiePolicyComponent,
    GDPRComponent,
    DPAComponent,
    CustomersPageComponent,
    AcceptableUsagePolicyComponent,
    RefundAndCancellationPolicyComponent,
    SecurityComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    SharedImagesModule,
    AppRoutingModule,
    PricingModule,
    ProductModule,
    BrowserAnimationsModule,
    IntegrationsModule,
    PartnershipsModule,
    CaseStudiesModule
  ]
})
export class PagesModule { }
