import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Observable, Subject, EMPTY } from 'rxjs';
import { take, tap, catchError } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { CaseStudiesService, ICaseStudy } from './case-studies.service';

@Component({
  selector: 'qw-case-studies',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.scss']
})
export class CaseStudiesComponent implements OnInit {

  caseStudies$ = new Observable<ICaseStudy[]>();
  caseStudiesError$ = new Subject();
  currentCategory = 'All use-cases';
  page = 1;
  itemsPerPage = 20;
  totalItems = 0;
  noCaseStudiesMessage: string;

  constructor(
    private appService: AppService,
    private caseStudiesService: CaseStudiesService,
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Industry Verticals, Case Studies | Quickwork');
    this.metaService.addTags([
      { name: 'description', content: 'Quickwork makes automation easy, allowing anyone to build simple or complex workflows effortlessly. Explore case studies and customer stories from different verticals.' },
      { name: 'robots', content: 'index, follow' }
    ]);
    this.appService.getApps().pipe(
      take(1),
      tap(() => this.refetchCaseStudies())
    ).subscribe();
  }

  getCaseStudies(page = 1) {
    this.caseStudiesError$.next(undefined);
    this.page = page;
    const params = {
      page: this.page - 1,
      size: this.itemsPerPage,
      sort: ['id, desc']
    };
    if (this.currentCategory !== 'All use-cases') { Object.assign(params, { category: this.currentCategory }); }
    this.caseStudies$ = this.caseStudiesService.getCaseStudies(params).pipe(
      tap(useCases => {
        const hasCaseStudies = useCases.length > 0;
        if (!hasCaseStudies) {
          if (this.currentCategory !== 'All use-cases') {
            this.noCaseStudiesMessage = 'No use cases found under this category';
          } else {
            this.noCaseStudiesMessage = 'No use cases found';
          }
        }
        // this.totalItems = this.appService.getTotalCaseStudies();
      }),
      // map(useCases => {
      //   for (let i = 0; i < 20; i++) {
      //     useCases.push(useCases[0]);
      //   }
      //   return useCases;
      // }),
      catchError((error: HttpErrorResponse) => {
        this.caseStudiesError$.next(error);
        return EMPTY;
      })
    );
  }

  filterCaseStudies(filterParams) {
    this.currentCategory = filterParams.category;
    this.getCaseStudies();
  }

  getCaseStudiesByCategory(category: string = null) {
    this.currentCategory = category === 'All use-cases' ? '' : category;
    this.getCaseStudies();
  }

  refetchCaseStudies() {
    this.getCaseStudies();
  }

  getDistinctApps(value, index, self) {
    return self.indexOf(value) === index;
  }

}
