import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService, ITestimonial } from 'src/app/app.service';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { takeUntil, concatMap, tap, take } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';
import { FEATURES, FAQS, CURRENCIES, RECOMMENDED_PLAN, SUCCESS_PLANS, FREE_PLAN_DETAILS } from './pricing.constants';
import { PricingService } from './pricing.service';
import { ICurrency, IPlan, ISubscriptionPlan } from './pricing.model';
import { AT2URL } from 'src/app/shared-components/constants/miscellaneous.constants';

@Component({
  selector: 'qw-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent implements OnInit, OnDestroy {

  plans: ISubscriptionPlan[];
  faqs = FAQS;
  features = FEATURES;
  currencies = CURRENCIES;

  exclusiveCharges = {
    poc: {
      USD: 275,
      INR: 275 * 70
    }
  };

  showDropDown = false;
  selectedCurrency: ICurrency;

  insideCurrencyDropDown = false;

  testimonial: ITestimonial[] = [];
  testimonialIndex = 0;
  testimonialAnimation: 'in' | 'out' = 'in';

  pricingLoadingState: 'success' | 'failure' | 'pending';
  recommendedPlan = RECOMMENDED_PLAN;
  showAnnualPlan: boolean;
  faqToggle$ = new BehaviorSubject<number>(0);
  private fetchPlansEvent$ = new BehaviorSubject('');
  private destroyed$ = new Subject();

  successPlans = SUCCESS_PLANS;
  freePlanDetails = FREE_PLAN_DETAILS;

  freedomPlan: IPlan;
  businessPlans: IPlan[];

  pricingDisplayStatus$ = new Observable<string>();

  platformURL = AT2URL;

  constructor(
    private appService: AppService,
    private titleService: Title,
    private metaService: Meta,
    private pricingService: PricingService
  ) { }


  ngOnInit() {
    this.titleService.setTitle('Freedom and Enterprise Plans | Quickwork Pricing');
    this.metaService.addTags([
      {name: 'description', content: ' Quickwork has a clear and transparent pricing  based on the transaction volume. Start with free forever Freedom plan which provides 10,000 transactions per month with 5 active workflows.'},
      {name: 'robots', content: 'index, follow'}
    ]);

    this.fetchPlansEvent$.pipe(
      tap(() => this.pricingLoadingState = 'pending'),
      concatMap(() => this.pricingService.getPlans().pipe(
        tap(plans => {
          this.showAnnualPlan = this.pricingService.getPlanFilter();
          this.plans = plans;
          this.freedomPlan = plans[0].plans[0];
          this.businessPlans = this.pricingService.filterPlansByInterval(this.showAnnualPlan);
          this.pricingLoadingState = 'success';
        })
      )),
      takeUntil(this.destroyed$)
    ).subscribe();

    this.pricingService.getPlansError().pipe(
      tap(error => {
        this.pricingLoadingState = 'failure';
      }),
      takeUntil(this.destroyed$)
    ).subscribe();

    this.appService.mouseDownEvent.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(() => {
      if (!this.insideCurrencyDropDown) {
        this.showDropDown = false;
      }
    });
    this.pricingDisplayStatus$ = this.appService.getPricingPageDisplayState();

    this.pricingService.getCurrency().pipe(
      tap(country => {
        const currencyIndex = this.currencies.map(currency => currency.uppercaseUnit).indexOf(country);
        this.selectedCurrency = this.currencies[currencyIndex];
      }),
      takeUntil(this.destroyed$)
    ).subscribe();
  }

  toggleDropDown() {
    this.showDropDown = !this.showDropDown;
  }

  onMouseEnter() {
    this.insideCurrencyDropDown = true;
  }

  onMouseLeave() {
    this.insideCurrencyDropDown = false;
  }

  selectCurrency(currency: ICurrency) {
    this.selectedCurrency = currency;
    this.pricingService.setCurrency(currency.uppercaseUnit);
    this.showDropDown = false;
  }

  switchTestimonial(switchTo: 'next' | 'previous') {
    this.testimonialAnimation = 'out';
    setTimeout(() => {
      if (switchTo === 'next') {
        if (this.testimonialIndex === this.testimonial.length - 1) {
          this.testimonialIndex = 0;
        } else {
          this.testimonialIndex++;
        }
      } else {
        if (this.testimonialIndex === 0) {
          this.testimonialIndex = this.testimonial.length - 1;
        } else {
          this.testimonialIndex--;
        }
      }
      this.testimonialAnimation = 'in';
    }, 300);
  }

  setPriceFilter(value: boolean) {
    this.pricingService.setPlanFilter(value);
    this.businessPlans = this.pricingService.filterPlansByInterval(value);
  }

  openIntercom() {
    this.appService.openIntercomBot();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
