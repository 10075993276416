<ng-container *ngIf="useCasesTabs.length && useCasesTabs.length > 1">
    <qw-tabs-layout class="m-b-40" [tabs]="useCasesTabs" (onTabSwitch)="setUseCasesIndex($event)" scrollShadow
        orientation="horizontal"></qw-tabs-layout>
</ng-container>

<ng-container *ngFor="let useCasesGroup of groupedUseCases">
    <ng-container *ngIf="useCasesGroup.id === currentUseCaseCategory">
        <div class="column-layout c-1-half" [class.c-2-el]="useCasesGroup.data.length < 3">
            <ng-container *ngFor="let useCase of useCasesGroup.data | slice:0:6">
                <qw-journey-use-case class="column" [useCase]="useCase"></qw-journey-use-case>
            </ng-container>
        </div>
    </ng-container>
</ng-container>