<div id="privacy-policy-container" class="header-wave">
	<div class="main-container">
		<div class="privacy-policy">

			<div class="policy-content">
				<h1 class="c53"><span class="c2">DATA Processing </span><span class="c2">ADDENDUM</span>
				</h1>
				<p class="c49"><span class="c8">This </span><span class="c8">Data Processing Addendum
						(&ldquo;DPA&rdquo;</span><span class="c8">) supplements
						Quickwork&rsquo;s Terms of Service (the
						&ldquo;</span><strong class="c17">Agreement</strong><span class="c8">&rdquo;) available at
					</span><span class="c8 c12"><a class="c24" target="_blank"
							href="/terms-of-service">https://quickwork.co/terms-of-service</a></span><span
						class="c8">. </span><span class="c8">User</span><span class="c8">&nbsp;and Quickwork have agreed
						to this
						Data Processing Addendum (&ldquo;</span><strong class="c17">DPA</strong><span
						class="c8">&rdquo;) as
						of the date
						of the agreement into which it is incorporated (&ldquo;</span><strong
						class="c17">Agreement</strong><span class="c8">&rdquo;). This DPA
						applies to all Services
						provided
						by Quickwork to User that involve the
						processing by Quickwork of any Personal Data provided to Quickwork under
						the Agreement on behalf
						of User
						pursuant to or in connection with the Services. (&ldquo;</span><strong class="c17">User Personal
						Data</strong><span class="c0 c5">&rdquo;)</span></p>
				<br>
				<p class="c49"><span class="c0 c5">The terms used in this DPA shall have the meaning as
						set forth in
						this DPA.
						Capitalized terms not otherwise defined herein shall have the meaning
						given to them in the
						Agreement. Except
						as modified below, the terms of the Agreement shall remain in full force
						and effect.</span></p>
				<br>
				<p class="c49"><span class="c0 c5">In consideration of the mutual obligations set out
						herein, the
						parties hereby
						agree that the terms and conditions set out below shall be added as an
						addendum to the
						Agreement. </span>
				</p>
				<br>
				<ol class="c29 ol-list-1 lst-kix_list_1-1 start" type="1">
					<li class="c49"><strong class="c17">Definitions</strong></li>
					<ol class="c29 lst-kix_list_1-1 start" type="a">
						<li class="c51 li-bullet-0"><span class="c0 c5">&nbsp;In this DPA, the
								following terms shall
								have
								the meanings
								set out below </span></li>
						<li class="c30 li-bullet-0"><span class="c0">&nbsp;&ldquo;</span><strong
								class="c17 c18">Personal
								Data</strong><span class="c0 c5">&rdquo; means information
								that identifies, relates to,
								describes, is reasonably capable of
								being associated with, or could reasonably be linked,
								directly or indirectly, with a
								particular
								individual or household as defined under the Data
								Protection Laws.</span></li>
						<li class="c30 li-bullet-0"><span class="c17 c18">&nbsp;</span><span
								class="c0">&ldquo;</span><strong class="c17 c18">Data
								Protection Laws</strong><span class="c0">&rdquo; means, as
								applicable:
								(a) Regulation
								2016/679 of the European Parliament and of the Council
								on the protection of natural
								persons
								with regard
								to the processing of personal data and on the free
								movement of such data, and repealing
								Directive
								95/46/EC (the &ldquo;</span><strong class="c17 c18">GDPR</strong><span
								class="c0 c5">&rdquo;);
								and (b) any
								other applicable data privacy and security laws and
								regulations.</span></li>
						<li class="c30 li-bullet-0"><span class="c0">&nbsp;&ldquo;</span><strong
								class="c17 c18">EEA</strong><span class="c0 c5">&rdquo;
								means the European Economic
								Area.</span></li>
						<li class="c30 li-bullet-0"><span class="c0">&nbsp;&ldquo;</span><strong
								class="c17 c18">Services</strong><span class="c0">&rdquo;
								includes tools, softwares,
								channels,
								datafeeds, computer programmes etc. which are
								designed by Quickwork to connect applications, automate
								workflows and send or receive
								messages to and
								from humans and machines, all of which are hosted over
								the internet and available on the
								Quickwork
								Platform.</span></li>
						<li class="c30 li-bullet-0"><span class="c0">&ldquo;</span><strong class="c17 c18">Standard
								Contractual
								Clauses</strong><span class="c0">&rdquo; means the
								European Commission Standard
								Contractual
								Clauses and
								shall include the a.) Controller</span><span class="c8">&nbsp;to Controller </span><span
								class="c0">Clauses as available under Appendix 1;
								&nbsp;b.) </span><span class="c8">Processor to
							</span><span class="c0">Processor Clauses as available under
								Appendix 2</span><span class="c8">;
							</span><span class="c0">&nbsp;Quickwork relies on these Standard
								Contractual Clauses for
								data
								transfers as
								laid out in section 10.</span></li>
						<li class="c30 li-bullet-0"><span class="c0">&ldquo;</span><strong
								class="c17 c18">Sub-processor</strong><span class="c0">&rdquo; means any person
								appointed
								by
								or on behalf of Quickwork to process User Personal Data
								in connection with the Services provided under
								Agreement.</span></li>
						<li class="c30 li-bullet-0"><span class="c0">&ldquo;</span><strong
								class="c17 c18">Controller-to-Processor
								Clauses</strong><span class="c0 c5">&rdquo; means the
								standard contractual clauses between
								controllers and
								processors for Data Transfers, as approved by the
								European Commission Implementing
								Decision
								(EU)
								2021/914 of 4 June 2021, as elaborated under Appendix 1
								&nbsp;to this DPA.</span></li>
						<li class="c30 li-bullet-0"><span class="c8">&ldquo;</span><strong
								class="c17">Processor-to-Processor
								Clauses</strong><span class="c0 c5">&rdquo; means the
								standard contractual clauses between
								&nbsp;processors for Data Transfers, as approved by the
								European Commission Implementing
								Decision (EU)
								2021/914 of 4 June 2021, as elaborated under Appendix 2
								&nbsp;to this DPA.</span></li>
						<li class="c30 li-bullet-0"><span class="c0 c5">&ldquo;Third
								Country&rdquo; means a country
								outside
								the EEA not
								recognized by the European Commission as providing an
								adequate level of protection for
								personal data (as
								described in the GDPR).</span></li>
						<li class="c26 li-bullet-0"><span class="c0">The terms
								&ldquo;</span><strong class="c17 c18">Controller</strong><span class="c0">,&rdquo;
								&ldquo;</span><strong class="c17 c18">Data
								Subject</strong><span class="c0">,&rdquo;
								&ldquo;</span><strong class="c17 c18">Personal
								Data
								Breach</strong><span class="c0">,&rdquo;
								&ldquo;</span><strong class="c17 c18">Processing</strong><span class="c0">,&rdquo;
								&ldquo;</span><strong class="c17 c18">Processor</strong><span class="c0">,&rdquo; and
								&ldquo;</span><strong class="c17 c18">Supervisory
								Authority</strong><span class="c0 c5">&rdquo;
								shall have
								the same meaning as in the GDPR.</span></li>
					</ol>
					<br>
					<li class="c22 li-bullet-1"><strong class="c17 c18">Data Processing</strong></li>
					<ol class="c29 lst-kix_list_1-1" type="a">
						<li class="c26 li-bullet-0"><span class="c0">This DPA applies when User
								Personal Data is
								processed
								by Quickwork.
								In this context, Quickwork will act </span><span class="c8">as a Processor. </span></li>
						<li class="c26 li-bullet-0"><span class="c0 c5">Quickwork shall not
								process User Personal Data
								other
								than on
								User&rsquo;s documented instructions unless Processing
								is required by Data Protection
								Laws
								to which
								Quickwork is subject, in which case Quickwork shall, to
								the extent permitted by Data
								Protection Laws,
								inform User of that legal requirement before Processing
								User Personal Data.</span></li>
					</ol>
					<br>
					<li class="c36 li-bullet-1"><strong class="c17 c18">Security</strong></li>
					<table class="c41">
						<tbody>
							<tr class="c19">
								<td class="c1" colspan="1" rowspan="1">
									<p class="c32"><span class="c8">Quickwork has
											implemented the security
											measures for
											protection of
											personal data under the
											DPA</span></p>
								</td>
							</tr>
							<tr class="c45">
								<td class="c1" colspan="1" rowspan="1">
									<br>
								</td>
							</tr>
							<tr class="c19">
								<td class="c1" colspan="1" rowspan="1">
									<p class="c32"><strong class="c17">Technical and
											Organizational measures
											implemented
											by
											Quickwork
										</strong><span class="c0 c5">:</span></p>
								</td>
							</tr>
							<tr class="c19">
								<td class="c1" colspan="1" rowspan="1">
									<p class="c32"><span class="c0 c5">Quickwork
											maintains an ISMS Policy
											approved and
											reviewed by
											Management</span></p>
								</td>
							</tr>
							<tr class="c14">
								<td class="c1" colspan="1" rowspan="1">
									<p class="c32"><span class="c0 c5">Personal Data
											in transition is protected by
											SSL
											TLS
											1.2+ with SSL
											certificates using SHA-256 with
											RSA Encryption provided by
											Amazon
											Certificate
											Manager.
											Intercommunication between
											microservices encrypted using
											mutual TLS via mesh
											management
											service.</span></p>
								</td>
							</tr>
							<tr class="c31">
								<td class="c1" colspan="1" rowspan="1">
									<p class="c32"><span class="c0 c5">Personal Data
											at rest is stored in Mongo
											Enterprise
											Database,
											volume level and item level
											encrypted using individual
											encryption keys via
											AWS
											KMS.</span>
									</p>
								</td>
							</tr>
							<tr class="c14">
								<td class="c1" colspan="1" rowspan="1">
									<p class="c32"><span class="c0 c5">Data in
											memory: Each process in
											Quickwork is
											managed
											within
											containers, which have their own
											independent resources(volumes,
											memory and
											CPU).
											The logs of
											these processes are synced with
											a centralized logging
											system.</span></p>
								</td>
							</tr>
							<tr class="c19">
								<td class="c1" colspan="1" rowspan="1">
									<p class="c32"><span class="c0 c5">Has
											implemented anti-malware on its
											systems
											processing personal
											data.</span></p>
								</td>
							</tr>
							<tr class="c19">
								<td class="c1" colspan="1" rowspan="1">
									<p class="c32"><span class="c0 c5">Employees
											receive regular security and
											privacy
											training regarding
											the treatment and protection of
											Personal Data.</span></p>
								</td>
							</tr>
							<tr class="c19">
								<td class="c1" colspan="1" rowspan="1">
									<p class="c32"><span class="c0 c5">Ensures VAPT
											done by certified third parties
											to
											test
											security and
											identify vulnerabilities in the
											system.</span></p>
								</td>
							</tr>
							<tr class="c19">
								<td class="c1" colspan="1" rowspan="1">
									<p class="c32"><span class="c0 c5">Is ISO 27001
											certified and ensures regular
											audits
											in
											line with
											this standard.</span></p>
								</td>
							</tr>
							<tr class="c19">
								<td class="c1" colspan="1" rowspan="1">
									<p class="c32"><span class="c0 c5">Ensures that
											access to information systems is
											restricted to
											authorized employees
											only.</span></p>
								</td>
							</tr>
						</tbody>
					</table>
					<br>
					<li class="c54 li-bullet-2"><strong class="c17 c18">Confidentiality</strong></li>
					<ol class="c29 lst-kix_list_1-1 start" type="a">
						<li class="c48 li-bullet-3"><span class="c0 c5">Quickwork will not
								disclose User Personal Data
								to
								any
								unauthorized third-party subject to mandatory law. If a
								government demands access to
								User
								Personal Data,
								Quickwork will notify User prior to disclosure unless
								prohibited by the law.</span></li>
						<li class="c48 li-bullet-3"><span class="c0">Quickwork shall take
								reasonable steps to ensure
								that
								individuals or
							</span><span class="c0">entity</span><span class="c0 c5">&nbsp;that process User Personal
								Data
								are subject
								to obligations of confidentiality or are under an
								appropriate statutory obligation of
								confidentiality.</span></li>
					</ol>
					<br>
					<li class="c22 li-bullet-1"><strong class="c17 c18">Personal Data Breach</strong>
					</li>
					<p class="c50"><span class="c0 c5">Upon determining that a Personal Data Breach
							has occurred that
							affects User,
							Quickwork will notify User within 72 hours, after becoming aware
							of a breach of security in
							respect of the
							Services leading to the accidental or unlawful destruction,
							loss, alteration, unauthorized
							disclosure of, or
							access to, User Personal Data transmitted, stored, or otherwise
							processed by
							Quickwork.</span>
					</p>
					<br>
					<li class="c22 li-bullet-1"><strong class="c17 c18">Sub-processors </strong></li>
					<p class="c50">
						<span class="c0">User provides general authorization to
							Quickwork&rsquo;s use of
							sub-processors to
							provide processing activities on User Personal Data on behalf of
							User (&ldquo;</span><span class="c0">Sub</span><span class="c8">-</span><span
							class="c0">processors&rdquo;</span><span class="c0">) in
							accordance with this Section. The Quickwork website (currently
							posted at </span><span class="c12 c8"><a class="c24"
								href="/privacy-policy/gdpr">https://quickwork.co/privacy-policy/gdpr</a></span><span
							class="c0">&nbsp;lists Sub-processors that are currently engaged
							by Quickwork. Quickwork
							will
							update the
							list of sub-processor at least 30 days before engaging a new
							sub-processor. Further,
							Quickwork
							shall notify
							User of the addition or replacement of such Sub-processor and
							User may, on reasonable
							grounds,
							object to a
							Sub-processor by notifying Quickwork in writing within 10 days
							of receipt of
							Quickwork&rsquo;s
							notification,
							giving reasons for User&rsquo;s objection. Upon receipt of such
							objection, Quickwork shall:
							(a)
							work with
							User in good faith to make available a commercially reasonable
							change in the provision of
							the
							Services which
							avoids the use of that proposed Sub-processor; and (b) where
							such change cannot be made
							within
							10 days of
							Quickwork&rsquo;s receipt of User&rsquo;s notice, User may by
							written notice to </span><span class="c0">Quickwork</span><span class="c0">&nbsp;with
							immediate effect terminate the
							portion of
							the
							Agreement to the extent that it relates to the Services which
							require the use of the
							proposed
							Sub-processor.
							This termination right is User&rsquo;s sole and exclusive remedy
							to User&rsquo;s objection
						</span><span class="c0">of any</span><span class="c0">&nbsp;Sub-processor appointed by
							Quickwork.
							Quickwork shall require
							all Sub</span><span class="c8">-</span><span class="c0 c5">processors to enter into an
							agreement
							with
							equivalent effect to the Processing terms contained in this DPA.
						</span>
					</p>
					<br>
					<li class="c22 li-bullet-2"><strong class="c17 c18">User Rights</strong></li>
					<ol class="c29 lst-kix_list_1-1 start" type="a">
						<li class="c26 li-bullet-0"><strong class="c17 c20">Independent
								Determination</strong></li>
						<p class="c4"><span class="c7">User is responsible for reviewing the
								information made available by
								Quickwork
								relating to data security and its security standards and
								making an independent determination
								as
								to whether
								the Services meets User&rsquo;s requirements and legal
								obligations as well as User&rsquo;s
								obligations under
								this DPA. </span></p>
						<br>
						<li class="c26 li-bullet-0"><strong class="c17 c20">User Audit
								Rights.</strong><span class="c7">&nbsp;</span></li>
						<p class="c4"><span class="c5 c7">User has the right to confirm
								Quickwork compliance with this
								Addendum
								as
								applicable to the Services, including specific Quickwork
								compliance with its security
								standards,
								by
								exercising a reasonable right to conduct an audit or
								inspection, including under the
								Standard
								Contractual
								Clauses if they apply, by making a specific request of
								Quickwork in writing to the address
								set
								forth in the
								Agreement. If the Standard Contractual Clauses apply,
								nothing in this Section varies or
								modifies
								the
								Standard Contractual Clauses nor affects any supervisory
								authority&rsquo;s or data
								subject&rsquo;s rights
								under the Standard Contractual Clauses. This Section
								will also apply insofar as Quickwork
								carries out the
								control of Sub-processors on behalf of the User.</span>
						</p>
					</ol>
					<br>
					<li class="c22 li-bullet-1"><strong class="c17 c18">Quickwork Privacy
							Contact.</strong></li>
					<p class="c50"><span class="c0">The Quickwork privacy contact can be contacted
							at </span></p>
					<br>
					<ul class="c29 lst-kix_hsabni7zcfg7-0 start" type="dot">
						<li class="c23 li-bullet-1"><span class="c0 c5">Name: Quickwork
								Technologies Pvt. Ltd.</span>
						</li>
						<li class="c23 li-bullet-2"><span class="c0 c5">Attention: Chief
								Operating Officer</span></li>
						<li class="c23 li-bullet-1"><span class="c0 c5">Physical address: 404,
								Matharu Arcade, Subhash
								Road,
								Vile Parle
								(East), Mumbai 400057, India</span></li>
						<li class="c23 li-bullet-2"><span class="c8">Email address for contact:
								support@quickwork.co</span>
						</li>
					</ul>
					<br>
					<li class="c22 li-bullet-1"><strong class="c17 c18">Return or Deletion of User
							Personal Data.</strong>
					</li>
					<p class="c50"><span class="c0 c5">Unless otherwise required by applicable law,
							Quickwork will
							destroy
							or return
							User Personal Data within a reasonable period in a reasonable
							and common format upon
							receiving
							written
							instructions from the User prior to termination or expiration,
							provided that the User
							Personal
							Data is
							available to Quickwork.</span></p>
					<br>
					<li class="c22 li-bullet-2"><strong class="c17 c18">Transborder Data
							Processing.</strong></li>
					<ol class="c29 lst-kix_list_1-1 start" start="a">
						<li class="c26 li-bullet-0"><span class="c0 c5">Application of Standard
								Contractual Clauses: The
								Standard
								Contractual Clauses will only apply to User Personal
								Data that is transferred, either
								directly or via
								onward transfer, to any Third Country, (each a
								&ldquo;Data Transfer&rdquo;).</span></li>
						<li class="c26 li-bullet-0"><span class="c0 c5">The parties will conduct
								such Data Transfer in
								accordance with
								the applicable laws.</span></li>
						<li class="c26 li-bullet-0"><span class="c0">Taking into account the
								nature of the processing,
								User
								agrees that
								it is unlikely that Quickwork will know the identity of
								User&rsquo;s controllers because
								it
								has no
								direct relationship with User&rsquo;s controllers and
								therefore, User will </span><span class="c8">fulfill</span><span
								class="c0">&nbsp;Quickwork&rsquo;s obligations to
								User&rsquo;s
								controllers under the Processor-to-Processor
								Clauses.</span></li>
						<li class="c26 li-bullet-0"><span class="c0 c5">If there is any conflict
								between this DPA or the
								Agreement and
								the Standard Contractual Clauses, the Standard
								Contractual Clauses will prevail.</span>
						</li>
					</ol>
					<br>
					<li class="c22 li-bullet-1"><strong class="c17 c18">Entire Agreement;
							Conflict</strong><span class="c0 c5">: This
							DPA incorporates the Standard Contractual Clauses by reference.
							Except as amended by
							this
							DPA, the
							Agreement will remain in full force and effect. Nothing in this
							document varies or
							modifies
							the Standard
							Contractual Clauses.</span></li>
					<br>
					<li class="c22 li-bullet-1"><strong class="c17 c18">Termination of the
							DPA</strong><span class="c0 c5">:
							This DPA
							will continue in force until the termination of the Agreement
							(the &ldquo;Termination
							Date&rdquo;).</span></li>
					<br>
					<li class="c36 li-bullet-2"><strong class="c17 c18">Governing Law of the DPA:
						</strong><span class="c0">This DPA is
							governed by laws of</span><span class="c17 c18">&nbsp;</span><span
							class="c0">Ireland.</span><span class="c17 c18">&nbsp;</span><span class="c0">Any
							dispute
							shall be settled in the courts of Dublin. A
							data subject may also bring legal proceedings against the data
							exporter and/or data
							importer
							before the
							courts of the Member State in which he/she has his/her habitual
							residence. The
							supervisory
							authority
							shall be of Ireland.</span></li>
					<hr style="page-break-before:always;display:none;">
					<br>
					<hr style="page-break-before:always;display:none;">
					<br>
				</ol>
				<p class="c44 t-a-c"><span class="c2 c3">APPENDIX 1</span></p>
				<br>
				<p class="c44 t-a-c"><span class="c11 c3">Controller to Processor</span></p>
				<br>
				<p class="c43"><span class="c11 c3 text-bold">following shall be applicable on the transfer of personal
						data to
						Processors established in third countries which do not ensure an adequate level of data
						protection. The parties have agreed on the following Contractual Clauses in order to adduce
						adequate safeguards with respect to the protection of privacy and fundamental rights and
						freedoms of individuals for the transfer by the data exporter to the data importer of the
						personal data.</span></p>
				<br>
				<ol class="c29 lst-kix_list_3-0 start" start="1">
					<li class="c15 li-bullet-1"><span class="c8">Data importer</span><span class="c0">&nbsp;</span><span
							class="c8">shall process the
							personal data on the </span><span class="c0">documented
							instructions
							of</span><span class="c8">&nbsp;Data exporter</span><span class="c0">.</span><span
							class="c0">&nbsp;
						</span><span class="c8">Data </span><span class="c8">exporter</span><span class="c8">&nbsp;shall
						</span><span class="c0 c5">give adequate instructions throughout the
							duration of the
							applicability of the
							DPA. </span></li>
					<br>
					<li class="c15 li-bullet-2"><span class="c0">Data importer shall immediately
							inform </span><span class="c8">Data
							exporter </span><span class="c0">if it fails to follow the
							instructions </span><span class="c8">of data
							exporter</span><span class="c0 c5">.</span></li>
					<br>
					<li class="c15 li-bullet-2"><span class="c8">Data importer</span><span class="c0 c5">&nbsp;shall
							process
							personal data for the purposes enumerated in Annexure A of this
							Appendix 1.</span></li>
					<br>
					<li class="c15 li-bullet-2"><span class="c0">Data subjects shall have the right
							to demand a copy of
							this DPA
							with annexures. </span><span class="c8">Data Exporter
						</span><span class="c0 c5">shall be
							under
							obligations to provide details to such data subject after
							redacting critical information
							stating reasons
							for such reductions.</span></li>
					<br>
					<li class="c15 li-bullet-2"><span class="c8">Data importer</span><span class="c0">&nbsp;shall be
							liable to
							inform the </span><span class="c8">data exporter </span><span class="c0">when it becomes
							aware that
							personal data received is inaccurate or has become outdated; it
							shall inform </span><span class="c8">data exporter </span><span class="c0">of such
							inaccuracies without undue delay.
							In such
						</span><span class="c8">cases</span><span class="c0">, dat</span><span class="c8">a</span><span
							class="c0">&nbsp;importer shall
							cooperate with </span><span class="c8">data exporter
						</span><span class="c0 c5">to erase or rectify the date.</span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0">Duration of processing of data
							shall be in accordance
							with Annexure
							A of this Appendix 1. After the completion of the duration,
						</span><span class="c8">the
							data</span><span class="c0">&nbsp;importer shall at the choice
							of the </span><span class="c8">data exporter</span><span class="c0">, delete all personal
							data processed on
							behalf of the data exporter and certify to
						</span><span class="c8">data exporter </span><span class="c0">that it
							has deleted or return all
							the personal
							data to</span><span class="c8">&nbsp;data exporter</span><span class="c0">. Data importer
							shall ensure
							that until the data is deleted or returned to</span><span class="c8">&nbsp;data
							exporter,</span><span class="c0">&nbsp;</span><span class="c8">d</span><span
							class="c0 c5">ata importer shall be in
							compliance
							with the clauses. In case of local laws applicable to the data
							importer that prohibit return
							or deletion
							of the personal data, the data importer warrants that it will
							continue to ensure compliance
							with these
							Clauses and will only process it to the extent and for as long
							as required under that local
							law.</span>
					</li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0">The data importer and, during
							transmission, also the
							data exporter
							shall implement appropriate technical and </span><span class="c0">organisational</span><span
							class="c0">&nbsp;measures
							to ensure the security of the data, including protection against
							a
							breach of
							security leading to accidental or unlawful destruction, loss,
							alteration, </span><span class="c0">unauthorised</span><span class="c0">&nbsp;disclosure or
							access to that data
							(hereinafter
							&ldquo;</span><span class="c17 c18">personal data
							breach</span><span class="c0">&rdquo;). In
							assessing
							the appropriate level of security, the data importer as well as
							the data exporter shall take
							due account
							of the state of the art, the costs of implementation, the
							nature, scope, context and
							purpose(s) of
							processing and the risks involved in the processing for the data
							subjects. The parties shall
							in
							particular consider having recourse to encryption or
							pseudonymisation, including during
							transmission,
							where the purpose of processing can be fulfilled in that manner.
							In case of
							pseudonymisation, the
							additional information for attributing the personal data to a
							specific data subject shall,
							where
							possible, remain under the exclusive control of the data
							exporter. In complying with its
							obligations
							under this paragraph, the data importer shall at least implement
							the technical and
						</span><span class="c0">organisational</span><span class="c0 c5">&nbsp;measures specified in
							Annexure B of this
							Appendix 1. The data importer shall carry out regular checks to
							ensure that these measures
							continue to
							provide an appropriate level of security. </span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0">The data importer shall grant
							access to the personal
							data to
							members of its personnel only to the extent strictly necessary
							for the implementation,
							management and
							monitoring of the contract. It shall ensure that persons
						</span><span class="c0">authorised</span><span class="c0 c5">&nbsp;to
							process the personal data have
							committed themselves to confidentiality or are
							under an appropriate statutory obligation of confidentiality.
						</span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">In the event of a personal data
							breach concerning
							personal data
							processed by the data importer under these Clauses, the data
							importer shall take appropriate
							measures to
							address the breach, including measures to mitigate its adverse
							effects. The data importer
							shall also
							notify the data exporter without undue delay after having become
							aware of the breach. Such
							notification
							shall contain the details of a contact point where more
							information can be obtained, a
							description of
							the nature of the breach (including, where possible, categories
							and approximate number of
							data subjects
							and personal data records concerned), its likely consequences
							and the measures taken or
							proposed to
							address the breach including, where appropriate, measures to
							mitigate its possible adverse
							effects.
							Where, and in so far as, it is not possible to provide all
							information at the same time, the
							initial
							notification shall contain the information then available and
							further information shall, as
							it becomes
							available, subsequently be provided without undue delay. </span>
					</li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">The data importer shall
							cooperate with and assist
							the data
							exporter to enable the data exporter to comply with its
							obligations under Regulation (EU)
							2016/679, in
							particular to notify the competent supervisory authority and the
							affected data subjects,
							taking into
							account the nature of processing and the information available
							to the data importer.</span>
					</li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">Data importer shall promptly and
							adequately assist
							with the
							enquiries from the data exporter that relate to processing under
							these clauses. </span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">Data importer shall keep
							appropriate documentation
							with respect
							to processing activities as per the instructions of the
							data exporter.</span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">Data importer shall
							provide all information
							necessary to
							demonstrate compliance with obligations set out in this
							Appendix 1 and at the request of the
							Data
							exporter allow and contribute to auditing of its
							processing activities covered under this
							Appendix 1.
						</span></li>
					<br>
					<li class="c15 li-bullet-2"><span class="c0 c5">Data exporter, at its sole
							discretion, can decide to
							conduct the
							audit by itself or mandate an independent auditor. Audits may
							include inspections at the
							premises or
							physical facilities of the data importer and shall, where
							appropriate, be carried out with
							reasonable
							notice.</span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">Data exporter and data importer
							shall make the
							information
							referred to in paragraphs 12 and 13, including the results of
							any audits, available to the
							competent
							supervisory authority on request.</span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c8">The data importer shall promptly
							notify the data
							exporter of any
							request it has received from a data subject. It shall not
							respond to that request itself
							unless it has
							been </span><span class="c8">authorised</span><span class="c0 c5">&nbsp;to do so by the data
							exporter.</span></li>
					<br>
					<li class="c47 li-bullet-1"><span class="c8">The data importer shall assist the
							data exporter in
							fulfilling its
							obligations to respond to data subjects&rsquo; requests for the
							exercise of their rights
							under
							Regulation (EU) 2016/679. In this regard, the parties shall set
							out in Annexure B of this
							Appendix 1 the
							appropriate technical and </span><span class="c8">organisational</span><span
							class="c0 c5">&nbsp;measures, taking into account the nature of
							the processing, by which the
							assistance
							shall be provided, as well as the scope and the extent of the
							assistance required.</span>
					</li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">In fulfilling its obligations
							under paragraphs 16
							and 17, the
							data importer shall comply with the instructions from the data
							exporter.</span></li>
					<br>
					<li class="c58 li-bullet-2"><span class="c0 c5">Each party shall be liable to
							the other party/ies
							for any
							damages it causes the other party/ies by any breach of these
							Clauses.</span></li>
					<br>
					<li class="c47 li-bullet-1"><span class="c0 c5">The data importer shall be
							liable to the data
							subject, and the
							data subject shall be entitled to receive compensation, for any
							material or non-material
							damages the
							data importer or its sub-processor causes the data subject by
							breaching the third-party
							beneficiary
							rights under these Clauses.</span></li>
					<br>
					<li class="c47 li-bullet-2"><span class="c0 c5">Notwithstanding paragraph 20,
							the data exporter
							shall be liable
							to the data subject, and the data subject shall be entitled to
							receive compensation, for any
							material or
							non-material damages the data exporter or the data importer (or
							its sub-processor) causes
							the data
							subject by breaching the third-party beneficiary rights under
							these Clauses. This is without
							prejudice
							to the liability of the data exporter and, where the data
							exporter is a processor acting on
							behalf of a
							controller, to the liability of the controller under Regulation
							(EU) 2016/679 or Regulation
							(EU)
							2018/1725, as applicable.</span></li>
					<br>
					<li class="c47 li-bullet-1"><span class="c0 c5">The Parties agree that if the
							data exporter is held
							liable under
							paragraph 21 for damages caused by the data importer (or its
							sub-processor), it shall be
							entitled to
							claim back from the data importer that part of the compensation
							corresponding to the data
							importer&rsquo;s responsibility for the damage.</span></li>
					<br>
					<li class="c47 li-bullet-1"><span class="c0 c5">Where more than one party is
							responsible for any
							damage caused
							to the data subject as a result of a breach of these Clauses,
							all responsible parties shall
							be jointly
							and severally liable and the data subject is entitled to bring
							an action in court against
							any of these
							Parties.</span></li>
					<br>
					<li class="c47 li-bullet-1"><span class="c0 c5">The parties agree that if one
							party is held liable
							under
							paragraph 21, it shall be entitled to claim back from the other
							Party/ies that part of the
							compensation
							corresponding to its/their responsibility for the damage.</span>
					</li>
					<br>
					<li class="c15 li-bullet-2"><span class="c8">The data importer may not invoke
							the conduct of a
							sub-processor to
							avoid its own liability.</span></li>
					<br>
					<li class="c15 li-bullet-2"><span class="c0 c5">The data importer agrees to
							notify the data exporter
							and, where
							possible, the data subject promptly (if necessary with the help
							of the data exporter) if
							it:</span></li>
					<br>
					<ol class="c29 lst-kix_list_3-1 start" start="a">
						<li class="c42 li-bullet-4"><span class="c0 c5">Receives a legally binding
								request from a public
								authority,
								including judicial authorities, under the laws of the country of
								destination for the
								disclosure of
								personal data transferred pursuant to these Clauses; such
								notification shall include
								information about
								the personal data requested, the requesting authority, the legal
								basis for the request and
								the response
								provided; or</span></li>
						<br>
						<li class="c37 li-bullet-5"><span class="c0 c5">becomes aware of any direct
								access by public
								authorities to
								personal data transferred pursuant to these Clauses in
								accordance with the laws of the
								country of
								destination; such notification shall include all information
								available to the
								importer.</span></li>
					</ol>
					<br>
					<li class="c34 li-bullet-1"><span class="c0 c5">If the data importer is
							prohibited from notifying
							the data
							exporter and/or the data subject under the laws of the country
							of destination, the data
							importer agrees
							to use its best efforts to obtain a waiver of the prohibition,
							with a view to communicating
							as much
							information as possible, as soon as possible. The data importer
							agrees to document its best
							efforts in
							order to be able to demonstrate them on request of the data
							exporter. </span></li>
					<br>
					<li class="c34 li-bullet-1"><span class="c0 c5">Where permissible under the laws
							of the country of
							destination,
							the data importer agrees to provide the data exporter, at
							regular intervals for the duration
							of the
							contract, with as much relevant information as possible on the
							requests received (in
							particular, number
							of requests, type of data requested, requesting authority/ies,
							whether requests have been
							challenged and
							the outcome of such challenges, etc.). </span></li>
					<br>
					<li class="c34 li-bullet-1"><span class="c8">The data importer agrees to
							preserve the information
							pursuant to
							paragraphs 26 to 28 of this Appendix 1 for the duration of the
							contract and make it
							available to the
							competent supervisory authority on request. </span></li>
				</ol>
				<br>
				<br>
				<br>
				<p class="c28 t-a-c"><strong class="c2 c3 underline">ANNEXURE A: Details of the Processing</strong></p>
				<br>
				<p class="c13"><strong class="c11 c3">List of Parties</strong></p>
				<br>
				<p class="c35">
					<strong class="c2 underline">Data Exporter</strong>
					<br>
					<br>
					<strong class="c11 c3">Name:
						<br>
						<br>
						Address:
						<br>
						<br>
						Contact Person&rsquo;s Details:
						<br>
						<br>
						Signature &amp; Date:
						<br>
						<br>
						<br>
						Role: Controller
					</strong>
				</p>
				<br>
				<br>
				<br>
				<p class="c35">
					<strong class="c2 underline">
						Data Importer<br>
					</strong>
					<strong class="c17">
						Name: Quickwork Technologies Pvt. Lt.d
						<br>
						Address:
					</strong>
					<strong class="c17">
						404, Matharu Arcade, Subhash
						Road, Vile Parle (East),
						Mumbai
						400057, India
					</strong>
					<br>
					<br>
					<strong class="c17">
						Contact Person&rsquo;s Details: Chief Information Security Officer,
						support@quickwork.co<br>Signature &amp; Date: <br>Role:
					</strong>
					<strong class="c17">Processor</strong>
				</p>
				<p class="c40">
					<strong class="c17">Subject matter</strong>
					<strong class="c8">: The subject
						matter of the data
						processing
						under this DPA is User Personal Data.
					</strong>
				</p>
				<p class="c13">
					<strong class="c17">&nbsp; &nbsp; Duration</strong>
					<span class="c8">: As
						agreed between
						Quickwork and
						User, the duration of the data processing under this DPA is determined
						by User.</span>
				</p>
				<p class="c13"><strong class="c17">&nbsp; &nbsp;Purpose:</strong><span class="c0 c5">&nbsp;The purpose
						of
						the data
						processing under this DPA is the provision of the Services initiated by
						&nbsp; User from time to
						time.</span></p>
				<p class="c13"><strong class="c17">&nbsp; Nature of the processing</strong><span class="c0 c5">:
						Automate
						workflows,
						integrate applications and such other Services as described in the
						Agreement and initiated by
						User from time
						to time. </span></p>
				<p class="c13"><strong class="c17">Type of User Personal Data</strong><span class="c0 c5">:
						User Personal
						Data uploaded
						to &nbsp;User&rsquo;s account on the Quickwork Platform. </span></p>
				<p class="c13"><strong class="c17">Categories of data subjects</strong><span class="c0 c5">:
						The data
						subjects could
						include (but not limited to) User&rsquo;s customers, employees,
						suppliers and end user.</span>
				</p>
				<br>
				<br>
				<br>
				<p class="c28 t-a-c">
					<strong class="c2 underline">ANNEXURE B : Security Standards.</strong>
				</p><a id="t.3bd8fe86cf4384c3c70073a9c7f10ec4c79fadb4"></a><a id="t.1"></a>
				<br>
				<br>
				<br>
				<table class="c41">
					<tbody>
						<tr class="c19">
							<td class="c1" colspan="1" rowspan="1">
								<p class="c32"><strong class="c17">Technical and
										Organizational measures implemented by
										Quickwork
									</strong><span class="c0 c5">:</span></p>
							</td>
						</tr>
						<tr class="c19">
							<td class="c1" colspan="1" rowspan="1">
								<p class="c32"><span class="c0 c5">Quickwork maintains
										an ISMS Policy approved and
										reviewed by
										Management</span></p>
							</td>
						</tr>
						<tr class="c14">
							<td class="c1" colspan="1" rowspan="1">
								<p class="c32"><span class="c0 c5">Personal Data in
										transition is protected by SSL TLS
										1.2+ with SSL
										certificates using SHA-256 with RSA
										Encryption provided by Amazon
										Certificate
										Manager.
										Intercommunication between microservices
										encrypted using mutual TLS via mesh
										management
										service.</span></p>
							</td>
						</tr>
						<tr class="c31">
							<td class="c1" colspan="1" rowspan="1">
								<p class="c32"><span class="c0 c5">Personal Data at rest
										is stored in Mongo Enterprise
										Database,
										volume level and item level encrypted
										using individual encryption keys via AWS
										KMS.</span>
								</p>
							</td>
						</tr>
						<tr class="c14">
							<td class="c1" colspan="1" rowspan="1">
								<p class="c32"><span class="c0 c5">Data in memory: Each
										process in Quickwork is managed
										within
										containers, which have their own
										independent resources(volumes, memory
										and CPU).
										The logs of
										these processes are synced with a
										centralized logging system.</span></p>
							</td>
						</tr>
						<tr class="c19">
							<td class="c1" colspan="1" rowspan="1">
								<p class="c32"><span class="c0 c5">Has implemented
										anti-malware on its systems
										processing personal
										data.</span></p>
							</td>
						</tr>
						<tr class="c19">
							<td class="c1" colspan="1" rowspan="1">
								<p class="c32"><span class="c0 c5">Employees receive
										regular security and privacy
										training regarding
										the treatment and protection of Personal
										Data.</span></p>
							</td>
						</tr>
						<tr class="c19">
							<td class="c1" colspan="1" rowspan="1">
								<p class="c32"><span class="c0 c5">Ensures VAPT done by
										certified third parties to test
										security and
										identify vulnerabilities in the
										system.</span></p>
							</td>
						</tr>
						<tr class="c19">
							<td class="c1" colspan="1" rowspan="1">
								<p class="c32"><span class="c0 c5">Is ISO 27001
										certified and ensures regular audits in
										line with
										this standard.</span></p>
							</td>
						</tr>
						<tr class="c19">
							<td class="c1" colspan="1" rowspan="1">
								<p class="c32"><span class="c0 c5">Ensures that access
										to information systems is
										restricted to
										authorized employees only.</span></p>
							</td>
						</tr>
					</tbody>
				</table>
				<br>
				<br>
				<br>
				<br>
				<hr style="page-break-before:always;display:none;">
				<br>
				<p class="c44 t-a-c"><strong class="c2 underline">APPENDIX 2</strong></p>
				<br>
				<p class="c44 t-a-c"><strong class="c17">Processor to Processor</strong></p>
				<br>
				<p class="c43"><span class="c8 text-bold">Following shall be applicable on the transfer of
						personal data to
						Processors
						established in third countries which do not ensure an adequate level of
						data protection. The
						parties have
						agreed on the following Contractual Clauses in order to adduce adequate
						safeguards with respect
						to the
						protection of privacy and fundamental rights and freedoms of individuals
						for the transfer by the
						data
						exporter to the data importer of the personal data.</span></p>
				<br>
				<ol class="c29 lst-kix_j400f3zgc1sp-0 start" start="1">
					<li class="c15 li-bullet-1"><span class="c0 c5">Data exporter has informed the
							data importer that it
							acts as
							processor under the instructions of its controller(s), which the
							data exporter shall make
							available to
							the data importer prior to processing. </span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">Data importer shall process the
							personal data as per
							the
							documented instructions of the controller as communicated to
							data importer by the data
							exporter and any
							additional documented instructions of the data exporter. The
							Data exporter or the controller
							may provide
							additional documented instructions throughout the duration of
							the Agreement. </span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c8">D</span><span class="c8">ata
							importe</span><span class="c8 c33">r</span><span class="c0 c5">&nbsp;shall immediately
							inform the data exporter
							of its
							inability to follow the documented instructions. &nbsp;Data
							exporter shall immediately
							inform the
							controller of the data importer&rsquo;s inability to follow the
							documented instructions.
						</span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c8">Data exporter warrants that it has
						</span><span class="c8">imposed
							the same</span><span class="c0 c5">&nbsp;data protection
							obligations as entered between
							controller and
							the Data exporter. </span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">The data importer shall process
							personal data for
							the purposes
							enumerated in Annexure A of this Appendix 2.</span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">Data subject shall have the
							right to demand a copy
							of this DPA
							with appendices. Data Exporter shall be under obligations to
							provide details to such data
							subject after
							redacting critical information stating reasons for such
							redactions.</span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c8">Data </span><span class="c8">importe</span><span
							class="c8 c33">r</span><span class="c8">&nbsp;shall be liable to inform data exporter when
							it becomes
							aware that personal data received is inaccurate or has become
							outdated; it shall inform Data
							exporter of
							such inaccuracies without undue delay. In </span><span class="c8">such case</span><span
							class="c8">,
						</span><span class="c8">data</span><span class="c0 c5">&nbsp;importer
							shall cooperate with the
							data exporter
							to erase or rectify the date.</span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">Duration of processing of data
							shall be in
							accordance with
							Annexure A of this Appendix 2. After the completion of the
							duration, data importer shall at
							the choice
							of the data exporter, delete all personal data processed on
							behalf of the controller and
							certify to data
							exporter that it has deleted or return all the personal data to
							Data exporter. Data importer
							shall
							ensure that until data is deleted or returned, Data importer
							shall be in compliance with the
							clauses. In
							case of local laws applicable to the data importer that prohibit
							return or deletion of the
							personal
							data, the data importer warrants that it will continue to ensure
							compliance with these
							Clauses and will
							only process it to the extent and for as long as required under
							that local law.</span></li>
					<br>
					<li class="c15 li-bullet-2"><span class="c8">The data importer and, during
							transmission, also the
							data exporter
							shall implement appropriate technical and </span><span class="c8">organisational</span><span
							class="c8">&nbsp;measures
							to ensure the security of the data, including protection against
							a
							breach of
							security leading to accidental or unlawful destruction, loss,
							alteration, </span><span class="c8">unauthorised</span><span class="c8">&nbsp;disclosure or
							access to that data
							(hereinafter
							&ldquo;</span><strong class="c17">personal data breach</strong><span class="c8">&rdquo;). In
							assessing the
							appropriate level of security, the data importer as well as the
							data exporter shall take due
							account of
							the state of the art, the costs of implementation, the nature,
							scope, context and purpose(s)
							of
							processing and the risks involved in the processing for the data
							subjects. The parties shall
							in
							particular consider having recourse to encryption or
							pseudonymisation, including during
							transmission,
							where the purpose of processing can be fulfilled in that manner.
							In case of
							pseudonymisation, the
							additional information for attributing the personal data to a
							specific data subject shall,
							where
							possible, remain under the exclusive control of the data
							exporter. In complying with its
							obligations
							under this paragraph, the data importer shall at least implement
							the technical and
						</span><span class="c8">organisational</span><span class="c0 c5">&nbsp;measures specified in
							Annexure B of this
							Appendix 2. The data importer shall carry out regular checks to
							ensure that these measures
							continue to
							provide an appropriate level of security. </span></li>
					<br>
					<li class="c15 li-bullet-2"><span class="c8">The data importer shall grant
							access to the personal
							data to
							members of its personnel only to the extent strictly necessary
							for the implementation,
							management and
							monitoring of the contract. It shall ensure that persons
						</span><span class="c8">authorised</span><span class="c0 c5">&nbsp;to
							process the personal data have
							committed themselves to confidentiality or are
							under an appropriate statutory obligation of confidentiality.
						</span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">In the event of a personal data
							breach concerning
							personal data
							processed by the data importer under these Clauses, the data
							importer shall take appropriate
							measures to
							address the breach, including measures to mitigate its adverse
							effects. The data importer
							shall also
							notify the data exporter without undue delay after having become
							aware of the breach. Such
							notification
							shall contain the details of a contact point where more
							information can be obtained, a
							description of
							the nature of the breach (including, where possible, categories
							and approximate number of
							data subjects
							and personal data records concerned), its likely consequences
							and the measures taken or
							proposed to
							address the breach including, where appropriate, measures to
							mitigate its possible adverse
							effects.
							Where, and in so far as, it is not possible to provide all
							information at the same time, the
							initial
							notification shall contain the information then available and
							further information shall, as
							it becomes
							available, subsequently be provided without undue delay. </span>
					</li>
					<br>
					<li class="c15 li-bullet-2"><span class="c0 c5">The data importer shall
							cooperate with and assist
							the data
							exporter to enable the data exporter to comply with its
							obligations under Regulation (EU)
							2016/679, in
							particular to notify the competent supervisory authority and the
							affected data subjects,
							taking into
							account the nature of processing and the information available
							to the data importer.</span>
					</li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">Data importer shall promptly and
							adequately assist
							with the
							enquiries from the data exporter that relate to processing under
							these clauses. </span></li>
					<br>
					<li class="c15 li-bullet-2"><span class="c0 c5">Data exporter and data importer
							shall demonstrate
							compliance
							under Appendix 2. In particular, the data importer shall keep
							appropriate documentation with
							respect to
							processing activities as per the instructions of the
							controller.</span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">Data importer shall provide all
							information
							necessary to
							demonstrate compliance with obligations set out in this Appendix
							2 and at the request of the
							Data
							exporter allow and contribute to auditing of its processing
							activities covered under this
							Appendix 2.
						</span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">Data importer shall allow and
							contribute to audits
							by the Data
							exporter of the processing activities covered under this
							Appendix 2, The same shall apply
							where the Data
							exporter requests an audit on instructions of the controller. In
							deciding on an audit, the
							data exporter
							may take into account relevant certifications held by the data
							importer. In case the audit
							is carried
							out on the instructions of the controller, the Data exporter
							shall make the results
							available to the
							controller.</span></li>
					<br>
					<li class="c15 li-bullet-2"><span class="c0 c5">Data exporter and data importer
							shall make the
							information
							referred to in paragraphs 14 and 15, including the results of
							any audits, available to the
							competent
							supervisory authority on request.</span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">Each party shall be liable to
							the other party/ies
							for any
							damages it causes the other party/ies by any breach of these
							Clauses.</span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">The data importer shall be
							liable to the data
							subject, and the
							data subject shall be entitled to receive compensation, for any
							material or non-material
							damages the
							data importer or its sub-processor causes the data subject by
							breaching the third-party
							beneficiary
							rights under these Clauses.</span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">Notwithstanding paragraph 19,
							the data exporter
							shall be liable
							to the data subject, and the data subject shall be entitled to
							receive compensation, for any
							material or
							non-material damages the data exporter or the data importer (or
							its sub-processor) causes
							the data
							subject by breaching the third-party beneficiary rights under
							these Clauses. This is without
							prejudice
							to the liability of the data exporter and, where the data
							exporter is a processor acting on
							behalf of a
							controller, to the liability of the controller under Regulation
							(EU) 2016/679 or Regulation
							(EU)
							2018/1725, as applicable.</span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">The Parties agree that if the
							data exporter is held
							liable under
							paragraph 20 for damages caused by the data importer (or its
							sub-processor), it shall be
							entitled to
							claim back from the data importer that part of the compensation
							corresponding to the data
							importer&rsquo;s responsibility for the damage.</span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">Where more than one party is
							responsible for any
							damage caused
							to the data subject as a result of a breach of these Clauses,
							all responsible parties shall
							be jointly
							and severally liable and the data subject is entitled to bring
							an action in court against
							any of these
							Parties.</span></li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">The parties agree that if one
							party is held liable
							under
							paragraph 22, it shall be entitled to claim back from the other
							Party/ies that part of the
							compensation
							corresponding to its/their responsibility for the damage.</span>
					</li>
					<br>
					<li class="c15 li-bullet-2"><span class="c8">The data importer may not invoke
							the conduct of a
							sub-processor to
							avoid its own liability</span><span class="c8 c5 c33">.</span>
					</li>
					<br>
					<li class="c15 li-bullet-1"><span class="c0 c5">The data importer agrees to
							notify the data exporter
							and, where
							possible, the data subject promptly (if necessary with the help
							of the data exporter) if
							it:</span></li>
					<br>
					<ol class="c29 lst-kix_j400f3zgc1sp-1 start" start="a">
						<li class="c42 li-bullet-6"><span class="c0 c5">Receives a legally binding
								request from a public
								authority,
								including judicial authorities, under the laws of the country of
								destination for the
								disclosure of
								personal data transferred pursuant to these Clauses; such
								notification shall include
								information about
								the personal data requested, the requesting authority, the legal
								basis for the request and
								the response
								provided; or</span></li>
						<br>
						<li class="c52 li-bullet-7"><span class="c0 c5">becomes aware of any direct
								access by public
								authorities to
								personal data transferred pursuant to these Clauses in
								accordance with the laws of the
								country of
								destination; such notification shall include all information
								available to the
								importer.</span></li>
						<br>
						<li class="c27 li-bullet-8"><span class="c0 c5">The data exporter shall forward
								the notification to
								the
								controller.</span></li>
					</ol>
					<br>
					<li class="c34 li-bullet-1"><span class="c0 c5">If the data importer is
							prohibited from notifying
							the data
							exporter and/or the data subject under the laws of the country
							of destination, the data
							importer agrees
							to use its best efforts to obtain a waiver of the prohibition,
							with a view to communicating
							as much
							information as possible, as soon as possible. The data importer
							agrees to document its best
							efforts in
							order to be able to demonstrate them on request of the data
							exporter. </span></li>
					<br>
					<li class="c34 li-bullet-2"><span class="c0 c5">Where permissible under the laws
							of the country of
							destination,
							the data importer agrees to provide the data exporter, at
							regular intervals for the duration
							of the
							contract, with as much relevant information as possible on the
							requests received (in
							particular, number
							of requests, type of data requested, requesting authority/ies,
							whether requests have been
							challenged and
							the outcome of such challenges, etc.). The data exporter shall
							forward the information to
							the
							controller.</span></li>
					<br>
					<li class="c43 c60 li-bullet-1"><span class="c8">The data importer agrees to
							preserve the
							information pursuant
							to paragraphs 25 to </span><span class="c8">28 of this Appendix
							2</span><span class="c0 c5">&nbsp;for
							the duration of the contract and make it available to the
							competent supervisory authority on
							request.</span></li>
				</ol>
				<br>
				<br>
				<br>
				<p class="c28 t-a-c"><strong class="c2 c3 underline">ANNEXURE A: Details of the Processing</strong></p>
				<br>
				<p class="c13"><strong class="c11 c3">List of Parties</strong></p>
				<br>
				<p class="c40 c63"><strong class="c2 c3 underline">Data Exporter </strong></p>
				<br>
				<p class="c40"><span class="c17">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><strong class="c11 c3">Name:
						<br>
						<br>
						&nbsp;
						&nbsp; &nbsp; &nbsp; &nbsp;Address:
						<br>
						<br>
						&nbsp; &nbsp; &nbsp; &nbsp;
						&nbsp;Contact Person&rsquo;s
						Details:
						<br>
						<br>
						&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Signature &amp; Date:
						<br>
						<br>
						&nbsp; &nbsp; &nbsp;
						&nbsp;
						&nbsp;Role: Processor</strong></p>
				<br>
				<p class="c35"><strong class="c2 underline">
						Data Importer
					</strong>
					<br>
					<br>
					<strong class="c17">
						Name:
						Quickwork Technologies
						Pvt.
						Lt.d
						<br>
						<br>
						Address:
					</strong>
					<strong class="c17">404, Matharu Arcade, Subhash
						Road, Vile Parle (East),
						Mumbai
						400057, India
					</strong>
					<br>
					<strong class="c17">
						Contact Person&rsquo;s
						Details: Chief Information
						Security
						Officer, support@quickwork.co
						<br>
						<br>
						Signature &amp;
						Date:
						<br>
						<br>
						Role:
					</strong><strong class="c17">Processor</strong>
				</p>
				<br>
				<p class="c13"><strong class="c17">Subject matter</strong><span class="c0 c5">: The subject
						matter of the
						data
						processing under this DPA is User Personal Data. </span></p>
				<br>
				<p class="c13"><strong class="c17">Duration</strong><span class="c8">: As </span><span
						class="c8">agreed</span><span class="c8 c33">&nbsp;</span><span class="c0 c5">between Quickwork
						and User, the duration of the data
						processing under this DPA is determined by User.</span></p>
				<br>
				<p class="c13"><strong class="c17">Purpose:</strong><span class="c0 c5">&nbsp;The purpose of
						the data
						processing under
						this DPA is the provision of the Services initiated by User from time to
						time.</span></p>
				<br>
				<p class="c13"><strong class="c17">Nature of the processing</strong><span class="c0 c5">:
						Automate
						workflows, integrate
						applications and such other Services as described in the Agreement and
						initiated by User from
						time to time.
					</span></p>
				<br>
				<p class="c13"><strong class="c17">Type of User Personal Data</strong><span class="c0 c5">:
						User Personal
						Data uploaded
						to the under User&rsquo;s account on the Quickwork Platform. </span></p>
				<br>
				<p class="c13"><strong class="c17">Categories of data subjects</strong><span class="c0 c5">:
						The data
						subjects could
						include (but not limited to) User&rsquo;s customers, employees,
						suppliers and end user.</span>
				</p>
				<br>
				<br>
				<p class="c28 t-a-c" id="h.gjdgxs">
					<strong class="underline">ANNEXURE B : Security Standards.</strong>
				</p>
				<br>
				<a id="t.3bd8fe86cf4384c3c70073a9c7f10ec4c79fadb4"></a><a id="t.2"></a>
				<table class="c41">
					<tbody>
						<tr class="c19">
							<td class="c1" colspan="1" rowspan="1">
								<p class="c32"><strong class="c17">Technical and
										Organizational measures implemented by
										Quickwork
									</strong><span class="c0 c5">:</span></p>
							</td>
						</tr>
						<tr class="c19">
							<td class="c1" colspan="1" rowspan="1">
								<p class="c32"><span class="c0 c5">Quickwork maintains
										an ISMS Policy approved and
										reviewed by
										Management</span></p>
							</td>
						</tr>
						<tr class="c14">
							<td class="c1" colspan="1" rowspan="1">
								<p class="c32"><span class="c0 c5">Personal Data in
										transition is protected by SSL TLS
										1.2+ with SSL
										certificates using SHA-256 with RSA
										Encryption provided by Amazon
										Certificate
										Manager.
										Intercommunication between microservices
										encrypted using mutual TLS via mesh
										management
										service.</span></p>
							</td>
						</tr>
						<tr class="c31">
							<td class="c1" colspan="1" rowspan="1">
								<p class="c32"><span class="c0 c5">Personal Data at rest
										is stored in Mongo Enterprise
										Database,
										volume level and item level encrypted
										using individual encryption keys via AWS
										KMS.</span>
								</p>
							</td>
						</tr>
						<tr class="c14">
							<td class="c1" colspan="1" rowspan="1">
								<p class="c32"><span class="c0 c5">Data in memory: Each
										process in Quickwork is managed
										within
										containers, which have their own
										independent resources(volumes, memory
										and CPU).
										The logs of
										these processes are synced with a
										centralized logging system.</span></p>
							</td>
						</tr>
						<tr class="c19">
							<td class="c1" colspan="1" rowspan="1">
								<p class="c32"><span class="c0 c5">Has implemented
										anti-malware on its systems
										processing personal
										data.</span></p>
							</td>
						</tr>
						<tr class="c19">
							<td class="c1" colspan="1" rowspan="1">
								<p class="c32"><span class="c0 c5">Employees receive
										regular security and privacy
										training regarding
										the treatment and protection of Personal
										Data.</span></p>
							</td>
						</tr>
						<tr class="c19">
							<td class="c1" colspan="1" rowspan="1">
								<p class="c32"><span class="c0 c5">Ensures VAPT done by
										certified third parties to test
										security and
										identify vulnerabilities in the
										system.</span></p>
							</td>
						</tr>
						<tr class="c19">
							<td class="c1" colspan="1" rowspan="1">
								<p class="c32"><span class="c0 c5">Is ISO 27001
										certified and ensures regular audits in
										line with
										this standard.</span></p>
							</td>
						</tr>
						<tr class="c19">
							<td class="c1" colspan="1" rowspan="1">
								<p class="c32"><span class="c0 c5">Ensures that access
										to information systems is
										restricted to
										authorized employees only.</span></p>
							</td>
						</tr>
					</tbody>
				</table>
				<p class="c49 c25"><span class="c0 c5"></span></p>
				<p class="c38 c25"><span class="c2 c3 c39"></span></p>
			</div>
		</div>
	</div>
</div>