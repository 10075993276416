import { ITestimonial } from 'src/app/app.service';
// import * as s from '../../../assets/images/enterprise-qjb/customers/'

export const testimonial: ITestimonial[] = [
  {
    'name': 'Gurmeet Singh',
    'testimonal_qoute': 'At GMoney, we trust and rely on Quickwork for all API integrations, required by both our B2B and B2C businesses.  All GMoney workflows built on Quickwork are working flawlessly and scaling well with us as we grow our business.',
    'company_name': 'GMoney',
    // "customer_image": "https://website-static-assets-prod.s3.ap-south-1.amazonaws.com/testimonials/gurmeet_singh.jpeg",
    'customer_image': '../../../assets/images/enterprise-qjb/customers/gurmeet_singh.jpeg',
    'customer_position': 'Founder & CEO'
  },
  {
    'name': 'Omkar Dusane',
    'testimonal_qoute': 'Quickwork is an essential tool for any company\'s integration and automation needs; it cuts down months of development time. The API management feature takes away the hassles involved in publishing our workflows as APIs for integration with the community. With Quickwork, the API ecosystem is more connected than ever.',
    'company_name': 'Kennect',
    'customer_image': 'https://website-static-assets-prod.s3.ap-south-1.amazonaws.com/testimonials/omkar_dusane.jpeg',
    'customer_position': 'CTO'
  },
  {
    'name': 'Madanlal Arora',
    'testimonal_qoute': 'I find Quickwork to be a great platform to build integrations quickly - we use it to build many workflows and lending journeys at our company. Also, the API Management feature  on Quickwork is delightful - we can convert any workflow into an API in one click.',
    'company_name': 'FlexiLoans',
    'customer_image': 'https://website-static-assets-prod.s3.ap-south-1.amazonaws.com/testimonials/madanlal_arora.jpeg',
    'customer_position': 'Engineering Manager'
  },
  {
    'name': 'Nagaraj Taware',
    'testimonal_qoute': 'It has been a delight working with Quickwork team on solving problems around HR automation. Quickwork had helped us solve location specific, employee attendance marking problem using Facebook Messenger and syncing data with our enterprise systems, while maintaining security and privacy norms. We continue to engage with Quickwork to discover and create newer and better solutions for our employees using integration and automation.',
    'company_name': 'Tata AIA Life Insurance',
    // "customer_image": "https://website-static-assets-prod.s3.ap-south-1.amazonaws.com/testimonials/nagaraj_taware.jpeg",
    'customer_image': '../../../assets/images/enterprise-qjb/customers/nagaraj_taware.jpeg',
    'customer_position': 'Vice President (HR)'
  },
  {
    'name': 'Masayuki Inaba',
    'testimonal_qoute': 'At Azucar Asset Management, we needed a conversational interface using Line messenger which could query our enterprise information systems in Japanese language in a fully secure manner. Quickwork built an automated conversational chatbot for us on Line messenger very quickly to our satisfaction. I strongly recommend Quickwork for any business problems where secure integration between two or more systems is required.',
    'company_name': 'Azucar Asset Management',
    // "customer_image": "https://website-static-assets-prod.s3.ap-south-1.amazonaws.com/testimonials/masayuki_inaba_san.jpeg",
    'customer_image': '../../../assets/images/enterprise-qjb/customers/masayuki_inaba_san.jpeg',
    'customer_position': 'CEO'
  }
];
