import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'qw-plan-interval-toggle',
  templateUrl: './plan-interval-toggle.component.html',
  styleUrls: ['./plan-interval-toggle.scss']
})
export class PlanIntervalToggleComponent {

  @Input() intervalState: boolean;
  @Output() intervalStateChange = new EventEmitter();
  states = ['Annual', 'Monthly'];

  toggleInterval(value: boolean) {
    this.intervalStateChange.emit(value);
  }

}
