import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'qw-gdpr',
  templateUrl: './gdpr.component.html',
  styleUrls: ['./gdpr.component.scss']
})
export class GDPRComponent implements OnInit {

  revisionDate: string;

  constructor(private appService: AppService, private titleService: Title, private metaService: Meta) { }

  ngOnInit() {
    this.revisionDate = '03 Feb, 2022';
    this.titleService.setTitle('GDPR | Quickwork');
    this.metaService.addTags([
      { name: 'description', content: 'Quickwork respects the privacy needs of all its users. Please read our GDPR compliance document carefully before you use our products or services.' },
      { name: 'robots', content: 'index, follow' }
    ]);
  }

}
