import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { CaseStudiesService, IGroupedCaseStudies } from '../case-studies/case-studies.service';
import { IGroupedUseCases, UseCasesService } from '../product/use-cases/use-cases.service';

@Component({
  selector: 'qw-customers-page',
  templateUrl: './customers-page.component.html',
  styleUrls: ['./customers-page.component.scss']
})
export class CustomersPageComponent implements OnInit {

  caseStudies$ = new Observable<IGroupedCaseStudies[]>();
  useCasesStudies$ = new Observable<IGroupedUseCases[]>();

  constructor(
    private caseStudiesService: CaseStudiesService,
    private useCasesService: UseCasesService,
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit() {
    this.metaService.addTags([
      { name: 'robots', content: 'index, follow' }
    ]);
    this.useCasesStudies$ = this.useCasesService.getGroupedUseCases();
    this.caseStudies$ = this.caseStudiesService.getGroupedCaseStudies();
  }

}
