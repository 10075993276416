<section id="growth-section">
    <div class="main-container">
        <div class="growth-body">
            <div class="growth-globe">
                <qw-globe></qw-globe>
            </div>

            <div class="growth-content">
                <header class="growth-header">
                    <h2 class="growth-title">Growing rapidly with a global footprint</h2>
                    <p class="growth-description p-2">
                        With a growing user base, Quickwork is one of the most trusted real-time iPaaS for large
                        enterprises, SMEs, partners, and developers.
                    </p>
                </header>

                <div class="growth-parameters l-m-border">
                    <div class="growth-parameter">
                        <h3 class="parameter-title border-highlight">3 billion+</h3>
                        <p>App transactions executed with 5,000 transactions processed every second.</p>
                    </div>
                    <div class="growth-parameter">
                        <h3 class="parameter-title border-highlight">1,000+</h3>
                        <p>Applications integrated and ready to use out of the box.</p>
                    </div>
                    <div class="growth-parameter">
                        <h3 class="parameter-title border-highlight">130+</h3>
                        <p>Countries where users build workflows on Quickwork.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>