import { Pipe, PipeTransform } from '@angular/core';
import { count } from 'rxjs/operators';
import { COUNTRIES } from '../constants/countries.constants';

@Pipe({
  name: 'getCountry'
})
export class GetCountryPipe implements PipeTransform {

  private countries = COUNTRIES;

  transform(country: any, ...args: any[]): any {
    let countryIndex = this.countries.map(currentCountry => currentCountry.name).indexOf(country);
    if (countryIndex === -1) {
      countryIndex = this.countries.map(currentCountry => currentCountry.code3).indexOf(country);
    }
    if (countryIndex !== -1) {
      return this.countries[countryIndex].code2;
    }
    return null;
  }

}
