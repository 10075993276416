import { AfterViewInit, Component, ElementRef, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { interval, Subject, timer } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'qw-conversation-management',
  templateUrl: './conversation-management.component.html',
  styleUrls: ['./conversation-management.component.scss']
})
export class ConversationManagementComponent implements AfterViewInit, OnDestroy {

  @ViewChild('senderMsg1', { static: true }) senderMsg1: ElementRef;
  @ViewChild('senderMsg2', { static: true }) senderMsg2: ElementRef;
  @ViewChild('recMsg', { static: true }) recMsg: ElementRef;
  @ViewChild('msg4', { static: true }) msg4: ElementRef;
  @ViewChild('whatsappBubble', { static: true }) whatsappBubble: ElementRef;
  @ViewChild('lineBubble', { static: true }) lineBubble: ElementRef;
  @ViewChild('twitterBubble', { static: true }) twitterBubble: ElementRef;
  @ViewChild('fbBubble', { static: true }) fbBubble: ElementRef;
  @ViewChild('slackBubble', { static: true }) slackBubble: ElementRef;

  private destroyed$ = new Subject();

  constructor(private renderer: Renderer2, private appService: AppService) { }

  ngAfterViewInit() {
    if (this.appService.getIsPlatformBrowser()) {
      timer(0, 16000).pipe(
        tap(() => this.resetAnimation()),
        switchMap(() => interval(1000).pipe(
          tap(second => {
            let offset = 31;
            if (second === 1) { this.renderer.setStyle(this.senderMsg1.nativeElement, 'transform', this.translateY(offset)); }
            if (second === 3) {
              offset += 20;
              this.renderer.setStyle(this.senderMsg1.nativeElement, 'transform', this.translateY(offset));
              this.renderer.setStyle(this.recMsg.nativeElement, 'transform', this.translateY(offset));
            }
            if (second === 5) {
              offset += 30;
              this.renderer.setStyle(this.senderMsg1.nativeElement, 'transform', this.translateY(offset));
              this.renderer.setStyle(this.recMsg.nativeElement, 'transform', this.translateY(offset));
              this.renderer.setStyle(this.senderMsg2.nativeElement, 'transform', this.translateY(offset));
            }
            if (second === 7) {
              offset = 100;
              this.renderer.setStyle(this.senderMsg1.nativeElement, 'transform', this.translateY(offset));
              this.renderer.setStyle(this.recMsg.nativeElement, 'transform', this.translateY(offset));
              this.renderer.setStyle(this.senderMsg2.nativeElement, 'transform', this.translateY(offset));
              this.renderer.setStyle(this.msg4.nativeElement, 'transform', this.translateY(offset));
            }
            if (second === 9) {
              this.renderer.setStyle(this.fbBubble.nativeElement, 'transform', 'scale(1)');
              setTimeout(() => this.renderer.setStyle(this.slackBubble.nativeElement, 'transform', 'scale(1)'), 500);
              setTimeout(() => this.renderer.setStyle(this.twitterBubble.nativeElement, 'transform', 'scale(1)'), 750);
              setTimeout(() => this.renderer.setStyle(this.whatsappBubble.nativeElement, 'transform', 'scale(1)'), 1000);
              setTimeout(() => this.renderer.setStyle(this.lineBubble.nativeElement, 'transform', 'scale(1)'), 1500);
            }
          })
        )),
        takeUntil(this.destroyed$)
      ).subscribe();
    }
  }

  private translateY(offset) {
    return `translateY(${100 - offset}%)`;
  }

  resetAnimation() {
    const translateY = 'translateY(100%)';
    const scale = 'scale(0)';
    this.renderer.setStyle(this.senderMsg1.nativeElement, 'transform', translateY);
    this.renderer.setStyle(this.recMsg.nativeElement, 'transform', translateY);
    this.renderer.setStyle(this.senderMsg2.nativeElement, 'transform', translateY);
    this.renderer.setStyle(this.msg4.nativeElement, 'transform', translateY);

    this.renderer.setStyle(this.twitterBubble.nativeElement, 'transform', scale);
    this.renderer.setStyle(this.fbBubble.nativeElement, 'transform', scale);
    this.renderer.setStyle(this.lineBubble.nativeElement, 'transform', scale);
    this.renderer.setStyle(this.whatsappBubble.nativeElement, 'transform', scale);
    this.renderer.setStyle(this.slackBubble.nativeElement, 'transform', scale);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
