import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JourneyUseCaseComponent } from './journey-use-case/journey-use-case.component';
import { UseCasesComponent } from './use-cases.component';
import { SharedModule } from 'src/app/shared-components/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UseCaseFiltersComponent } from './use-case-filters/use-case-filters.component';
import { UseCasePageComponent } from './use-case-page/use-case-page.component';
import { GroupedUseCasesComponent } from './grouped-use-cases/grouped-use-cases.component';

@NgModule({
  declarations: [
    UseCasesComponent,
    JourneyUseCaseComponent,
    UseCaseFiltersComponent,
    UseCasePageComponent,
    GroupedUseCasesComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule
  ],
  exports: [
    UseCasesComponent,
    JourneyUseCaseComponent,
    UseCaseFiltersComponent,
    GroupedUseCasesComponent
  ]
})
export class UseCasesModule { }
