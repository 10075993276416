<div id="header-section" class="trapezoid-fill animated gradient-6">
    <div class="main-container">
        <div class="use-cases-header">
            <h1 class="use-cases-title">A platform which works across all verticals</h1>
        </div>
        <!-- <qw-use-case-filters (onFilter)="filterUseCases($event)"></qw-use-case-filters> -->
    </div>
</div>

<div id="all-apps-section">
    <div class="main-container">
        <div class="use-cases-container">
            <ng-container *ngIf="useCases$ | async as useCases; else errorUseCases">
                <ng-container *ngIf="useCases.length > 0; else noUseCasesTemplate;">
                    <div class="column-layout c-1-half" [class.c-2-el]="useCases.length < 3">
                        <ng-container *ngFor="let useCase of useCases">
                            <qw-journey-use-case class="column" [useCase]="useCase"></qw-journey-use-case>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-template #noUseCasesTemplate>
                    <div class="use-cases">
                        {{ noUseCasesMessage }}
                    </div>
                </ng-template>
            </ng-container>
            <ng-template #errorUseCases>
                <ng-container *ngIf="useCasesError$ | async as error; else useCasesLoading">
                    <div class="error" [qwConditionalScroll]="![null, undefined].includes(currentCategory)"
                        [offset]="-120">
                        <h4>Failed to fetch use-cases</h4>
                        <p class="m-0">{{ error | manageError }}</p>
                        <button type="button" class="button filled bg-red m-t-20" (click)="refetchUseCases()">
                            Try again
                        </button>
                    </div>
                </ng-container>
                <ng-template #useCasesLoading>
                    <div class="loader">
                        <qw-spinner loadingText="Fetching use-cases"></qw-spinner>
                    </div>
                </ng-template>
            </ng-template>
            <ng-container *ngIf="totalItems > itemsPerPage">
                <qw-pagination [(page)]="page" [itemsPerPage]="itemsPerPage" [totalItems]="totalItems"
                    (pageChange)="getUseCases(page)"></qw-pagination>
            </ng-container>
        </div>
    </div>
</div>