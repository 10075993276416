import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ProductRoutes } from './pages/product/product-routes';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TermsOfServiceComponent } from './pages/terms-of-service/terms-of-service.component';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { GDPRComponent } from './pages/gdpr/gdpr.component';
import { DPAComponent } from './pages/dpa/dpa.component';
import { PartnershipsComponent } from './pages/partnerships/partnerships.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { CaseStudiesComponent } from './pages/case-studies/case-studies.component';
import { CaseStudyPageComponent } from './pages/case-studies/case-study-page/case-study-page.component';
import { INTEGRATION_ROUTES } from './pages/integrations/integrations.routes';
import { ZohoCampaignFormComponent } from './shared-components/zoho-campaign-form/zoho-campaign-form.component';
import { AcceptableUsagePolicyComponent } from './pages/acceptable-usage-policy/acceptable-usage-policy.component';
import { ActiveLinkGuard } from './shared-components/core/active-link.guard';
import { RefundAndCancellationPolicyComponent } from './pages/refund-and-cancellation-policy/refund-and-cancellation-policy.component';
import { SecurityComponent } from './pages/security/security.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    resolve: [ActiveLinkGuard]
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    resolve: [ActiveLinkGuard]
  },
  {
    path: 'pricing',
    component: PricingComponent,
    data: {
      label: 'Pricing'
    },
    resolve: [ActiveLinkGuard]
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    resolve: [ActiveLinkGuard]
  },
  {
    path: 'privacy-policy/gdpr',
    component: GDPRComponent,
    resolve: [ActiveLinkGuard]
  },
  {
    path: 'legal/dpa',
    component: DPAComponent,
    resolve: [ActiveLinkGuard]
  },
  {
    path: 'terms-of-service',
    component: TermsOfServiceComponent,
    resolve: [ActiveLinkGuard]
  },
  {
    path: 'cookie-policy',
    component: CookiePolicyComponent,
    resolve: [ActiveLinkGuard]
  },
  {
    path: 'acceptable-usage-policy',
    component: AcceptableUsagePolicyComponent,
    resolve: [ActiveLinkGuard]
  },
  ProductRoutes,
  ...INTEGRATION_ROUTES,
  {
    path: 'partnerships',
    component: PartnershipsComponent,
    resolve: [ActiveLinkGuard]
  },
  {
    path: 'case-studies',
    component: CaseStudiesComponent,
    data: {
      label: 'Case studies'
    },
    resolve: [ActiveLinkGuard]
  },
  {
    path: 'case-studies/:id',
    component: CaseStudyPageComponent,
    data: {
      label: 'Case studies'
    },
    resolve: [ActiveLinkGuard]
  },
  {
    path: 'zoho-form',
    component: ZohoCampaignFormComponent
  },
  {
    path: 'refund-and-cancellation-policy',
    component: RefundAndCancellationPolicyComponent
  },
  {
    path: 'security',
    component: SecurityComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    scrollOffset: [0, 100],
    useHash: false,
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
