import { Component, Input, OnInit } from '@angular/core';
import { IGroupedUseCases, UseCasesService } from '../use-cases.service';

@Component({
  selector: 'qw-grouped-use-cases',
  templateUrl: './grouped-use-cases.component.html',
  styleUrls: ['./grouped-use-cases.component.scss']
})
export class GroupedUseCasesComponent implements OnInit {

  @Input() groupedUseCases: IGroupedUseCases[] = [];
  useCasesTabs: string[] = [];
  caseStudiesIndex = 0;
  currentUseCaseCategory: string;
  useCasesSliceFactor = 3;

  constructor(private useCasesService: UseCasesService) { }

  ngOnInit() {
    this.useCasesTabs = this.useCasesService.getCategories();
    this.setUseCasesIndex(0);
  }

  setUseCasesIndex(index: number) {
    this.currentUseCaseCategory = this.useCasesTabs[index];
  }

  changeCaseStudyIndex(index: number) {
    this.caseStudiesIndex = index;
  }

}
