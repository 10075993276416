<div id="app-section" class="trapezoid-fill animated gradient-4">
    <div class="main-container">
        <div class="apps-explanation">
            <h1 class="title__app-explanation">All the apps you will ever need on one platform</h1>
            <div class="app-filters">
                <div class="app-search-box">
                    <!-- <h5 class="app-search-title">Search</h5> -->
                    <div class="app-search">
                        <i class="fas fa-search"></i>
                        <!-- <qw-icon class="app-search-icon" icon="search"></qw-icon> -->
                        <input type="text" class="app-search-input" [formControl]="appsSearch" name="search" id="search"
                            placeholder="Search for apps" autocomplete="off">
                    </div>
                </div>
                <div class="app-filter">
                    <qw-categories-dropdown [categories]="categories$ | async" [currentCategory]="currentCategory"
                        (currentCategoryChange)="getAppsByCategory($event)"></qw-categories-dropdown>
                </div>
            </div>
        </div>
    </div>
</div>


<div id="all-apps-section">
    <div class="main-container">
        <div class="all-apps">
            <h3 class="app-section-title">{{currentCategory}}</h3>
            <ng-container [ngSwitch]="loadingState">
                <ng-container *ngSwitchCase="'success'">
                    <ng-container *ngIf="appKeys.length > 0; else noAppsTemplate">
                        <div class="app-list-container">
                            <ng-container *ngFor="let app of appKeys | slice: 0 : sliceFactor">
                                <a [routerLink]="['/apps', app, 'integrations']" class="single-app">
                                    <div class="app-icon">
                                        <qw-app-icon [appAlias]="app"></qw-app-icon>
                                    </div>
                                    <p class="app-title">
                                        <qw-high-light-text [needle]="appsSearch.value"
                                            [haystack]="$apps[app].name">
                                        </qw-high-light-text>
                                    </p>
                                </a>
                            </ng-container>
                        </div>
                        <div class="t-a-c m-t-40 m-b-200">
                            <!-- <p class="m-b-20">Showing {{ sliceFactor < appKeys.length ? sliceFactor : appKeys.length
                                    }} of {{ appKeys.length }} apps </p> -->
                            <button type="button" *ngIf="sliceFactor < appKeys.length" class="button filled"
                                (click)="incSliceFactor()">
                                Show more
                                <i class="fas fa-chevron-right"></i>
                            </button>
                        </div>
                    </ng-container>
                    <ng-template #noAppsTemplate>
                        <div class="no-apps">
                            <h4>{{ noAppsMessage }}</h4>
                            <p>Try changing your search or select a different category</p>
                        </div>
                    </ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="'failure'">
                    <div class="apps-error">
                        <h4 class="color-red">Failed to fetch apps</h4>
                        <p class="color-red">{{ errorMessage }}</p>
                    </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <div class="apps-loading">
                        <qw-spinner loadingText="Fetching apps"></qw-spinner>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>