import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ITestimonial, AppService } from 'src/app/app.service';
import { tabsSlide } from 'src/app/shared-components/animations/tabs-slide.animations';
import { AT2URL } from 'src/app/shared-components/constants/miscellaneous.constants';

@Component({
  selector: 'qw-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.scss'],
  animations: [tabsSlide]
})
export class PlatformComponent implements OnInit {

  testimonial: ITestimonial[] = [];

  platformFeatures = [
    {
      title: 'Drag & Drop',
      description: 'With a user interface designed for citizen developers and non-technical users, building workflows is as simple as drag and drop.',
      icon: 'fa-hand-rock'
    },
    {
      title: 'Build your own connectors',
      description: 'Use our connector SDK to build your own connectors and launch them with public or private access.',
      icon: 'fa-box-alt'
    },
    {
      title: 'Code snippets',
      description: 'Get flexibility to perform any operation with our built-in formula engine and programming connectors (like Node.js).',
      icon: 'fa-code'
    },
    {
      title: 'Federated clusters',
      description: 'Workflows execution and data storage can be hosted in your preferred region or on-premise anywhere in the world with a single-click deployment.',
      icon: 'fa-server'
    }
  ];

  versatilityTabs = [
    'Real-time',
    'Polling',
    'Scheduled',
    'Manual'
  ];

  conversationTabs = [
    'WhatsApp',
    'Telegram',
    'Messenger',
    'Microsoft Teams',
    'Slack'
  ];

  extraPoints = [
    {
      title: 'Connectivity',
      points: [
        'On-prem services',
        'Databases',
        'FTP, SMTP, SSH and more',
        'Private cloud'
      ]
    },
    {
      title: 'Business Logic',
      points: [
        'Conditional Logic',
        'Error monitoring and handling',
        'Custom connectors',
        'Looping'
      ]
    },
    {
      title: 'Security & Privacy',
      points: [
        'ISO 27001',
        'GDPR Compliance',
        'VAPT Report',
        'SSO & LDAP'
      ]
    },    
    {
      title: 'Support',
      points: [
        'Chat support',
        'Helpdesk',
        'Community forum',
        'Support Centre',
      ]
    },
  ];

  versatilityFeatures = [
    {
      title: 'Real-time',
      description: `Your workflow can be triggered in real-time as soon as an event occurs in the source application system. This event type is suitable for conversational, IoT, financial, and other use cases that require instantenous response.`,
      image: 'Real-time.svg',
      alt: 'Automate workflows in real-time'
    },
    {
      title: 'Polling',
      description: `Quickwork will check the polling application on an interval of one minute to identify if there is any change which has occurred in the respective application; if yes, it triggers the workflow. Quickwork also handles deduplication to avoid any duplicate workflows getting triggered.`,
      image: 'Polling.svg',
      alt: ''
    },
    {
      title: 'Scheduled',
      description: `Quickwork provides a built-in scheduler which will trigger your workflow on a scheduled interval. You can schedule any interval ranging between a few seconds up to several years.`,
      image: 'Scheduled.svg',
      alt: ''
    },
    {
      title: 'Manual',
      description: `This type of event allows you to trigger a workflow instantaneously by initiating a manual call to action like the press of a button.`,
      image: 'Manual.svg',
      alt: ''
    }
  ];

  conversationImages = [
    'WhatsApp',
    'Telegram',
    'Messenger',
    'Microsoft Teams',
    'Slack'
  ];

  tabsIndexes = {
    versatility: 0,
    conversation: 0
  };

  platformURL = AT2URL;

  constructor(private appService: AppService, private titleService: Title, private metaService: Meta) { }

  ngOnInit() {
    this.titleService.setTitle('Enterprise grade iPaaS | Build Automated Workflows With Quickwork');
    this.metaService.addTags([
      { name: 'description', content: 'Quickwork is becoming the go-to iPaaS platform to build automated workflows quickly!' },
      { name: 'robots', content: 'index, follow' }
    ]);
  }

  setTabIndex(index: number, feature: string) {
    this.tabsIndexes[feature] = index;
  }

  navigateToFormulaHelpDesk() {
    window.open('https://helpdesk.quickwork.co/hc/en-us/articles/360052293774-How-to-use-the-formulas', '_blank');
  }


}
