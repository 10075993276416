import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimal'
})
export class DecimalNumberPipe implements PipeTransform {

  transform(value: any, extract?: 'integer' | 'decimal'): any {
    let stringValue = value;
    if (typeof value === 'number') {
      stringValue = value.toString();
    }
    if (stringValue.includes('.')) {
      if (extract === 'integer') {
        return stringValue.substring(0, stringValue.indexOf('.'));
      } else {
        stringValue = stringValue.substring(stringValue.indexOf('.'));
        if (stringValue.length === 2) {
          stringValue += '0';
        }
        return stringValue;
      }
    } else if (extract === 'decimal') {
      return null;
    }
    return value;
  }

}
