import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EMPTY } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';
import { LoadingState, AppService } from 'src/app/app.service';

@Component({
  selector: 'qw-partnerships-join-us',
  templateUrl: './partnerships-join-us.component.html',
  styleUrls: ['./partnerships-join-us.component.scss']
})
export class PartnershipsJoinUsComponent implements OnInit {

  benefits = [
    'New revenue streams',
    'Increase sales',
    'Expand your market reach',
    'Knowledge resources'
  ];

  submitState: LoadingState;
  submitError: {
    title: string;
    message: string;
  };

  formFields = {
    name: '',
    email: '',
    phone: '',
    company: '',
    country: ''
  };

  whiteSpacePattern = /(?!\s)+.+/g;
  phoneNoPattern = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g;

  @Output() onClose = new EventEmitter();

  constructor(private appService: AppService) { }

  ngOnInit() {
  }

  submitPartnerForm(form: NgForm) {
    const partnerForm = {
      name: 'Email Form',
      data: this.formFields
    };

    partnerForm.data.country = this.appService.getUserGeoLocation().country_name;

    const success = () => {
      form.reset();
      this.submitState = 'success';
    };

    if (form.valid) {
      this.submitState = 'pending';
      this.appService.submitPartnerForm(partnerForm).pipe(
        tap(success),
        catchError((error: HttpErrorResponse) => {
          if (error.status !== 200) {
            this.submitError = {
              title: 'Unable to submit details',
              message: error.message
            };
            this.submitState = 'failure';
          } else { success(); }
          return EMPTY;
        }),
        finalize(() => setTimeout(() => this.submitState = null, 5000))
      ).subscribe();
    }

  }

  closeForm() {
    this.onClose.emit();
  }

  fieldValueChange(key: string, value: string) {
    this.formFields[key] = value.trim();
  }

}
