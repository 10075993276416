import { HttpErrorResponse } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'manageError'
})
export class ManageErrorPipe implements PipeTransform {

  transform(error: HttpErrorResponse, ...args: any[]): any {
    if (error.error) {
      return error.error.title || error.error.message;
    }
    return null;
  }

}
