<section id="header-section" class="trapezoid-fill animated gradient-3">
  <div class="main-container">
    <header class="partnership-header">
      <!-- <span class="fa-stack fa-2x icon">
        <i class="fas fa-circle fa-stack-1x"></i>
        <i class="fad fa-handshake fa-stack-1x"></i>
      </span> -->
      <h1 class="title__partnership">Let’s work together</h1>
      <p class="description__partnership p-2">
        Partner with us today to unlock our value-based partner program that provides you with the tools and guidance to
        enable integration capabilities for your customers.
      </p>
      <button class="button filled" type="button" (click)="scrollToPartnerForm(partnerFormSection)">
        Become a Partner
        <i class="fas fa-chevron-right"></i>
      </button>
    </header>
  </div>
</section>

<section id="partner-logos-section">
  <div class="main-container">
    <qw-customers [customers]="partnerLogos" path="enterprise-qjb/partnerships/partner-logos/"></qw-customers>
  </div>
</section>

<section id="partner-importance-section">
  <div class="main-container">
    <header class="partner-importance-header">
      <!-- <i class="fad fa-question-circle icon"></i> -->
      <h2>Why partner with us?</h2>
      <p class="p-1">
        As our partner, get immediate access to Quickwork’s capabilities for Integrations, API Management and
        Conversation Management and enhance your solution offerings to your customers.
      </p>
    </header>

    <div class="column-layout c-1-1" id="ipaas-content">
      <header>
        <h3>There is a lot you can do with an iPaaS</h3>
        <p class="p-2">
          Building integrations manually can be cumbersome, resource intensive and error-prone. With Quickwork’s iPaaS,
          partners can instantly establish integration with thousands of applications, while the platform handles the
          complexities involved. You can also use our platform to build APIs and to create complex conversational flows
          for your customers with ease.
        </p>
      </header>
      <div class="column-representation">
        <div class="column-image">
          <!-- <img src="../../../assets/images/enterprise-qjb/partnerships/partner-ipaas.png" alt=""> -->
          <img src="../../../assets/images/enterprise-qjb/partnerships/partnership.svg" alt="Partnerships" width="100%" height="100%">
        </div>
      </div>
    </div>
    <div class="partner-cx-content">
      <header>
        <h3>Focus on your customer and end-user experiences</h3>
        <p class="p-2">
          As our partner, you can focus on providing richer experiences to your customers and end-users, and let
          Quickwork do the heavy lifting of managing integrations and workflows at any scale.
        </p>
      </header>
      <ul class="partner-cx-points points">
        <li *ngFor="let point of partnerCXPoints">{{ point }}</li>
      </ul>
    </div>
  </div>
</section>

<qw-testimonial></qw-testimonial>

<section id="partner-points-section">
  <div class="main-container">
    <h2>What's in it for you?</h2>
    <div class="column-layout c-1-1-1-1">
      <div class="column" *ngFor="let feature of partnerShipsFAQs">
        <div class="column-icon icon">
          <img src="../../../assets/images/enterprise-qjb/partnerships/{{ feature.icon }}" [alt]="feature.title" width="100%" height="100%">
        </div>
        <div class="column-title text-bold">{{ feature.title }}</div>
        <p class="column-description">{{ feature.description }}</p>
      </div>
    </div>
  </div>
</section>

<!-- <section id="partner-types-section">
  <div class="main-container">
    <header>
      <h3 class="caption">Types of partnership</h3>
      <h2>Find the right partnership for you</h2>
      <p class="p-2">We have designed our partnership programs to be simple and flexible. </p>
    </header>
    <div class="column-layout">
      <div class="column l-m-border" id="partnership-types">
        <div class="partner-type" *ngFor="let partnerType of partnershipTypes">
          <h3 class="column-title">{{ partnerType.title }}</h3>
          <p class="p-2">{{ partnerType.description }}</p>
          <ul class="points">
            <li *ngFor="let point of partnerType.points">{{ point }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section id="partner-form-section" #partnerFormSection>
  <div class="main-container">
    <div class="column-layout c-1-1">
      <header>
        <h3 class="caption">Apply now</h3>
        <h2>Ready to partner with us?</h2>
        <p class="p-2">
          Fill out this simple form and our team will reach out to you to discuss the details.
        </p>
      </header>
      <qw-partnerships-join-us></qw-partnerships-join-us>
    </div>
  </div>
</section>