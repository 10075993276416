import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ICaseStudy } from '../case-studies.service';

@Component({
  selector: 'qw-case-study',
  templateUrl: './case-study.component.html'
})
export class CaseStudyComponent {

  constructor(private router: Router) {}

  @Input() caseStudy: ICaseStudy;

  navigateToCaseStudy() {
    this.router.navigateByUrl('/case-studies', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/case-studies/${this.caseStudy.id}`]);
    });
  }

}
