
export const SUPPORT_CENTER_URL = 'https://support.quickwork.co/hc/en-us';
export const AT2URL = 'https://automation.quickwork.co/#/';
export const AT2URL_JOURNEY_STORE = `${AT2URL}workflow-store`;

export const CUSTOMER_LOGOS = [
    {
        image: 'aweber__logo.svg',
        label: 'Aweber'
    },
    {
        image: 'freshworks__logo.svg',
        label: 'Freshworks'
    },
    {
        image: 'inuit__logo.svg',
        label: 'Inuit'
    },
    {
        image: 'fujitsu__logo.svg',
        label: 'Fujitsu'
    },
    {
        image: 'bajaj-finserv__logo.svg',
        label: 'Bajaj Finserv'
    },
    {
        image: 'harvard-university__logo.svg',
        label: 'Harvard University'
    },
    {
        image: 'virgin-media__logo.svg',
        label: 'Virgin Media'
    },
    // {
    //   image:  'hp__logo.svg',
    //   label: 'hp'
    // },
    {
        image: 'randstad__logo.svg',
        label: 'Randstad'
    },
    {
        image: 'ajanta_pharma__logo.svg',
        label: 'Ajanta Pharma'
    },
    {
        image: 'axis_bank__logo.svg',
        label: 'Axis Bank'
    },
    {
        image: 'bms_rebrand__logo.svg',
        label: 'BMS Rebrand'
    },
    {
        image: 'cipla__logo.svg',
        label: 'Cipla'
    },
    {
        image: 'clear_tax__logo.svg',
        label: 'Clear Tax'
    },
    {
        image: 'hotelengine__logo.svg',
        label: 'Hotelengine'
    },
    // {
    //   image:  'karza__logo.svg',
    //   label: 'karza'
    // },
    {
        image: 'schbang__logo.svg',
        label: 'Schbang'
    },
    {
        image: 'yahoo__logo.svg',
        label: 'Yahoo'
    }
];