<ng-container *ngIf="caseStudy$ | async as caseStudy; else errorLoading">
    <section id="case-study-header-section" class="trapezoid-fill">
        <div class="main-container">
            <div class="case-study-header">
                <div class="case-study-header-representation">
                    <!-- <div class="case-study-header-image img-card no-hover" [bgImage]="caseStudy.imageThumbnailSrc"
                        [overlayColor]="caseStudy.imageOverlayColor">
                    </div> -->
                    <img class="case-study-header-image" [src]="caseStudy.imageThumbnailSrc" alt="" srcset="">
                    <div class="case-study-header-image-gradient" [style.backgroundImage]="linearGradient"></div>
                </div>
            </div>
        </div>
    </section>

    <section id="case-study-challenge-section">
        <div class="main-container">
            <div class="column-layout c-1-1 size-3fr-1fr">
                <div class="column" id="customer-problem">
                    <div class="column-body">
                        <h2 class="case-study-header-title">{{ caseStudy.title }}</h2>
                        <p class="customer-description p-2" [innerHTML]="caseStudy.aboutCompany"></p>
                    </div>
                </div>
                <div class="column">
                    <div class="case-study-integrations">
                        <div class="company-logo">
                            <img [src]="caseStudy.companyLogo" />
                        </div>
                        <p class="integrations-title">Integrations used</p>
                        <div class="integration-apps">
                            <div class="integration-app" *ngFor="let app of caseStudy.apps">
                                <qw-app-icon [appAlias]="app"></qw-app-icon>
                                <p>{{ app | appName | async }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="customer-challenge-section">
        <div class="main-container">
            <div class="column-layout c-1-1 size-1fr-3fr">
                <div class="column">
                    <div class="l-m-border" id="case-study-metrics">
                        <div class="column-body">
                            <h3 class="border-highlight">Industry</h3>
                            <p>{{ caseStudy.industry }}</p>
                        </div>
                        <div class="column-body">
                            <h3 class="border-highlight">Go live</h3>
                            <p>{{ caseStudy.goLiveDays }} days</p>
                        </div>
                    </div>
                </div>
                <div class="column l-m-border" id="challenge-section">
                    <div class="column-body">
                        <h3 class="border-highlight">Customer Challenge</h3>
                        <p class="p-2" [innerHTML]="caseStudy.customerChallenge"></p>
                    </div>
                    <div class="column-body">
                        <h3 class="border-highlight">Solution</h3>
                        <p class="p-2" [innerHTML]="caseStudy.solution"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <qw-testimonial></qw-testimonial>

    <ng-container *ngIf="otherCaseStudies$ | async as otherCaseStudies">
        <section id="other-case-studies-section" *ngIf="otherCaseStudies.length">
            <div class="main-container">
                <h2>Explore other case studies</h2>
                <div class="column-layout c-1-half" [class.c-2-el]="otherCaseStudies.length < 3">
                    <ng-container *ngFor="let otherUseCase of otherCaseStudies | slice:0:3">
                        <qw-case-study class="column" [caseStudy]="otherUseCase"></qw-case-study>
                    </ng-container>
                </div>
            </div>
        </section>
    </ng-container>
</ng-container>

<ng-template #errorLoading>
    <section id="case-study-header-section" class="trapezoid-fill">
        <div class="main-container">
            <ng-container *ngIf="caseStudyError$ | async as error; else loading">
                <div class="case-studies-error">
                    <h4 class="color-red">{{ error.title }}</h4>
                    <p class="color-red">{{ error.message }}</p>
                </div>
            </ng-container>
            <ng-template #loading>
                <div class="case-studies-loading\">
                    <qw-spinner loadingText="Fetching case-studies"></qw-spinner>
                </div>
            </ng-template>
        </div>
    </section>
</ng-template>