import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { fadeAnimation } from '../animations/fade-in-fade-out.animation';
import { modalBodyEnterExit, modalEnterExitDuration } from '../animations/modal.animations';

@Component({
  selector: 'qw-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [fadeAnimation, modalBodyEnterExit]
})
export class ModalComponent implements OnInit, OnDestroy {

  modalToggle: boolean;
  @HostBinding('@fadeAnimation') get getToggle() {
    return true;
  }

  constructor(private appService: AppService) { }

  ngOnInit() {
    // this.appService.lockWindowScroll(true);
    setTimeout(() => this.modalToggle = true, modalEnterExitDuration);
  }

  ngOnDestroy() {
    // this.appService.lockWindowScroll(false);
    setTimeout(() => this.modalToggle = false, modalEnterExitDuration);
  }

}
