import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'qw-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss']
})
export class TermsOfServiceComponent implements OnInit {

  revisionDate: string;
  basePath: string;

  constructor(private metaService: Meta, private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('Terms of Service | Quickwork');
    this.metaService.addTags([
      { name: 'description', content: 'Quickwork offers its products and services as explained in our Terms of Service. Please read them carefully before you use our products or services.' },
      { name: 'robots', content: 'index, follow' }
    ]);
    // this.revisionDate = this.appService.revisionDate;
    this.revisionDate = '03 Jan, 2022';
  }

}
