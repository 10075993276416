<div id="privacy-policy-container" class="header-wave">
    <div class="main-container">
        <div class="privacy-policy">
            <div class="m-b-20">
                <div class="highlight-text">Privacy Policy</div>
            </div>
            <div class="policy-content">
                <p class="c24">
                    <span>Effective Date: </span>
                    <span class="c7">{{ revisionDate }}</span>
                </p>
                <br>
                <p class="c10"><span>We at Quickwork Technologies Private Limited (&ldquo;</span><strong
                        class="c5">Quickwork</strong><span>&rdquo;, &ldquo;</span><strong
                        class="c5">We</strong><span>&rdquo;,
                        &ldquo;</span><strong class="c5">Us</strong><span>&rdquo;, &ldquo;</span><strong
                        class="c5">Our</strong><span>&rdquo;) have created a Platform that provides customers/user with
                        access to
                        certain products and services (&ldquo;</span><strong class="c5">Services</strong><span>&rdquo;)
                        designed to
                        connect applications, automate workflows and send or receive messages to/from humans and
                        machines. This
                        Privacy Policy (&quot;Privacy Policy&quot;) discloses the practices and policies adopted by
                        Quickwork for
                        collecting, storing, retrieving or using Personal Data it may receive from its
                        customer/visitor/user
                        (&ldquo;</span><strong class="c5">You</strong><span>&rdquo;, &ldquo;</span><strong
                        class="c5">Your</strong><span>&rdquo;) who may access the Portal (defined below) to receive the
                        Services of
                        Our Platform through Our website located at </span><a href="/"
                        class="c7">https://quickwork.co</a><span
                        class="c7">.</span><span>&nbsp;The information received
                        from You while accessing the Services through Our website and platform (together referred to as
                        the
                        &ldquo;</span><strong class="c5">Portal</strong><span class="c2">&rdquo;) shall be utilized in a
                        manner as set
                        out in this Privacy Policy.</span></p>
                <p class="c10"><span>This Privacy Policy (the &ldquo;</span><strong class="c5">Privacy
                        Policy</strong><span>&rdquo;)
                        along with the Terms of Service provides the terms and conditions governing Your use of Portal
                        and Services
                        thereunder. Capitalized terms not defined in this Privacy Policy shall have the definition
                        provided in Our
                        Terms of Service available at:&lt;</span><a href="/terms-of-service" target="_blank"
                        class="c7 c20">https://quickwork.co/terms-of-service</a><span class="c2">&gt;.</span></p>
                <p class="c10"><span>We value Your trust &amp; respect Your privacy. This Privacy Policy seeks to comply
                        with the
                        provisions of the Information Technology Act, 2000 and the Information Technology (Reasonable
                        security
                        practices, procedures and sensitive personal data or information) Rules, 2011
                        (&quot;</span><strong class="c5">Rules</strong><span class="c2">&quot;) that require publishing
                        of
                        the Privacy Policy for the
                        collection, use, storage and transfer of sensitive Personal Data or information.</span></p>
                <p class="c10"><span class="c2">Please read this Privacy Policy carefully to make sure You understand
                        how any
                        Personal Data that You provide to Us will be handled. For the purpose of the Privacy Policy,
                        Personal Data
                        shall mean any information that relates to a natural person, either directly or indirectly. If
                        You use Our
                        Portal, You will be regarded as having read and accepted this Privacy Policy. By mere use of
                        this Portal,
                        You expressly give Us consent to Our use and disclosure of Your Personal Data in accordance with
                        this
                        Privacy Policy. If You do not agree to the terms of this Privacy Policy, please do not use or
                        access Our
                        Portal. </span></p>
                <br>
                <p class="c10"><strong class="c8 c4 c5">Information We Collect:</strong></p>
                <p class="c10"><span class="c2">Quickwork uses the information collected from You or about You for
                        providing
                        Services to You.&nbsp;This Privacy Policy tells You how We process information that relates to
                        Personal Data
                        through the Portal. </span></p>
                <p class="c10"><span class="c2">We collect and process the following categories of Personal Data:</span>
                </p>
                <ul class="c13 lst-kix_list_6-0 start">
                    <li class="c3 c6 li-bullet-0"><span class="point-ul">Identity:</span><span class="c4">&nbsp;Personal
                            Data about You
                            and Your identity, such as Your First name, Last Name,</span></li>
                    <li class="c3 c6 li-bullet-0"><span class="point-ul">Contact Information Data:</span><span
                            class="c4">&nbsp;Personal Data used to contact an
                            individual</span><span>&nbsp;like</span><span class="c4">&nbsp;email address</span><span>,
                        </span></li>
                    <li class="c3 c6 li-bullet-0"><span class="point-ul">Feedback Data and Other Data</span><span
                            class="c2">: This
                            includes the following:</span></li>
                    <ul class="c13 lst-kix_list_6-1 start">
                        <li class="c6 c19 li-bullet-0"><span class="c4">If You call Our call </span><span
                                class="c4">centre</span><span class="c2">/office, We may record information provided by
                                You
                                to service You or record the calls for
                                quality and training purposes;</span></li>
                        <li class="c19 c6 li-bullet-0"><span>If You provide any feedback or comments to Us on mail, chat
                                or
                                the Portal,
                                we may &nbsp;store the mail , chat or comments for quality purpose</span></li>
                    </ul>
                </ul>
                <br>
                <p class="c10"><span class="c2">We collect Personal Data from various sources based on the context in
                        which the
                        Personal Data will be processed:</span></p>
                <ul class="c13 lst-kix_list_1-0 start">
                    <li class="c3 c6 li-bullet-0"><span class="c2">Data We collect from You: We collect Personal Data
                            from You
                            directly, for example, when You input information into an online form, or contact Us
                            directly.</span>
                    </li>
                    <li class="c3 c6 li-bullet-0"><span class="c4">Data We receive from others: We receive Personal Data
                            from third
                            parties with whom We have a relationship, for example, Our customers, </span><span>business
                            associates,
                            vendors, service providers, channel partners and any third-party parties which have a
                            written agreement
                            with Us </span><span class="c4">,</span><span class="c1">service providers</span><span
                            class="c2">,
                            agencies, Third-Party Applications or linked websites.</span></li>
                    <li class="c9 c6 li-bullet-0" id="h.30j0zll"><span>Data collected automatically: We may collect
                            certain Personal
                            Data automatically as part of Your use of Our Portal. To know more please read our Cookie
                            Policy
                            available at: </span>&lt;<a href="/cookie-policy" target="_blank" class="c7">https://quickwork.co/cookie-policy</a>&gt;</li>
                </ul>
                <br>
                <p class="c10"><strong class="c8 c4 c5">Processing and Use of Information:</strong></p>
                <p class="c10"><span>We will process identity data and contact data when You register and open an
                        account
                        with
                    </span><span>US for the</span><span class="c2">&nbsp;access of Our Platform. We use this information
                        as may be
                        necessary for the creation, operation, and maintenance of the account and as may be necessary to
                        fulfill Our
                        contractual obligations with You, and related operations.</span></p>
                <p class="c10"><span class="c2">We may use Personal Data to contact You and keep You posted on Our
                        latest
                        announcements, upcoming events, including confirmations, security alerts and support and
                        administrative
                        messages. It also helps Us to improve Our Services. If You do not want to be on Our mailing
                        list, You can
                        unsubscribe or write to us at <a href="mailto:support@quickwork.co">support@quickwork.co</a></span></p>
                <p class="c10"><span class="c2">When You contact Us through the Portal, We process certain Personal Data
                        such as
                        identity data, contact data that You may provide Us. We use this data to communicate with You
                        about the
                        subject matter of Your request and related matters, which includes subscriptions, billing,
                        account
                        information, newsletter, payment confirmations, etc.</span></p>
                <p class="c10"><span class="c4">From time to time, We may use Your Personal Data to send important
                        notices, such as
                        communications and changes to Our terms, conditions and policies and other documentation. Since
                        this
                        information is important to Your interaction with Us, You cannot opt out of receiving these
                        communications.</span></p>
                <br>
                <p class="c10"><strong class="c4 c5 c8">Our Disclosure of Your Personal Data and Other
                        Information:</strong></p>
                <p class="c10"><span>We may share Your Personal Data with certain third parties without further notice
                        to You, as
                        set forth below:</span></p>
                <ul class="c13 lst-kix_list_5-0 start">
                    <li class="c16 c6 li-bullet-0"><span class="point-ul">Business Transfers</span><span class="c2">: In
                            the
                            event of a
                            corporate sale, merger, reorganization, dissolution or similar event, Personal Data may be
                            part of the
                            transferred assets, or We may disclose certain Personal Data as part of the due diligence
                            process.</span></li>
                    <li class="c6 c16 li-bullet-0"><span class="point-ul">Related Companies</span><span class="c2">: In
                            order
                            to
                            streamline certain business operations, improve Our Services in order to better suit the
                            needs of You
                            and for any other purposes consistent with this Privacy Policy, We may share Your Personal
                            Data with any
                            of Our current or future affiliated entities, subsidiaries, and parent companies. &nbsp;
                            &nbsp; </span>
                    </li>
                    <li class="c16 c6 li-bullet-0"><span class="point-ul">Service Providers</span><span class="c2">: In
                            performance of Our
                            general business operations, product/service improvements, to enable certain features, and
                            in connection
                            with Our other lawful business interests, We may share Personal Data with service providers
                            or
                            sub-processors who perform certain services or process data on Our behalf e.g. payment
                            processing,
                            infrastructure management, insurance and cloud service. While doing so, We only provide them
                            with the
                            information that they need to perform their specific function.</span></li>
                    <li class="c16 c6 li-bullet-0"><span class="point-ul">Legal Requirements</span><span class="c2">: We
                            may
                            disclose Your
                            Personal Data if required under applicable law. </span></li>
                    <li class="c16 c6 li-bullet-0"><span class="point-ul">Consent/direction</span><span class="c2">: We
                            may
                            disclose Your
                            personal information to any other third party with Your prior consent or as You
                            direct.</span></li>
                    <li class="c16 c6 li-bullet-0"><span class="point-ul">Data Sale</span><span class="c2">: We do not
                            sell
                            Your Personal
                            Data.</span></li>
                </ul>
                <br>
                <p class="c10"><strong class="c8 c4 c5">Personal Data and Customer Data with respect to Third-Party
                        Applications and
                        Linked Websites</strong></p>
                <p class="c10"><span class="c4">Personal Data may be stored in Our servers temporarily as part of
                        processing and
                        retained for a limited time to support Platform features such as history logging and error
                        recovery. We
                        protect Personal Data using industry Standard methods from attack, interception or alteration.
                        We do not
                        examine or distribute any Personal Data except as required by law. We may however use automated
                        tools to
                        examine Personal Data in aggregate, in order to improve Our Services.</span></p>
                <p class="c10"><span class="c4">The Platform allows You to connect to third-party applications, to
                        periodically or
                        on-demand pull Personal Data from those applications into Our servers, and to pass such data
                        from the
                        Platform onward to other third-party applications. These transfers are done only under Your
                        direction and
                        under Your sole control </span><span>b</span><span class="c4">y using the Platform, You warrant
                        that We have
                        Your permission to perform any such data transfers on Your behalf, notwithstanding that these
                        may contain
                        Personal Data, and may cross international borders. We do not control the privacy practices of
                        third-party
                        applications to or from which You may transfer data, and You warrant that when transferring Your
                        data, You
                        are accepting the privacy terms to which those Third-Party Applications subscribe.</span></p>
                <p class="c10"><span class="c4">Similar to the above, The Quickwork Platform may provide links (or allow
                        &nbsp;You to
                        provide links) that allow You to leave the Quickwork Platform and/or access third party websites
                        or access
                        the Quickwork Platform through third party websites and the access of the linked websites by You
                        shall be
                        governed by the terms of use and privacy policy of the relevant linked websites. The linked
                        websites are not
                        under the control of Quickwork and it is not responsible for the contents of any linked websites
                        or any link
                        contained in a linked website, or any changes or updates to such sites or the performance or
                        security of
                        such other sites. &nbsp;We strongly advise You to review the Privacy Policy of every site You
                        visit.
                        &nbsp;Quickwork is not responsible for any content of or transmission to or from any linked
                        website.
                        Quickwork provides these links only as a convenience or feature of the Quickwork Platform, and
                        the inclusion
                        of any link does not imply endorsement by Quickwork</span><span>&nbsp; &nbsp; &nbsp;</span><span
                        class="c4">&nbsp;of the linked websites. You may access any such third-party websites or access
                        the
                        Quickwork Platform through any third-party websites at Your sole risk and discretion. </span>
                </p>
                <br>
                <p class="c10"><strong class="c8 c4 c5">Payments</strong></p>
                <p class="c10"><span>We provide paid products and/or services within the
                    </span><span>Portal.</span><span class="c2">&nbsp;In that case, We use third-party services for
                        payment processing. We will not store or
                        collect Your payment card or bank details. That information is provided directly to our
                        third-party payment
                        processors whose use of your personal information is governed by their Privacy Policy.</span>
                </p>
                <br>
                <p class="c10"><strong class="c5">International Transfer</strong></p>
                <p class="c10"><span class="c2">We may transfer data outside of the country it was collected in. A
                        prudent degree of
                        safeguard mechanism is in place to ensure the safety of data while it is being transferred. We
                        ensure that
                        compliance with applicable data protection laws is strictly followed. </span></p>
                <br>
                <p class="c10"><strong class="c5">Rights over Your Personal Data</strong></p>
                <p class="c10"><span class="c2">You have the following rights with respect to Your Personal Data.</span>
                </p>
                <ul class="c13 lst-kix_list_3-0 start">
                    <li class="c3 c6 li-bullet-0"><span class="c4">You may view, restrict the processing or update any
                            Personal Data
                            We have about You.</span></li>
                    <li class="c3 c6 li-bullet-0"><span class="c1">You may remove yourself from marketing by clicking
                            the opt-out
                            link at the bottom of any marketing email.</span></li>
                    <li class="c3 c6 li-bullet-0"><span class="c4">You may correct inaccurate Personal Data and ensure
                            it is
                            reasonably complete.</span></li>
                    <li class="c3 c6 li-bullet-0"><span class="c4">You may request that We delete certain Personal Data
                        </span><span>&nbsp; &nbsp; &nbsp;</span></li>
                </ul>
                <br>
                <p class="c10"><strong class="c5">Security</strong></p>
                <ul class="c13 lst-kix_list_2-0 start">
                    <li class="c3 c6 li-bullet-0"><span class="c4">The security of Your Personal Data is important to
                            Us. We take
                            precautions, including administrative, technical, legal &nbsp;and physical measures, to
                            safeguard Your
                            Personal Data against loss, theft and misuse, as well as against unauthorized access,
                            disclosure,
                            alteration and destruction. All &nbsp;Personal Data </span><span>is</span><span
                            class="c4">&nbsp;encrypted to ensure safety.</span></li>
                    <li class="c3 c6 li-bullet-0"><span class="c4">This Portal has various electronic, procedural and
                            physical
                            security measures in place to protect the loss, misuse and alteration of information, or any
                            accidental
                            loss, destruction or damage to data. &nbsp;When You submit Your information through the
                            Portal, it is
                            protected through Our security systems. &nbsp;</span></li>
                    <li class="c9 c6 li-bullet-0"><span class="c4">The security systems put in place are of industry
                            standard and as
                            would have been commercially viable. </span></li>
                </ul>
                <br>
                <p class="c10"><strong class="c8 c4 c5">Minors:</strong></p>
                <p class="c10"><span>Our Portal is neither directed at nor intended for use by minors (</span><span>who
                        have not
                        attained age of Majority</span><span class="c2">) in the relevant jurisdiction. Further, We do
                        not knowingly
                        collect Personal Data from such individuals. If We learn that We have inadvertently done so, We
                        will
                        promptly delete it.</span></p>
                <br>
                <p class="c10"><strong class="c5">Changes to the Privacy Policy</strong></p>
                <p class="c10"><span>We reserve the right to change the Privacy Policy at Our sole discretion, and we
                        may inform You
                        of any changes to the Privacy Policy via the Portal and/or a written communication. We will
                        inform You of
                        any such change by Us posting the updated Privacy Policy on Our Website. </span><span>We
                        encourage You to
                        review this Privacy Policy regularly for any such changes.</span><span class="c2">&nbsp;Your
                        continued use
                        of the Portal and its Services will be subject to the then-current Privacy Policy.</span></p>
                <br>
                <p class="c10"><strong class="c8 c4 c5">Data Retention:</strong></p>
                <p class="c10"><span>We may retain Your information for as long as Your account is active or as needed
                        to provide
                        You Services, maintain accurate accounting, financial and other operational records, comply with
                        Our legal
                        obligations, resolve disputes and enforce our agreements.</span><span
                        class="c23 c29">&nbsp;</span><span class="c2">We have established internal policies for the
                        deletion of data from Your accounts following
                        termination of a Customer&rsquo;s subscription to the Platform.</span></p>
                <br>
                <p class="c10"><strong class="c8 c4 c5">EU Users:</strong></p>
                <p class="c10"><span>If You reside in the European Economic Area (&ldquo;</span><strong
                        class="c5">EEA</strong><span>&rdquo;), You may have additional rights with respect to Your
                        Personal Data, as
                        further outlined at: &lt;</span><a href="/privacy-policy/gdpr" target="_blank"
                        class="c7">https://quickwork.co/privacy-policy/gdpr</a><span>&gt;.
                        &nbsp;Contact Us for more information regarding the mechanisms We use to ensure adequate
                        protection of data
                        subject to EU Law. </span></p>
                <br>
                <p class="c10"><strong class="c23 c5">Contacting Quickwork </strong></p>
                <p class="c10"><span class="c2 c23">You may update or change the Personal Data you have provided us by
                        logging into
                        the Platform and providing such additional information where applicable. If You are Our customer
                        and would
                        like to gain access to, or request deletion of information We have collected, please contact us
                        at
                        <a href="mailto:support@quickwork.co">support@quickwork.co</a>. We will use commercially reasonable efforts to respond to such queries
                        within 30
                        business days but are not liable for any failure to do so.</span></p>
                <p class="c10"><span class="c2 c23">For any grievances about our Platform or the Website, You may
                        contact
                        <a href="mailto:support@quickwork.co">support@quickwork.co</a>- </span></p>
            </div>
        </div>
    </div>
</div>