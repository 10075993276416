<section id="security-header-section" class="trapezoid-fill animated gradient-1">
    <div class="main-container">
        <header class="security-header">
            <h2 class="security-title m-b-10">Security at Quickwork</h2>
            <p class="security-description p-2">
                Quickwork is committed to providing a highly secure and reliable environment
            </p>
        </header>
    </div>
</section>

<section id="security-details-section">
    <div class="main-container">
        <header class="security-details-header m-b-40">
            <h3 class="caption">Security Details</h3>
        </header>
        <div class="security-details-container">
            <ng-container *ngFor="let detail of securityDetails; let i = index;">
                <div class="security-detail" [class.collapsed]="!detail.showContent">
                    <div class="security-detail-header" (click)="toggleContent(detail)">
                        <h4 class="security-detail-title">{{ detail.title }}</h4>
                        <i class="fal fa-chevron-up"></i>
                    </div>
                    <div class="security-detail-body">
                        <ul class="security-detail-points">
                            <li class="security-detail-point" *ngFor="let point of detail.points">{{ point }}</li>
                        </ul>
                        <ng-container *ngIf="!i">
                            <div class="security-logos">
                                <ng-container *ngFor="let logo of complianceLogos">
                                    <div class="security-logo">
                                        <img src="../../../assets/images/enterprise-qjb/security/{{ logo }}" alt="" srcset="">
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>

<section id="security-enterprises-section">
    <div class="main-container">
        <header class="security-enterprises-header m-b-60">
            <h3 class="t-a-c">Maintaining Data Security for Enterprises</h3>
        </header>
        <qw-customers [flipAnimation]="true"></qw-customers>
    </div>
</section>