<div id="footer-section">
    <div class="main-container">
        <div class="grid footer-content">
            <div class="footer-logo">
                <div class="logo" routerLink="">
                    <img src="../../../assets/images/company logo/Quickwork logo rectangle style-01.svg" alt="Quickwork Logo" width="100%" height="100%" />
                </div>
                <a href="/security" routerLink="/security" class="security-logos">
                    <ng-container *ngFor="let logo of complianceLogos">
                        <div class="security-logo">
                            <img src="../../../assets/images/enterprise-qjb/security/{{ logo }}" alt="" srcset="">
                        </div>
                    </ng-container>
                </a>
                <!-- <button type="button" class="button filled">
                    <i class="fad fa-language"></i>
                    English (United States)
                </button> -->
            </div>
            <div class="footer-links" *ngFor="let fLink of footerLinks">
                <h6 class="m-b-20">{{fLink.title}}</h6>
                <div class="footer-link" *ngFor="let link of fLink.links">
                    <ng-container *ngIf="link.type === 'href'">
                        <a class="footer-item" [href]="link.link" target="_blank">{{link.name}}</a>
                    </ng-container>
                    <ng-container *ngIf="link.type === 'router'">
                        <ng-container *ngIf="fLink.title !== 'Popular apps'">
                            <a href="" class="footer-item" [routerLink]="link.link">{{link.name}}</a>
                        </ng-container>
                        <ng-container *ngIf="fLink.title === 'Popular apps'">
                            <a class="footer-item" href="" [routerLink]="link.link" (click)="redirectToIntegrations(link.link)">{{link.name}}</a>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="copy-right-container">
            <div class="qw-footer">
                <div class="qw-copyright">
                    <!-- Copyright © Quickwork {{ currentYear }} -->
                    © Quickwork Technologies Private Limited
                </div>
                <div class="social-media-icons">
                    <a href="https://www.youtube.com/c/Quickwork" target="_blank">
                        <img src="../../../assets/images/enterprise-qjb/footer/Youtube.svg" alt="Youtube" width="100%" height="100%" />
                    </a>
                    <a href="https://twitter.com/quickworkco" target="_blank">
                        <img src="../../../assets/images/enterprise-qjb/footer/Twitter.svg" alt="Twitter" width="100%" height="100%" />
                    </a>
                    <a href="https://www.facebook.com/quickworkco" target="_blank">
                        <img src="../../../assets/images/enterprise-qjb/footer/Facebook.svg" alt="Facebook" width="100%" height="100%" />
                    </a>
                    <a href="https://www.instagram.com/quickworkco" target="_blank">
                        <img src="../../../assets/images/enterprise-qjb/footer/Instagram.svg" alt="Instagram" width="100%" height="100%" />
                    </a>
                    <a href="https://www.linkedin.com/company/quickwork/mycompany/?viewAsMember=true" target="_blank">
                        <img src="../../../assets/images/enterprise-qjb/footer/LinkedIn.svg" alt="LinkedIn" width="100%" height="100%" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>