import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { fromEvent, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { fadeEnterExit } from '../animations/fade-enter-exit.animation';
// import { CATEGORIES_DROPDOWN_OPTIONS } from '../constants/tooltip.constants';

@Component({
  selector: 'qw-categories-dropdown',
  templateUrl: './categories-dropdown.component.html',
  styleUrls: ['./categories-dropdown.component.scss'],
  animations: [fadeEnterExit]
})
export class CategoriesDropdownComponent implements OnInit, OnDestroy {

  toggleCategories = false;
  categorySearch = new FormControl();
  insideDropdown: boolean;
  filteredCategories: any[] = [];
  @Input() currentCategory: string;
  @Input() categories: any[] = [];
  @Output() currentCategoryChange = new EventEmitter();

  // categoriesDropdownOptions = CATEGORIES_DROPDOWN_OPTIONS;

  private destroyed$ = new Subject();

  constructor() { }

  ngOnInit() {
    this.categorySearch.valueChanges.pipe(
      tap(search => {
        const regex = new RegExp(search, 'i');
        this.filteredCategories = this.categories.filter(category => (category as string).search(regex) !== -1);
      })
    ).subscribe();

    fromEvent(window, 'mousedown').pipe(
      tap(() => this.hideCategories())
    ).subscribe();
  }

  showCategories() {
    this.toggleCategories = true;
  }

  hideCategories() {
    if (!this.insideDropdown) { this.toggleCategories = false; }
  }

  selectCategory(category: string) {
    if (category !== this.currentCategory) {
      this.currentCategoryChange.emit(category);
      this.toggleCategories = false;
    }
  }

  setInsideDropdown(state: boolean) {
    this.insideDropdown = state;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
