import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseStudyPageComponent } from './case-study-page/case-study-page.component';
import { CaseStudyComponent } from './case-study/case-study.component';
import { CaseStudiesComponent } from './case-studies.component';
import { SharedModule } from 'src/app/shared-components/shared.module';
import { CaseStudyFiltersComponent } from './case-study-filters/case-study-filters.component';
import { GroupedCaseStudiesComponent } from './grouped-case-studies/grouped-case-studies.component';



@NgModule({
  declarations: [
    CaseStudiesComponent,
    CaseStudyPageComponent,
    CaseStudyComponent,
    CaseStudyFiltersComponent,
    GroupedCaseStudiesComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [CaseStudyFiltersComponent, GroupedCaseStudiesComponent]
})
export class CaseStudiesModule { }
