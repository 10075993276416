import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subject, Observable, EMPTY } from 'rxjs';
import { filter, map, takeUntil, concatMap, catchError, tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { AT2URL } from 'src/app/shared-components/constants/miscellaneous.constants';
import { ErrorHandlingServiceService } from 'src/app/shared-components/core/error-handling-service.service';

@Component({
  selector: 'qw-app-integration',
  templateUrl: './app-integration.component.html',
  styleUrls: ['./app-integration.component.scss']
})
export class AppIntegrationComponent implements OnInit, OnDestroy {

  private appAlias: string;
  private destroyed$ = new Subject();
  app: any;
  actionAliases: any[] = [];
  triggerAliases: any[] = [];
  isAppPresent: boolean;
  appNotFound: boolean;

  journeys$ = new Observable<any[]>();
  hasJourneys = false;
  blogs$ = new Observable<any[]>();
  loadingState: 'success' | 'failure' | 'pending' = 'pending';
  journeysSliceFactor = 6;
  appsError: string;

  filterTabs = [];

  appAPIs: any[] = [];
  actionTriggersSlicingFactor = 8;

  platformURL = AT2URL;

  private appFetched = false;

  constructor(
    private actRoute: ActivatedRoute,
    private appService: AppService,
    private titleService: Title,
    private metaService: Meta,
    private errorHandlingService: ErrorHandlingServiceService
  ) { }

  ngOnInit() {
    this.actRoute.params.pipe(
      filter(params => params['app-alias']),
      map(params => params['app-alias']),
      takeUntil(this.destroyed$)
    ).subscribe(appAlias => this.appAlias = appAlias);
    this.journeys$ = this.getJourneysFromStore(this.appAlias);
    this.blogs$ = this.getBlogsFromGhost(this.appAlias);

    this.appService.getApps().pipe(
      filter(() => this.searchApp()),
      map(apps => apps[this.appAlias]),
      concatMap(app => {
        if (!this.appFetched) {
          this.app = app;
          this.setAppData();
          const storedApps = this.appService.getStoredApps();
          if (!storedApps.includes(this.appAlias)) {
            return this.getMetaApps();
          } else {
            this.setActionsAndTriggers();
            return EMPTY;
          }
        }
        return EMPTY;
      }),
      catchError(error => {
        console.error(error);
        this.app = this.appService.getAppFromAlias(this.appAlias);
        this.setAppData();
        this.loadingState = 'failure';
        return this.getMetaApps();
      }),
      takeUntil(this.destroyed$)
    ).subscribe();
  }

  getMetaApps() {
    this.loadingState = 'pending';
    return this.appService.getMetaObject(this.appAlias).pipe(
      tap(apps => {
        this.app = apps[this.appAlias];
        this.setActionsAndTriggers();
        this.appFetched = true;
      }),
      catchError((error) => {
        console.error(error);
        this.loadingState = 'failure';
        this.appsError = this.errorHandlingService.getErrorMessage(error);
        return EMPTY;
      }),
      takeUntil(this.destroyed$)
    );
  }

  getJourneysFromStore(alias) {
    return this.appService.getJourneysInStoreByAlias(alias).pipe(
      tap(journeys => this.hasJourneys = journeys.length > 0)
    );
  }

  getBlogsFromGhost(alias) {
    alias = alias.replace('_', '-');
    return this.appService.getBlogs(alias).pipe(
      map(blogs => blogs['posts'])
    );
  }

  searchApp() {
    const appAliases = this.appService.getAllAppAliases();
    if (!appAliases.includes(this.appAlias)) {
      this.loadingState = 'success';
      this.appNotFound = true;
      return false;
    }
    return true;
  }

  setAppData() {
    const appName = this.app.name;
    this.titleService.setTitle(`${appName} | Integration and Workflow Automation`);
    this.metaService.addTags([
      { name: 'description', content: `Integrate with ${appName} on Quickwork to automate business workflows across industries. Connect with 1,000s of apps and more. No code required.` },
      { name: 'robots', content: 'index, follow' }
    ]);
  }

  setActionsAndTriggers() {
    this.filterTabs = ['All'];
    if (this.app.actionCount > 0) {
      Object.keys(this.app.actions).forEach(alias => {
        this.actionAliases.push({
          type: 'action',
          alias
        });
      });
      this.filterTabs.push('Actions');
    }
    if (this.app.triggerCount > 0) {
      Object.keys(this.app.triggers).forEach(alias => {
        this.triggerAliases.push({
          type: 'trigger',
          alias
        });
      });
      this.filterTabs.push('Triggers');
    }

    this.filterActionsOrTriggers(0);
    this.loadingState = 'success';
  }

  filterActionsOrTriggers(index = 0) {
    const currentFilter = this.filterTabs[index];
    switch (currentFilter) {
      case 'Actions':
        this.appAPIs = this.actionAliases;
        break;
      case 'Triggers':
        this.appAPIs = this.triggerAliases;
        break;
      default:
        this.appAPIs = this.triggerAliases.concat(this.actionAliases);
        break;
    }
  }

  loadMoreActionsOrTriggers() {
    this.actionTriggersSlicingFactor += 8;
  }

  openIntercomBot() {
    this.appService.openIntercomBot();
  }

  refetchMetaApp() {
    this.getMetaApps().subscribe();
  }

  loadMoreJourneys() {
    this.journeysSliceFactor += 6;
  }

  superEncodeURI(url) {

    let encodedStr = '';
    const encodeChars = ['(', ')', '\\', '/'];
    url = encodeURI(url);

    for (let i = 0, len = url.length; i < len; i++) {
      if (encodeChars.indexOf(url[i]) >= 0) {
        const hex = parseInt(url.charCodeAt(i), 0).toString(16);
        encodedStr += '%' + hex;
      } else {
        encodedStr += url[i];
      }
    }

    return encodedStr;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }


}
