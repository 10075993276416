import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared-components/shared.module';
import { AppIntegrationComponent } from './app-integration.component';
import { IntegrationsComponent } from './integrations.component';



@NgModule({
  declarations: [IntegrationsComponent, AppIntegrationComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class IntegrationsModule { }
