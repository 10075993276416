import { Component, Input } from '@angular/core';

@Component({
  selector: 'qw-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  @Input() loadingText?: string = "Loading";

  constructor() { }

}
