<div id="privacy-policy-container" class="header-wave">
        <div class="main-container">
                <div class="privacy-policy">
                        <div class="m-b-20">
                                <div class="highlight-text">Privacy Policy (GDPR)</div>
                        </div>
                        <div class="policy-content">
                                <p class="m-b-10"><span class="c2">Effective date: </span><span
                                                class="c11 c18">{{ revisionDate }}</span></p>
                                <p class="m-b-10"><span class="c2">We are Quickwork Technologies Private Limited
                                                (&ldquo;</span><strong class="c9">We</strong><span class="c2">&rdquo; or
                                                &ldquo;</span><strong class="c9">Us</strong><span class="c2">&rdquo; or
                                                &ldquo;</span><strong class="c9">Our</strong><span class="c2">&rdquo; or
                                                &ldquo;</span><strong class="c9">Quickwork</strong><span
                                                class="c2">&rdquo;). This privacy policy
                                                will explain
                                                how We use and protect the personal data We collect from the
                                                user/customer/visitor
                                                (&ldquo;</span><strong class="c9">You</strong><span class="c2">&rdquo;,
                                                &ldquo;</span><strong class="c9">Your</strong><span class="c2">&rdquo;)
                                                when You use Our website or platform.
                                                (Collectively called the &ldquo;</span><strong
                                                class="c9">Portal</strong><span class="c2">&rdquo;).
                                                Capitalized terms not defined in this privacy policy will
                                                have the definition provided in Our </span><span class="c2 c37">Terms of
                                                Use available
                                                at:</span><span
                                                class="c2 c37">&nbsp;&lt;</span>
                                                <a href="/terms-of-service" target="_blank" class="c3">https://quickwork.co/terms-of-service</a><span
                                                class="c0 c37">&gt;.</span></p>
                                <p class="m-b-10"><span class="c2">We have created a Platform that provides You with access
                                                to certain
                                                products and
                                                services (&ldquo;</span><strong class="c9">Services</strong><span
                                                class="c0">&rdquo;) designed to
                                                connect
                                                applications, automate workflows and send or receive messages to/from
                                                humans and
                                                machines.</span></p>
                                <p class="m-b-30"><span class="c0">By mere use of this Portal, You expressly give Us
                                                consent to Our use and
                                                disclosure
                                                of Your personal data in accordance with this privacy policy. If You do
                                                not approve to the terms
                                                of this
                                                privacy policy, kindly do not use or access Our Portal. </span></p>
                                <p class="m-b-10"><strong class="c11 c9">Definitions</strong></p>
                                <p class="m-b-10"><span class="c0">For the purposes of this Policy, the following terms
                                                shall have the
                                                meanings set out
                                                below:</span></p>
                                <ul class="c4 m-b-30">
                                        <li class="c5 li-bullet-0"><span class="c0">&lsquo;</span><strong
                                                        class="c11 c9">personal
                                                        data</strong><span class="c0">&rsquo; means any information
                                                        relating to an identified or
                                                        identifiable natural person
                                                        (&lsquo;data subject&rsquo;); an identifiable natural person is
                                                        one who can be identified,
                                                        directly or
                                                        indirectly, in particular by reference to an identifier such as
                                                        a name, an identification
                                                        number,
                                                        location data, an online identifier or to one or more factors
                                                        specific to the physical,
                                                        physiological,
                                                        genetic, mental, economic, cultural or social identity of that
                                                        natural person</span><span class="c17">.</span></li>
                                        <li class="c5 li-bullet-0"><span class="c0">&lsquo;</span><strong
                                                        class="c11 c9">processing</strong><span class="c0">&rsquo; means
                                                        any operation or set of
                                                        operations which is performed on personal data or on
                                                        sets of personal data, whether or not by automated means, such
                                                        as collection, recording,
                                                </span><span class="c0">organisation</span><span class="c0">,
                                                        structuring, storage, adaptation
                                                        or alteration,
                                                        retrieval, consultation, use, disclosure by transmission,
                                                        dissemination or otherwise making
                                                        available,
                                                        alignment or combination, restriction, erasure or
                                                        destruction</span><span class="c17">.</span></li>
                                        <li class="c5 li-bullet-0"><span class="c0">&lsquo;</span><strong
                                                        class="c11 c9">pseudonymisation</strong><span class="c0">&rsquo;
                                                        means the processing of
                                                        personal data in such a manner that the personal data can no
                                                        longer be attributed to a specific data subject without the use
                                                        of additional information,
                                                        provided that
                                                        such additional information is kept separately and is subject to
                                                        technical and </span><span class="c0">organisational</span><span
                                                        class="c0">&nbsp;measures to ensure that the personal
                                                        data are not
                                                        attributed to an identified or identifiable natural
                                                        person;</span></li>
                                        <li class="c5 li-bullet-0"><span class="c0">&lsquo;</span><strong
                                                        class="c11 c9">controller</strong><span class="c0">&rsquo; means
                                                        the natural or legal person,
                                                        public authority, agency or other body which,
                                                        alone or jointly with others, determines the purposes and means
                                                        of the processing of
                                                        personal data;
                                                        where the purposes and means of such processing are determined
                                                        by Union or Member State law,
                                                        the
                                                        controller or the specific criteria for its nomination may be
                                                        provided for by Union or
                                                        Member State
                                                        law;</span></li>
                                        <li class="c5 li-bullet-0"><span class="c0">&lsquo;</span><strong
                                                        class="c11 c9">processor</strong><span class="c0">&rsquo; means
                                                        a natural or legal person, public authority, agency or other
                                                        body
                                                        which
                                                        processes personal data on behalf of the controller;</span></li>
                                        <li class="c5 li-bullet-0"><span class="c0">&lsquo;</span><strong
                                                        class="c11 c9">third
                                                        party</strong><span class="c0">&rsquo; means a natural or legal
                                                        person, public authority,
                                                        agency or body other than the data
                                                        subject, controller, processor and persons who, under the direct
                                                        authority of the controller
                                                        or
                                                        processor, are </span><span class="c0">authorised</span><span
                                                        class="c0">&nbsp;to process
                                                        personal
                                                        data;</span></li>
                                        <ul class="c4 lst-kix_list_18-1 start">
                                                <li class="c15 li-bullet-0"><span class="c0">&lsquo;</span><strong
                                                                class="c11 c9">consent</strong><span class="c0">&rsquo;
                                                                of the data subject means any freely given, specific,
                                                                informed and
                                                                unambiguous
                                                                indication of the data subject&#39;s wishes by which he
                                                                or she, by a statement or by a clear
                                                                affirmative
                                                                action, signifies agreement to the processing of
                                                                personal data relating to him or
                                                                her;</span></li>
                                        </ul>
                                        <li class="c5 li-bullet-0"><span class="c0">&lsquo;</span><strong
                                                        class="c11 c9">enterprise</strong><span class="c0">&rsquo; means
                                                        a natural or legal person
                                                        engaged in an economic activity, irrespective of its
                                                        legal form, including partnerships or associations regularly
                                                        engaged in an economic
                                                        activity;</span>
                                        </li>
                                        <li class="c5 li-bullet-0"><span class="c0">&lsquo;</span><strong
                                                        class="c11 c9">group of
                                                        undertakings</strong><span class="c0">&rsquo; means a
                                                        controlling undertaking and its
                                                        controlled undertakings;</span></li>
                                        <li class="c5 li-bullet-0"><span class="c0">&lsquo;</span><strong
                                                        class="c11 c9">binding corporate
                                                        rules</strong><span class="c0">&rsquo; means personal data
                                                        protection policies which are
                                                        adhered to by a
                                                        controller or processor established on the territory of a Member
                                                        State for transfers or a
                                                        set of
                                                        transfers of personal data to a controller or processor in one
                                                        or more third countries
                                                        within a group of
                                                        undertakings, or group of enterprises engaged in a joint
                                                        economic activity;</span></li>
                                        <li class="c5 li-bullet-0"><span class="c0">&lsquo;</span><strong
                                                        class="c11 c9">supervisory
                                                        authority</strong><span class="c0">&rsquo; means an independent
                                                        public authority which is
                                                        established by a Member State pursuant
                                                        to Article 51 the Regulation;</span></li>
                                        <li class="c5 li-bullet-0"><span class="c0">&lsquo;</span><strong
                                                        class="c9 c11">supervisory authority
                                                        concerned</strong><span class="c0">&rsquo; means a supervisory
                                                        authority which is concerned by
                                                        the
                                                        processing of personal data because:</span></li>
                                        <ul class="c4 lst-kix_list_18-1 start">
                                                <li class="c15 li-bullet-0"><span class="c0">the controller or processor
                                                                is
                                                                established on the
                                                                territory of the
                                                                Member State of that supervisory authority;</span></li>
                                                <li class="c15 li-bullet-0"><span class="c0">data subjects residing in
                                                                the
                                                                Member State of that
                                                                supervisory
                                                                authority are substantially affected or likely to be
                                                                substantially affected by the
                                                                processing; or</span>
                                                </li>
                                                <li class="c15 li-bullet-0"><span class="c0">a complaint has been lodged
                                                                with
                                                                that supervisory
                                                                authority;</span>
                                                </li>
                                        </ul>
                                        <li class="c5 li-bullet-0"><span class="c0">&lsquo;</span><strong
                                                        class="c11 c9">cross-border
                                                        processing</strong><span class="c0">&rsquo; means either:</span>
                                        </li>
                                        <ul class="c4 lst-kix_list_18-1 start">
                                                <li class="c15 li-bullet-0"><span class="c0">processing of personal data
                                                                which
                                                                takes place in the
                                                                context of the
                                                                activities of establishments in more than one Member
                                                                State of a
                                                                controller or processor in
                                                                the Union
                                                                where the controller or processor is established in more
                                                                than
                                                                one Member State; or</span>
                                                </li>
                                                <li class="c15 li-bullet-0"><span class="c0">processing of personal data
                                                                which
                                                                takes place in the
                                                                context of the
                                                                activities of a single establishment of a controller or
                                                                processor in the Union but which
                                                                substantially
                                                                affects or is likely to substantially affect data
                                                                subjects in
                                                                more than one Member
                                                                State.</span></li>
                                        </ul>
                                        <li class="c5 li-bullet-0"><span class="c0">&nbsp;&lsquo;</span><strong
                                                        class="c41 c45">regulation</strong><span class="c0">&rsquo;
                                                        means and refers to General Data
                                                        Protection Regulation 2016/679, as may be amended
                                                        from time to time</span></li>
                                        <li class="m-b-10"><span class="c0">&lsquo;</span><strong
                                                        class="c11 c9">Union</strong><span class="c0">&rsquo; shall
                                                        collectively mean and refer to the
                                                        member states of the European Union.</span>
                                        </li>
                                </ul>
                                <p class="m-b-10"><span class="c0">Topics covered in this policy:</span></p>
                                <ul class="c4 m-b-30">
                                        <li class="li-bullet-0"><span class="c0">What data do We collect?</span></li>
                                        <li class="li-bullet-0"><span class="c0">How do We collect Your data?</span>
                                        </li>
                                        <li class="li-bullet-0"><span class="c0">How will We use Your data?</span>
                                        </li>
                                        <li class="li-bullet-0"><span class="c0">How do We store Your data?</span>
                                        </li>
                                        <li class="li-bullet-0"><span class="c0">Marketing</span></li>
                                        <li class="li-bullet-0"><span class="c0">Transfer of Data</span></li>
                                        <li class="li-bullet-0"><span class="c0">What are Your data protection
                                                        rights?</span></li>
                                        <li class="li-bullet-0"><span class="c0">What are cookies?</span></li>
                                        <li class="li-bullet-0"><span class="c0">How do We use cookies?</span></li>
                                        <li class="li-bullet-0"><span class="c0">What types of cookies do We
                                                        use?</span></li>
                                        <li class="li-bullet-0"><span class="c0">How to manage Your cookies?</span>
                                        </li>
                                        <li class="li-bullet-0"><span class="c0">Privacy policies of other
                                                        Websites</span></li>
                                        <li class="li-bullet-0"><span class="c0">Changes to Our privacy policy</span>
                                        </li>
                                        <li class="li-bullet-0"><span class="c0">How to contact Us</span>
                                        </li>
                                        <li class="li-bullet-0"><span class="c2">How to contact the appropriate
                                                        authorities</span>
                                        </li>
                                </ul>
                                <p class="m-b-10"><strong class="c11 c9">What data do We collect?</strong></p>
                                <p class="c1"><span class="c0">Quickwork collects the following personal data:</span>
                                </p>
                                <ul class="m-b-30">
                                        <li class="c5 li-bullet-0"><span class="c0">Identity: Personal Data about You
                                                        and Your identity,
                                                        such as Your
                                                        First name, Last Name,</span></li>
                                        <li class="c5 li-bullet-0"><span class="c0">Contact Information Data: Personal
                                                        Data used to contact
                                                        an
                                                        individual like email address, </span></li>
                                        <li class="c5 li-bullet-0"><span class="c0">Feedback Data and Other Data: This
                                                        includes the
                                                        following:</span>
                                        </li>
                                        <li class="c5 li-bullet-0"><span class="c17">If You call Our call </span><span
                                                        class="c17">centre</span><span class="c0">/office, We may record
                                                        information provided by You
                                                        to service You or record the calls for
                                                        quality and training purposes;</span></li>
                                        <li class="c5 li-bullet-0"><span class="c17">If You provide any feedback or
                                                        comments to Us on mail,
                                                        chat or the
                                                        Portal, we may &nbsp;store the mail , chat or comments for
                                                        quality purpose</span></li>
                                </ul>
                                <p class="m-b-10"><strong class="c11 c9">How do We collect Your data?</strong></p>
                                <p class="m-b-10"><span class="c0">We collect data in the following manner: </span></p>
                                <ul class="m-b-30">
                                        <li class="c20 li-bullet-0"><span class="c2 c10">Data We collect from
                                                        You</span><span class="c0">:
                                                        We gather
                                                        personal data from You directly, for instance, when You input
                                                        information into a form online
                                                        or You
                                                        contact Us directly. Below are the examples of how We get this
                                                        information:</span></li>
                                        <ul class="c4 lst-kix_list_1-0 start">
                                                <li class="c7 li-bullet-0"><span class="c0">When You fill Our form for
                                                                registration and account
                                                                opening.</span>
                                                </li>
                                                <li class="c7 li-bullet-0"><span class="c0">When You enter or edit
                                                                details of
                                                                Your profile.</span>
                                                </li>
                                        </ul>
                                        <li class="c5 c23 li-bullet-0"><span class="c2 c10">Data We receive from
                                                        others: </span><span class="c2">We
                                                        receive personal data from third parties with
                                                        whom We have a relationship, for example, Our customers, third
                                                        party service providers,
                                                </span><span class="c17">dealers</span><span class="c0">, third-party
                                                        applications or linked
                                                        websites. </span></li>
                                        <li class="c5 c23 li-bullet-0"><span class="c2 c10">Data collected
                                                        automatically: </span><span class="c2"></span><span
                                                        class="c38">We may collect certain Personal Data automatically
                                                        as part of
                                                        Your use of Our Portal. To know more please read our Cookie
                                                        Policy available at:
                                                </span>
                                                <span class="c37 c38">&lt;
                                                        <a href="/cookie-policy" target="_blank">
                                                                https://quickwork.co/cookie-policy
                                                        </a>
                                                        &gt;</span>
                                        </li>
                                </ul>
                                <p class="m-b-10"><strong class="c11 c9">How do We use Your data?</strong></p>
                                <ul class="m-b-30">
                                        <li class="c1 c34 c36 li-bullet-0"><span class="c17">To the extent that
                                                        Quickwork is a Processor for
                                                        Customer of
                                                        Personal Data that is subject to the European Union General Data
                                                        Protection Regulation and
                                                        for which the
                                                        Customer is the Controller or Processor for their controller
                                                        (each as defined in the DPA),
                                                        the EU Data
                                                        Processing Addendum located at </span><a href="/legal/dpa" target="_blank"
                                                        class="c3">https://quickwork.co/legal/dpa
                                                </a><span class="c17">(the &ldquo;DPA&rdquo;) is hereby incorporated
                                                        into the Agreement with
                                                        respect to the
                                                        processing of such Personal Data.</span></li>
                                        <li class="c5 c23 li-bullet-0"><span class="c17">We will process identity data
                                                        and contact data when
                                                        You
                                                        register and open an account with </span><span class="c17">US
                                                        for the</span><span class="c0">&nbsp;access of Our Platform. We
                                                        use this information as may be necessary for the
                                                        creation,
                                                        operation, and maintenance of the account and as may be
                                                        necessary to fulfill Our contractual
                                                        obligations
                                                        with You, and related operations.</span></li>
                                        <li class="c5 c23 li-bullet-0"><span class="c0">We may use Personal Data to
                                                        contact You and keep You
                                                        posted on
                                                        Our latest announcements, upcoming events, including
                                                        confirmations, security alerts and
                                                        support and
                                                        administrative messages. It also helps Us to improve Our
                                                        Services. If You do not want to be
                                                        on Our
                                                        mailing list, You can unsubscribe or write to us at
                                                        <a href="mailto:support@quickwork.co">support@quickwork.co</a>
                                                </span></li>
                                        <li class="c5 c23 li-bullet-0"><span class="c0">When You contact Us through the
                                                        Portal, We process
                                                        certain
                                                        Personal Data such as identity data, contact data that You may
                                                        provide Us. We use this data
                                                        to
                                                        communicate with You about the subject matter of Your request
                                                        and related matters, which
                                                        includes
                                                        subscriptions, billing, account information, newsletter, payment
                                                        confirmations, etc.</span>
                                        </li>
                                        <li class="c5 c23 li-bullet-0"><span class="c17">From time to time, We may use
                                                        Your Personal Data to
                                                        send
                                                        important notices, such as communications and changes to Our
                                                        terms, conditions and policies
                                                        and other
                                                        documentation. Since this information is important to Your
                                                        interaction with Us, You cannot
                                                        opt out of
                                                        receiving these communications.</span></li>
                                </ul>
                                <p class="m-b-10"><strong class="c11 c9">Our Disclosure of Your personal data and other
                                                information:</strong></p>
                                <p class="m-b-10"><span class="c2">We may share Your personal data with certain third
                                                parties without
                                                further
                                                notice to You, as set forth below:</span></p>
                                <ul class="m-b-30">
                                        <li class="c5 c14 li-bullet-0"><span class="point-ul">Business Deals and other
                                                        Corporate
                                                        Actions</span><span class="c0">: In the event of a business deal
                                                        or other corporate events
                                                        including but not limited to
                                                        transfer of business, corporate sale, merger, reorganization,
                                                        dissolution , personal data
                                                        will most
                                                        likely be part of the transferred assets and We may disclose
                                                        personal data occurring in the
                                                        due
                                                        diligence process.</span></li>
                                        <li class="c5 c14 li-bullet-0"><span class="point-ul">Subsidiaries and Related
                                                        Companies</span><span class="c0">:,
                                                        We may share Your personal data with any of Our current or
                                                        future affiliated entities,
                                                        subsidiaries, and
                                                        parent companies if so needed in order to streamline Our
                                                        Services.</span></li>
                                        <li class="c5 c14 li-bullet-0"><span class="point-ul">Sub-processors and other
                                                        Service
                                                        Providers</span><span class="c2">: In order to conduct Our
                                                        general business operations,
                                                        enable features and process certain
                                                        tasks and in connection with Our other lawful business
                                                        interests, We may share personal data
                                                        with
                                                        service providers or sub-processors who perform certain services
                                                        or process data on Our
                                                        behalf e.g.
                                                        payment processing and cloud service. While employing such other
                                                </span><span class="c17">entities</span><span class="c0">&nbsp;to
                                                        perform a function of this kind, We
                                                        only provide
                                                        them with the information that they need to perform their
                                                        specific function.</span>
                                                        <br>
                                                        <br>

                                                        <table class="c38" border="1">
                                                                <tbody>
                                                                    <tr class="c42">
                                                                        <th class="c35" colspan="1" rowspan="1">
                                                                            <p class="c4"><strong class="c19">Sub-Processor</strong></p>
                                                                        </th>
                                                                        <th class="c18" colspan="1" rowspan="1">
                                                                            <p class="c4"><strong class="c19">Purpose</strong></p>
                                                                        </th>
                                                                    </tr>
                                                                    <tr class="c17">
                                                                        <td class="c1" colspan="1" rowspan="1">
                                                                            <p class="c4"><span class="c7">AWS</span></p>
                                                                        </td>
                                                                        <td class="c18" colspan="1" rowspan="1">
                                                                            <p class="c4"><span class="c7">Cloud Infrastructure for our platform</span></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr class="c40">
                                                                        <td class="c35" colspan="1" rowspan="1">
                                                                            <p class="c4"><span class="c7">Stripe, USA</span></p>
                                                                        </td>
                                                                        <td class="c18" colspan="1" rowspan="1">
                                                                            <p class="c4"><span class="c7">Customer payments</span></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr class="c41">
                                                                        <td class="c35" colspan="1" rowspan="1">
                                                                            <p class="c4"><span class="c7">Google, USA</span></p>
                                                                        </td>
                                                                        <td class="c18" colspan="1" rowspan="1">
                                                                            <p class="c4"><span class="c7">Platform analytics (web) and customer interactions
                                                                                    (email)</span></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr class="c17">
                                                                        <td class="c35" colspan="1" rowspan="1">
                                                                            <p class="c4"><span class="c7">Intercom</span></p>
                                                                        </td>
                                                                        <td class="c18" colspan="1" rowspan="1">
                                                                            <p class="c4"><span class="c7">Customer Support and Lead Interaction</span></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr class="c17">
                                                                        <td class="c35" colspan="1" rowspan="1">
                                                                            <p class="c4"><span class="c7">Zoho CRM</span></p>
                                                                        </td>
                                                                        <td class="c18" colspan="1" rowspan="1">
                                                                            <p class="c4"><span class="c7">For Lead Management</span></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr class="c17">
                                                                        <td class="c35" colspan="1" rowspan="1">
                                                                            <p class="c4"><span class="c7">Zendesk</span></p>
                                                                        </td>
                                                                        <td class="c18" colspan="1" rowspan="1">
                                                                            <p class="c4"><span class="c7">For capturing all Support queries</span></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr class="c17">
                                                                        <td class="c35" colspan="1" rowspan="1">
                                                                            <p class="c4"><span class="c7">Facebook Pixels</span></p>
                                                                        </td>
                                                                        <td class="c18" colspan="1" rowspan="1">
                                                                            <p class="c4"><span class="c7">Retargeting for advertisments</span></p>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                            <br>
                                                
                                                </li>
                                        <li class="c5 c14 li-bullet-0"><span class="point-ul">Legal
                                                        Requirements</span><span class="c0">: We
                                                        may disclose
                                                        personal data if essential to do so by law such as to conform
                                                        with a subpoena, bankruptcy
                                                        proceedings,
                                                        or similar legal process or in the good faith belief that such
                                                        action is obligatory to (i)
                                                        comply with a
                                                        legal obligation or request, (ii) protect and shield the rights
                                                        or property of Quickwork,
                                                        (iii) act in
                                                        urgent/emergency conditions to protect the security or vital
                                                        interests of an individual or
                                                        the public,
                                                        or (iv) protect against legal liability. </span></li>
                                        <li class="c5 c14 li-bullet-0"><span
                                                        class="point-ul">Consent/direction</span><span class="c0">. We
                                                        may disclose
                                                        Your personal information to any other third party with Your
                                                        prior consent or as You
                                                        direct.</span></li>
                                        <li class="c5 c14 li-bullet-0"><span class="point-ul">Data Sale</span><span
                                                        class="c2">: We do not
                                                        sell Your
                                                        personal data.</span></li>
                                </ul>
                                <p class="m-b-10"><strong class="c41 c45">How do We Store Your data?</strong></p>
                                <p class="m-b-10"><span class="c2">Quickwork securely stores Your data at </span><span
                                                class="c0">&nbsp;AWS
                                                Mumbai ,
                                                India. The following are the technical and organizational measures we
                                                implement.</span></p>
                                <p class="m-b-10"><span class="c0">Quickwork
                                                maintains an ISMS Policy approved and
                                                reviewed by
                                                Management</span></p>
                                <p class="m-l-20 m-b-10"><span class="c0">Personal Data in
                                                transition is protected by SSL TLS
                                                1.2+ with SSL
                                                certificates using SHA-256 with RSA
                                                Encryption provided by Amazon
                                                Certificate
                                                Manager.
                                                Intercommunication between microservices
                                                encrypted using mutual TLS via mesh
                                                management
                                                service.</span></p>
                                <p class="m-l-20 m-b-10"><span class="c0">Personal Data at rest
                                                is stored in Mongo Enterprise
                                                Database,
                                                volume level and item level encrypted
                                                using individual encryption keys via AWS
                                                KMS.</span>
                                </p>
                                <p class="m-l-20 m-b-10"><span class="c0">Data in memory: Each
                                                process in Quickwork is managed
                                                within
                                                containers, which have their own
                                                independent resources(volumes, memory
                                                and CPU).
                                                The logs of
                                                these processes are synced with a
                                                centralized logging system.</span></p>
                                <p class="m-l-20 m-b-10"><span class="c0">Has implemented
                                                anti-malware on its systems
                                                processing personal
                                                data.</span></p>
                                <p class="m-l-20 m-b-10"><span class="c0">Employees receive
                                                regular security and privacy
                                                training regarding
                                                the treatment and protection of Personal
                                                Data.</span></p>
                                <p class="m-l-20 m-b-10"><span class="c0">Ensures VAPT done by
                                                certified third parties to test
                                                security and
                                                identify vulnerabilities in the
                                                system.</span></p>
                                <p class="m-l-20 m-b-10"><span class="c0">Is ISO 27001
                                                certified and ensures regular audits in
                                                line with
                                                this standard.</span></p>
                                <p class="m-l-20 m-b-20"><span class="c0">Ensures that access
                                                to information systems is
                                                restricted to
                                                authorized employees only.</span></p>
                                <p class="m-b-30"><span class="c0">If You are a customer, We may retain Your information
                                                for as long as
                                                Your account is
                                                active or as required to deliver You Services, maintain accurate
                                                accounting, financial and other
                                                operational
                                                records, comply with Our legal obligations, resolve disputes and enforce
                                                our agreements. We have
                                                established
                                                internal policies for the deletion of data from customer accounts
                                                following termination of a
                                                customer&rsquo;s subscription to the Platform.</span></p>
                                <p class="m-b-10"><strong class="c11 c9">Transfer of Data</strong></p>
                                <p class="m-b-10"><span class="c0">Your information, including personal data, may be moved
                                                to and preserved
                                                on
                                                computers situated outside of your state, province, country or other
                                                governmental jurisdiction
                                                where the
                                                data protection laws may vary than those from your jurisdiction.</span>
                                </p>
                                <p class="m-b-30"><span class="c2">A prudent degree of safeguard mechanism is in place to
                                                ensure the safety
                                                of data
                                                while it is being transferred. We ensure that compliance with applicable
                                                data protection laws is
                                                strictly
                                                followed. Your consent to this privacy policy followed by your
                                                submission of data represents
                                                your agreement
                                                to that transfer.</span></p>
                                <p class="m-b-10"><strong class="c9">Marketing</strong></p>
                                <p class="m-b-30"><span class="c17">If You have subscribed to receive any marketing
                                                communication from
                                                Quickwork, and
                                                You do not wish to continue, You can unsubscribe by clicking on the
                                                &quot;Unsubscribe&quot; link
                                                in the
                                                communication or write to us at 
                                                <a href="mailto:support@quickwork.co">support@quickwork.co</a>
                                        </span></p>
                                <p class="m-b-10"><strong class="c9">What are your Data Protection Rights</strong></p>
                                <p class="m-b-10"><span class="c0">We value Your trust &amp; respect Your privacy.
                                                Quickwork would like to
                                                make sure
                                                You are fully aware of all of Your data protection rights. Every user is
                                                entitled to the
                                                following:</span>
                                </p>
                                <ul class="m-b-10">
                                        <li class="c5 li-bullet-0"><strong class="c9">The right to access</strong><span
                                                        class="c0">: You have
                                                        the right to
                                                        request Quickwork for copies of Your personal data. We may
                                                        charge You a small fee for this
                                                        service.</span></li>
                                        <li class="c5 li-bullet-0"><strong class="c9">The right to
                                                        rectification</strong><span class="c0">: You
                                                        have the
                                                        right to request that Quickwork correct any information You
                                                        believe is inaccurate. You also
                                                        have the
                                                        right to request Quickwork to complete the information You
                                                        believe is incomplete.</span>
                                        </li>
                                        <li class="c5 li-bullet-0"><strong class="c9">The right to erasure</strong><span
                                                        class="c0">: You have
                                                        the right to
                                                        request that Quickwork erase Your personal data, under certain
                                                        conditions.</span></li>
                                        <li class="c5 li-bullet-0"><strong class="c9">The right to restrict
                                                        processing</strong><span class="c0">: You have
                                                        the right to request that Quickwork restrict the processing of
                                                        Your personal data.</span>
                                        </li>
                                        <li class="c5 li-bullet-0"><strong class="c9">The right to object to
                                                        processing</strong><span class="c0">: You have
                                                        the right to object to Quickwork processing of Your personal
                                                        data.</span></li>
                                        <li class="c5 li-bullet-0"><strong class="c9">The right to withdraw
                                                        consent.</strong><span class="c0">&nbsp;You also
                                                        have the right to withdraw your consent at any time where
                                                        Quickwork relied on your consent
                                                        to process
                                                        your personal data.</span></li>
                                        <li class="m-b-10"><strong class="c9">The right to data
                                                        portability:</strong><span class="c0">&nbsp;You have the right
                                                        to
                                                        request that Quickwork transfer the data that we have
                                                        collected
                                                        to another organization, or directly to you, in structured,
                                                        commonly used and machine
                                                        readable
                                                        formats.</span></li>
                                </ul>
                                <p class="m-b-10"><span class="c2">If You make a request, </span><span class="c2 c23">We
                                                have one month to
                                                respond to
                                                You.</span><span class="c0">&nbsp;If You would like to exercise any of
                                                these rights, please
                                                contact Us at
                                        </span></p>
                                <p class="m-b-10"><span class="c2 c23">Our email: &lt;</span>
                                        <a href="mailto:support@quickwork.co">support@quickwork.co</a>
                                        <span
                                                class="c0 c23">&gt;</span></p>
                                <p class="m-b-10"><span class="c2 c23">Or write to Us: &lt;</span><span class="c17 c23">404,
                                                Matharu Arcade,
                                                Subhash
                                                Road, Vile Parle (East), Mumbai 400057,India</span><span
                                                class="c0 c23">&gt;</span></p>
                                <p class="m-b-30"><span class="c0">Moreover, You have the right to complain to a Data
                                                Protection Authority
                                                about our
                                                collection and use of your personal data. For more information, please
                                                contact your local data
                                                protection
                                                authority in the European Economic Area (EEA).</span></p>
                                
                                <p class="m-b-10"><strong class="c9">Cookies</strong></p>
                                <p class="m-b-30"><span class="c2">The cookies on the Portal shall be governed by the
                                                cookie policy of the
                                                company as
                                                available on </span><a href="/cookie-policy" target="_blank"
                                                class="c3">https://quickwork.co/cookie-policy</a></p>
                                
                                <p class="m-b-10"><strong class="c11 c9">Privacy policies of other websites</strong></p>
                                <p class="m-b-30"><span class="c17">Quickworks Privacy Policy is applicable only for
                                                Quickwork Portal.
                                                Quickwork
                                                Portal provides links to other websites and in case you choose to click
                                                on the link to visit the
                                                other
                                                website, we strongly recommend that you read the relevant websites&#39;
                                                Privacy Policy and
                                                proceed
                                                further.</span></p>
                                
                                <p class="m-b-10" id="h.gjdgxs"><strong class="c11 c9">Changes to Our privacy
                                                policy</strong>
                                </p>
                                <p class="m-b-30"><span class="c2">Quickwork keeps its privacy policy under regular review
                                                and places any
                                                updates on
                                                this web page. We encourage You to review this privacy policy regularly
                                                for any such changes.
                                        </span><span class="c17">Please refer to the header of this document for the
                                                date it was last
                                                updated</span></p>
                                <p class="m-b-10"><strong class="c41 c45">How to contact Us</strong></p>
                                <p class="m-b-10"><span class="c0">If You have any questions about this privacy policy, the
                                                data We hold on
                                                You, or You
                                                would like to exercise one of Your data protection rights, please do not
                                                hesitate to contact
                                                Us.</span></p>
                                <p class="m-b-10"><span class="c2">Email Us at: </span>
                                        <a href="mailto:support@quickwork.co">support@quickwork.co</a></p>
                                <p class="m-b-10"><span class="c2">Or write to Us at:&lt;</span><span class="c3">404,
                                                Matharu Arcade,
                                                Subhash Road, Vile
                                                Parle (East), Mumbai 400057,India</span><span class="c0">&gt;</span></p>
                        </div>
                </div>
        </div>
</div>