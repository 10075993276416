<div class="customers">
    <ng-container *ngIf="flipAnimation; else normalTemplate">
        <ng-container *ngFor="let item of customers; let i = index;">
            <div class="customer flip-animation" *ngIf="i < 8">
                <div class="customer-logo-container" #customerImage>
                    <div class="customer-logo front">
                        <img src="../../../assets/images/{{path}}{{item.image}}" alt="{{item.label}}" width="100%" height="100%">
                    </div>
                    <div class="customer-logo back">
                        <img src="../../../assets/images/{{path}}{{customers[7 + i + 1].image}}" alt="{{item.label}}" width="100%" height="100%">
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #normalTemplate>
        <ng-container *ngFor="let item of customers">
            <div class="customer">
                <img src="../../../assets/images/{{path}}{{item.image}}" alt="{{item.label}}" width="100%" height="100%">
            </div>
        </ng-container>
    </ng-template>
</div>