<div class="partnerships-modal-form">
  <div class="partnerships-form">
    <form #partnerForm="ngForm" (submit)="submitPartnerForm(partnerForm)" autocomplete="off">
      <div class="partner-form-inputs">
        <div class="partner-form-group">
          <label>Name</label>
          <div class="partner-input-group">
            <input type="text" class="partner-form-input" placeholder="John Doe" name="name" id="name" #name="ngModel"
              ngModel (ngModelChange)="fieldValueChange('name', name.value)" [pattern]="whiteSpacePattern" required>
            <ng-container *ngIf="name.invalid && (name.dirty || name.touched)">
              <ng-container *ngIf="name.errors">
                <ng-container *ngIf="name.errors.required || name.errors.pattern">
                  <span class="form-error">Name is required</span>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="partner-form-group">
          <label>Email Address</label>
          <div class="partner-input-group">
            <input type="email" class="partner-form-input" placeholder="john@acme.com" name="emailAddress"
              id="emailAddress" #email="ngModel" ngModel (ngModelChange)="fieldValueChange('email', email.value)" email
              required>
            <ng-container *ngIf="email.invalid && (email.dirty || email.touched)">
              <ng-container *ngIf="email.errors">
                <ng-container *ngIf="email.errors.required">
                  <span class="form-error">Email is required</span>
                </ng-container>
                <ng-container *ngIf="email.errors.invalid">
                  <span class="form-error">Email is invalid</span>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="partner-form-group">
          <label>Phone Number</label>
          <div class="partner-input-group">
            <input type="text" class="partner-form-input" placeholder="+91 888888888" name="phoneNumber"
              id="phoneNumber" #phone="ngModel" ngModel (ngModelChange)="fieldValueChange('phone', phone.value)"
              required [pattern]="phoneNoPattern">
            <ng-container *ngIf="phone.invalid && (phone.dirty || phone.touched)">
              <ng-container *ngIf="phone.errors">
                <ng-container *ngIf="phone.errors.required">
                  <span class="form-error">Phone Number is required</span>
                </ng-container>
                <ng-container *ngIf="phone.errors.pattern">
                  <span class="form-error">Phone Number is invalid</span>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="partner-form-group">
          <label>Company Name</label>
          <div class="partner-input-group">
            <input type="text" class="partner-form-input" placeholder="Acme Inc" name="companyName" id="companyName"
              #company="ngModel" ngModel (ngModelChange)="fieldValueChange('company', company.value)" [pattern]="whiteSpacePattern" required>
            <ng-container *ngIf="company.invalid && (company.dirty || company.touched)">
              <ng-container *ngIf="company.errors">
                <ng-container *ngIf="company.errors.required || company.errors.pattern">
                  <span class="form-error">Company Name is required</span>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <p class="color-green m-t-10" *ngIf="submitState === 'success'">Thank you! Your submission has been sent</p>
      <div class="partner-form-buttons">
        <button type="submit" id="partner-form-submit" class="button plain color-white"
          [disabled]="partnerForm.invalid || submitState === 'pending'">
          Apply
          <ng-container *ngIf="submitState === 'pending'">
            <i class="fad fa-circle-notch fa-spin"></i>
          </ng-container>
        </button>
      </div>
    </form>
    <div class="submit-error" *ngIf="submitError && submitState === 'failure'">
      <p class="color-red error-title">{{ submitError.title }}</p>
      <p class="color-red error-message">{{ submitError.message }}</p>
    </div>
  </div>
</div>