import { trigger, transition, style, animate, group, animateChild, query } from '@angular/animations';

export const modalEnterExitDuration = 300;

export const modalBackdropFadeInFadeOut = trigger('modalBackdropFadeInFadeOut', [
    transition(':enter', [
        group([
            query('@modalBodyEnterExit', animateChild())
        ]),
        style({ opacity: 0 }), animate(200, style({ opacity: 1 }))]
    ),
    transition(':leave',
        [
            group([
                query('@modalBodyEnterExit', animateChild())
            ]),
            style({ opacity: 1 }), animate(200, style({ opacity: 0 }))
        ]
    )
]);

export const modalBodyEnterExit = trigger('modalBodyEnterExit', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        animate(modalEnterExitDuration, style({ opacity: 1, transform: 'translateY(0)' })),
    ]),
    transition(':leave', [
        animate(modalEnterExitDuration, style({ opacity: 0, transform: 'translateY(10px)' })),
    ]),
]);

export const modalSlideInOut = trigger('fadeSlideInOut', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        animate(modalEnterExitDuration, style({ opacity: 1, transform: 'translateY(0)' })),
    ]),
    transition(':leave', [
        animate(modalEnterExitDuration, style({ opacity: 0, transform: 'translateY(10px)' })),
    ]),
]);
