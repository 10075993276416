<div class="app-search-box" (mouseenter)="setInsideDropdown(true)" (mouseleave)="setInsideDropdown(false)"
    (click)="showCategories()">
    <div class="app-search">
        <div class="app-search-input">
            <ng-container *ngTemplateOutlet="currencyTemplate; context: { currency: selectedCurrency }"></ng-container>
        </div>
        <i class="fas fa-caret-down" [class.rotate]="toggleDropdown"></i>
    </div>
    <div class="pricing-currencies-container" *ngIf="toggleDropdown" [@fadeEnterExit]>
        <div class="pricing-currencies">
            <ng-container *ngFor="let currency of currencies">
                <div class="pricing-currency" [class.active]="currency.unit === selectedCurrency.unit"
                    (click)="selectCurrency(currency);$event.stopPropagation()">
                    <ng-container *ngTemplateOutlet="currencyTemplate; context: { currency: currency }"></ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</div>


<ng-template #currencyTemplate let-currency="currency">
    <div class="pricing-currency-content">
        <div class="pricing-currency-flag">
            <img src="../../../../assets/images/currencyImages/{{currency.uppercaseUnit}}.svg" alt="" srcset="">
        </div>
        <div class="pricing-currency-name">
            {{ currency.uppercaseUnit }}
        </div>
    </div>
</ng-template>