import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';

@Pipe({
  name: 'appName'
})
export class AppNamePipe implements PipeTransform {

  constructor(private appService: AppService) {}

  transform(alias: string, ...args: any[]): any {

    return this.appService.getAppByAlias(alias).pipe(
      map(app => app.name)
    );
  }

}
